import { Component, Input, OnInit } from '@angular/core';
import { ControlBase } from '../controls-base';
import { FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DateInputComponent implements OnInit {
  @Input() meta!: ControlBase;
  @Input() form!: FormGroup;
  @Input() formMarkAsTouched!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  get selfControl() {
    return this.form.controls[`${this.meta.fieldId}`];
  }

}
