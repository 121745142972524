<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px md:max-h-max
md-landscape:w-528px md-landscape:max-h-max
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px">
  <p class="relative uppercase font-Lato-Regular font-semibold
    us:text-sm us:tracking-1.75px
    lg-landscape:text-sm lg-landscape:tracking-1.75px">{{'ACTIVATE_WALLET' | translate}}</p>
  <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
  <form class="relative w-full grid
                us:grid-cols-1 us:gap-y-6 us:mt-6
                us-landscape:grid-cols-1 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-1 md:gap-x-1.5 md:mt-10
                 lg-landscape:grid-cols-1 lg-landscape:gap-x-6px lg-landscape:mt-5" [formGroup]="termsForm">
    <div class="relative w-full">
      <div class="relative w-full pt-4">
        <input
          class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
          type="checkbox" formControlName="terms_cond">
        <label class="relative font-Lato-Regular text-sm lg-landscape:ml-3" for="">
          Acepto los
          <a target="_blank" routerLink="/contents/terms" class="relative text-blue-#18396C underline font-Lato-Regular font-bold capitalize us:text-xs lg-landscape:text-sm lg-landscape:leading-loose">
            {{'TERMS_CONDITIONS' | translate}}
          </a>
          y
          <a target="_blank" routerLink="/customer/affidavit" class="relative font-Lato-Regular text-blue-#18396C underline font-bold capitalize us:text-xs lg-landscape:text-sm lg-landscape:leading-loose">
            {{'AFFIDAVIT_CERTIFICATION' | translate }}
          </a>
        </label>
      </div>
    </div>
  </form>

  <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-5 lg-landscape:justify-end">
    <button class="relative bg-yellow-#FFC35D flex items-center
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button"
            [ngClass]="{'opacity-50': (termsForm.invalid || isSaving || !termsConditions), 'cursor-pointer': (termsForm.valid && termsConditions && !isSaving)}"
            [disabled]="termsForm.invalid || isSaving || !termsConditions" (click)="onSubmit()">
      <fa-icon class="relative
            us:w-22% us:ml-2
         lg-landscape:w-22% lg-landscape:ml-2" [icon]="faWallet">
      </fa-icon>
      <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         lg-landscape:text-15px lg-landscape:leading-4">{{'ACTIVATE' | translate}}</p>
      <fa-icon *ngIf="isSaving"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>

    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
            mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'LATER' | translate}}</p>
    </button>
  </div>
</section>
