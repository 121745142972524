import { Component, OnInit } from '@angular/core';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-about-katapulk',
  templateUrl: './about-katapulk.component.html',
  styleUrls: ['./about-katapulk.component.scss']
})
export class AboutKatapulkComponent implements OnInit {
  TITLE: any = null;
  ABOUT: any = null;

  constructor(private languageService: LanguageService, private dynamicService: DynamicCOnfigService) { }

  ngOnInit(): void {
    window.scroll({ top: 0 });
    this.languageService.selectedLanguage.subscribe(() => {
      setTimeout(() => {
        this.getDynamicSettings();
      });
    })
  }

  getDynamicSettings() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es'; // this.translateService.getBrowserLang();
      this.getGLobalConfig(language, 'about-us');
    } else {
      this.getGLobalConfig(language, 'about-us');
    }
  }

  getGLobalConfig(lang: string, content_key: string) {
    let TERMS_VARIABLES: any = [];
    let QUERY: any = null;
    const VARIABLES: any = { locale: lang, contentKey: content_key };
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      JSON.parse(aux)?.FRONT_SETTINGS?.data[0]?.attributes?.query_discoveries?.data?.forEach((query: any) => {
        if (query?.attributes?.Key == 'contents') {
          query?.attributes?.Query?.Variables.map((element: any) => {
            TERMS_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
          });
          TERMS_VARIABLES.push({ key: 'query_discovery_key', value: 'contents' });
          QUERY = query?.attributes?.Query?.Uri;
          this.dynamicService.doDynamicQuery(QUERY, TERMS_VARIABLES)
            .subscribe((data: any) => {
              this.TITLE = data?.data[0]?.attributes?.Title;
              this.ABOUT = data?.data[0]?.attributes?.Content;
            });
        }
      });
    }
  }
}
