<section class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5">
    <div class="relative overflow-hidden flex justify-between
    us:p-4
    xl:w-1291px xl:pl-60px xl:pr-60px xl:pt-7">
        <div class="flex flex-col flex-1 gap-10
       md:flex-row md:gap-12">
            <div class="flex flex-col flex-1">
                <div *ngIf="paymentMethodConfiguration?.userMessage && paymentMethodConfiguration?.userMessage !== ''"
                    class="flex bg-gray-#D4E1EE w-full min-h-40px items-center">
                    <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full
                text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning">
                    </fa-icon>
                    <p class="pt-2 py-2 w-full font-Lato-Regular md:text-lg text-gray-#5d5d5d leading-4 break-words
                        us:text-15px">{{paymentMethodConfiguration?.userMessage}}</p>
                </div>

                <p class="relative font-Lato-Regular font-semibold text-gray-#363636
            text-19px">
                    {{ 'PAYMENT' | translate }}
                </p>
              <div *ngIf="!user?.useNewPayment">
                <p class="relative font-Lato-Regular leading-4 text-gray-#727272 mt-1">
                  {{ 'ALL_TRANSACTIONS_ARE_SECURE_AND_ENCRYPTED' | translate }}
                </p>


                <!-- tipos de pago disponibles    -->
                <div class="relative mt-3 w-full min-h-40px">
                  <div class="relative w-full flex flex-col gap-0.5">
                    <div class="relative w-full
                  us:grid us:grid-cols-2 us:gap-5 us:items-center us:justify-items-center
                  landscape:flex landscape:flex-row landscape:gap-2 landscape:ml-0 animate-pulse"
                         *ngIf="isLoadingPaymentMethodConfiguration">
                      <div class="relative bg-white flex flex-1 text-center justify-center items-center h-24 w-44 px-3 min-w-fit border border-gray-400 rounded"
                           *ngFor="let pmc of [1,2]">

                        <fa-icon class="absolute bg-white text-gray-300 left-1 top-0"
                                 [icon]="faCheckCircle"></fa-icon>
                        <div class="relative w-full flex flex-row justify-center items-center">
                                    <span class="relative flex flex-col gap-1 items-center py-3 w-20">
                                        <img class="relative w-8 h-8 bg-gray-300 rounded" />
                                        <span class="relative bg-gray-300 font-Lato-Regular w-20 h-4 rounded"></span>
                                    </span>
                        </div>
                        <span
                          class="absolute right-0 top-0 text-white text-center text-sm rounded-l px-1 bg-gray-300 w-12 h-5"></span>
                      </div>
                    </div>
                    <!--        <p  *ngIf="isLoadingPaymentVariantFee" class="relative text-left font-Lato-Regular text-gray-#5d5d5d-->
                    <!--                us:text-13px us:font-medium mt-4 h-4 animate-pulse bg-gray-300-->
                    <!--                lg-landscape:text-sm lg-landscape:font-medium ">-->
                    <!--        </p>-->

                    <!-- <div *ngIf="isLoadingPaymentMethodConfiguration" class="flex flex-col justify-between items-center bg-white border border-gray-400 rounded px-6 pb-4 animate-pulse mt-7
                us:h-36
                lg:flex-row lg:h-32 lg:w-1/2 xl:w-auto lg:pb-0">
                <div class="h-14 w-60 bg-gray-300 rounded mt-2 mb-2"></div>
                <div
                    class="flex flex-1 flex-col font-Lato-Regular h-full justify-center items-end gap-2 animate-pulse">
                    <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
                    <div class="flex">
                        <div class="relative w-40 h-8 rounded bg-gray-300"></div>
                    </div>
                    <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
                </div>
              </div> -->
                  </div>

                  <div class="relative w-full flex flex-col gap-0.5" *ngIf="!isLoadingPaymentMethodConfiguration">
                    <div class="relative w-full us:grid us:grid-cols-2 us:gap-5 us:items-center us:justify-items-center
                  landscape:flex landscape:flex-row landscape:gap-2 landscape:ml-0">
                      <div class="relative bg-white flex flex-1 text-center justify-center items-center h-24 w-44 px-3 min-w-fit border border-gray-400 rounded cursor-pointer"
                           *ngFor="let pmc of paymentMethodConfiguration?.allowedPaymentVariants"
                           (click)="selectPaymentMethodConfigurationVariant(pmc, false)">
                        <fa-icon *ngIf="pmc.paymentVariantId === selectedPaymentVariant"
                                 class="absolute bg-white text-yellow-#FF6D03 left-1 top-0"
                                 [icon]="faCheckCircle"></fa-icon>
                        <div class="relative w-full flex flex-row justify-center items-center">
                                    <span class="relative flex flex-col gap-0.5 items-center py-3">
                                        <img class="relative w-8 h-8" *ngIf="!pmc.logo"
                                             src="./assets/imgs/icon-pay.svg" />
                                        <img class="relative w-8 h-8" *ngIf="pmc.logo" [src]="pmc.logo.url" />
                                        <span class="relative text-gray-#5d5d5d font-Lato-Regular">{{pmc.title}}</span>
                                    </span>
                        </div>
                        <span *ngIf="pmc.paymentVariantFee"
                              class="absolute right-0 top-0 text-white text-center text-sm rounded-l px-1"
                              [style.background-color]="pmc.paymentVariantFeeLabelColor">{{pmc.paymentVariantFee}}</span>
                      </div>
                    </div>

                    <div *ngIf="selectedPaymentVariantObject?.agreementQuery"
                         class="flex flex-1 flex-row gap-2 items-start lg:items-center mt-4 pb-5">
                      <p class="relative font-Lato-Regular text-gray-#727272">
                        {{'PAYMENT_VARIANT_AGREEMENT' | translate}}
                        <span (click)="openAgreementModal()" class="text-blue-#18396C cursor-pointer">
                                    {{'AGREE_SCND' |
                          translate}}</span>
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <ng-container
                      *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET"
                      [ngTemplateOutlet]="KWallet"></ng-container>

                    <!-- <ng-container *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL"
                [ngTemplateOutlet]="Transfermovil"></ng-container> -->

                    <ng-container [ngTemplateOutlet]="Cards"
                                  *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSACTION || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET">
                    </ng-container>

                    <!-- <ng-container
                *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET || selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET || selectedPaymentVariant === paymentMethodEnum.TRANSFER_MOVIL"
                [ngTemplateOutlet]="BillingAddress"></ng-container> -->
                  </div>

                </div>


                <!-- Template para el wallet -->
                <ng-template #KWallet>
                    <div class="relative pb-7" *ngIf="!isLoadingPaymentMethodConfiguration">
                        <div class="flex flex-col justify-between items-center bg-white border rounded px-6 pb-4 mt-7 lg:flex-row lg:h-32 lg:pb-0"
                             [ngClass]="(isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < display_item_price && selectedPaymentVariant === paymentMethodEnum.WALLET) ||
                            (formMarkAsTouched && !isKWalletFundsApplied) || insufficientBalance ? 'border-red-500' : 'border-gray-400'"
                        >
                            <!-- [ngClass]="(isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < cart.total && selectedPaymentVariant === paymentMethodEnum.WALLET) ||
                        (formMarkAsTouched && !isKWalletFundsApplied) || insufficientBalance ? 'border-red-500' : 'border-gray-400'" -->
                            <img class="h-14 lg:w-1/2 xl:w-auto" src="../../../../../assets/imgs/kwallet-logo.png"
                                alt="">
                            <div *ngIf="!isLoadingWalletAccount && walletAccountData && !isLoadingChangePaymentMethod"
                                class="flex flex-col font-Lato-Regular h-full justify-center items-end gap-2">
                                <div class="flex flex-col items-end gap-1" *ngIf="walletAccountData.isActive">
                                    <div class="flex flex-row leading-4">
                                        <p class="text-gray-#252525">{{ 'CURRENT_BALANCE' | translate }}:</p>
                                        <!--                [ngClass]="{'line-through text-13px': isKWalletFundsApplied, 'font-semibold': !isKWalletFundsApplied}"-->
                                        <p class="ml-1" [ngClass]="{'line-through': isKWalletFundsApplied}">{{
                                            walletAccountData?.balance || 0.00 | currency }}</p>
                                    </div>

                                    <div *ngIf="selectedPaymentVariant === paymentMethodEnum.WALLET"
                                        class="flex flex-row leading-4 py-3">
                                        <p class="text-gray-#252525">{{ 'BALANCE_APPLIED' | translate }}:</p>
                                        <p class="ml-1 font-semibold">{{ kwalletFounds | currency }}</p>
                                    </div>

                          <div *ngIf="selectedPaymentVariant === paymentMethodEnum.TRANSACTION_AND_WALLET"
                               class="flex flex-row leading-4 gap-2" [formGroup]="formGroup">
                            <div class="flex flex-1 flex-col relative pb-5">
                              <!--                  bg-gray-#f2f2f2-->
                              <input placeholder="{{ 'BALANCE' | translate }}*"
                                     formControlName="wallet_amount" id="wallet_amount" type="number" pattern="^\d+(\.\d{0,2})?$"
                                     [min]="minAmount" [max]="maxAmount" [step]="minAmount" [ngClass]="{
                           'opacity-50 pointer-events-none': isKWalletFundsApplied,
                           'border-red-500': (walletAmount?.invalid && formMarkAsTouched) || (walletAmount?.invalid && (walletAmount?.dirty || walletAmount?.touched))
                         }" class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-40px rounded-3px pl-4 text-base w-32 border-gray-#BBBBBB">
                              <div *ngIf="walletAmount?.invalid && (walletAmount?.dirty || walletAmount?.touched)"
                                   class="absolute bottom-0.5 text-sm text-red-500 text-11px">
                                <p *ngIf="walletAmount?.hasError('required')">{{ 'REQUIRED_FIELD' |
                                  translate }}
                                </p>
                                <p *ngIf="walletAmount?.hasError('min')">{{ 'MIN_VALIDATION' |
                                  translate:
                                  {min:
                                    minAmount} }}</p>
                                <p *ngIf="walletAmount?.hasError('max')">{{ 'MAX_VALIDATION' |
                                  translate:
                                  {max:
                                    maxAmount} }}</p>
                              </div>
                            </div>

                            <div class="flex">
                              <button
                                (click)="applyFunds(!isKWalletFundsApplied, selectedPaymentVariant!)"
                                [disabled]="walletAmount?.invalid"
                                [ngClass]="{'opacity-50 pointer-events-none': walletAmount?.invalid, 'pl-1 pr-4': isKWalletFundsApplied, 'px-6': !isKWalletFundsApplied}"
                                class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular font-semibold
                            text-md justify-center h-40px">
                                <fa-icon *ngIf="isKWalletFundsApplied"
                                         class="relative flex justify-center items-center rounded-full w-9 h-9 text-xl text-white"
                                         [icon]="faCheck">
                                </fa-icon>
                                <div class="whitespace-nowrap" *ngIf="!isKWalletFundsApplied">{{ 'APPLY'
                                  |
                                  translate }}</div>
                                <div class="whitespace-nowrap" *ngIf="isKWalletFundsApplied">{{
                                  'APPLIED' |
                                    translate }}</div>
                              </button>
                            </div>
                          </div>

                          <div class="flex flex-row leading-4">
                            <p class="text-gray-#252525">{{ 'REMAINING_BALANCE' | translate }}:</p>
                            <p class="ml-1 font-semibold">{{ calculateRest() | currency }}</p>
                          </div>
                        </div>
                      </div>

                      <button
                        *ngIf="walletAccountData && !walletAccountData.isActive && !isLoadingWalletAccount && !isLoadingChangePaymentMethod"
                        (click)="activateWallet()"
                        class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular font-semibold text-md justify-center px-6 h-11">
                        {{ 'ACTIVATE_WALLET' | translate }}
                      </button>

                            <div *ngIf="isLoadingWalletAccount || isLoadingChangePaymentMethod"
                                class="flex flex-1 flex-col font-Lato-Regular h-full justify-center items-end gap-2 animate-pulse">
                                <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
                                <div class="flex">
                                    <div class="relative w-40 h-8 rounded bg-gray-300"></div>
                                </div>
                                <div class="relative w-40 h-5 rounded-full bg-gray-300"></div>
                            </div>
                        </div>
                      <div
                        *ngIf="(formMarkAsTouched && !isKWalletFundsApplied) || insufficientBalance ||
                          (isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < display_item_price && selectedPaymentVariant === paymentMethodEnum.WALLET)"
                          class="absolute bottom-0.5 text-sm text-red-500">
                        <p *ngIf="formMarkAsTouched && !isKWalletFundsApplied && !insufficientBalance">
                          {{ 'MUST_APPLY_FUNDS' | translate }}
                        </p>
                        <p *ngIf="((insufficientBalance && selectedPaymentVariant === paymentMethodEnum.WALLET) ||
                         (isKWalletFundsApplied && formMarkAsTouched && walletAccountData.balance < display_item_price &&
                           selectedPaymentVariant === paymentMethodEnum.WALLET))">
                          {{ 'INSUFFICIENT_FUNDS' | translate }}
                        </p>
                      </div>
                    </div>
                </ng-template>

                <!-- Template para Transfermovil -->
                <!-- <ng-template #Transfermovil>

            <div class="relative pb-7"
                *ngIf="!isLoadingPaymentMethod && !isLoadingPaymentMethodConfiguration && transferMovilActive && !isLoadingPaymentVariantFee">
                <div [ngClass]="formMarkAsTouched && !isTransferMovilApplied ? 'border-red-500' : 'border-gray-400'"
                    class="flex flex-col gap-4 pt-4 justify-between items-center bg-white border rounded px-6 pb-4 mt-7
                   lg:flex-row lg:h-32 lg:pb-0 lg:gap-0 lg:pt-0">
                    <img class="h-14 lg:w-1/2 xl:w-auto" src="../../../../../assets/imgs/bolsa-transfermovil-logo.svg"
                        alt="">
                    <div class="flex flex-col font-Lato-Regular h-full justify-center items-end gap-2">
                        <div class="flex flex-col gap-1 w-187px">
                            <button (click)="applyTransferMovil(!isTransferMovilApplied)" class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular font-semibold text-md
                     justify-center h-11 py-2 hover:bg-yellow-#FFA101">
                                <fa-icon *ngIf="isTransferMovilApplied"
                                    class="relative flex justify-center items-center rounded-full w-9 h-9 text-xl text-white"
                                    [icon]="faCheck">
                                </fa-icon>
                                <div class="whitespace-nowrap" *ngIf="!isTransferMovilApplied">{{ 'APPLY' | translate }}
                                </div>
                                <div class="whitespace-nowrap" *ngIf="isTransferMovilApplied">{{ 'APPLIED' | translate
                                    }}</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="formMarkAsTouched && !isTransferMovilApplied"
                    class="absolute bottom-0.5 text-sm text-red-500">
                    <p>{{ 'MUST_APPLY_TRANSFERMOVIL' | translate }}</p>
                </div>
            </div>
        </ng-template> -->

                <!-- Template para el componente de tarjetas -->
                <ng-template #Cards>
                  <app-payments-cards [ngClass]="{
                                  'mt-5': selectedPaymentVariant === paymentMethodEnum.TRANSACTION
                                }" [formMarkAsTouched]="formMarkAsTouched" [showAddCards]="true"
                                      [showCardDetails]="true" [isViewCheckout]="true" (onCardSelection)="setSelectedCard($event)">
                  </app-payments-cards>
                  <!-- *ngIf="!isLoadingCard && (transactionPaymentActive || combinedPaymentActive) && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee" -->
                </ng-template>

                <!-- Template para las direcciones de facturacion -->
                <!-- <ng-template #BillingAddress>
            <div class="flex flex-col mt-3"
                *ngIf="showBillingAddress && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee">
                <div class="flex flex-row justify-between">
                    <p class="relative font-Lato-Regular font-semibold text-gray-#363636 text-19px">
                        {{ 'BILLING_ADDRESS' | translate }}
                    </p>
                    <button class="relative flex justify-end items-center" (click)="addAddress(false)">
                        <fa-icon
                            class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                            [icon]="faAdd">
                        </fa-icon>
                    </button>
                </div>

                <p class="relative font-Lato-Regular text-gray-#727272 mt-1">
                    {{ 'SELECT_ADDRESS_THAT_MATCHES_YOUR_CARD_OR_PAYMENT_METHOD' | translate }}
                </p>

                <div class="flex flex-1 flex-col">
                    <div
                        *ngIf="!isLoadingAddress && !isLoadingBillingAddress && !isLoadingPaymentMethodConfiguration && !isLoadingPaymentVariantFee">
                        <app-checkout-address-manager [formMarkAsTouched]="formMarkAsTouched"
                            [errorText]="'SELECT_A_BILLING_ADDRESS'" [addresses]="billingAddresses"
                            (onAddressSelect)="setSelectedBillingAddress($event)"
                            (onAddressUpdate)="refreshAddresses($event)" [selectedAddress]="selectedBillingAddress"
                            [isShipping]="false"></app-checkout-address-manager>
                    </div>

                    <div *ngIf="isLoadingAddress || isLoadingBillingAddress || isLoadingPaymentMethodConfiguration || isLoadingPaymentVariantFee"
                        class="flex flex-1 flex-col h-100px mt-10px border border-gray-#D6D6D6 animate-pulse">
                        <div class="flex h-full flex-row p-4 bg-white">
                            <div class="flex flex-row">
                                <div class="relative w-5 h-5 rounded-full bg-gray-300"></div>
                            </div>

                            <div class="flex flex-col flex-1 ml-3">
                                <div class="relative w-60 h-4 rounded-full bg-gray-300"></div>
                                <div class="relative rounded w-full h-5 my-2 bg-gray-300"></div>
                                <div class="relative w-40 h-4 rounded-full bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template> -->

                <!--    <div class="flex flex-col mt-3"></div>-->
              </div>


                <div *ngIf="selectedPaymentVariant !== -1" class="flex flex-row gap-2 lg:gap-5 mt-3 lg:mt-5">
                    <div [ngClass]="isLoadingWalletAccount || isLoadingPaymentMethodConfiguration || isLoadingCard || isConfirming ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'"
                        class="relative bg-yellow-#FF6D03 font-Lato-Regular text-white px-3 lg:px-6 flex items-center z-10
                w-auto h-10 lg:h-11 rounded-3xl text-md lg:text-19px" (click)="user?.useNewPayment ? goToPayment() : performPaymentIntention()">
                        <div *ngIf="!user?.useNewPayment">{{ 'CONFIRM' | translate }}</div>
                        <div *ngIf="user?.useNewPayment">{{ 'KATAPULK_PAY' | translate }}</div>
                        <fa-icon *ngIf="isConfirming"
                            class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                            [icon]="faSpinner">
                        </fa-icon>
                    </div>
                </div>
            </div>

            <!-- <mat-divider [vertical]="true" class="hidden md:flex"></mat-divider> -->
            <!-- <mat-divider class="flex md:hidden"></mat-divider> -->

            <div class="relative flex flex-col basis-1/4">
                <div *ngIf="item_price > 0" class="flex flex-col">
                    <p class="relative font-Lato-Regular font-semibold text-gray-#363636
                          text-19px">
                        {{ 'ORDER_SUMMARY' | translate }}
                    </p>

                    <div class="mt-5 border-b border-solid border-gray-#D6D6D6 flex flex-col">
                        <!-- <div class="text-sm font-semibold font-Lato-Regular" [style.color]="store?.favorite_color">
                            {{ store.name }}
                        </div> -->
                        <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular mt-1">
                            <div class="flex flex-row">
                                <div class="text-gray-500">{{ 'CHECKOUT_TOTAL_PRODUCTS' | translate }}</div>
                                <div class="font-Lato-Regular text-sm text-gray-500 ml-1">
                                    (1)
                                </div>
                            </div>
                            <!-- store.totalPriceProducts -->
                            <div class="font-Lato-Bold text-lg text-gray-600">{{ display_item_price | currency }}
                            </div>
                        </div>
                        <!-- <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                            <div class="text-gray-500 text-sm">{{ 'SHIPPING_COST' | translate }}</div>
                            store.included_tax_total
                            <div class="text-gray-500 text-sm">{{ store.shippingRatePriceSelected | currency }}</div>
                        </div> -->

                        <div class="flex flex-row justify-between mt-3 mb-5 font-Lato-Bold text-sm text-gray-500">
                            <div class="">{{ 'SUB_TOTAL' | translate }}</div>
                            <!-- item_price -->
                            <div class="">{{ display_item_price | currency }}</div>
                        </div>
                    </div>

                    <div class="mt-5 flex flex-col">
                        <!-- <div *ngIf="cart.kwalletFounds"
                        class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                        <div class="text-gray-500 text-sm">{{ 'K_WALLET_FUNDS' | translate }}</div>
                        <div class="text-gray-500 text-sm">{{ cart.kwalletFounds | currency }}</div>
                    </div>
                    <div *ngIf="cart.creditCard"
                        class="text-sm flex flex-row justify-between items-center font-Lato-Regular"
                        [ngClass]="{'mt-1': cart.kwalletFounds}">
                        <div class="text-gray-500 text-sm">{{ 'CREDIT_CARD' | translate }}</div>
                        <div class="text-gray-500 text-sm">{{ cart.creditCard | currency }}</div>
                    </div>
                    <div *ngIf="cart.externalAmount"
                        class="text-sm flex flex-row justify-between items-center font-Lato-Regular"
                        [ngClass]="{'mt-1': cart.kwalletFounds || cart.creditCard}">
                        <div class="text-gray-500 text-sm">{{ 'TRANSFERMOVIL' | translate }}</div>
                        <div class="text-gray-500 text-sm">{{ cart.externalAmount | currency }}</div>
                    </div> -->

                        <div class="flex flex-col mt-4 mb-4">
                            <!-- <div *ngIf="cart.adjustment_payment_method_total"
                            class="text-sm flex flex-row justify-between items-center font-Lato-Regular"
                            [ngClass]="{'mt-1': cart.kwalletFounds || cart.creditCard}">
                            <div class="text-gray-500 text-sm">{{ 'PAYMENT_FEE' | translate }}</div>
                            <div class="text-gray-500 text-sm">{{ cart.adjustment_payment_method_total | currency }}
                            </div>
                        </div>
                        <div *ngIf="cart.promo_total != 0" class="flex flex-col">
                            <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                                <div class="text-gray-500 text-sm">{{ 'DISCOUNT' | translate }}</div>
                                <div class="text-gray-500 text-sm">{{ cart.display_promo_total }}</div>
                            </div>
                            <div class="flex justify-end mt-1">
                                <div class="font-Lato-Regular font-semibold text-sm line-through">{{ cart.item_total |
                                    currency }}</div>
                            </div>
                        </div> -->
                            <div
                                class="flex flex-row font-Lato-Regular font-semibold text-19px justify-between leading-4">
                                <div>{{ 'TOTAL_SUMMARY_PRODUCTS' | translate: {total: 1} }}</div>
                                <div>{{ display_item_price | currency }}</div>
                            </div>
                        </div>


                        <!-- <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                        <div class="text-gray-500 text-sm">{{ 'NO_OF_ORDER' | translate }}</div>
                        <div class="text-gray-500 text-sm">{{ cart.number }}</div>
                    </div> -->
                        <!-- <div *ngIf="cart.shippingAddress && cart.shippingAddress != ''"
                        class="text-sm flex flex-row justify-between font-Lato-Regular mt-1">
                        <div class="flex flex-1 text-gray-500 text-sm">{{ 'SHIPPING_ADDRESS' | translate }}:</div>

                        <div class="flex flex-1 text-end text-gray-500 text-sm leading-4 break-words">{{
                            cart.shippingAddress }}</div>
                    </div>
                    <div *ngIf="cart.shippingAddress && cart.phone"
                        class="flex flex-1 justify-end text-end text-gray-500 text-sm leading-4">{{ 'PHONE' | translate
                        }}: {{ cart.phone }}</div> -->

                        <div (click)="user?.useNewPayment ? goToPayment() : performPaymentIntention()" *ngIf="selectedPaymentVariant !== -1" class="relative bg-yellow-#FF6D03 font-Lato-Regular text-white px-3 lg:px-6 flex items-center
                              w-auto h-10 lg:h-11 rounded-3xl mt-10 text-19px justify-center"
                            [ngClass]="isLoadingWalletAccount || isLoadingPaymentMethodConfiguration || isLoadingCard || isConfirming ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'">
                          <div *ngIf="!user?.useNewPayment">{{ 'CONFIRM_YOUR_ORDER' | translate }}</div>
                          <div *ngIf="user?.useNewPayment">{{ 'KATAPULK_PAY' | translate }}</div>
                            <fa-icon *ngIf="isConfirming"
                                class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                                [icon]="faSpinner">
                            </fa-icon>
                        </div>
                    </div>
                </div>


                <!-- <div *ngIf="!cart" class="flex flex-col animate-pulse">
                <div class="relative rounded w-187px h-5 my-2 bg-gray-300"></div>

                <div class="mt-5 border-b border-solid border-gray-#D6D6D6 flex flex-col">
                    <div class="relative w-100px h-4 rounded bg-gray-300"></div>

                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular mt-1">
                        <div class="flex flex-row">
                            <div class="relative w-130px h-4 rounded bg-gray-300"></div>
                        </div>
                        <div class="relative w-14 h-5 rounded bg-gray-300"></div>
                    </div>

                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>

                        <div class="relative w-10 h-4 rounded bg-gray-300"></div>
                    </div>

                    <div class="flex flex-row justify-between mt-3 mb-5 font-Lato-Bold text-sm text-gray-500">
                        <div class="relative w-16 h-5 rounded bg-gray-300"></div>

                        <div class="relative w-10 h-4 rounded bg-gray-300"></div>
                    </div>
                </div>

                <div class="mt-5 flex flex-col">
                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>
                        <div class="relative w-14 h-4 rounded bg-gray-300"></div>
                    </div>
                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular mt-1">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>
                        <div class="relative w-14 h-4 rounded bg-gray-300"></div>
                    </div>
                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular mt-1">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>
                        <div class="relative w-14 h-4 rounded bg-gray-300"></div>
                    </div>
                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular mt-1">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>
                        <div class="relative w-14 h-4 rounded bg-gray-300"></div>
                    </div>
                    <div class="flex flex-row mt-4 mb-4 font-Lato-Regular font-semibold text-19px justify-between">
                        <div class="relative rounded w-187px h-7 bg-gray-300"></div>
                        <div class="relative rounded w-100px h-7 bg-gray-300"></div>
                    </div>

                    <div class="text-sm flex flex-row justify-between items-center font-Lato-Regular">
                        <div class="relative w-100px h-4 rounded bg-gray-300"></div>
                        <div class="relative w-20 h-4 rounded bg-gray-300"></div>
                    </div>

                    <div class="relative bg-gray-300 font-Lato-Regular text-white px-3 lg:px-6
                              w-auto h-10 lg:h-49px rounded-3xl mt-10 text-19px">
                    </div>
                </div>
            </div> -->

            </div>
        </div>
    </div>
</section>
