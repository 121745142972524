export const groupedStoresMock = {
    "data": {
        6: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 735,
                "url": "https://la-criolla.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-criolla",
                "default": false,
                "created_at": "2022-04-24T14:00:31.989-04:00",
                "updated_at": "2024-03-19T15:06:06.151-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a97ykyjsf7z853g0kppgcd8be8yc",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda en Katapulk con entregas a domicilio en todos los municipios de La Habana. Mercado con gran variedad de productos para Cuba como enlatados, confituras, básicos de la despensa, productos de aseo, belleza y mucho más. Estos productos de excelente calidad se encuentran en Cuba, listos para ser entregados a domicilio.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com,rosaliaf9@gmail.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "it,es,en",
                "position": 3,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 40,
                "name": "La Criolla",
                "meta_description": null,
                "meta_keywords": "Alimentos, Electrodomésticos",
                "seo_title": "La Criolla"
            },
            {
                "id": 2019,
                "url": "https://reforma.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "reforma",
                "default": false,
                "created_at": "2022-10-09T12:11:10.708-04:00",
                "updated_at": "2024-03-28T10:12:42.651-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/x3onywh89mbytq12ijz10xwuffr6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "sales@katapulk.com",
                "default_country_id": 233,
                "description": " Una tienda con todos los electrodomésticos y artículos de ferretería más buscados para las renovaciones y arreglos en Cuba. Con ofertas al mejor precio en productos como televisores, ventiladores, plantas eléctricas y bombas de agua para Cuba, hasta suministros como pintura, piezas de baño y cemento para reformar hogares y negocios en el país.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "perico@katapulk.dev",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 4,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 140,
                "name": "Reforma",
                "meta_description": null,
                "meta_keywords": "Ferretería, Hogar, Plomería, Electricidad",
                "seo_title": null
            },
            {
                "id": 40,
                "url": "https://eylin-pizzas-y-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eylin-pizzas-y-combos",
                "default": false,
                "created_at": "2021-09-13T16:07:48.518-04:00",
                "updated_at": "2024-03-19T15:33:19.434-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/h9i3s89nq800lczxfiadft2989jq",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Especializados en comida criolla y pizzas, ideales para fiestas y grupos de amigos.\r\n\r\nTiempo de Entrega: 24 horas\r\n\r\nModo de Entrega:  Entrega a Domicilio en las provincias: La Habana (Todos los municipios EXCLUYENDO HABANA DEL ESTE)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 5,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Eylin Pizzas y Combos",
                "meta_description": "Especializados en comida criolla y pizzas, ideales para fiestas y grupos de amigos.\r\n\r\nTiempo de Entrega: 24 horas\r\n\r\nModo de Entrega:  Entrega a Domicilio en las provincias: La Habana (Todos los municipios EXCLUYENDO HABANA DEL ESTE)\r\n\r\n",
                "meta_keywords": "Comidas, Pizzas, pizzería, pizzeria, combo, combos, envíos a cuba, envio a cuba, cerdo, puerco, pizza de queso, pizza familiar, ",
                "seo_title": null
            },
            {
                "id": 1689,
                "url": "https://paladar-santa-barbara.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "paladar-santa-barbara",
                "default": false,
                "created_at": "2022-08-09T09:19:22.317-04:00",
                "updated_at": "2024-03-19T15:42:02.181-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/q0hl1duad8q258uc0lgbh8kiuruw",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda especializada en comida criolla, muy recomendada, popular y con precios absequibles.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "Calle M # 162 e/ Línea y 17. Vedado. ",
                "contact_phone": "+53 5 2645177",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 6,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Paladar Santa Bárbara",
                "meta_description": "Tienda especializada en comida criolla, muy recomendada, popular y con precios absequibles.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Pizzas, tables, pastas, ensaladas, refresco, comida criolla e internaciona,l mercado",
                "seo_title": null
            },
            {
                "id": 1435,
                "url": "https://bc-suministros-industriales.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "bc-suministros-industriales",
                "default": false,
                "created_at": "2022-07-12T09:09:16.507-04:00",
                "updated_at": "2023-07-28T16:23:32.859-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/u9rcn2hemu0tl3xijvs4tsaejkyx",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "BC Suministros Industriales ofrece la posibilidad de adquirir artículos de ferretería, pinturas y productos de vidrio en comercios especializados. Comercializar materiales para la construcción y equipo y materiales de fontanería y calefacción.\r\n\r\nTiempo de Entrega: 15 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "+34 689 21 32 76",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 7,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "BC Suministros Industriales",
                "meta_description": "BC Suministros Industriales ofrece la posibilidad de adquirir artículos de ferretería, pinturas y productos de vidrio en comercios especializados. Comercializar materiales para la construcción y equipo y materiales de fontanería y calefacción.\r\n\r\nTiempo de Entrega: 15 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Ferretería, Materiales de Construcción,losas, Azulejos, Porcelanato",
                "seo_title": null
            },
            {
                "id": 37,
                "url": "https://sin-molde.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "sin-molde",
                "default": false,
                "created_at": "2021-08-02T14:50:36.868-04:00",
                "updated_at": "2023-07-28T15:29:58.326-04:00",
                "facebook": "https://www.facebook.com/Sinmoldetaller",
                "twitter": "",
                "instagram": "https://instagram.com/sinmolde_cuba?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/oxt9zd91hqpkowhzft46xsvell5k",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Diversos y coloridos productos utilitarios y de diseño para todos los gustos y necesidades.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.\r\n",
                "address": "Calle 29 #1089 e/ 28 y 30. Nuevo Vedado",
                "contact_phone": "+5352964525",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 8,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Sin Molde",
                "meta_description": "Diversos y coloridos productos utilitarios y de diseño para todos los gustos y necesidades.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.\r\n\r\n",
                "meta_keywords": "Hogar, utensilio, utensilios, adornos, casa, envíos a cuba",
                "seo_title": null
            },
            {
                "id": 1428,
                "url": "https://cuba-flor.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "cuba-flor",
                "default": false,
                "created_at": "2022-07-12T06:13:05.670-04:00",
                "updated_at": "2023-07-28T16:09:18.218-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100063785040817",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/kqzjnlfw1gbf68l29nor2h2pxskm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En CUBAFLOR S.R.L brindamos servicios de arreglos florales, plantas ornamentales servicios de jardinería, regalos y decoración. Con más de 2 años de experiencia.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega:Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "+53 53248273",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 10,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Flor",
                "meta_description": "En CUBAFLOR S.R.L brindamos servicios de arreglos florales, plantas ornamentales servicios de jardinería, regalos y decoración. Con más de 2 años de experiencia.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega:Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Arreglos florales, Flores, rosas, girasoles, girasol, jirasol, jorasoles, gladiolo, gladiolos, rosas rojas, rosas blancas, ramos, ramo de , bouquet, buquet, buque ",
                "seo_title": null
            },
            {
                "id": 2353,
                "url": "https://paolo.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "paolo",
                "default": false,
                "created_at": "2022-11-11T06:42:18.917-05:00",
                "updated_at": "2023-07-28T16:26:37.481-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/ievj7lrhkak0qujf38vkm7gygoib",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de calzados y prendas de vestir. Artículos hechos con cuero.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 11,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Paolo",
                "meta_description": "Tienda de calzados y prendas de vestir. Artículos hechos con cuero.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "carteras, regalo, prenda de vestir, calzado",
                "seo_title": null
            },
            {
                "id": 503,
                "url": "https://home-pizza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "home-pizza",
                "default": false,
                "created_at": "2022-02-25T04:56:47.105-05:00",
                "updated_at": "2024-03-19T15:34:04.069-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100037636988204",
                "twitter": "",
                "instagram": "https://instagram.com/homepizzacuba?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/mlv7c5cy815rihdr66y16qgpwlsd",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Home Pizzas ofrece un servicio a domicilio con entrega a todos los lugares de La Habana. Disponemos de una amplia variedad de PIZZAS elaboradas artesanalmente, masa suave y con un auténtico sabor.\r\n\r\nTiempo de Entrega: 24 horas\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "Calle 48 e/ 19 y 21, Playa.",
                "contact_phone": "+53 5 6798637 / +53 5 6373636",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 13,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Home Pizza",
                "meta_description": "Home Pizzas ofrece un servicio a domicilio con entrega a todos los lugares de La Habana. Disponemos de una amplia variedad de PIZZAS elaboradas artesanalmente, masa suave y con un auténtico sabor.\r\n\r\nTiempo de Entrega: 24 horas\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.\r\n",
                "meta_keywords": "Restaurante, Pizzería",
                "seo_title": null
            },
            {
                "id": 1161,
                "url": "https://ama-regalo.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ama-regalo",
                "default": false,
                "created_at": "2022-06-17T15:49:40.049-04:00",
                "updated_at": "2023-10-11T12:59:59.299-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/ama_regalo?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/asmuax67mxgwv851ymyhtvwgqwac",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de regalos manufacturados con servicio de personalización.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "+53 5 2363341",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com,rosaliaf9@gmail.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 17,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Ama Regalo",
                "meta_description": "Tienda de regalos manufacturados con servicio de personalización.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Hogar, utensilio, utensilios, adornos, casa, envíos a cuba, flores, arreglos florales, loreria, florería, floresteria",
                "seo_title": null
            },
            {
                "id": 13,
                "url": "https://jardin-angelica.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "jardin-angelica",
                "default": false,
                "created_at": "2021-04-20T15:15:32.849-04:00",
                "updated_at": "2023-10-05T17:19:39.207-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/m0xr32jkuqbpnlw9lnfr9s2mdogt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "sales@katapulk.com",
                "default_country_id": 233,
                "description": "Confección de arreglos florales de todo tipo, así como a la ambientación de interiores y exteriores. Se utilizan elementos fundamentalmente naturales, plantas ornamentales y flores.\r\n\r\nEntregas y recogida en tienda: 24 a 72 horas.\r\n\r\nZona: La Habana\r\n\r\nDirección: Calle 19 #4210 entre 42 y 44. Playa \r\n\r\nTeléfonos: 72061987 // 52957319",
                "address": "calle 19 #4210 entre 42 y 44. Playa ",
                "contact_phone": "72061987 // 52957319",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com,rosaliaf9@gmail.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 19,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Jardín Angélica  ",
                "meta_description": "Confección de arreglos florales de todo tipo, así como a la ambientación de interiores y exteriores. Se utilizan elementos fundamentalmente naturales, plantas ornamentales y flores.",
                "meta_keywords": "Floristería, Ramos, Peluches, rosas, girasoles, girasol, jirasol, jorasoles, gladiolo, gladiolos, rosas rojas, rosas blancas, ramos, ramo de , bouquet, buquet, buque ",
                "seo_title": null
            },
            {
                "id": 3116,
                "url": "https://d-montes.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "d-montes",
                "default": false,
                "created_at": "2023-02-23T10:27:18.788-05:00",
                "updated_at": "2023-10-09T17:58:32.999-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/l4mtpcie97kyshcdemtibn4h1qpb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Ofrecemos una amplia gama de productos que incluyen Ferretería consumible de uso profesional, Ferretería para el hogar, Útiles para el hogar, Bombas de agua, Herramientas eléctricas y manuales, Artículos de higiene, Materiales de oficina y escolares. \r\n\r\nEntregas: 4 días.  \r\n\r\nZona: La Habana, menos municipio Habana del Este. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 20,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "D'Montes",
                "meta_description": "Ofrecemos una amplia gama de productos que incluyen Ferretería consumible de uso profesional, Ferretería para el hogar, Útiles para el hogar, Bombas de agua, Herramientas eléctricas y manuales, Artículos de higiene, Materiales de oficina y escolares. \r\n\r\nEntregas: 4 días.  \r\n\r\nZona: La Habana, menos municipio Habana del Este. ",
                "meta_keywords": "Ferretería, Hogar, Plomería, Electricidad, herramientas ",
                "seo_title": "D'Montes"
            },
            {
                "id": 73,
                "url": "https://picabu-articulos-para-bebes.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "picabu-articulos-para-bebes",
                "default": false,
                "created_at": "2021-10-07T08:21:22.345-04:00",
                "updated_at": "2023-07-28T15:32:58.005-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/5476r70afqx5hkkxrf2f0pdtum98",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Picabú es una tienda dedicada principalmente al bebé y a los niños; la mayoría de las propuestas son personalizables. Los materiales empleados son muy seguros para el bebé, libre de elementos tóxicos y respetuosos del medio ambiente.\r\n\r\nTiempo de Entrega: 5 a 7 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 22,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Picabú - Artículos para Bebés",
                "meta_description": "Picabú es una tienda dedicada principalmente al bebé y a los niños; la mayoría de las propuestas son personalizables. Los materiales empleados son muy seguros para el bebé, libre de elementos tóxicos y respetuosos del medio ambiente.\r\n\r\nTiempo de Entrega: 5 a 7 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Artículos para Bebés",
                "seo_title": null
            },
            {
                "id": 2250,
                "url": "https://ferroelektro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ferroelektro",
                "default": false,
                "created_at": "2022-11-07T09:48:50.646-05:00",
                "updated_at": "2023-08-03T13:42:59.420-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/4s0n4rdxl4k4bglktwojuy9vzmlb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional. \r\nEnvíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas\r\nLos tiempos de entregas varían según la provincia:\r\nLa Habana- 24 horas \r\nMayabeque /Artemisa-10 días\r\nMatanzas- 15 días\r\nModo de entrega:\r\nEntrega a domicilio en todos los municipios de La Habana, Mayabeque, Artemisa y Matanzas.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "ferretería, envíos cuba",
                "supported_locales": "es",
                "position": 23,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "FerroElektro",
                "meta_description": "Tienda de ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional.  Envíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas",
                "meta_keywords": "Ferretería, Motor de Agua, Llaves",
                "seo_title": "Tienda de Ferretería Cubana"
            },
            {
                "id": 2679,
                "url": "https://viti-s-charcuteria.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "viti-s-charcuteria",
                "default": false,
                "created_at": "2022-12-14T12:43:17.084-05:00",
                "updated_at": "2023-07-28T16:29:48.990-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/206ob9v4qk5ik1jzzodisnwvp8wf",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda especializada en la elaboración y comercialización ahumados. Ademas cuentan con una gran variedad de cárnicos y quesos.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS BOYEROS)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 26,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Viti’s Charcutería ",
                "meta_description": "Tienda especializada en la elaboración y comercialización ahumados. Ademas cuentan con una gran variedad de cárnicos y quesos.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS BOYEROS)\r\n\r\n\r\n\r\n",
                "meta_keywords": "Embutido, combos, carne ",
                "seo_title": null
            },
            {
                "id": 2878,
                "url": "https://gao.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "gao",
                "default": false,
                "created_at": "2023-01-12T10:13:53.829-05:00",
                "updated_at": "2023-08-03T14:19:51.634-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/sk0x1yprmjwdw0j3xfkhte62zpku",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de materiales de construcción, ferretería, herramientas y apertura a nuevos productos de mercado de primera necesidad en el hogar. \r\n\r\nEntregas: 24 horas. \r\nNo establecen entregas los fines de semana.  \r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 27,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "GAO ",
                "meta_description": "Tienda de materiales de construcción, ferretería, herramientas y apertura a nuevos productos de mercado de primera necesidad en el hogar. \r\n\r\nEntregas: 24 horas. \r\nNo establecen entregas los fines de semana.  \r\n\r\nZona: La Habana",
                "meta_keywords": "Ferretería, Materiales, Herramientas, Contrucción, Mercado",
                "seo_title": null
            },
            {
                "id": 2351,
                "url": "https://cubelas.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "cubelas",
                "default": false,
                "created_at": "2022-11-11T06:18:45.032-05:00",
                "updated_at": "2023-05-09T12:04:15.771-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/bsgj3ysk44jpu5j2x01r1nbwgvpm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una Mipyme dedicada a la gestión integral de limpieza que ofrece servicios de lavandería, tintorería, planchado de productos textiles, proceso de almidón y desinfección en todos los casos, tratamiento de desmanche, blanqueado, anti moho, desgrase, arreglo simple de ropa, servicio a domicilio y de empaquetado.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 28,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cubelas",
                "meta_description": "Somos una Mipyme dedicada a la gestión integral de limpieza que ofrece servicios de lavandería, tintorería, planchado de productos textiles, proceso de almidón y desinfección en todos los casos, tratamiento de desmanche, blanqueado, anti moho, desgrase, arreglo simple de ropa, servicio a domicilio y de empaquetado.",
                "meta_keywords": "Lavandería, Ropa, Lavado, Secado, Planchado",
                "seo_title": null
            },
            {
                "id": 502,
                "url": "https://naturarte.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "naturarte",
                "default": false,
                "created_at": "2022-02-25T04:39:05.174-05:00",
                "updated_at": "2023-07-28T15:49:49.074-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/naturarte_habana?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/xcmmiv2jap1ipzkvjyrhib9mbqe1",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un emprendimiento de cosmética natural especializado en la elaboración de productos artesanales naturales. Surge por el deseo de satisfacer las necesidades de cuidado personal, salud e imagen de las personas y potenciar su belleza. Tenemos como principio promover una cultura y una conciencia ecológica y saludable.  Por ello elaboramos nuestros productos 100% naturales. A través de nuestros artículos artesanales confeccionados de material totalmente reciclado también promovemos el cuidado del medio ambiente en busca de generar una relación amigable con la naturaleza.\r\n\r\nTiempo de Entrega: 72 horas\r\n\r\nModo de Entrega: ntrega a domicilio en todos los municipios de La Habana.",
                "address": "Calle H e/25 y Lindero. Vedado",
                "contact_phone": "+5358892202 / +5358975840",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 29,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Naturarte",
                "meta_description": "Somos un emprendimiento de cosmética natural especializado en la elaboración de productos artesanales naturales. Surge por el deseo de satisfacer las necesidades de cuidado personal, salud e imagen de las personas y potenciar su belleza. Tenemos como principio promover una cultura y una conciencia ecológica y saludable.  Por ello elaboramos nuestros productos 100% naturales. A través de nuestros artículos artesanales confeccionados de material totalmente reciclado también promovemos el cuidado del medio ambiente en busca de generar una relación amigable con la naturaleza.\r\n\r\nTiempo de Entrega: 72 horas\r\n\r\nModo de Entrega: ntrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Hogar, utensilio, utensilios, adornos, casa, envíos a cuba",
                "seo_title": null
            },
            {
                "id": 2253,
                "url": "https://m-g-cafe.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "m-g-cafe",
                "default": false,
                "created_at": "2022-11-07T11:09:37.506-05:00",
                "updated_at": "2023-07-28T16:33:06.361-04:00",
                "facebook": "https://www.facebook.com/mgcafehabana",
                "twitter": "",
                "instagram": "https://instagram.com/mgcafehabana?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/j1p1h1av90rj1ma3hveb2erkd2dp",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Lugar ideal para hacerle llegar a sus familiares deliciosas pizzas, hamburguesas, sándwich y tacos. Con ofertas para toda la familia.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (10 de oct, Habana Vieja, Centro Habana, Plaza, Playa).",
                "address": "Calle J #607/ 27 y universidad. Vedado. ",
                "contact_phone": "+53 55239400 - 78302215",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 30,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "M\u0026G Café",
                "meta_description": "Lugar ideal para hacerle llegar a sus familiares deliciosas pizzas, hamburguesas, sándwich y tacos. Con ofertas para toda la familia.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (10 de oct, Habana Vieja, Centro Habana, Plaza, Playa).",
                "meta_keywords": "Comida italiana, pizzas, pastas, hamburguesas, sándwich, tacos. ",
                "seo_title": null
            },
            {
                "id": 2349,
                "url": "https://don-carlos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "don-carlos",
                "default": false,
                "created_at": "2022-11-11T05:51:16.416-05:00",
                "updated_at": "2023-07-28T16:35:16.483-04:00",
                "facebook": "No tienen",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/hl3j82ax3q44o6iw0vm4ayhh2rpx",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda que ofrece variedad de productos cárnicos y sus derivados de primera calidad. Entregas a domiclio en La Habana y Artemisa.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios), Artemisa (Todos los municipios).",
                "address": "",
                "contact_phone": "+5353105277",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 32,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Don Carlos ",
                "meta_description": "Tienda que ofrece variedad de productos cárnicos y sus derivados de primera calidad. Entregas a domiclio en La Habana y Artemisa.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios), Artemisa (Todos los municipios).",
                "meta_keywords": "Charcutería, Chorizo, Jamón, Cerdo, Ahumados ",
                "seo_title": "Don Carlos Minidustria"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            },
            {
                "id": 832,
                "url": "https://la-caja-juguetona.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-caja-juguetona",
                "default": false,
                "created_at": "2022-05-24T15:36:46.999-04:00",
                "updated_at": "2023-07-28T15:54:27.564-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100077581043381",
                "twitter": "",
                "instagram": "https://instagram.com/caja_juguetona?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/cs5wm2pupmbcnah1lhh2uny6bfbe",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Juguetes didácticos artesanales y de mesa  para niños y niñas.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "+53 5 2952555",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 36,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Caja Juguetona",
                "meta_description": "Juguetes didácticos artesanales y de mesa  para niños y niñas.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Juguetes, didácticos, entretenimiento, juguetes artesanales ",
                "seo_title": null
            },
            {
                "id": 271,
                "url": "https://la-gran-cesta.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-gran-cesta",
                "default": false,
                "created_at": "2022-01-18T09:40:37.129-05:00",
                "updated_at": "2023-09-27T11:40:12.940-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/3fjgylg4ofg48mkj89lnf7h210r4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Variada oferta de productos de agromercado, lavados y 100% orgánicos. Todo lo que necesita la familia cubana. \r\nEntregas: 10 días \r\n\r\nZona: La Habana, Artemisa (no incluye Bahía Honda) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 37,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Gran Cesta ",
                "meta_description": "Variada oferta de productos de agromercado, lavados y 100% orgánicos. Todo lo que necesita la familia cubana. \r\n\r\nEntregas: 10 días \r\n\r\nZona: La Habana, Artemisa (no incluye Bahía Honda) ",
                "meta_keywords": "Mercado, Combos, Agromercado",
                "seo_title": null
            },
            {
                "id": 435,
                "url": "https://charcuteria-cuervos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "charcuteria-cuervos",
                "default": false,
                "created_at": "2022-02-14T14:08:31.879-05:00",
                "updated_at": "2023-05-14T10:39:28.181-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100064519826022",
                "twitter": "",
                "instagram": "https://instagram.com/charcuteriacuervos?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/7u498wgju7h3ue3cqbzf4dcsmm1r",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "La Charcutería Cuervo's es una empresa especializada en la elaboración y venta de productos ahumados y sus derivados.",
                "address": "Calle 52 No.2914 e/ 29 y 31. Playa",
                "contact_phone": "+53 5 2968211",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 38,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Charcutería Cuervos",
                "meta_description": "La Charcutería Cuervo´s es una empresa especializada en la elaboración y venta de productos ahumados y sus derivados.",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 1591,
                "url": "https://chocoronela.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "chocoronela",
                "default": false,
                "created_at": "2022-07-29T06:38:53.963-04:00",
                "updated_at": "2023-11-07T12:00:59.767-05:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/74o36dtzj0egzo41bv5fw6jt26e4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda especializada en la elaboración y comercialización de helados artesanales. Helados de primera calidad.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS COTORRO), Artemisa (Bauta y San Antonio de los Baños).\r\n",
                "address": "",
                "contact_phone": "+53 5 9876699",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com, rosaliaf9@gmail.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 39,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Chocoronela",
                "meta_description": "Tienda especializada en la elaboración y comercialización de helados artesanales. Helados de primera calidad.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS COTORRO), Artemisa (Bauta y San Antonio de los Baños).\r\n",
                "meta_keywords": "Helados, Heladería, Mercado",
                "seo_title": null
            },
            {
                "id": 864,
                "url": "https://mauro-s-6c8e7cb9-20a0-4663-8cd3-b1343e610080.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mauros",
                "default": false,
                "created_at": "2022-05-30T13:20:18.671-04:00",
                "updated_at": "2023-07-28T15:56:58.918-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100063648787212",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/wwz998fjtb98z4azryptl789h40b",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Juguetes artesanales y  artículos para bebés personalizados, disfraces, útiles escolares, bolsas, títeres y mucho más ",
                "address": "Juguetes artesanales y  artículos para bebés personalizados, disfraces, útiles escolares, bolsas, títeres y mucho más.\r\n\r\nTiempo de Entrega: 12 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "contact_phone": "52962224",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 43,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Mauro's",
                "meta_description": "Juguetes artesanales y  artículos para bebés personalizados, disfraces, útiles escolares, bolsas, títeres y mucho más.\r\n\r\nTiempo de Entrega: 12 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Juguetes, artículos personalizados para niños y bebés, disfraces, cojines, bolsas, columpios ",
                "seo_title": "artículos personalizados para niños y bebés, disfraces, cojines, bolsas, columpios "
            },
            {
                "id": 105,
                "url": "https://living-havana.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "living-havana",
                "default": false,
                "created_at": "2021-10-18T09:33:02.656-04:00",
                "updated_at": "2023-07-28T15:44:15.575-04:00",
                "facebook": "https://www.facebook.com/livinghavanafood",
                "twitter": "",
                "instagram": "https://instagram.com/la_especial_cuba?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/w9wquc5k142qkdydr0vfbzl4rtvn",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos pre elaborados de primera calidad. \r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Plaza, Playa y Centro Habana) ",
                "address": "Calle 23 esquina 8. Casa IACAS. Vedado",
                "contact_phone": "+53 50913263",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 44,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Living Havana",
                "meta_description": "Productos pre elaborados de primera calidad. \r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Plaza, Playa y Centro Habana) ",
                "meta_keywords": "pack congelados  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, croqueta, croquetas, croquetas de bechamel, congelados, preelaborados, pre elaborados, albondigas, bolitas de queso ",
                "seo_title": null
            },
            {
                "id": 39,
                "url": "https://arte-vintage.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "arte-vintage",
                "default": false,
                "created_at": "2021-09-13T15:17:02.082-04:00",
                "updated_at": "2023-07-28T15:37:32.940-04:00",
                "facebook": "https://www.facebook.com/decoupagevintagecuba",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/018n0xwfq6kc8q7i7oemsrb3wjgm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de regalos y decoración donde podrán encontrar variados artículos utilitarios en  madera, cristal, entre otros materiales hechos a mano.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "address": "",
                "contact_phone": "52431855",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 46,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Arte Vintage",
                "meta_description": "Tienda de regalos y decoración donde podrán encontrar variados artículos utilitarios en  madera, cristal, entre otros materiales hechos a mano.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Decoración, artículos utilitarios, madera, cristal ",
                "seo_title": null
            },
            {
                "id": 1393,
                "url": "https://fantasia-de-princesa.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "fantasia-de-princesa",
                "default": false,
                "created_at": "2022-07-05T00:12:17.004-04:00",
                "updated_at": "2023-07-28T16:06:45.755-04:00",
                "facebook": "https://www.facebook.com/salondebellezafantasiasdeprincesas",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/8ysjrrhu6ru7fbinexhh5fpiqyei",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un spa especializado en público infantil. Realizamos maquillajes y peinados de fantasías y ofertamos diversos disfraces para los más pequeños de casa y también sus padres. Ofrecemos una experiencia inolvidable y personalizada que puede recordar con fotos profesionales que le realizamos. \r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Cita en el salón, Dirección: Calle línea entre d y e número 538.",
                "address": "Calle línea entre d y e número 538",
                "contact_phone": "78326533",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 47,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Fantasía de Princesa",
                "meta_description": "Somos un spa especializado en público infantil. Realizamos maquillajes y peinados de fantasías y ofertamos diversos disfraces para los más pequeños de casa y también sus padres. Ofrecemos una experiencia inolvidable y personalizada que puede recordar con fotos profesionales que le realizamos. \r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Cita en el salón, Dirección: Calle línea entre d y e número 538.",
                "meta_keywords": "Disfraces, maquillaje, peinados, fotos, fantasías ",
                "seo_title": "Disfraces, maquillaje, peinados, fotos, fantasías "
            },
            {
                "id": 6,
                "url": "https://el-idilio.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "el-idilio",
                "default": false,
                "created_at": "2021-04-20T11:47:51.024-04:00",
                "updated_at": "2023-07-28T15:24:22.267-04:00",
                "facebook": "https://www.facebook.com/elidiliorestaurante",
                "twitter": "",
                "instagram": "https://instagram.com/el_idilio_?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/km9yboqi0pcmqpdndxct047ybbab",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Restaurante de comida internacional y al carbón en parrillada a la vista del cliente.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Plaza, Cerro, Centro Habana y Habana Vieja)",
                "address": "",
                "contact_phone": "+53 5 2913832",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 49,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "El Idilio",
                "meta_description": "Restaurante de comida internacional y al carbón en parrillada a la vista del cliente.\r\n\r\nTiempo de Entrega: 24 horas.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Plaza, Cerro, Centro Habana y Habana Vieja)\r\n",
                "meta_keywords": "restaurante,  pizzeria, paella, combos, envíos a cuba, envio a cuba, cerdo, puerco, pizza de queso, pizza familiar, ",
                "seo_title": null
            },
            {
                "id": 2712,
                "url": "https://rapid-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rapid-cuba",
                "default": false,
                "created_at": "2022-12-15T06:56:32.126-05:00",
                "updated_at": "2023-08-06T11:08:12.556-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/qf1iz0nf1s4fpmls7nccc89zamjy",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana, Artemisa (Ciudad de Artemisa ,Alquilar, San Antonio de los Baños,Güira de Melena, Bauta y Caimito), Mayabeque (San José, Güines, Quivicán, La Salud, Buenaventura, Bejucal, San Antonio de las Vegas, Batabano, San Felipe).",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 51,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Rapid Cuba ",
                "meta_description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Mercado, Combos, Agromercado",
                "seo_title": null
            },
            {
                "id": 14,
                "url": "https://lola-cafe.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "lola-cafe",
                "default": false,
                "created_at": "2021-04-20T15:22:20.029-04:00",
                "updated_at": "2023-08-03T14:35:03.465-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/w2fdb00lubk3lsz5a71paqlpnly7",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Repostería artesanal con recetas internacionales, ingredientes locales y sabores tradicionales cubanos. Dulces a base de bizcocho, chocolate, canela y frutas tropicales, que buscan elaboraciones creativas y nutritivas para la familia cubana. \r\n\r\nRecogida en tienda: 24 a 48 horas. \r\nAbierto todos los días de 10 am a 10 pm. \r\n\r\nZona: La Habana\r\nDirección: Ave. 31 No. 5409 entre 54 y 56. Playa, Habana. ",
                "address": "Municipio Playa ave 31 #5409 entre 54 y 56",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 54,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Lola Café ",
                "meta_description": "Repostería artesanal con recetas internacionales, ingredientes locales y sabores tradicionales cubanos. Dulces a base de bizcocho, chocolate, canela y frutas tropicales, que buscan elaboraciones creativas y nutritivas para la familia cubana. \r\n\r\nRecogida en tienda: 24 a 48 horas. \r\n\r\nZona: La Habana\r\nDirección: Ave. 31 No. 5409 entre 54 y 56. Playa, Habana. ",
                "meta_keywords": "Dulces, Dulcería, Tartas, Cakes, Postres, Panetela, Chocolate",
                "seo_title": null
            },
            {
                "id": 964,
                "url": "https://bembe.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "bembe",
                "default": false,
                "created_at": "2022-06-08T10:08:29.534-04:00",
                "updated_at": "2023-07-28T16:00:53.692-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/bembe_tienda_de_dulces?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/6xe4gcmowkvizgt61pymur2gimep",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Dulcería - Especializada en dulces cubanos.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS HABANA DEL ESTE)\r\n",
                "address": "",
                "contact_phone": "+53 5 9525920",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 55,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "BemBè",
                "meta_description": "Dulcería - Especializada en dulces cubanos.\r\n\r\nTiempo de Entrega: 3 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS HABANA DEL ESTE)\r\n\r\n\r\n\r\n",
                "meta_keywords": "Dulces, Cakes,  dulces dulceria dulcería chocolate torta quei panetela chocolate, vainilla,  envíos a cuba,  envio a cuba",
                "seo_title": null
            },
            {
                "id": 1427,
                "url": "https://miga-dorada.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "miga-dorada",
                "default": false,
                "created_at": "2022-07-12T06:05:20.084-04:00",
                "updated_at": "2023-07-28T10:52:52.980-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/jpmovyke4ukph5gkinxq7li6mn81",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Panadería y dulcería con variadas ofertas de productos elaborados artesanalmente con  los mejores ingredientes.\r\nTiempo de Entrega: 72 hrs\r\nModo de Entrega: Entrega a Domicilio en toda La Habana.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 57,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Miga Dorada  ",
                "meta_description": "Panadería y dulcería con variadas ofertas de productos elaborados artesanalmente con  los mejores ingredientes.\r\nTiempo de Entrega: 72 hrs\r\nModo de Entrega: Entrega a Domicilio en toda La Habana.",
                "meta_keywords": "Panadería, Dulcería, Cakes, Combos",
                "seo_title": "Panadería y dulcería con ofertas de combos para toda la familia. "
            },
            {
                "id": 1227,
                "url": "https://dulce-mezcla.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "dulce-mezcla",
                "default": false,
                "created_at": "2022-06-22T15:32:16.480-04:00",
                "updated_at": "2023-08-09T07:22:06.314-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100083564254602",
                "twitter": "",
                "instagram": "https://www.instagram.com/dulcemezcla.oficial/",
                "logo_file_name": "https://dev.static.katapulk.com/fgwtwoehap1b7kdn4wc8ssa566jg",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un pequeño negocio en cuba dedicado a la repostería. Nos especializamos en cakes de crema de mantequilla de diferentes sabores y otros pasteles. con un servicio a domicilio rápido y eficiente. \r\n\r\nTiempo de entrega: 72 horas.\r\n\r\nZona: La Habana. ",
                "address": "Calle 240 /5ta y 5ta A Jaimanitas Playa, Havana, Cuba. ",
                "contact_phone": "53096343 - 51170816",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 58,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Dulce Mezcla",
                "meta_description": "Somos un pequeño negocio en cuba dedicado a la repostería. Nos especializamos en cakes de crema de mantequilla de diferentes sabores y otros pasteles. con un servicio a domicilio rápido y eficiente. \r\n\r\nTiempo de entrega: 72 horas.\r\n\r\nZona: La Habana. ",
                "meta_keywords": "Dulce, resposteria, tarta, panetela, pastel, cumpleaños,  domicilio. ",
                "seo_title": null
            },
            {
                "id": 15,
                "url": "https://home-deli.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "home-deli",
                "default": false,
                "created_at": "2021-04-20T16:35:10.275-04:00",
                "updated_at": "2024-03-19T15:37:40.122-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/l7vxv5hkt2bzgl3yg4icw6704wew",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda especializada en los productos pre elaborados y congelados. Ofertas gourmet hasta la comodidad de su hogar. Comida italiana y criolla elaborada / pre elaborada, sellada al vacío y congelada.\r\nTiempo de entrega: 48-72 hrs\r\nModo de entrega: \r\nEntrega a Domicilio de productos:  La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución,  Playa.\r\nEntrega de Combos: La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución,  Playa, Cotorro, Boyeros, San Miguel del Padrón, Guanabacoa,  Habana del Este",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 59,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Home Deli  ",
                "meta_description": "Somos una tienda especializada en los productos pre elaborados y congelados. Ofertas gourmet hasta la comodidad de su hogar. Comida italiana y criolla elaborada / pre elaborada, sellada al vacío y congelada.\r\nTiempo de entrega: 48-72 hrs\r\nModo de entrega: \r\nEntrega a Domicilio de productos:  La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución,  Playa.\r\nEntrega de Combos: La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución,  Playa, Cotorro, Boyeros, San Miguel del Padrón, Guanabacoa,  Habana del Este",
                "meta_keywords": "Mercado, PreElaborados, Congelados, Productos de Despensa",
                "seo_title": null
            },
            {
                "id": 337,
                "url": "https://croquetas-fernandez.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "croquetas-fernandez",
                "default": false,
                "created_at": "2022-02-01T13:54:24.868-05:00",
                "updated_at": "2023-08-03T15:03:42.592-04:00",
                "facebook": "https://www.facebook.com/CroquetasFernandez",
                "twitter": "https://twitter.com/CroquetasFdez?t=tWkoWyGj-CzNuMlfvU9Oyg\u0026s=09",
                "instagram": "https://www.instagram.com/croquetasfernandez/",
                "logo_file_name": "https://dev.static.katapulk.com/7vysfe8alfztjfw6216dwrefvj6k",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Croquetería donde puedes disfrutar de la bechamel en una croqueta cremosa y crujiente. Con ingredientes 100% naturales.\r\n\r\nTiempo de entrega: 48 horas, Envío Gratis. \r\n\r\nZona: La Habana. ",
                "address": "",
                "contact_phone": "(53) 5272 4641",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 60,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Croquetas Fernández",
                "meta_description": "Croquetería donde puedes disfrutar de la bechamel en una croqueta cremosa y crujiente. Con ingredientes 100% naturales.\r\n\r\nTiempo de entrega: 48 horas, Envío Gratis. \r\n\r\nZona: La Habana. ",
                "meta_keywords": "Croquetas caseras, ingredientes naturales, cremosas, crujientes. ",
                "seo_title": null
            },
            {
                "id": 2911,
                "url": "https://bianca-s-r-l.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "bianca-s-r-l",
                "default": false,
                "created_at": "2023-01-12T12:05:39.586-05:00",
                "updated_at": "2023-08-03T15:08:10.332-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/r5fw0lmjquajujahp3wre32zhs1a",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En Bianca S.R.L hacemos tus deseos de tomar helados de primera categoria realidad, helados artesanales italianos a base de pura crema, con 22 sabores disponibles en varios formatos, para que te des un gustazo. \r\n\r\nTiempo de entrega: 72 horas \r\n\r\nZona: La Habana (Marianao, Cerro, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 63,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Bianca S.R.L",
                "meta_description": "En Bianca S.R.L hacemos tus deseos de tomar helados de primera categoria realidad, helados artesanales italianos a base de pura crema, con 22 sabores disponibles en varios formatos, para que te des un gustazo. \r\n\r\nTiempo de entrega: 72 horas \r\n\r\nZona: La Habana (Marianao, Cerro, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "Heladería, Helado Artesanal",
                "seo_title": null
            },
            {
                "id": 139,
                "url": "https://bb-s.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "bb-s",
                "default": false,
                "created_at": "2021-10-22T13:41:06.419-04:00",
                "updated_at": "2023-08-03T15:09:57.335-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100076794678441",
                "twitter": "https://twitter.com/bbs_havana?s=21\u0026t=zfMK5FXFpam5H3WwT2_jcw",
                "instagram": "https://instagram.com/bbs_cuba?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/ndg5rlh6vzllaj5ubs3vbyhi2sqa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos BB’s, producimos y comercializamos Confituras de CHOCOLATE, Bombones, alimentos congelados y pre elaborados. \r\nTiempo de entrega: 3 a 5 días. \r\n\r\nZona: La Habana (10 de Octubre, Guanabacoa, Habana del Este, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución y Playa)\r\n\r\nCalle Pepe Antonio entre Martí y Candelaria. La Pastelera. \r\nMunicipio: Guanabacoa. \r\nHorario: 9:30 am - 6:00 pm\r\nTenemos un tiempo de entrega de 3 a 5 días. ",
                "address": "Calle Pepe Antonio entre Martí y Candelaria. La Pastelera. \r\nMunicipio: Guanabacoa. \r\nHorario: 9:30 am - 6:00 pm",
                "contact_phone": "53 59757877‬ // 77923080",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 64,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "BB’s ",
                "meta_description": "Somos BB’s, producimos y comercializamos Confituras de CHOCOLATE, Bombones, alimentos congelados y pre elaborados. \r\n\r\nTiempo de entrega: 3 a 5 días. \r\n\r\nZona: La Habana (10 de Octubre, Guanabacoa, Habana del Este, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución y Playa)",
                "meta_keywords": "chocolate, tarta, dulce, congelados, preelaborados, croquetas, mini tarta, katapulk, cuba, envio, domicilio. ",
                "seo_title": null
            },
            {
                "id": 2350,
                "url": "https://la-chela.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-chela",
                "default": false,
                "created_at": "2022-11-11T06:09:38.216-05:00",
                "updated_at": "2023-08-03T15:13:44.069-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100083689570516",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/prnsm9px1jnwpsvti6mg0toumi5f",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Ofertamos una amplia gama de productos que va desde alimentos ya elaborados, productos de mercado, útiles del hogar, regalos y más. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana ",
                "address": "Calle 39 número 899 entre 24 y 26 nuevo vedado ",
                "contact_phone": "52894618",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 66,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Chela ",
                "meta_description": "Ofertamos una amplia gama de productos que va desde alimentos ya elaborados, productos de mercado, útiles del hogar, regalos y más. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana ",
                "meta_keywords": "Pizza confrituras combos comida italiana, helados, juguetes, yogurt ",
                "seo_title": "Pizza confrituras tamal refresco pasta dulces chocolates paletas "
            },
            {
                "id": 668,
                "url": "https://sweet-love.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "sweet-love",
                "default": false,
                "created_at": "2022-03-17T14:51:51.133-04:00",
                "updated_at": "2023-08-03T13:44:11.161-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/iz8f8ps9921jq6x33ydnwe6fplcn",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Dulcería especializada en cakes y tartas de la mejor calidad.\r\nTiempo de entrega: 72 hrs\r\nModo de entrega: Envío a Domicilio en toda La Habana",
                "address": "",
                "contact_phone": "+53 5 8186360",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 67,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Sweet Love",
                "meta_description": "Dulcería especializada en cakes y tartas de la mejor calidad.\r\nTiempo de entrega: 72 hrs\r\nModo de entrega: Envío a Domicilio en toda La Habana",
                "meta_keywords": "Dulces, dulcería, tartas, cakes, combos, ofertas.",
                "seo_title": null
            },
            {
                "id": 2184,
                "url": "https://al-campestre.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "al-campestre",
                "default": false,
                "created_at": "2022-10-20T04:35:59.016-04:00",
                "updated_at": "2024-04-13T17:49:17.493-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/9hubijb2i9lqmmnq8npcenewb7le",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Al Campestre brinda servicio de comida criolla y pizzas al carbón, también tenemos una amplia gama de productos de mercado y alimentos del agro, regalos y más. \r\n\r\nTiempo de entrega: 48 horas \r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 68,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 100,
                "name": "Al Campestre",
                "meta_description": "Al Campestre brinda servicio de comida criolla y pizzas al carbón, también tenemos una amplia gama de productos de mercado y alimentos del agro, regalos y más. \r\n\r\nTiempo de entrega: 48 horas \r\n\r\nZona: La Habana",
                "meta_keywords": "Comida criolla, carne, cerdo, pescado, arroz, al carbón, alimentos, katapulk, cuba",
                "seo_title": null
            },
            {
                "id": 406,
                "url": "https://isla-360.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "isla-360",
                "default": false,
                "created_at": "2022-02-08T13:12:52.998-05:00",
                "updated_at": "2023-08-03T15:17:50.168-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/uwx9sl27n35iudelmeu8ikedsnkc",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Venta de productos de supermercado importados y nacionales con una amplia variedad de ofertas para satisfacer las necesidades de tu despensa.\r\nTiempo de entrega: 7 días\r\n\r\nZona: La Habana. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 96,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Isla 360",
                "meta_description": "Venta de productos de supermercado importados y nacionales con una amplia variedad de ofertas para satisfacer las necesidades de tu despensa.\r\n\r\nTiempo de entrega: 7 días\r\n\r\nZona: La Habana. ",
                "meta_keywords": "Supermercado, Agro, Alimentos, carnes, enlatados, granos, pastas, enlatados, aseo ",
                "seo_title": null
            },
            {
                "id": 600,
                "url": "https://sovi-juguetes-didacticos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "sovi-juguetes-didacticos",
                "default": false,
                "created_at": "2022-03-08T16:40:09.287-05:00",
                "updated_at": "2023-07-28T10:38:47.677-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/zfdjw96tmzuaxm0e2n2sy5yy3h7c",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Juguetes didácticos y de mesa para niñas y niños.\r\nTiempo de entrega: 7 días\r\nModo de entrega: Entrega a Domicilio en La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 105,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Sovi Juguetes Didácticos",
                "meta_description": "Juguetes didácticos y de mesa para niñas y niños.\r\nTiempo de entrega: 7 días\r\nModo de entrega: Entrega a Domicilio en La Habana",
                "meta_keywords": "Juguetes, juegos de mesa didáctico, pasatiempos, implementos para niños ",
                "seo_title": null
            },
            {
                "id": 2943,
                "url": "https://linterna-roja.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "linterna-roja",
                "default": false,
                "created_at": "2023-01-13T08:06:07.022-05:00",
                "updated_at": "2023-08-03T15:19:51.544-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/46h5ab5wnmcot5s5g40p1vt0p27s",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Restaurante de comida China para llevar. Con todo el sabor de Asia en nuestros platillos. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 106,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Linterna Roja ",
                "meta_description": "Restaurante de comida China para llevar. Con todo el sabor de Asia en nuestros platillos. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana",
                "meta_keywords": "Restaurante, Comida, China",
                "seo_title": null
            },
            {
                "id": 2745,
                "url": "https://disangie.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "disangie",
                "default": false,
                "created_at": "2022-12-20T06:41:37.483-05:00",
                "updated_at": "2023-08-03T15:21:41.209-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/aoyk262c32sxqi4lxym52e7lzqnq",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Variedad de productos disponibles para toda la familia cubana. En nuestra tienda puede encontrar todo tipo de panes, dulces y platos elaborados y útiles del hogar. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 111,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Disangie",
                "meta_description": "Variedad de productos disponibles para toda la familia cubana. En nuestra tienda puede encontrar todo tipo de panes, dulces y platos elaborados y útiles del hogar. \r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana. ",
                "meta_keywords": "Panadería, Dulcería, Restaurante",
                "seo_title": null
            },
            {
                "id": 2844,
                "url": "https://d-lya.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "d-lya",
                "default": false,
                "created_at": "2023-01-12T07:18:26.111-05:00",
                "updated_at": "2023-08-03T15:24:07.050-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/bh0pgrjsqo2vi7gjsiqtawhgglm8",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una empresa especializada en la elaboración y comercialización de productos alimenticios. La mayoría de nuestros productos son importados de primera calidad. \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 117,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "D'LYA",
                "meta_description": "Somos una empresa especializada en la elaboración y comercialización de productos alimenticios. La mayoría de nuestros productos son importados de primera calidad. \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana.  ",
                "meta_keywords": "Mercado , Pan, lácteos, útiles del hogar",
                "seo_title": null
            },
            {
                "id": 2514,
                "url": "https://sandwich-gourmet.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "sandwich-gourmet",
                "default": false,
                "created_at": "2022-11-29T16:06:24.589-05:00",
                "updated_at": "2023-02-17T16:02:33.749-05:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/d4bjyix7ym495rsr5w0o79ca3780",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Sándwich gourmet, especialista en sandwiches cubanos y mediterráneos, papas fritas y los rollitos de canela y moka dulces al estilo escandinavo.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 119,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Sándwich Gourmet ",
                "meta_description": "Sándwich gourmet, especialista en sandwiches cubanos y mediterráneos, papas fritas y los rollitos de canela y moka dulces al estilo escandinavo.",
                "meta_keywords": "sándwich, sándwiches cubanos, sándwiches mediterráneos, papas fritas, dulces, rollitos, moka",
                "seo_title": null
            },
            {
                "id": 1262,
                "url": "https://regalitos-miau.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "regalitos-miau",
                "default": false,
                "created_at": "2022-06-22T21:59:26.423-04:00",
                "updated_at": "2023-08-03T15:26:42.191-04:00",
                "facebook": "https://www.facebook.com/regalitosmiau.cuba",
                "twitter": "",
                "instagram": "https://www.instagram.com/regalitos_miau/",
                "logo_file_name": "https://dev.static.katapulk.com/bmwqaew9r03znncf9wwlx6hi9p0h",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Regalitos MIAU es una tienda de regalos de Manualidades para todos los gustos, todas las edades y para cualquier ocasión.\r\n\r\n\r\nTiempo de entrega: 5 días\r\n\r\nZona: La Habana (Marianao, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "5 2670780",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 130,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Regalitos MIAU",
                "meta_description": "Regalitos MIAU es una tienda de regalos de Manualidades para todos los gustos, todas las edades y para cualquier ocasión.\r\n\r\nTiempo de entrega: 5 días\r\n\r\nZona: La Habana (Marianao, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "regalos, regalitos miau, manualidades, artesania, papeleria, cuba, envios, domicilio, katapulk. ",
                "seo_title": null
            },
            {
                "id": 2912,
                "url": "https://jibaro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "jibaro",
                "default": false,
                "created_at": "2023-01-12T12:27:31.681-05:00",
                "updated_at": "2023-08-03T16:23:44.153-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/b51zxwm10np8edazc9ze6d526cl4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Jíbaro es tu restaurante especializado en comida cubana e internacional, con ese toque entre la modernidad y aquello que ha hecho mágicos muchos platos con los que contamos. Con amplia variedad para toda la familia, para todos los gustos. \r\n\r\nTiempo de entrega: 24 a 48 horas \r\nEnvío Gratis. \r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 131,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Jíbaro",
                "meta_description": "Jí.baro es tu restaurante especializado en comida cubana e internacional, con ese toque entre la modernidad y aquello que ha hecho mágicos muchos platos con los que contamos. Con amplia variedad para toda la familia, para todos los gustos. \r\n\r\nTiempo de entrega: 24 a 48 horas \r\nEnvío Gratis. \r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "Restaurante, Cubano, Internacional ",
                "seo_title": null
            },
            {
                "id": 1789,
                "url": "https://herbolaria-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "herbolaria-cuba",
                "default": false,
                "created_at": "2022-08-29T06:36:41.849-04:00",
                "updated_at": "2023-08-03T13:48:16.971-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/57ae1s6vf0jx5h1ierlit96ll7oa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un  emprendimiento familiar que busca volver la mirada al consumo de lo natural.",
                "address": "Plaza San Fang Kong del barrio Chino, San Nicolás entre Zanja y Dragones.",
                "contact_phone": "58181000 - 52950490",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 132,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Herbolaria Cuba",
                "meta_description": "Somos un  emprendimiento familiar que busca volver la mirada al consumo de lo natural.",
                "meta_keywords": "Productos naturales, aceites esenciales, jabones.",
                "seo_title": null
            },
            {
                "id": 2877,
                "url": "https://big-combo.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "big-combo",
                "default": false,
                "created_at": "2023-01-12T10:01:16.655-05:00",
                "updated_at": "2023-08-03T15:31:22.587-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/88evysih3jxeeg0r4hm3b99h5wtf",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Grandes ofertas en combos de todo tipo para todos los bolsillos, con los productos más variados y necesarios para tu hogar. \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 135,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Big Combo ",
                "meta_description": "Grandes ofertas en combos de todo tipo para todos los bolsillos, con los productos más variados y necesarios para tu hogar. \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana",
                "meta_keywords": "Mercado, Combos, Alimentos, Importados",
                "seo_title": null
            },
            {
                "id": 1986,
                "url": "https://yerberito-co.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "yerberito-co",
                "default": false,
                "created_at": "2022-10-03T10:28:54.581-04:00",
                "updated_at": "2023-08-03T13:48:28.703-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/yerberito_cuba?r=nametag",
                "logo_file_name": "https://dev.static.katapulk.com/a4jhgvcnn4ldo3dhqw7r5khxviob",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En Yerberito \u0026 CO producimos hortalizas orgánicas, frutas, plantas medicinales y aromáticas, asequibles, de temporada y frescas.Cuidamos y tratamos las hierbas con máximo cuidado y dedicación, dándole un sello de exclusividad a nuestro producto. \r\nTiempo de Entrega: 24 horas\r\nModo de Entrega:  Entrega a Domicilio en La Lisa, Habana Vieja, Centro Habana, Plaza, Playa",
                "address": "5ta avenida #26411 entre 264 y 266 Santa Fe Playa",
                "contact_phone": "+53 5 5054554",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 138,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Yerberito \u0026 Co",
                "meta_description": "En Yerberito \u0026 CO producimos hortalizas orgánicas, frutas, plantas medicinales y aromáticas, asequibles, de temporada y frescas.Cuidamos y tratamos las hierbas con máximo cuidado y dedicación, dándole un sello de exclusividad a nuestro producto. \r\nTiempo de Entrega: 24 horas\r\nModo de Entrega:  Entrega a Domicilio en La Lisa, Habana Vieja, Centro Habana, Plaza, Playa",
                "meta_keywords": "Agro mercado, hortalizas orgánicas, frutas, vegetales, productos naturales.",
                "seo_title": null
            },
            {
                "id": 2317,
                "url": "https://orozuz.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "orozuz",
                "default": false,
                "created_at": "2022-11-10T04:59:10.837-05:00",
                "updated_at": "2023-08-03T13:48:50.501-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/iy2svihzla59qyjsxlsrqdyd4q3x",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una marca dedicada a la creación de infusiones 100% orgánicas, deshidratamos hojas, flores y frutas locales para crear blends únicos con aroma y sabor a Cuba, siendo los primeros del país en comercializar infusiones frías y combinar la cultura del té y la coctelería. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 142,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "OroZuz",
                "meta_description": "Somos una marca dedicada a la creación de infusiones 100% orgánicas, deshidratamos hojas, flores y frutas locales para crear blends únicos con aroma y sabor a Cuba, siendo los primeros del país en comercializar infusiones frías y combinar la cultura del té y la coctelería. ",
                "meta_keywords": "Infusiones, te, bebidas frías, productos orgánicos, naturales ",
                "seo_title": null
            },
            {
                "id": 930,
                "url": "https://miss-karina.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "miss-karina",
                "default": false,
                "created_at": "2022-06-06T14:12:07.615-04:00",
                "updated_at": "2023-08-03T15:33:38.713-04:00",
                "facebook": "https://www.facebook.com/profile.php?id=100063772761026",
                "twitter": "",
                "instagram": "https://www.instagram.com/invites/contact/?i=xlxsvjpo6hz6\u0026utm_content=loq3uj4",
                "logo_file_name": "https://dev.static.katapulk.com/fnvpu8h9axfe5fo6adhu29ulbko4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Dulcería con variedad de Tartas finas y dulces gourmet ideales para una ocasión especial. \r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana",
                "address": "Calle Oquendo y Concordia # 634. Centro Habana ",
                "contact_phone": "53577201",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 145,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Miss Karina",
                "meta_description": "Dulcería con variedad de Tartas finas y dulces gourmet ideales para una ocasión especial. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana",
                "meta_keywords": "Dulcería, dulces finos, tartas, semifríos. ",
                "seo_title": null
            },
            {
                "id": 1821,
                "url": "https://merca-tuaba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "merca-tuaba",
                "default": false,
                "created_at": "2022-09-06T08:26:11.394-04:00",
                "updated_at": "2023-08-03T15:35:53.852-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/5km109yjko8tv7f81kl93w5w77jm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de productos agro industriales conservas, cárnicos y productos naturales. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila. ",
                "address": "",
                "contact_phone": "52850530",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 146,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Merca Tuaba",
                "meta_description": "Tienda de productos agro industriales conservas, cárnicos y productos naturales. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila. ",
                "meta_keywords": "Conservas, Jugos, Enlatados, Carnícos",
                "seo_title": "Tienda de productos agroindustriales conservas carnicos y productos naturales "
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 303,
                "url": "https://helados-q-rollo.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "helados-q-rollo",
                "default": false,
                "created_at": "2022-01-27T09:10:06.547-05:00",
                "updated_at": "2023-08-03T15:36:57.057-04:00",
                "facebook": "https://www.facebook.com/HeladosQRollo",
                "twitter": "",
                "instagram": "https://www.instagram.com/heladosqrollo/",
                "logo_file_name": "https://dev.static.katapulk.com/uej3yo897bb4rqr77itxxahivh5a",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Helados artesanales de primera calidad. Heladería Q'Rollo, mini-industria privada de producción de helados de crema, con un proceso tecnológico completo. \r\nTiempo de entrega: 48 a 72 horas \r\n\r\nZona: La Habana (Plaza de la Revolución, Playa, Marianao, Guanabacoa, Regla, Centro Habana y Habana Vieja)",
                "address": "Dirección: calle 21 no 1403 e/ 26 y 28, Vedado, La Habana",
                "contact_phone": "5 9709913",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 156,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Helados Q´Rollo",
                "meta_description": "Helados artesanales de primera calidad. Heladería Q'Rollo, mini-industria privada de producción de helados de crema, con un proceso tecnológico completo. \r\nTiempo de entrega: 48 a 72 horas \r\n\r\nZona: La Habana (Plaza de la Revolución, Playa, Marianao, Guanabacoa, Regla, Centro Habana y Habana Vieja)",
                "meta_keywords": "helado, heladería, chocolate, dulcería, dulce, vainilla, helado de chocolate, helado cubano, cuba, katapulk, q'rollo, helado artesanal, envio, domicilio.  ",
                "seo_title": null
            },
            {
                "id": 1560,
                "url": "https://la-cueva.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-cueva",
                "default": false,
                "created_at": "2022-07-28T09:41:56.874-04:00",
                "updated_at": "2023-09-05T10:31:11.764-04:00",
                "facebook": "https://www.facebook.com/lacuevadeabcuba",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a6v3smy2iupdqetc582vn3sskmnc",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nuestro negocio oferta deliciosos dulces y cakes para el disfrute en familia, con ingredientes de primera calidad y gran prestigio.\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: Entrega a Domicilio en Arroyo Naranjo, Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Guanabacoa, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución\r\nModo de entrega: Entrega a Domicilio en Arroyo Naranjo, Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Guanabacoa, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución",
                "address": "Ave. Acosta # 68 / Felipe Poey y Heredia. La Víbora. 10 de Octubre.",
                "contact_phone": "59710570 - 76489151",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 160,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Cueva",
                "meta_description": "Nuestro negocio oferta deliciosos dulces y cakes para el disfrute en familia, con ingredientes de primera calidad y gran prestigio.\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: Entrega a Domicilio en Arroyo Naranjo, Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Guanabacoa, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución",
                "meta_keywords": "Dulcería, dulces finos, cakes, tartas. ",
                "seo_title": null
            },
            {
                "id": 996,
                "url": "https://dona-manuela.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "dona-manuela",
                "default": false,
                "created_at": "2022-06-08T14:12:10.369-04:00",
                "updated_at": "2023-08-07T12:41:20.459-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/dulcesdonamanuela?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/b2xdwa48pk32o6iurysupbbzfzut",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de dulces tradicionales, con un estilo de vida eco amigable elaborados artesanalmente.\r\n Productos orgánicos y naturales. \r\nTiempo de Entrega: 7 días\r\nModo de entrega: Entrega a Domicilio en toda La Habana",
                "address": "Calle 60 #108 bajos e/ 1ra y 1raA, Miramar",
                "contact_phone": "51320917",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 172,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Doña Manuela ",
                "meta_description": "Tienda de dulces tradicionales, con un estilo de vida eco amigable elaborados artesanalmente.\r\n Productos orgánicos y naturales. \r\nTiempo de Entrega: 7 días\r\nModo de entrega: Entrega a Domicilio en toda La Habana",
                "meta_keywords": "Productos orgánicos, productos naturales, dulces caseros, postres tradicionales",
                "seo_title": null
            },
            {
                "id": 26,
                "url": "https://nely-s-burger.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "nely-s-burger",
                "default": false,
                "created_at": "2021-06-07T12:29:32.511-04:00",
                "updated_at": "2023-08-03T15:39:16.488-04:00",
                "facebook": "https://www.facebook.com/NelysBurger",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/uh9mtujlalnx1yecpe85ntc371iq",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un restaurante de comida rápida cubana con Variedad de Hamburguesas, Sándwich, Ensaladas, Postres y Jugos de Frutas Naturales. Recuerda que no hay pan que por bien no venga. \r\n\r\nTiempo de entrega: 24 a 72 horas de miércoles a domingos.\r\n\r\nZona: La Habana (Cerro, 10 de Octubre, Habana Vieja, Centro Habana, Plaza de la Revolución y Playa)",
                "address": "",
                "contact_phone": "5 6708562",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 176,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Nely's burger ",
                "meta_description": "Somos un restaurante de comida rápida cubana con Variedad de Hamburguesas, Sándwich, Ensaladas, Postres y Jugos de Frutas Naturales. Recuerda que no hay pan que por bien no venga. \r\n\r\nTiempo de entrega: 24 a 72 horas de miércoles a domingos.\r\n\r\nZona: La Habana (Cerro, 10 de Octubre, Habana Vieja, Centro Habana, Plaza de la Revolución y Playa)",
                "meta_keywords": "comida rápida, hamburguesas, comida, alimentos,  pan, comida criolla, postres, dulces, variedades, mercado, carne. ",
                "seo_title": null
            },
            {
                "id": 506,
                "url": "https://morro-52.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "morro-52",
                "default": false,
                "created_at": "2022-02-25T17:51:47.555-05:00",
                "updated_at": "2023-08-07T12:42:20.499-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/i62t5lfxvxb1mrzls8pm2wunwqer",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestro restaurante encontrarás  una amplia oferta de comida criolla e internacional al carbón, para compartir y disfrutar de momentos inolvidables.\r\nTiempo de Entrega: 24 hrs\r\nModo de entrega: Entrega a domicilio en Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la revolución, Playa",
                "address": "Morro 52/ Genio y Refugio. Habana 𝕧𝕚𝕖𝕛𝕒 ",
                "contact_phone": "78601437",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 177,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Morro 52",
                "meta_description": "En nuestro restaurante encontrarás  una amplia oferta de comida criolla e internacional al carbón, para compartir y disfrutar de momentos inolvidables.\r\nTiempo de Entrega: 24 hrs\r\nModo de entrega: Entrega a domicilio en Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la revolución, Playa",
                "meta_keywords": "Comida criolla, comida internacional, comida al carbón, tapas, tacos, coctelería. ",
                "seo_title": null
            },
            {
                "id": 36,
                "url": "https://dforja.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "dforja",
                "default": false,
                "created_at": "2021-07-28T05:10:07.480-04:00",
                "updated_at": "2023-08-03T15:40:46.816-04:00",
                "facebook": "https://www.facebook.com/dforjacreaciones",
                "twitter": "",
                "instagram": "https://www.instagram.com/dforjacreaciones/",
                "logo_file_name": "https://dev.static.katapulk.com/9zuy34vu5j16jg8yvxyojm9atwdh",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Brindamos confort a su hogar con nuestros muebles únicos para hacer de su casa un lugar más agradable, muebles con un bonito y fuerte acabado para preservar la durabilidad del mismo.\r\n\r\nTiempo de entrega: 10 a 15 días. \r\n\r\nZona: La Habana\r\n",
                "address": "",
                "contact_phone": "5 8011173",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 184,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "DFORJA",
                "meta_description": "Brindamos confort a su hogar con nuestros muebles únicos para hacer de su casa un lugar más agradable, muebles con un bonito y fuerte acabado para preservar la durabilidad del mismo.\r\n\r\nTiempo de entrega: 10 a 15 días. \r\n\r\nZona: La Habana\r\n",
                "meta_keywords": "muebles, restauracion, dforja creaciones, utiles para el hogar, madera, katapulk, creacion, carpinteria, diseño, domicilio. ",
                "seo_title": null
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 3471,
                "url": "https://dulcecitos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "dulcecitos",
                "default": false,
                "created_at": "2023-04-19T12:57:22.711-04:00",
                "updated_at": "2023-08-07T12:43:18.329-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/sanphajkfhirxryg2fgi1j1lrewj",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nuestra tienda oferta dulces, panes y cakes para las más disímiles ocasiones con productos de primera calidad. \r\nAtención personalizada al cliente y rápida entrega.\r\nTiempo de entrega: 48 hrs\r\nModo de entrega: Entrega a domicilio en toda La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 188,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Dulcecitos ",
                "meta_description": "Nuestra tienda oferta dulces, panes y cakes para las más disímiles ocasiones con productos de primera calidad. \r\nAtención personalizada al cliente y rápida entrega.\r\nTiempo de entrega: 48 hrs\r\nModo de entrega: Entrega a domicilio en toda La Habana",
                "meta_keywords": "Ducería, Panadería, Cakes ",
                "seo_title": null
            },
            {
                "id": 1854,
                "url": "https://afro-wright.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "afro-wright",
                "default": false,
                "created_at": "2022-09-07T07:07:04.041-04:00",
                "updated_at": "2023-08-07T12:39:23.364-04:00",
                "facebook": "https://www.facebook.com/groups/730062267674378/?ref=share",
                "twitter": "",
                "instagram": "https://www.instagram.com/p/CjLUabWOEX3/?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/2hm3rk2fmi5gacyyi8rmtcell3tc",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una tienda especializada en la producción y comercialización de productos naturales para el cabello afro-rizado de forma artesanal\r\nBelleza, cosmética y cuidado personal al alcance de todos.\r\nTiempo de Entrega: 3 días\r\nModo de Entrega: Entrega a domicilio en toda La Habana",
                "address": "",
                "contact_phone": "55662948. 56353216",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 189,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Afro Wright",
                "meta_description": "Somos una tienda especializada en la producción y comercialización de productos naturales para el cabello afro-rizado de forma artesanal\r\nBelleza, cosmética y cuidado personal al alcance de todos.\r\nTiempo de Entrega: 3 días\r\nModo de Entrega: Entrega a domicilio en toda La Habana",
                "meta_keywords": "Cabello Afro-rizado, productos naturales, belleza, cuidado personal ",
                "seo_title": null
            },
            {
                "id": 442,
                "url": "https://el-levant.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "el-levant",
                "default": false,
                "created_at": "2022-02-21T09:17:26.941-05:00",
                "updated_at": "2023-08-03T16:00:20.844-04:00",
                "facebook": "https://www.facebook.com/LevantHabana",
                "twitter": "",
                "instagram": "https://instagram.com/levanthabana?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/wqgxze4bcxcncd0pmexl8tax3ex9",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos un equipo conocedor de la comida árabe y la ha introducido con sus particularidades en el país, junto a los platos tradicionales, creando una mezcla inigualable al paladar. \r\n\r\nTiempo de entrega: 24 horas\r\n\r\nZona: La Habana",
                "address": "Águila no 605 bajos entre reina y dragones centro habana",
                "contact_phone": "52589982",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 190,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "El Levant",
                "meta_description": "Somos un equipo conocedor de la comida árabe y la ha introducido con sus particularidades en el país, junto a los platos tradicionales, creando una mezcla inigualable al paladar. \r\n\r\nTiempo de entrega: 24 horas\r\n\r\nZona: La Habana",
                "meta_keywords": "Platos tradicionales, comida árabe, pizzas, pastas, kebab, ",
                "seo_title": null
            },
            {
                "id": 237,
                "url": "https://la-linea-bakery.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-linea-bakery",
                "default": false,
                "created_at": "2022-01-10T06:44:20.149-05:00",
                "updated_at": "2023-08-08T14:15:21.768-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://instagram.com/lalineabakery?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/xe7h6zqub71w827r36teqjthdqem",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nos especializamos en una gran variedad de repostería y pastelería para todo tipo de ocasión. \r\n\r\nTiempo de entrega: 24 horas\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "+53 53034529",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 195,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Línea Bakery",
                "meta_description": "Nos especializamos en una gran variedad de repostería y pastelería para todo tipo de ocasión. \r\n\r\nTiempo de entrega: 24 horas\r\n\r\nZona: La Habana",
                "meta_keywords": "Dulcería, tartas, dulces, cakes, combos, Reposteria, Pasteleria. ",
                "seo_title": null
            },
            {
                "id": 963,
                "url": "https://7-dias.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "7-dias",
                "default": false,
                "created_at": "2022-06-07T17:06:50.523-04:00",
                "updated_at": "2023-08-03T16:04:29.000-04:00",
                "facebook": "Facebook: https://www.facebook.com/Restaurante7Dias",
                "twitter": "",
                "instagram": "https://instagram.com/restaurante7dias?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/5nc628uhukey3586r28klybigcbv",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "El restaurante 7 Días ofrece comida saludable, en un ambiente apacible y decoración minimalista con una diversa carta de comida internacional concebida para satisfacer a todos los gustos.\r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana",
                "address": "Calle 14 entre 1ra y Mar. Miramar. Playa.",
                "contact_phone": "72096889",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 204,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "7 Días",
                "meta_description": "Restaurante 7 Días ofrece comida saludable, en un ambiente apacible y decoración minimalista con una diversa carta de comida internacional concebida para satisfacer a todos los gustos.\r\n\r\nTiempo de entrega: 24 horas \r\n\r\nZona: La Habana",
                "meta_keywords": "Comida saludable, comida internacional, carnes, pescados, preelaborados ",
                "seo_title": null
            },
            {
                "id": 601,
                "url": "https://eventos-reinas.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eventos-reinas",
                "default": false,
                "created_at": "2022-03-08T17:17:31.797-05:00",
                "updated_at": "2023-08-07T12:44:28.258-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/bgu43oscxq18m7gci86ecfgkyclb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Organizamos eventos en su más amplio espectro. \r\nOfertamos locales con decoraciones, maquillaje, peinado y arreglos florales. \r\nTiempo de Entrega: 24 hrs\r\nModo de Entrega: Entrega a domicilio en toda La Habana y en el caso de los packs de fiesta en el local.",
                "address": "Calle San Lázaro #1163/ Infanta y Baserrate. Centro Habana. ",
                "contact_phone": "54141884 - 78764238",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 209,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Eventos Reinas ",
                "meta_description": "Organizamos eventos en su más amplio espectro. \r\nOfertamos locales con decoraciones, maquillaje, peinado y arreglos florales. \r\nTiempo de Entrega: 24 hrs\r\nModo de Entrega: Entrega a domicilio en toda La Habana y en el caso de los packs de fiesta en el local.",
                "meta_keywords": "Eventos, Decoración de espacios, maquillajes, peinados, fiestas, bodas, cumpleaños, ocasiones especiales. ",
                "seo_title": null
            },
            {
                "id": 675,
                "url": "https://jorgito-s-pizza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "jorgito-s-pizza",
                "default": false,
                "created_at": "2022-04-03T13:37:03.846-04:00",
                "updated_at": "2023-08-07T12:46:02.513-04:00",
                "facebook": "https://www.facebook.com/jorgitospizza",
                "twitter": "",
                "instagram": "https://instagram.com/jorgitospizza?igshid=YmMyMTA2M2Y=",
                "logo_file_name": "https://dev.static.katapulk.com/vk1fjf5bsyyxgscwdhiwc90sy1vt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Deliciosa comida italiana hecha al momento al estilo cubano.\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: Entrega en toda La Habana",
                "address": "Calle Oquendo #565/Pocito y Jesús Peregrino centro habana",
                "contact_phone": "54031349 - 78798232",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 213,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Jorgito’s Pizza",
                "meta_description": "Deliciosa comida italiana hecha al momento al estilo cubano.\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: Entrega en toda La Habana",
                "meta_keywords": "Comida italiana, pizzas, pastas, ",
                "seo_title": null
            },
            {
                "id": 3108,
                "url": "https://la-rosa.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-rosa",
                "default": false,
                "created_at": "2023-02-21T08:17:00.901-05:00",
                "updated_at": "2023-08-03T16:07:43.839-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/oehnp8adro0g6nfevgbih3ebz0b7",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Bellos ramos y arreglos florales de todo tipo para cualquier ocasión. Regalar un detalle o adornar tu casa con flores nunca pasa de moda. \r\n\r\nTiempo de entrega: 24 a 48 horas. (Envíos a domicilio y recogida en tienda)\r\n\r\nZona: La Habana\r\n\r\nDirección: Calle Línea entre J e I. Vedado. Plaza de la Revolución. ",
                "address": "Calle Línea entre J e I. Vedado. Plaza de la Revolución. ",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 219,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Rosa",
                "meta_description": "Bellos ramos y arreglos de todo tipo para cualquier ocasión. \r\n\r\nTiempo de entrega: 24 a 48 horas. \r\n\r\nZona: La Habana",
                "meta_keywords": "Floristería, Ramos, flores, bouquets, arreglos florales, arreglos fúnebres ",
                "seo_title": "la rosa"
            },
            {
                "id": 3113,
                "url": "https://m-groceries.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "m-groceries",
                "default": false,
                "created_at": "2023-02-23T08:32:05.946-05:00",
                "updated_at": "2023-08-07T12:47:30.857-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/u7owl9a6i6tyrlcgku7zbd1qn0kk",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 220,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "M'Groceries",
                "meta_description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "meta_keywords": "Confituras, Alimentos",
                "seo_title": null
            },
            {
                "id": 3114,
                "url": "https://doble-5.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "doble-5",
                "default": false,
                "created_at": "2023-02-23T08:58:25.737-05:00",
                "updated_at": "2023-08-03T16:10:29.481-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/jmequlc048deib5zlkv4sfhsr8dh",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Doble Cinco SRL es una fábrica de helado artesanal italiano (Gelato), que trabaja con el compromiso de mantener un alto estándar de calidad. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 222,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Doble 5 ",
                "meta_description": "Tienda especializada en la fabricación de helados artesanales de la mejor calidad. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "Heladería, Helados Artesanales",
                "seo_title": null
            },
            {
                "id": 3112,
                "url": "https://hojas-de-azucar.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "hojas-de-azucar",
                "default": false,
                "created_at": "2023-02-23T07:11:15.461-05:00",
                "updated_at": "2023-08-03T16:11:21.696-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/o4qms81svqeft5rihus7hj2pvied",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Hojas de Azúcar es un negocio dedicado a la creación y venta de dulces finos elaborados con las mejores materias primas para satisfacer su paladar. \r\n\r\nTiempo de entrega: 48 horas \r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 223,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Hojas de Azúcar ",
                "meta_description": "Hojas de azúcar es un negocio radicado en la Habana, Cuba dedicado a la creación y venta de dulces finos elaborados con las mejores materias primas para satisfacer su paladar. \r\n\r\nTiempo de entrega: 48 horas \r\n\r\nZona: La Habana",
                "meta_keywords": "Dulces finos, Tortas, Cakes, Pasteles ",
                "seo_title": "Hojas de Azúcar"
            },
            {
                "id": 3111,
                "url": "https://heladoro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "heladoro",
                "default": false,
                "created_at": "2023-02-23T06:36:13.013-05:00",
                "updated_at": "2023-08-03T16:14:37.100-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/l5kxoy5pp1uzomhuot8b311boguq",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una heladería artesanal, llevamos nuestros exquisitos helados de múltiples sabores a la puerta de tu casa. No dejes para mañana el helado que puedes comer hoy. Saborea la vida con nuestros helados. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Boyeros, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 224,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Heladoro",
                "meta_description": "Somos una heladería artesanal, llevamos nuestros exquisitos helados de múltiples sabores a la puerta de tu casa. No dejes para mañana el helado que puedes comer hoy. Saborea la vida con nuestros helados. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Boyeros, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "Heladería, Helado Artesanal, Dulces",
                "seo_title": "Heladoro"
            },
            {
                "id": 3306,
                "url": "https://columba-srl.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "columba-srl",
                "default": false,
                "created_at": "2023-03-23T11:09:24.419-04:00",
                "updated_at": "2023-08-08T17:38:41.857-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/0aug3ifrl8ouyd03si7psvrzhy61",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrá encontrar una variada selección de productos importados y nacionales para toda la familia cubana. \r\n\r\nTiempo de entrega: 72 horas. \r\n\r\nZona: La Habana. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 227,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Columba SRL",
                "meta_description": "En nuestra tienda podrá encontrar una variada selección de productos importados y nacionales para toda la familia cubana. \r\n\r\nTiempo de entrega: 72 horas. \r\n\r\nZona: La Habana. ",
                "meta_keywords": "Mercado, Combos, Confituras, Cárnicos, lácteos, queso",
                "seo_title": null
            },
            {
                "id": 3209,
                "url": "https://natura-signum.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "natura-signum",
                "default": false,
                "created_at": "2023-03-08T13:32:28.369-05:00",
                "updated_at": "2023-08-07T12:48:15.478-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/5ak9ommhrdgezz1vfzzybwm2keln",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nuestra tienda oferta suplementos nutricionales y productos naturales para la salud\r\nTiempo de Entrega: 3 días\r\nModo de Entrega: Entrega a Domicilio en La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 228,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Natura Signum",
                "meta_description": "Tienda que ofrece variados productos con alta composición en proteínas para una adecuada alimentación , además del cuidado de la piel.\r\nTiempo de Entrega: 3 días\r\nModo de Entrega: Entrega a Domicilio en La Lisa, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa",
                "meta_keywords": " Suplementos Nutricionales y Cosmética",
                "seo_title": "Nuestra tienda oferta suplementos nutricionales y productos naturales para la salud"
            },
            {
                "id": 3208,
                "url": "https://la-proteina.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-proteina",
                "default": false,
                "created_at": "2023-03-08T13:20:28.689-05:00",
                "updated_at": "2023-08-03T16:23:33.727-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/tes57tu8qhdfn8nla4uue2o897pi",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En esta tienda encontrarás alimentos necesarios, bebidas y otras ofertas claves en la alimentación de tu hogar. \r\n\r\nTiempo de entrega: 24 a 48 horas \r\nEnvío Gratis. \r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 231,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Proteína",
                "meta_description": "En esta tienda encontrarás alimentos necesarios, bebidas y otras ofertas claves en la alimentación de tu hogar. \r\n\r\nTiempo de entrega: 24 a 48 horas \r\nEnvío Gratis. \r\n\r\nZona: La Habana (Marianao, Cerro, 10 de octubre, San Miguel del Padrón, Regla, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)",
                "meta_keywords": "Proteína, pescado, carne, mercado, bebidas",
                "seo_title": null
            },
            {
                "id": 3405,
                "url": "https://artsgourmets.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "artsgourmets",
                "default": false,
                "created_at": "2023-04-12T09:08:53.098-04:00",
                "updated_at": "2023-08-03T16:34:23.611-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/28owg8owrqmvandlrggvwe64vns4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Mercado con productos y sazonadores gourmet de primer nivel y de calidad excelente, para darle a tus comidas un toque profesional y único. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Playa, Plaza de la Revolución, Centro Habana, Habana Vieja)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 238,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ArtsGourmets",
                "meta_description": "Mercado con productos y sazonadores gourmet de primer nivel y de calidad excelente, para darle a tus comidas un toque profesional y único. \r\n\r\nTiempo de entrega: 48 horas\r\n\r\nZona: La Habana (Playa, Plaza de la Revolución, Centro Habana, Habana Vieja)",
                "meta_keywords": "Mercado, Combos, Confituras",
                "seo_title": null
            },
            {
                "id": 3504,
                "url": "https://ajac-soluciones.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ajac-soluciones",
                "default": false,
                "created_at": "2023-05-24T17:15:00.326-04:00",
                "updated_at": "2023-08-03T16:35:53.574-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/pill2dctnjac8ec2u1304iehbkr9",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 241,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Ajac Soluciones",
                "meta_description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "meta_keywords": "Ferretería, Materiales de la Construcción, Útiles del Hogar, Accesorios de Casa",
                "seo_title": "Ajac Soluciones "
            },
            {
                "id": 3604,
                "url": "https://donas-de-mami.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "donas-de-mami",
                "default": false,
                "created_at": "2023-07-27T15:37:51.307-04:00",
                "updated_at": "2023-08-03T13:51:24.306-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/4my234uj20xmb4ti1nglcesstspq",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Dulces y cakes elaborados con ingredientes de primera calidad. \r\nCon tres años de experiencia nuestro negocio familiar oferta a sus clientes productos con textura y sabor exquisitos\r\nTiempo de Entrega: 48 horas\r\nModo de Entrega: Recogida en el Local \r\nSita: Calle 72 entre 29a y 29b número 29a06 Apto 1. Buenavista, Playa.\r\nNúmero de Contacto: +53 5 3727812 Elianny",
                "address": "Calle 72 entre 29a y 29b número 29a06 Apto 1. Buenavista, Playa.",
                "contact_phone": "+53 5 3727812",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "dulces, cakes",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Donas de Mami",
                "meta_description": "Dulces y cakes elaborados con ingredientes de primera calidad. \r\nCon tres años de experiencia nuestro negocio familiar oferta a sus clientes productos con textura y sabor exquisitos\r\nTiempo de Entrega: 48 horas\r\nModo de Entrega: Recogida en el Local \r\nSita: Calle 72 entre 29a y 29b número 29a06 Apto 1. Buenavista, Playa.\r\nNúmero de Contacto: +53 5 3727812 Elianny",
                "meta_keywords": "dulces, cakes",
                "seo_title": "Donas de Mami"
            },
            {
                "id": 3539,
                "url": "https://mega-combo.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mega-combo",
                "default": false,
                "created_at": "2023-06-23T17:28:02.385-04:00",
                "updated_at": "2023-08-03T16:39:56.139-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/7hmg82ib6dgjal19xpymfn6i6z1d",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nuestra tienda tiene variedad de ofertas de combos con productos de primera necesidad del mercado. Llena tu despensa con una sola compra!! \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Mega Combo",
                "meta_description": "Nuestra tienda tiene variedad de ofertas de combos con productos de primera necesidad del mercado. Llena tu despensa con una sola compra!! \r\n\r\nTiempo de entrega: 3 a 5 días\r\n\r\nZona: La Habana",
                "meta_keywords": "Combos, Mercado, Alimentos",
                "seo_title": null
            },
            {
                "id": 3537,
                "url": "https://confecciones-maria.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "confecciones-maria",
                "default": false,
                "created_at": "2023-06-23T17:27:26.245-04:00",
                "updated_at": "2023-08-07T12:37:10.268-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/iayn35xkxam1uteun9skkrjxlhxm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una tienda de artículos para niños desde peluches, hasta útiles variados elaborados con tela. \r\nCon años de experiencia en el trabajo con las demandas del público infantil ofrecemos productos pensados en su disfrute y cuidado a la vez.\r\nTiempo de Entrega: 24 hrs\r\nModo de Entrega: Se entrega en toda La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Confecciones María",
                "meta_description": "Somos una tienda de artículos para niños desde peluches, hasta útiles variados elaborados con tela. \r\nCon años de experiencia en el trabajo con las demandas del público infantil ofrecemos productos pensados en su disfrute y cuidado a la vez.\r\nTiempo de Entrega: 24 hrs\r\nModo de Entrega: Se entrega en toda La Habana",
                "meta_keywords": "Juguetes, peluches, artículos para niños, ",
                "seo_title": "Tienda de artículos para niños"
            },
            {
                "id": 3538,
                "url": "https://jimm.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "jimm",
                "default": false,
                "created_at": "2023-06-23T17:27:44.996-04:00",
                "updated_at": "2023-08-03T16:42:32.487-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/do2e1f8vp0k9ddeijnc0sdvorg37",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nuestra tienda especializada en ahumados y embutidos de primera calidad, también poseemos productos variados de mercado para cubrir las necesidades de tu hogar. \r\n\r\nTiempo de entrega: 5 a 7 días\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "JIMM",
                "meta_description": "Nuestra tienda especializada en ahumados y embutidos de primera calidad, también poseemos productos variados de mercado para cubrir las necesidades de tu hogar. \r\n\r\nTiempo de entrega: 5 a 7 días\r\n\r\nZona: La Habana",
                "meta_keywords": "Ahumados, Embutidos, Carnes, Mercado",
                "seo_title": null
            },
            {
                "id": 3636,
                "url": "https://la-pasteleria.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-pasteleria",
                "default": false,
                "created_at": "2023-08-09T10:28:39.719-04:00",
                "updated_at": "2023-10-24T09:50:21.642-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": null,
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Pastelería",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3540,
                "url": "https://xax.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "xax",
                "default": false,
                "created_at": "2023-06-23T17:28:15.990-04:00",
                "updated_at": "2023-08-03T16:38:11.295-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/0tr4iid0m12pm6xoe8zchprb8o60",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tenemos todo para amueblar tu casa y oficina, con acabados cómodos al mejor estilo internacional.\r\n\r\nTiempo de entrega: 7 a 10 días\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "XAX",
                "meta_description": "Tenemos todo para amueblar tu casa y oficina, con acabados cómodos al mejor estilo internacional.\r\n\r\nTiempo de entrega: 7 a 10 días\r\n\r\nZona: La Habana",
                "meta_keywords": "Muebles, Decoración, Oficina, Hogar, Casa",
                "seo_title": null
            }
        ],
        50: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1359,
                "url": "https://son-de-la-tierra.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "son-de-la-tierra",
                "default": false,
                "created_at": "2022-06-30T18:20:32.181-04:00",
                "updated_at": "2023-08-06T10:32:55.673-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/8xjo1vztpmb63x7hlkk3skt93r9s",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 12 días \r\n\r\nZona: Camagüey, Ciego de Ávila y Sancti Spíritus. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 42,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Son de La Tierra ",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nEntregas: 10 a 12 días \r\n\r\nZona: Mayabeque, Artemisa, Matanzas, Holguín, Las Tunas, La Habana, Santiago de Cuba, Granma y Cienfuegos. ",
                "meta_keywords": "Mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 1590,
                "url": "https://la-esperanza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-esperanza",
                "default": false,
                "created_at": "2022-07-29T06:19:36.357-04:00",
                "updated_at": "2023-08-03T14:33:03.630-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/gp6jl6v4ydqnay4uz1jj6tmdji3l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 45,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Esperanza",
                "meta_description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "meta_keywords": "mercado, agro, carne, combos, alimentos, comida, katapulk, envios, pan, pizza, preelaborados, ajo, platano, arroz, carne, cerdo, carnero, jamon. ",
                "seo_title": null
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 3240,
                "url": "https://chachi-la-bala.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "chachi-la-bala",
                "default": false,
                "created_at": "2023-03-09T11:00:50.187-05:00",
                "updated_at": "2024-01-10T18:53:28.702-05:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5zhd0rdk6ecnhoi02ipmjx6wltg",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Variedad de productos para toda la familia cubana. Realiza tu compra en Chachi la Bala y en 72 horas estamos en la puerta de tu casa. \r\n\r\nTiempo de de entrega: 72 horas \r\n\r\nZona: La Habana (Arroyo Naranjo, Boyeros, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)\r\nPinar del Río (Pinar del Río, Consolación, San Juan, San Luis)",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 235,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 100,
                "name": "Chachi La Bala ",
                "meta_description": "Variedad de productos para toda la familia cubana. Realiza tu compra en Chachi la Bala y en 72 horas estamos en la puerta de tu casa. \r\n\r\nTiempo de de entrega: 72 horas \r\n\r\nZona: La Habana (Arroyo Naranjo, Boyeros, Marianao, Cerro, 10 de octubre, Habana Vieja, Centro Habana, Plaza de la Revolución, Playa)\r\nPinar del Río (Pinar del Río, Consolación, San Juan, San Luis)",
                "meta_keywords": "Mercado, Bebidas, Confituras ",
                "seo_title": null
            }
        ],
        14: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 1359,
                "url": "https://son-de-la-tierra.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "son-de-la-tierra",
                "default": false,
                "created_at": "2022-06-30T18:20:32.181-04:00",
                "updated_at": "2023-08-06T10:32:55.673-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/8xjo1vztpmb63x7hlkk3skt93r9s",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 12 días \r\n\r\nZona: Camagüey, Ciego de Ávila y Sancti Spíritus. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 42,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Son de La Tierra ",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nEntregas: 10 a 12 días \r\n\r\nZona: Mayabeque, Artemisa, Matanzas, Holguín, Las Tunas, La Habana, Santiago de Cuba, Granma y Cienfuegos. ",
                "meta_keywords": "Mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 1590,
                "url": "https://la-esperanza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-esperanza",
                "default": false,
                "created_at": "2022-07-29T06:19:36.357-04:00",
                "updated_at": "2023-08-03T14:33:03.630-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/gp6jl6v4ydqnay4uz1jj6tmdji3l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 45,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Esperanza",
                "meta_description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "meta_keywords": "mercado, agro, carne, combos, alimentos, comida, katapulk, envios, pan, pizza, preelaborados, ajo, platano, arroz, carne, cerdo, carnero, jamon. ",
                "seo_title": null
            },
            {
                "id": 1821,
                "url": "https://merca-tuaba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "merca-tuaba",
                "default": false,
                "created_at": "2022-09-06T08:26:11.394-04:00",
                "updated_at": "2023-08-03T15:35:53.852-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/5km109yjko8tv7f81kl93w5w77jm",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda de productos agro industriales conservas, cárnicos y productos naturales. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila. ",
                "address": "",
                "contact_phone": "52850530",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 146,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Merca Tuaba",
                "meta_description": "Tienda de productos agro industriales conservas, cárnicos y productos naturales. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila. ",
                "meta_keywords": "Conservas, Jugos, Enlatados, Carnícos",
                "seo_title": "Tienda de productos agroindustriales conservas carnicos y productos naturales "
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            }
        ],
        51: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            },
            {
                "id": 1590,
                "url": "https://la-esperanza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-esperanza",
                "default": false,
                "created_at": "2022-07-29T06:19:36.357-04:00",
                "updated_at": "2023-08-03T14:33:03.630-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/gp6jl6v4ydqnay4uz1jj6tmdji3l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 45,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Esperanza",
                "meta_description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "meta_keywords": "mercado, agro, carne, combos, alimentos, comida, katapulk, envios, pan, pizza, preelaborados, ajo, platano, arroz, carne, cerdo, carnero, jamon. ",
                "seo_title": null
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3605,
                "url": "https://rivierashop.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rivierashop",
                "default": false,
                "created_at": "2023-07-27T15:38:20.640-04:00",
                "updated_at": "2023-08-07T14:51:54.424-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/oz1o6f2wld6lt726ob0epkyjcwcz",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "alimentos, envío de comida a cuba, aseo, envío de aseo a Cuba",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "RivieraShop",
                "meta_description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "meta_keywords": "Alimentos, Combos, Aseo",
                "seo_title": "Tienda de alimentos y aseo"
            }
        ],
        17: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            }
        ],
        83: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            }
        ],
        12: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            }
        ],
        13: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            }
        ],
        11: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 2250,
                "url": "https://ferroelektro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ferroelektro",
                "default": false,
                "created_at": "2022-11-07T09:48:50.646-05:00",
                "updated_at": "2023-08-03T13:42:59.420-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/4s0n4rdxl4k4bglktwojuy9vzmlb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional. \r\nEnvíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas\r\nLos tiempos de entregas varían según la provincia:\r\nLa Habana- 24 horas \r\nMayabeque /Artemisa-10 días\r\nMatanzas- 15 días\r\nModo de entrega:\r\nEntrega a domicilio en todos los municipios de La Habana, Mayabeque, Artemisa y Matanzas.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "ferretería, envíos cuba",
                "supported_locales": "es",
                "position": 23,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "FerroElektro",
                "meta_description": "Tienda de ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional.  Envíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas",
                "meta_keywords": "Ferretería, Motor de Agua, Llaves",
                "seo_title": "Tienda de Ferretería Cubana"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            }
        ],
        7: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 2019,
                "url": "https://reforma.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "reforma",
                "default": false,
                "created_at": "2022-10-09T12:11:10.708-04:00",
                "updated_at": "2024-03-28T10:12:42.651-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/x3onywh89mbytq12ijz10xwuffr6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "sales@katapulk.com",
                "default_country_id": 233,
                "description": " Una tienda con todos los electrodomésticos y artículos de ferretería más buscados para las renovaciones y arreglos en Cuba. Con ofertas al mejor precio en productos como televisores, ventiladores, plantas eléctricas y bombas de agua para Cuba, hasta suministros como pintura, piezas de baño y cemento para reformar hogares y negocios en el país.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "perico@katapulk.dev",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 4,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 140,
                "name": "Reforma",
                "meta_description": null,
                "meta_keywords": "Ferretería, Hogar, Plomería, Electricidad",
                "seo_title": null
            },
            {
                "id": 2250,
                "url": "https://ferroelektro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ferroelektro",
                "default": false,
                "created_at": "2022-11-07T09:48:50.646-05:00",
                "updated_at": "2023-08-03T13:42:59.420-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/4s0n4rdxl4k4bglktwojuy9vzmlb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional. \r\nEnvíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas\r\nLos tiempos de entregas varían según la provincia:\r\nLa Habana- 24 horas \r\nMayabeque /Artemisa-10 días\r\nMatanzas- 15 días\r\nModo de entrega:\r\nEntrega a domicilio en todos los municipios de La Habana, Mayabeque, Artemisa y Matanzas.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "ferretería, envíos cuba",
                "supported_locales": "es",
                "position": 23,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "FerroElektro",
                "meta_description": "Tienda de ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional.  Envíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas",
                "meta_keywords": "Ferretería, Motor de Agua, Llaves",
                "seo_title": "Tienda de Ferretería Cubana"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            },
            {
                "id": 2712,
                "url": "https://rapid-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rapid-cuba",
                "default": false,
                "created_at": "2022-12-15T06:56:32.126-05:00",
                "updated_at": "2023-08-06T11:08:12.556-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/qf1iz0nf1s4fpmls7nccc89zamjy",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana, Artemisa (Ciudad de Artemisa ,Alquilar, San Antonio de los Baños,Güira de Melena, Bauta y Caimito), Mayabeque (San José, Güines, Quivicán, La Salud, Buenaventura, Bejucal, San Antonio de las Vegas, Batabano, San Felipe).",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 51,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Rapid Cuba ",
                "meta_description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Mercado, Combos, Agromercado",
                "seo_title": null
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3113,
                "url": "https://m-groceries.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "m-groceries",
                "default": false,
                "created_at": "2023-02-23T08:32:05.946-05:00",
                "updated_at": "2023-08-07T12:47:30.857-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/u7owl9a6i6tyrlcgku7zbd1qn0kk",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 220,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "M'Groceries",
                "meta_description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "meta_keywords": "Confituras, Alimentos",
                "seo_title": null
            },
            {
                "id": 3504,
                "url": "https://ajac-soluciones.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ajac-soluciones",
                "default": false,
                "created_at": "2023-05-24T17:15:00.326-04:00",
                "updated_at": "2023-08-03T16:35:53.574-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/pill2dctnjac8ec2u1304iehbkr9",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 241,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Ajac Soluciones",
                "meta_description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "meta_keywords": "Ferretería, Materiales de la Construcción, Útiles del Hogar, Accesorios de Casa",
                "seo_title": "Ajac Soluciones "
            }
        ],
        9: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            }
        ],
        16: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3009,
                "url": "https://mercado-unico-centro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico-centro",
                "default": false,
                "created_at": "2023-01-26T22:47:44.501-05:00",
                "updated_at": "2023-01-30T14:20:08.842-05:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/efdx7yjd5pnlb9za10rezf8wt7qu",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 31,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Mercado Único Centro",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 1359,
                "url": "https://son-de-la-tierra.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "son-de-la-tierra",
                "default": false,
                "created_at": "2022-06-30T18:20:32.181-04:00",
                "updated_at": "2023-08-06T10:32:55.673-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/8xjo1vztpmb63x7hlkk3skt93r9s",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 12 días \r\n\r\nZona: Camagüey, Ciego de Ávila y Sancti Spíritus. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 42,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Son de La Tierra ",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nEntregas: 10 a 12 días \r\n\r\nZona: Mayabeque, Artemisa, Matanzas, Holguín, Las Tunas, La Habana, Santiago de Cuba, Granma y Cienfuegos. ",
                "meta_keywords": "Mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 1590,
                "url": "https://la-esperanza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-esperanza",
                "default": false,
                "created_at": "2022-07-29T06:19:36.357-04:00",
                "updated_at": "2023-08-03T14:33:03.630-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/gp6jl6v4ydqnay4uz1jj6tmdji3l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 45,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Esperanza",
                "meta_description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "meta_keywords": "mercado, agro, carne, combos, alimentos, comida, katapulk, envios, pan, pizza, preelaborados, ajo, platano, arroz, carne, cerdo, carnero, jamon. ",
                "seo_title": null
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 3605,
                "url": "https://rivierashop.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rivierashop",
                "default": false,
                "created_at": "2023-07-27T15:38:20.640-04:00",
                "updated_at": "2023-08-07T14:51:54.424-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/oz1o6f2wld6lt726ob0epkyjcwcz",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "alimentos, envío de comida a cuba, aseo, envío de aseo a Cuba",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "RivieraShop",
                "meta_description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "meta_keywords": "Alimentos, Combos, Aseo",
                "seo_title": "Tienda de alimentos y aseo"
            }
        ],
        84: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3009,
                "url": "https://mercado-unico-centro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico-centro",
                "default": false,
                "created_at": "2023-01-26T22:47:44.501-05:00",
                "updated_at": "2023-01-30T14:20:08.842-05:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/efdx7yjd5pnlb9za10rezf8wt7qu",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 31,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Mercado Único Centro",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            },
            {
                "id": 1590,
                "url": "https://la-esperanza.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-esperanza",
                "default": false,
                "created_at": "2022-07-29T06:19:36.357-04:00",
                "updated_at": "2023-08-03T14:33:03.630-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/gp6jl6v4ydqnay4uz1jj6tmdji3l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 45,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Esperanza",
                "meta_description": "Finca la Esperanza, destinada a la producción de cultivos varios, elaboración de productos alimenticios y su comercialización. Garantizamos la calidad de nuestros productos. \r\n\r\nTiempo de entrega: 15 días hábiles. \r\n\r\nZona: Ciego de Ávila, Sancti Spíritus, Camagüey, Villa Clara y Ciego de Ávila. ",
                "meta_keywords": "mercado, agro, carne, combos, alimentos, comida, katapulk, envios, pan, pizza, preelaborados, ajo, platano, arroz, carne, cerdo, carnero, jamon. ",
                "seo_title": null
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 699,
                "url": "https://eriode.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "eriode",
                "default": false,
                "created_at": "2022-04-12T14:36:44.708-04:00",
                "updated_at": "2023-08-06T14:55:15.984-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/6po4r29bu0v9b4xnugodgk20houa",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana (Centro Habana, Habana Vieja y Plaza de la Revolución) Ciego de Ávila\r\n Villa Clara (Municipio cabecera Villa Clara) \r\nSancti Spíritus (Municipio cabecera Sancti Spiritus)\r\nCamagüey (Municipio cabecera Camagüey) \r\nLas Tunas (Municipio cabecera Las Tunas)\r\nHolguín (Municipio cabecera Holguín) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 185,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ERIODE",
                "meta_description": "Productos de mercado que incluyen carnes, dulces criollos, viandas, hortalizas, etc. 100% orgánicos, lavados y empaquetados. \r\n\r\nTiempo de entrega: 10 a 15 días\r\n\r\nZona: La Habana, Ciego de Ávila, Villa Clara, Sancti Spíritus, Camagüey, Las Tunas y Holguín. ",
                "meta_keywords": "cerdo carne pollo combo combos pack congelados dulce dulces queso envios envíos cuba  mercado, combos para cuba, envíos cuba, envios a cuba, pollo, carne, cerdo, congelados, viandas, vegetales, ",
                "seo_title": null
            },
            {
                "id": 3570,
                "url": "https://apexcell.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "apexcell",
                "default": false,
                "created_at": "2023-07-19T12:36:53.231-04:00",
                "updated_at": "2023-08-03T16:46:00.976-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/iuoyjwm1lr1mjbwtihsr8uv4d2jk",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestro mercado podrá encontrar productos de primera necesidad para abastecer su despensa personal. Esperamos que quede complacido con nuestros servicios y ofertas variadas. \r\n\r\nTiempo de entrega: 7 días\r\n\r\nZona: La Habana",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Apexcell",
                "meta_description": "En nuestro mercado podrá encontrar productos de primera necesidad para abastecer su despensa personal. Esperamos que quede complacido con nuestros servicios y ofertas variadas. \r\n\r\nTiempo de entrega: 7 días\r\n\r\nZona: La Habana",
                "meta_keywords": "Mercado, Alimentos, Comida, Yogurt, Embutidos, Jugos ",
                "seo_title": null
            },
            {
                "id": 3605,
                "url": "https://rivierashop.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rivierashop",
                "default": false,
                "created_at": "2023-07-27T15:38:20.640-04:00",
                "updated_at": "2023-08-07T14:51:54.424-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/oz1o6f2wld6lt726ob0epkyjcwcz",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "alimentos, envío de comida a cuba, aseo, envío de aseo a Cuba",
                "supported_locales": "es",
                "position": null,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "RivieraShop",
                "meta_description": "- Somos una tienda que comercializa combos y productos alimenticios y de aseo para el disfrute de la familia cubana. \r\n- Productos de primera calidad y precios competitivos\r\nTiempo de entrega: 1-7 días\r\nModo de Entrega: Entrega a domicilio en todos los municipios de Villa Clara, Cienfuegos y Sancti Spíritus",
                "meta_keywords": "Alimentos, Combos, Aseo",
                "seo_title": "Tienda de alimentos y aseo"
            }
        ],
        15: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 2019,
                "url": "https://reforma.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "reforma",
                "default": false,
                "created_at": "2022-10-09T12:11:10.708-04:00",
                "updated_at": "2024-03-28T10:12:42.651-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/x3onywh89mbytq12ijz10xwuffr6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "sales@katapulk.com",
                "default_country_id": 233,
                "description": " Una tienda con todos los electrodomésticos y artículos de ferretería más buscados para las renovaciones y arreglos en Cuba. Con ofertas al mejor precio en productos como televisores, ventiladores, plantas eléctricas y bombas de agua para Cuba, hasta suministros como pintura, piezas de baño y cemento para reformar hogares y negocios en el país.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "perico@katapulk.dev",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 4,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 140,
                "name": "Reforma",
                "meta_description": null,
                "meta_keywords": "Ferretería, Hogar, Plomería, Electricidad",
                "seo_title": null
            }
        ],
        8: [
            {
                "id": 534,
                "url": "https://recargas-a-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "recargas-a-cuba",
                "default": false,
                "created_at": "2022-03-03T11:51:44.414-05:00",
                "updated_at": "2023-09-11T06:44:19.552-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/squmoy06mldu65uaf2dr8i3k2mlo",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "info@katapulk.com",
                "default_country_id": 233,
                "description": "La opción más barata para la recarga móvil a Cuba. Con los precios más competitivos y las promos de celulares para Cuba, Katapulk Recarga siempre ofrece seguridad en la recarga móvil y mejores precios. Estas ofertas pueden adquirirse desde cualquier lugar del mundo para recargar un móvil en Cuba. Este servicio de Katapulk permite hacer recargas de varios teléfonos a la vez para mayor conveniencia y facilidad de los usuarios que utilizan un servicio móvil en Cuba a través de Etecsa.\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 1,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Recargas a Cuba",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1,
                "url": "https://mercado-unico.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "mercado-unico",
                "default": true,
                "created_at": "2020-10-20T23:55:24.460-04:00",
                "updated_at": "2024-04-02T12:55:57.716-04:00",
                "facebook": "katapulk.marketplace",
                "twitter": "",
                "instagram": "katapulk.marketplace",
                "logo_file_name": "https://dev.static.katapulk.com/0rtq2gos9qe44qgr3ycb6wibjpld",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "rosaliaf9@gmail.com",
                "default_country_id": 233,
                "description": "Mercado Único de Katapulk  \r\n\r\nUna tienda con entregas a domicilio en todos los municipios de Cuba. Compra en el mercado por excelencia de Katapulk para Cuba y envía a tu gente lo mejor, incluyendo cajas de pollo, leche, carnes, mariscos, enlatados, productos de marcas reconocidas como Goya y Vima, ofertas de aseo, limpieza, y más. Todos los productos están en Cuba, listos para ser entregados.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "rosaliaf9@gmail.com,lazaro.casana@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 2,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": false,
                "maximum_products": 150,
                "name": "Mercado Único",
                "meta_description": "Katapulk is an Ecommerce platform to sell products from Cuba.",
                "meta_keywords": "katapulk, mercado, mercado unico, groceries, products, cuba",
                "seo_title": "Katapulk"
            },
            {
                "id": 2019,
                "url": "https://reforma.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "reforma",
                "default": false,
                "created_at": "2022-10-09T12:11:10.708-04:00",
                "updated_at": "2024-03-28T10:12:42.651-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/x3onywh89mbytq12ijz10xwuffr6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "sales@katapulk.com",
                "default_country_id": 233,
                "description": " Una tienda con todos los electrodomésticos y artículos de ferretería más buscados para las renovaciones y arreglos en Cuba. Con ofertas al mejor precio en productos como televisores, ventiladores, plantas eléctricas y bombas de agua para Cuba, hasta suministros como pintura, piezas de baño y cemento para reformar hogares y negocios en el país.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "perico@katapulk.dev",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 4,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 140,
                "name": "Reforma",
                "meta_description": null,
                "meta_keywords": "Ferretería, Hogar, Plomería, Electricidad",
                "seo_title": null
            },
            {
                "id": 2250,
                "url": "https://ferroelektro.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ferroelektro",
                "default": false,
                "created_at": "2022-11-07T09:48:50.646-05:00",
                "updated_at": "2023-08-03T13:42:59.420-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/4s0n4rdxl4k4bglktwojuy9vzmlb",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Somos una ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional. \r\nEnvíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas\r\nLos tiempos de entregas varían según la provincia:\r\nLa Habana- 24 horas \r\nMayabeque /Artemisa-10 días\r\nMatanzas- 15 días\r\nModo de entrega:\r\nEntrega a domicilio en todos los municipios de La Habana, Mayabeque, Artemisa y Matanzas.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "ferretería, envíos cuba",
                "supported_locales": "es",
                "position": 23,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "FerroElektro",
                "meta_description": "Tienda de ferretería con una amplia gama de productos de marcas reconocidas en el mercado internacional.  Envíos a Domicilios rápidos y seguros.\r\nTiempo de Entrega: 24 horas en La Habana, 10 días en Artemisa y Mayabeque, 15 días en Matanzas.\r\nModo de entrega: Entrega a domicilio en La Habana, Artemisa, Mayabeque y Matanzas",
                "meta_keywords": "Ferretería, Motor de Agua, Llaves",
                "seo_title": "Tienda de Ferretería Cubana"
            },
            {
                "id": 2349,
                "url": "https://don-carlos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "don-carlos",
                "default": false,
                "created_at": "2022-11-11T05:51:16.416-05:00",
                "updated_at": "2023-07-28T16:35:16.483-04:00",
                "facebook": "No tienen",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/hl3j82ax3q44o6iw0vm4ayhh2rpx",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda que ofrece variedad de productos cárnicos y sus derivados de primera calidad. Entregas a domiclio en La Habana y Artemisa.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios), Artemisa (Todos los municipios).",
                "address": "",
                "contact_phone": "+5353105277",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 32,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Don Carlos ",
                "meta_description": "Tienda que ofrece variedad de productos cárnicos y sus derivados de primera calidad. Entregas a domiclio en La Habana y Artemisa.\r\n\r\nTiempo de Entrega: 48 horas\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios), Artemisa (Todos los municipios).",
                "meta_keywords": "Charcutería, Chorizo, Jamón, Cerdo, Ahumados ",
                "seo_title": "Don Carlos Minidustria"
            },
            {
                "id": 3075,
                "url": "https://tiger-combos.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "tiger-combos",
                "default": false,
                "created_at": "2023-02-13T11:45:06.271-05:00",
                "updated_at": "2023-08-03T14:22:04.136-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/c8gmnif7ys0y6wifi7h2ak2ddflt",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad.  \r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 33,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Tiger Combos",
                "meta_description": "En nuestra tienda podrás encontrar los mejores combos para la familia cubana. Variedad de Productos con la mejor calidad. \r\n\r\nEntregas gratis en toda La Habana, 3 a 5 días\r\n\r\nEntregas en Pinar del Río, Matanzas, Mayabeque, Artemisa y Cienfuegos, de 10 a 12 días. ",
                "meta_keywords": "Combos, Alimentos, Mercado ",
                "seo_title": null
            },
            {
                "id": 271,
                "url": "https://la-gran-cesta.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "la-gran-cesta",
                "default": false,
                "created_at": "2022-01-18T09:40:37.129-05:00",
                "updated_at": "2023-09-27T11:40:12.940-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/3fjgylg4ofg48mkj89lnf7h210r4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Variada oferta de productos de agromercado, lavados y 100% orgánicos. Todo lo que necesita la familia cubana. \r\nEntregas: 10 días \r\n\r\nZona: La Habana, Artemisa (no incluye Bahía Honda) ",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 37,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "La Gran Cesta ",
                "meta_description": "Variada oferta de productos de agromercado, lavados y 100% orgánicos. Todo lo que necesita la familia cubana. \r\n\r\nEntregas: 10 días \r\n\r\nZona: La Habana, Artemisa (no incluye Bahía Honda) ",
                "meta_keywords": "Mercado, Combos, Agromercado",
                "seo_title": null
            },
            {
                "id": 1591,
                "url": "https://chocoronela.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "chocoronela",
                "default": false,
                "created_at": "2022-07-29T06:38:53.963-04:00",
                "updated_at": "2023-11-07T12:00:59.767-05:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/74o36dtzj0egzo41bv5fw6jt26e4",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Tienda especializada en la elaboración y comercialización de helados artesanales. Helados de primera calidad.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS COTORRO), Artemisa (Bauta y San Antonio de los Baños).\r\n",
                "address": "",
                "contact_phone": "+53 5 9876699",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com, rosaliaf9@gmail.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 39,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Chocoronela",
                "meta_description": "Tienda especializada en la elaboración y comercialización de helados artesanales. Helados de primera calidad.\r\n\r\nTiempo de Entrega: 5 días.\r\n\r\nModo de Entrega: Entrega a Domicilio en las provincias: La Habana (Todos los municipios MENOS COTORRO), Artemisa (Bauta y San Antonio de los Baños).\r\n",
                "meta_keywords": "Helados, Heladería, Mercado",
                "seo_title": null
            },
            {
                "id": 2712,
                "url": "https://rapid-cuba.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "rapid-cuba",
                "default": false,
                "created_at": "2022-12-15T06:56:32.126-05:00",
                "updated_at": "2023-08-06T11:08:12.556-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/qf1iz0nf1s4fpmls7nccc89zamjy",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana, Artemisa (Ciudad de Artemisa ,Alquilar, San Antonio de los Baños,Güira de Melena, Bauta y Caimito), Mayabeque (San José, Güines, Quivicán, La Salud, Buenaventura, Bejucal, San Antonio de las Vegas, Batabano, San Felipe).",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 51,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Rapid Cuba ",
                "meta_description": "Negocio que ofrece una gran variedad de productos de mercado. Todo de primera calidad y con excelentes precios.\r\n\r\nTiempo de Entrega: 10 días.\r\n\r\nModo de Entrega: Entrega a domicilio en todos los municipios de La Habana.",
                "meta_keywords": "Mercado, Combos, Agromercado",
                "seo_title": null
            },
            {
                "id": 501,
                "url": "https://www.cubalicores.com",
                "mail_from_address": "CubaLicores Pedidos \u003cpedidos@mail.cubalicores.com\u003e",
                "default_currency": "USD",
                "code": "cuba-licores",
                "default": false,
                "created_at": "2022-02-24T12:25:54.617-05:00",
                "updated_at": "2023-03-28T10:52:07.943-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/a5hvtu3s341q8z52px3ydjzwb1d6",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 151,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Cuba Licores",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 3113,
                "url": "https://m-groceries.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "m-groceries",
                "default": false,
                "created_at": "2023-02-23T08:32:05.946-05:00",
                "updated_at": "2023-08-07T12:47:30.857-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/u7owl9a6i6tyrlcgku7zbd1qn0kk",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 220,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "M'Groceries",
                "meta_description": "Ofertamos confituras, alimentos enlatados y productos de limpieza.\r\nNuestras premisas: calidad del producto, prioridad del cliente y rápida entrega\r\nTiempo de entrega: 24 hrs\r\nModo de entrega: La Habana\r\n",
                "meta_keywords": "Confituras, Alimentos",
                "seo_title": null
            },
            {
                "id": 3504,
                "url": "https://ajac-soluciones.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "ajac-soluciones",
                "default": false,
                "created_at": "2023-05-24T17:15:00.326-04:00",
                "updated_at": "2023-08-03T16:35:53.574-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/pill2dctnjac8ec2u1304iehbkr9",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 241,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "Ajac Soluciones",
                "meta_description": "Nos encargamos de dar solución a todos aquellos problemas de tu casa, dándote las herramientas y materiales necesarios para que tu hogar luzca hermoso y arreglado, confortable para el futuro. \r\n\r\nTiempo de entrega: 4 días\r\n\r\nZona: La Habana, Artemisa, Mayabeque",
                "meta_keywords": "Ferretería, Materiales de la Construcción, Útiles del Hogar, Accesorios de Casa",
                "seo_title": "Ajac Soluciones "
            }
        ],
        116: [
            {
                "id": 304,
                "url": "https://forshoppers-moda.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "forshoppers-moda",
                "default": false,
                "created_at": "2022-01-29T06:29:28.173-05:00",
                "updated_at": "2022-11-11T05:21:37.582-05:00",
                "facebook": "",
                "twitter": "",
                "instagram": "",
                "logo_file_name": "https://dev.static.katapulk.com/xhhppjjni92o9ube1m8rxm57wl8l",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "",
                "default_country_id": 233,
                "description": "",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es",
                "position": 80,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#C34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "ForShoppers - Moda",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            },
            {
                "id": 1195,
                "url": "https://krip.www.katapulk.com",
                "mail_from_address": "Katapulk Orders \u003corders@katapulk.com\u003e",
                "default_currency": "USD",
                "code": "krip",
                "default": false,
                "created_at": "2022-06-21T15:53:19.858-04:00",
                "updated_at": "2023-10-02T04:52:51.453-04:00",
                "facebook": "",
                "twitter": "",
                "instagram": "https://www.instagram.com/krip_mobile/",
                "logo_file_name": "https://dev.static.katapulk.com/q2ezyhc91qkaczbn0pdif3iyduub",
                "supported_currencies": "USD",
                "default_locale": "es",
                "customer_support_email": "christy.cancio@katapulk.com",
                "default_country_id": 233,
                "description": "ABOUT KRIP\r\n\r\nAffordable state-of-the-art smartphones with all of the features consumers need and love. Stylish industrial design, sturdiness and reliability. Manufactured and marketed by mobile industry trailblazers. We are bringing to the Latin American market the best quality smartphones, at the best price.",
                "address": "",
                "contact_phone": "",
                "new_order_notifications_email": "ariel.machado@katapulk.com,carlos.torres@katapulk.com",
                "checkout_zone_id": null,
                "seo_robots": "",
                "supported_locales": "es,en",
                "position": 197,
                "deleted_at": null,
                "deleted_by": null,
                "channel": "dev.api.katapulk.com",
                "favorite_color": "#c34400",
                "active": true,
                "shipping_category_default_scoped": true,
                "maximum_products": 100,
                "name": "KRIP",
                "meta_description": null,
                "meta_keywords": null,
                "seo_title": null
            }
        ]
    },
    "zones": [
        {
            "id": 6,
            "name": "La Habana"
        },
        {
            "id": 50,
            "name": "Camagüey"
        },
        {
            "id": 14,
            "name": "Ciego de Avila"
        },
        {
            "id": 51,
            "name": "Cienfuegos"
        },
        {
            "id": 17,
            "name": "Granma"
        },
        {
            "id": 83,
            "name": "Guantánamo"
        },
        {
            "id": 12,
            "name": "Holguín"
        },
        {
            "id": 13,
            "name": "Las Tunas"
        },
        {
            "id": 11,
            "name": "Matanzas"
        },
        {
            "id": 7,
            "name": "Mayabeque"
        },
        {
            "id": 9,
            "name": "Pinar del Río"
        },
        {
            "id": 16,
            "name": "Sancti Spíritus"
        },
        {
            "id": 84,
            "name": "Villa Clara "
        },
        {
            "id": 15,
            "name": "Santiago de Cuba"
        },
        {
            "id": 8,
            "name": "Artemisa"
        },
        {
            "id": 116,
            "name": "Continental USA"
        }
    ]
};
