import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {Taxonomy} from "../models";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class TaxonomiesService {

  private resource = '/multi_stores'; // peticion para /api/v1

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {
  }

  /**
   * Get Taxonomies (Categorias) by store id
   * @param storeId
   */
  getTaxonomies(storeId: number): Observable<Taxonomy> {
    return this.apiService.get(`${this.resource}/${storeId}/taxonomies`, new HttpParams(), false, this.getHeader(), true, true).pipe(
      map((taxonomies) => {
        return this.orderTaxonomies(taxonomies);
      })
    );
  }

  /**
   * Ordenar los taxonomies teniendo en cuenta lo que llega de la api
   * @param taxonomies
   */
  private orderTaxonomies(taxonomies: any[]) {
    const parent = taxonomies.find((taxonomy) => taxonomy.parent_id === null);
    const orderedTaxonomy: Taxonomy = {
      id: parent.id,
      depth: parent.depth,
      name: parent.name,
      parentId: null,
      permalink: parent.permalink,
      children: []
    };
    return this.getAllOrderedChildren(taxonomies, orderedTaxonomy);
  }

  /**
   * Ordena los taxonomies en padres e hijos recursivamente
   * @param taxonomies
   * @param parent
   */
  private getAllOrderedChildren(taxonomies: any[], parent: Taxonomy): Taxonomy {
    const children: Taxonomy[] = taxonomies
      .filter((taxonomy) => taxonomy.parent_id === parent.id)
      .map((taxonomy) => ({
        id: taxonomy.id,
        name: taxonomy.name,
        parentId: parent.id,
        depth: taxonomy.depth,
        permalink: taxonomy.permalink,
        children: []
      }));
    if (children.length === 0) {
      return parent;
    }
    parent.children = [...children];
    children.forEach((child) => this.getAllOrderedChildren(taxonomies, child));
    return parent;
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    return  this.authService.getHeaderNoRefresh();
  }
}
