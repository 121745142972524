import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectedZone } from 'src/app/shared/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

const FORM_SETTINGS = {
  min: [null, []],
  max: [null, []],
};

@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss']
})
export class PriceRangeComponent implements OnInit {
  filterPriceForm: FormGroup;
  selectedTaxon: number | null = null
  selectedPrice: string | null = null
  @Output() change = new EventEmitter<any>();
  resp_filters = false;

  constructor(
    private zonesService: ZonesService,
    private fb: FormBuilder) {
    this.filterPriceForm = this.fb.group({
      ...FORM_SETTINGS
    });
  }

  ngOnInit(): void {
    let filters_p = document.querySelectorAll('.filter-p');
    filters_p.forEach((element: any) => {
      let clickeable = element as HTMLElement;
      clickeable.addEventListener('click', (event) => {
        let el = event.target as HTMLSelectElement;
        this.clearCssClass();
        el.classList.add('selected-filter')
      })
    })
  }

  emitFilterData(taxon?: number, price?: string) {
    if (this.getSelectedZone) {
      this.closeFilters();
      if (taxon) {
        this.selectedTaxon = taxon;
      }
      if (price) {
        this.selectedPrice = price;
      }
      this.change.emit({ data: { taxon: this.selectedTaxon, price: this.selectedPrice } });
      this.filterPriceForm.reset();
    }
  }

  emitByPriceFields() {
    if (this.getSelectedZone) {
      this.closeFilters();
      if (this.filterPriceForm.value.min && this.filterPriceForm.value.max) {
        this.selectedPrice = `${this.filterPriceForm.value.min},${this.filterPriceForm.value.max}`
      } else if (this.filterPriceForm.value.min) {
        this.selectedPrice = `${this.filterPriceForm.value.min}`
      } else if (this.filterPriceForm.value.max) {
        this.selectedPrice = `0,${this.filterPriceForm.value.max}`
      }
      this.change.emit({ data: { taxon: this.selectedTaxon, price: this.selectedPrice } });
      this.clearCssClass();
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  closeFilters() {
    this.resp_filters = !this.resp_filters;
  }

  resetFilters() {
    if (this.getSelectedZone) {
      this.filterPriceForm.reset();
      this.clearCssClass();
      this.change.emit({ data: {} });
    }
  }

  clearCssClass() {
    let filters_p = document.querySelectorAll('.filter-p');
    filters_p.forEach((element: any) => {
      let el = element as HTMLElement;
      if (el?.classList.contains('selected-filter')) {
        el?.classList.remove('selected-filter');
      }
    })
  }

}
