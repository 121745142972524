import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CartService} from "../../../../shared/services/cart.service";
import {empty, Subject} from "rxjs";
import {catchError, takeUntil} from "rxjs/operators";
import {SelectedZone} from "../../../../shared/models";
import {ZonesService} from "../../../../shared/services/zones.service";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {NewCartService} from "../../../../shared/services/newcart.service";
import {NotificationService} from "../../../../shared/services/notification.service";

@Component({
  selector: 'app-new-cart-card',
  templateUrl: './new-cart-card.component.html',
  styleUrls: ['./new-cart-card.component.scss']
})
export class NewCartCardComponent implements OnInit, OnDestroy {
  @Input() productName = 'Néctar La Vitta 200ml (varios sabores) lorem ipsum long name';
  @Input() variant = 'Durazno';
  @Input() promotionCode = 'KATA1234';
  @Input() productPrice = 5.74;
  @Input() currency = 'USD';
  @Input() id: string = '';
  @Input() itemCount: number = 2;
  @Input() canEmptyCart: boolean = false;
  @Input() imageUrl = '../../../../../assets/imgs/product-card/no-product-image.png';
  @Input() slug: string = '';
  @Input() data_extra_types: { id: number, value: string }[] = [];
  @Input() message: string = '';
  @Input() position: number = 0;

  @Output() updateCart =  new EventEmitter<boolean>();

  quantity: number = 0;

  destroy$: Subject<boolean> = new Subject<boolean>();

  faSpinner = faSpinner;

  @Input() isDeletingProduct: boolean = false;

  blockDeletingProduct: boolean = false;

  constructor(
    private newCartService: NewCartService,
    private zonesService: ZonesService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.quantity = this.itemCount || 0;
    this.newCartService.isBlockDeleteFrom
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.blockDeletingProduct = value;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  increaseQuantity() {
    if (this.data_extra_types.length === 0) {
      this.newCartService.setLineItemQuantity({itemId: this.id, count: this.quantity + 1})
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.quantity += 1;
        });
    }
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      if (this.data_extra_types.length === 0) {
        this.newCartService.setLineItemQuantity({itemId: this.id, count: this.quantity - 1})
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.quantity -= 1;
          });
      }
    }
  }

  deleteProductFromCart(id: string) {
    this.isDeletingProduct = true;
    if (this.canEmptyCart) {
      this.newCartService.cleanCart()
        .pipe(
            takeUntil(this.destroy$),
            catchError((error: any) => {
                return empty();
            })
        )
        .subscribe(() => {});
    } else {
      // this.newCartService.removeItemCart({itemId: this.id})
      this.newCartService.removeItemCart({itemId: id})
        .pipe(takeUntil(this.destroy$))
        .subscribe({
            next: (response: any) => {
                if (response && response.status && response.status == 400 && response.error && response.error.responseCode && response.error.responseCode == 'ShoppingCartError') {
                    this.notificationService.showAndSubscribe(response.error.message, 'CLOSE');
                    this.isDeletingProduct = false;
                }
            },
            error: (error: any) => {
                this.notificationService.showAndSubscribe(error.error.message, 'CLOSE');
                this.isDeletingProduct = false;
            }
        });
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
