<div class="navigation-wrapper relative w-full
lg-landscape:mt-18px">
    <div class="relative keen-slider flex overflow-hidden w-full" #sliderRef>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
        <div class="keen-slider__slide flex justify-center items-center
        us:w-full us:h-271px
        lg-landscape:w-257px lg-landscape:h-271px">
            <p class="relative w-auto font-Lato-Regular text-white 
        lg-landscape:font-medium lg-landscape:text-lg">loren ipsum</p>
        </div>
    </div>

    <svg [ngClass]="
      'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')
    " (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
    <svg *ngIf="slider" [ngClass]="
      'arrow arrow--right ' +
      (slider.track?.details?.slides?.length - 1 === currentSlide
        ? 'arrow--disabled'
        : '')
    " (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
</div>