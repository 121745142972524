export const travels: any = {
    // "headers": [
    //     {
    //         "text": "CONSULAR_TRAVEL_SERVICES"
    //     }
    // ],
    // "links": [
    //     {
    //         "text": "VISAS_AND_PASSPORTS",
    //         "route": "",
    //         "links": [
    //             {
    //                 "text": "TOURIST_VISA_CARD",
    //                 "route": "/travel-services/visa",
    //                 "headers": ["VISAS_AND_PASSPORTS"]
    //             },
    //             {
    //                 "text": "Visa HE-11",
    //                 "route": "/travel-services/he11"
    //             },
    //             {
    //                 "text": "CUBAN_PASSPORT",
    //                 "route": "/travel-services/passport"
    //             },
    //             // {
    //             //     "text": "Pasaporte Americano",
    //             //     "route": ""
    //             // },
    //         ]
    //     },
    //     // {
    //     //     "text": "Hoteles & Casas Privadas",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    //     // {
    //     //     "text": "Excursiones",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    //     // {
    //     //     "text": "Programas",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    //     // {
    //     //     "text": "Vuelos",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    //     // {
    //     //     "text": "Renta de Autos",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    //     // {
    //     //     "text": "Transfers",
    //     //     "route": "",
    //     //     "links": []
    //     // },
    // ]
}