import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from 'src/app/shared/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification.service';


@Component({
  selector: 'app-confirm-password-recover',
  templateUrl: './confirm-password-recover.component.html',
  styleUrls: ['./confirm-password-recover.component.scss']
})
export class ConfirmPasswordRecoverComponent implements OnInit {
  faSpinner = faSpinner;
  confirm_reset_pass: FormGroup;
  spin = false;
  token = "";


  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private activeRoute: ActivatedRoute,
  ) {
    this.confirm_reset_pass = new FormGroup({
      password: new FormControl(null, {
        validators: [Validators.required]
      }),
      password_confirmation: new FormControl(null, {
        validators: [Validators.required]
      }),
    })

    this.router
  }

  ngOnInit(): void {
    this.token = this.activeRoute.snapshot.params['token'];
  }

  onSubmit() {
    if (this.confirm_reset_pass.valid && (this.confirm_reset_pass.controls['password'].value === this.confirm_reset_pass.controls['password_confirmation'].value)) {
      this.spin = true;
      this.userService.resetPassword(this.confirm_reset_pass?.value, this.token).subscribe((data: any) => {
        this.notificationService.showAndSubscribe('PASSWORD_RESETED', 'ACCEPT').afterClosed().subscribe({
          next: () => {
            this.spin = false;
            this.router.navigateByUrl('/');
          }, error: () => {
            this.spin = false;
          }
        })
      })
    } else {
      this.confirm_reset_pass.markAsUntouched();
    }
    // https://api.katapulk.com/api/v2/storefront/passwords
  }

}
