import {Component, Inject, OnInit} from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-proccessing-payment-modal',
  templateUrl: './proccessing-payment-modal.component.html',
  styleUrls: ['./proccessing-payment-modal.component.scss']
})
export class ProccessingPaymentModalComponent implements OnInit {
  faSpinner = faSpinner;
  message: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    this.message = data.message;
  }

  ngOnInit(): void {
  }

}
