<section class="relative bg-gray-#f2f2f2 w-96 max-w-sm min-h-screen max-h-screen px-4 py-4 overflow-y-auto">

    <fa-icon (click)="close()"
        class="absolute top-3 right-3 cursor-pointer z-10 flex justify-center items-center text-yellow-#ff4103 lg-landscape:text-lg"
        [icon]="faClose"></fa-icon>

    <p class="relative font-Lato-Regular font-semibold text-gray-#252525
        us:text-19px
        lg-landscape:text-19px">{{'GIFT_CARDS' | translate}}</p>

    <div class="realtive w-full flex justify-between mt-2">
        <div (click)="navigateToGiftCards()" class="relative cursor-pointer w-47% bg-yellow-#FF6D03 font-Lato-Regular text-white h-9 rounded-full
        flex justify-center items-center leading-none">
            <p class="relative text-base leading-none">{{'SEE_ALL_GIFTS' | translate}}</p>
        </div>
        <div (click)="navigateToGiftCards()" class="relative cursor-pointer w-47% bg-white font-Lato-Regular border border-yellow-#FF6D03
         text-yellow-#FF6D03 h-9 rounded-full flex justify-center items-center leading-none">
            <p class="relative text-base leading-none">{{'GO_TO_HISTORY' | translate}}</p>
        </div>
    </div>

    <div *ngIf="!loading_gift_cards" class="grid grid-cols-1 items-start 
        flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-5">
        <app-gift-product-card *ngFor="let gift_card of gift_cards" [name]="gift_card?.promo_name"
            [display_usd_amount]="gift_card?.usd_display_amount" [usd_amount]="gift_card?.usd_amount"
            [imageUrl]="gift_card?.image" [id]="gift_card?.id" [turn]="true"
            (send_data)="selectedGiftCard($event)"></app-gift-product-card>
    </div>

    <div *ngIf="loading_gift_cards" class="grid grid-cols-1 items-start 
        flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-5">
        <div class="relative h-40 animate-pulse bg-gray-#dfdfdf" *ngFor="let item of [1,2,3,4]"></div>
    </div>

</section>