export const trendings: any = {
    "headers": [
        {
            "text": "tendencias"
        }
    ],
    "links": [
        {
            "text": "Mercado único",
            "route": "",
            "links": [
                // {
                //     "text": "Tarjeta de Visa de Turista",
                //     "route": "",
                //     "headers": ["Visas & Pasaportes"]
                // },
                // {
                //     "text": "Visa HE-11",
                //     "route": ""
                // },
                // {
                //     "text": "Pasaporte Cubano",
                //     "route": ""
                // },
                // {
                //     "text": "Pasaporte Americano",
                //     "route": ""
                // },
            ]
        },
        {
            "text": "Tiendas",
            "route": "",
            "links": [
            ]
        },
        {
            "text": "Mayorista",
            "route": "",
            "links": []
        },
        {
            "text": "Recargas",
            "route": "",
            "links": []
        },
        {
            "text": "Vuelos",
            "route": "",
            "links": []
        },
        {
            "text": "Renta de Autos",
            "route": "",
            "links": []
        },
        {
            "text": "Transfers",
            "route": "",
            "links": []
        },
    ]
}