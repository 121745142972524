import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: 'app-single-img-slider',
  templateUrl: './single-img-slider.component.html',
  styleUrls: ['./single-img-slider.component.scss']
})
export class SingleImgSliderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;
  slider: KeenSliderInstance | undefined = undefined;
  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  @Input() imagesDirs: any[] = [];
  @Input() changeTime: number = 10000;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();
  }

  ngAfterViewInit(): void {
    this.slider = new KeenSlider(this.sliderRef?.nativeElement, {
      loop: true,
      slides: {
        perView: 1,
      },
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
    },
      [
        (slider) => {
          let timeout: number | ReturnType<typeof setTimeout>;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout)
          }

          const nextTimeout = () => {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              slider.next()
            }, this.changeTime)
          };
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on("dragStarted", clearNextTimeout);
          slider.on("animationEnded", nextTimeout);
          slider.on("updated", nextTimeout);
        },
      ]);
    this.dotHelper = [
      ...Array(this.slider?.track?.details?.slides?.length).keys(),
    ]
  }
  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (changes['imagesDirs']) {
        this.ngAfterViewInit();
      }
    }, 0);
  }

}
