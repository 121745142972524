import {Product} from "./product.model";
import {Address} from "./address.model";
import {ShipmentInterfaceModel} from "./shipping.model";
import {ProductInCart} from "./product_in_cart.model";

export interface Order {
    id: number;
    number: string;
    itemTotal: number;
    total: number;
    shipTotal: number;
    createdAt: Date;
    updatedAt: Date;
    completedAt: Date;
    currency: string;
    state: string;
    paymentState: string;
    items: Product[];
    shippingAddress: Address;
    shipmentAddress?: Address;
    shipments: ShipmentInterfaceModel[],
    groupedItemsByShipment?: {shipment: ShipmentInterfaceModel, items: ProductInCart[]}[];
    isLegacy: boolean;
}
