import {Component, Input, OnInit} from '@angular/core';

import {of, Subject, Subscription} from "rxjs";

import {AlgoliaService} from "../../../../shared/services/algolia.service";
import {SelectedZone, Store} from "../../../../shared/models";
import {StoreService} from "../../../../shared/services/store.service";
import {ZonesService} from "../../../../shared/services/zones.service";
import {LanguageService} from "../../../../shared/services/language.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-products-grid',
    templateUrl: './products-grid.component.html',
    styleUrls: ['./products-grid.component.scss']
})
export class ProductsGridComponent implements OnInit {

    @Input()
    grid: any;

    destroy$: Subject<boolean> = new Subject<boolean>();

    current_page: number = 1;
    defaultStoreObject: Store | undefined;
    facets: any;
    is_store_page: Boolean = false;
    next_page: any;
    perPage: number = 25;
    variables: any;

    loadedProducts: any[] = [];
    skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    constructor(
        private storeService: StoreService,
        private zonesService: ZonesService,
        private algoliaService: AlgoliaService,
        private languageService: LanguageService,
    ) {
    }

    ngOnInit(): void {
        this.variables = this.grid.attributes.Query.Variables;
        if (this.grid.attributes.Type === 'Products') {
            this.fetch_products(null, this.languageService.selectedLanguage.value, this.grid, this.getSelectedZone?.zone, this.getSelectedZone?.municipality, false,);
        } else {
            this.fetch_products(this.defaultStoreObject, this.languageService.selectedLanguage.value, this.grid, this.getSelectedZone?.zone, this.getSelectedZone?.municipality, false);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }


    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    private async fetch_products(store_id: any, lang: string, grid: any, zone: number, municipality?: number, exclude_facet?: boolean) {
        let uri = grid.attributes.Query.Uri;

        let products: any;

        let modifiedUri = this.replaceParamsInUri(uri, lang, this.variables, zone || 6,
            municipality || 37);
        this.showSkeletons();
        let FILTERS: any = {StoreIds: store_id,};
        FILTERS['ZoneIds'] = zone;
        if (municipality) FILTERS['MunicipalityId'] = municipality;
        exclude_facet ? FILTERS['ExcludeFacets'] = true : FILTERS['ExcludeFacets'] = false;
        try {
            products = await this.algoliaService.searchProducts(this.perPage, this.current_page, lang, FILTERS, modifiedUri).toPromise();
            products?.data == null ? this.next_page = false : null;
            this.loadedProducts = products?.data !== null ? products?.data?.items?.map((product: any, index: number) => {
                return {
                    ...product,
                    ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
                    currency: product.currency || 'USD',
                    store: product?.stores ?
                        (product?.stores[0]?.id && !this.is_store_page) ?
                            this.storeService.getStoreById(product?.stores[0]?.id)
                            : of(undefined)
                        : of(undefined)
                }
            }) : [];
            this.hideSkeletons();
        } catch (e) {

        }
    }

    hideSkeletons() {
        this.skeletons = [];
    }

    showSkeletons(){
        this.skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
    replaceParamsInUri(uri: string, language:string, variables: Map<number, string>, zone: number, municipalityId: number): string {
        let modifiedUri = uri;
        variables.forEach((value, key) => {
            if (value === 'locale') {
                modifiedUri = modifiedUri.replace(`{{${value}}}`, language);
            } else if (value === 'zoneId') {
                modifiedUri = modifiedUri.replace(`{{${value}}}`, zone.toString());
            } else if (value === 'municipalityId') {
                modifiedUri = modifiedUri.replace(`{{${value}}}`, municipalityId.toString());
            }
        });
        return modifiedUri;
    }
}
