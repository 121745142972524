export class Stack {
  private stack: object;
  private count: number;
  constructor(stack?: object, count?: number) {
    this.stack = stack || {};
    this.count = count || 0;
  }

  push(element: unknown) {
    // @ts-ignore
    this.stack[this.count] = element;
    this.count++;
    return this.stack;
  }

  pop() {
    this.count--;
    // @ts-ignore
    const element = this.stack[this.count];
    // @ts-ignore
    delete this.stack[this.count];
    return element;
  }

  peek() {
    // @ts-ignore
    return this.stack[this.count - 1];
  }

  size() {
    return this.count;
  }

  print() {
    console.log(this.stack);
  }

  clear() {
    this.stack = {};
  }
}
