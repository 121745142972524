export interface Address {
    id?: number;
    firstname: string;
    lastname: string;
    address1: string;
    address2: string;
    city: string;
    zipcode: number;
    phone: string;
    state_name: string;
    company?: string;
    country_name?: string;
    country_iso3?: string;
    country_iso: string;
    label?: string;
    state_code?: string;
    email_receiver?: string;
}
