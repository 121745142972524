import {of} from "rxjs";

export function replaceParamsInUri(uri: string, language: string, variables: Map<number, string>, zone: number, municipalityId: number): string {
    let modifiedUri = uri;
    variables.forEach((value, key) => {
        if (value === 'locale') {
            modifiedUri = modifiedUri.replace(`{{${value}}}`, language);
        } else if (value === 'zoneId') {
            modifiedUri = modifiedUri.replace(`{{${value}}}`, zone.toString());
        } else if (value === 'municipalityId') {
            modifiedUri = modifiedUri.replace(`{{${value}}}`, municipalityId.toString());
        }
    });
    return modifiedUri;
}
