import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { DeliveryPolicyAndFeesComponent } from './components/delivery-policy-and-fees/delivery-policy-and-fees.component';
import { ReturnPolicyComponent } from './components/return-policy/return-policy.component';
import { CustomerSupportComponent } from './components/customer-support/customer-support.component';
import { AboutKatapulkComponent } from './components/about-katapulk/about-katapulk.component';
import { FaqComponent } from './components/faq/faq.component';
import { AffidavitComponent } from './components/affidavit/affidavit.component';
import { GlobalSearchGridComponent } from './components/global-search-grid/global-search-grid.component';
import { CategoriesSearchGridComponent } from './components/categories-search-grid/categories-search-grid.component';
import { PassportIframeComponent } from './components/passport-iframe/passport-iframe.component';
import { He11Component } from './components/he11/he11.component';
import { VisaIframeComponent } from './components/visa-iframe/visa-iframe.component';
import { PropagationGuard } from "../../shared/guards/propagation.guard";
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TravelServicesComponent } from './components/travel-services/travel-services.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MigratoryProcessesComponent } from './components/migratory-processes/migratory-processes.component';
import { ClearRoutesStackGuard } from "../../shared/guards/clear-routes-stack.guard";
import { ContentComponent } from "./components/content/content.component";


const routes: Routes = [
    // { path: 'contents/delivery-policy', component: DeliveryPolicyAndFeesComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'contents/return-policy', component: ReturnPolicyComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'contents/support', component: CustomerSupportComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'contents/about-us', component: AboutKatapulkComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'katapulk/privacy-policy', component: PrivacyPolicyComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'contents/faq', component: FaqComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'customer/affidavit', component: AffidavitComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'global-search/:filter', canActivate: [PropagationGuard], component: GlobalSearchGridComponent },
    // { path: 'category/:id', component: CategoriesSearchGridComponent },
    { path: 'travel-services/main', component: TravelServicesComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'travel-services/passport', component: PassportIframeComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'travel-services/he11', component: He11Component, canActivate: [ClearRoutesStackGuard] },
    { path: 'travel-services/visa', component: VisaIframeComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'travel-services/migratory-processes', component: MigratoryProcessesComponent, canActivate: [ClearRoutesStackGuard] },
    // { path: 'contents/terms', component: TermsAndConditionsComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'katapulk/not-found', component: NotFoundComponent, canActivate: [ClearRoutesStackGuard] },
    { path: 'contents/:id', component: ContentComponent, canActivate: [ClearRoutesStackGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SharedRoutingModule { }
