export enum CheckoutEnum {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION_CHECKOUT',
  PERSONAL_INFORMATION_CART = 'PERSONAL_INFORMATION_CART_CHECKOUT',
  DELIVERY = 'DELIVERY_CHECKOUT',
  PAYMENT = 'PAYMENT_CHECKOUT',
  PAYMENT_CARDS = 'PAYMENT_CARDS_CHECKOUT',
  CONFIRM = 'CONFIRM_CHECKOUT',
  IS_WAS_SHOW_NOT_FOUND_404 = 'IS_WAS_SHOW_NOT_FOUND_404',
  IS_BOLSA_TRANSFER_ENUM = 'isBolsaTransfer',
}
export enum NewCheckoutEnum {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION_NEW_CHECKOUT',
  PERSONAL_INFORMATION_CART = 'PERSONAL_INFORMATION_CART_NEW_CHECKOUT',
  DELIVERY = 'DELIVERY_NEW_CHECKOUT',
  PAYMENT = 'PAYMENT_NEW_CHECKOUT',
  PAYMENT_CARDS = 'PAYMENT_CARDS_NEW_CHECKOUT',
  CONFIRM = 'CONFIRM_NEW_CHECKOUT',
  IS_WAS_SHOW_NOT_FOUND_404 = 'IS_WAS_SHOW_NOT_FOUND_404',
  IS_BOLSA_TRANSFER_ENUM = 'isBolsaTransfer',
  BOLSA_TRANSFER_DATA = 'BolsaTransferData',
  LAST_STEP = 'LAST_STEP_NEW_CHECKOUT'
}
