import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentRoutesEnum } from 'src/app/shared/enum/payment-routes.enum';
import { SelectedZone } from 'src/app/shared/models';
import { WalletService } from 'src/app/shared/services/wallet.service';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { faClose } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-gift-preview',
  templateUrl: './gift-preview.component.html',
  styleUrls: ['./gift-preview.component.scss']
})
export class GiftPreviewComponent implements OnInit {
  loading_gift_cards = false;
  gift_cards: any = [];
  faClose = faClose;

  constructor(
    private service: WalletService,
    private router: Router,
    private zonesService: ZonesService,
    private dialog: MatDialog,
    private dialogref: MatDialogRef<GiftPreviewComponent>) { }

  ngOnInit(): void {
    this.loadGiftCards();
  }

  loadGiftCards() {
    this.loading_gift_cards = true;
    this.service.getGiftCards().subscribe({
      next: (data: any) => {
        this.loading_gift_cards = false;
        this.gift_cards = data?.data?.items?.map((data: any) => {
          return {
            promo_name: data?.name,
            id: data?.id,
            usd_display_amount: data?.displayPaymentAmount,
            image: data?.image,
            usd_amount: data?.paymentAmount,
          };
        });
      },
      error: (err) => {
        this.loading_gift_cards = false;
        console.log(err);
      }
    })
  }

  async selectedGiftCard(e: any) {
    this.dialog.closeAll();
    try {
      if (e) {
        console.log({
          ...e
        });
        let params = {
          pa: btoa(e?.paymentAmount),
          id: btoa(e?.giftCardProductId),
          pr: btoa(PaymentRoutesEnum.GIFT_CARDS.toString()),
          du: btoa(e?.display_usd)
        };
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/payment`],
          {
            // relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  close() {
    this.dialogref.close();
  }

  navigateToGiftCards() {
    this.close();
    this.router.navigateByUrl(`/gift-cards`);
  }

}
