import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-proccessing-payment-modal',
  templateUrl: './proccessing-payment-modal.component.html',
  styleUrls: ['./proccessing-payment-modal.component.scss']
})
export class ProccessingPaymentModalComponent implements OnInit {
  faSpinner = faSpinner;

  constructor() { }

  ngOnInit(): void {
  }

}
