export enum ErrorsEnum {
  INVALID_GRANT = 'invalid_grant',
  UNPROCESSABLE_ENTITY = 422,
  UNVERIFIED_ACCOUNT = 'UnVerifiedAccount',
  WALLET_USAGE_NOT_ALLOWED = 'WalletUsageNotAllowed',
  UNCONFIRMED_EMAIL = 'UnconfirmedEmail',
  PAYMENT_CARD_NEEDS_REVALIDATION = 'PaymentCardNeedsRevalidation',
  NEED_TO_DO_QUICK_SCAN = 'NeedToDoQuickScan',
  ID_DOCUMENT_EXPIRED = 'IdDocumentExpired',
  REMITTANCE_COMPLIANCE_ERROR = 'RemittanceComplianceError',
  PENDING_STORE_ORDER = 'PendingStoreOrder',
  SHOPPING_CART_NEED_RELOCATION = 'ShoppingCartNeedReLocation',
  INSUFFICIENT_WALLET_FOUNDS = 'InsufficientWalletFounds',
}
