<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white">
    <div
        class="relative
    us:pt-3 us:w-full us:pl-5.3vw us:pr-5.3vw us pb-4
    us-landscape:pt-3 us-landscape:w-full us-landscape:pl-5.3vw us-landscape:pr-0
    md:pt-3 md:w-full md:pl-5.3vw md:pr-0
    md-landscape:w-full md-landscape:px-5.3vw
    lg:w-full lg:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px overflow-hidden box-border lg-landscape:h-auto lg-landscape:pt-0">
        <div class="relative w-full h-auto">
            <p class="relative font-Lato-Regular text-black underline mt-5">Affidavit Certification.
            </p>

            <p class="relative font-Lato-Regular text-black mt-4">I, the user, understand that exports from the United
                States to the Republic of Cuba are prohibited by the United States unless such exports are specifically
                authorized pursuant to a license or license exception. Specifically, the US Department of Commerce,
                Bureau of Industry and Security (BIS) administers and enforces the Export Administration Regulations
                (EAR; 15 C.F.R. §§ 730-774), which regulate exports from the US to the Republic of Cuba, among other
                exports.
            </p>

            <p class="relative font-Lato-Regular text-black mt-4">I, the user, declare and certify that:
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">The products were purchased from Katapulk Marketplace.
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">Exports of the products from the US to the Republic of
                Cuba are permissible pursuant to, and comply with, one of the following BIS License Exceptions:
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">License Exception Gift Parcels and Humanitarian
                Donations (15 CFR § 740.12);
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">License Exception Support for the Cuban People (15 CFR
                § 740.21); and
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">License Exception Baggage (15 CFR § 740.14).
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">I am not:
            </p>
            <p class="relative font-Lato-Regular text-black mt-1">(i) a member of the government of the Republic of
                Cuba; neither a prohibited officials of the Government of Cuba; nor (ii) purchasing these products on
                behalf of the government of the Republic of Cuba or any prohibited officials (31 CFR § 515.337).
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">(iii) a person designated on a US designated persons
                list, including the US Treasury Department, Office of Foreign Assets Control Specially Designated
                National List or the US State Department Cuba Restricted List; (iv) part of an entity on either list;
                nor (v) purchasing these products on behalf of a person or entity on either list (31 CFR § 515.209).
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">(vi) a prohibited members of the Cuban Communist Party
                (31 CFR § 515.338).
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">I am 18 years of age or older.
            </p>
            <p class="relative font-Lato-Regular text-black mt-4">I solemnly affirm under penalty of perjury that, to
                the best of my knowledge, the foregoing in true and accurate.
            </p>
        </div>
    </div>
</section>