<form *ngIf="meta?.length > 0" [formGroup]="form" class="relative w-full bg-gray-#f2f2f2 grid gap-y-1">
  <div *ngFor="let section of meta"
       [ngClass]="widthFull ? 'grid-cols-1' : 'us:grid-cols-1 us-landscape:grid-cols-2 md:grid-cols-2 lg-landscape:grid-cols-2'"
       class="relative w-full grid gap-x-5 gap-y-1">
    <div class="relative w-full" *ngFor="let control of section.section">
      <app-control [control]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched" [selectedReceiverData]="selectedReceiverData"></app-control>
    </div>
    <!-- <div class="relative w-full"> -->
    <!-- <div class="relative w-full grid grid-cols-1" *ngIf="meta.nestedField"> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
  <!-- <div class="relative w-full flex justify-start items-center bg-gray-#f2f2f2
    lg-landscape:mt-7 lg-landscape:pl-23px lg-landscape:pr-23px">
    <button (click)="submit()" class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer
           lg-landscape:w-auto lg-landscape:h-41px lg-landscape:rounded-3px lg-landscape:pl-5 lg-landscape:pr-5"
      type="button">
      <p class="relative font-Lato-Bold
          lg-landscape:text-15px lg-landscape:leading-4">{{'CONTINUE' | translate}}</p>
    </button>
    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
           lg-landscape:w-28 lg-landscape:h-41px lg-landscape:rounded-3px lg-landscape:ml-2" type="button">
      <p class="relative font-Lato-Bold
          lg-landscape:text-15px lg-landscape:leading-4">{{'CANCEL' | translate}}</p>
    </button>
  </div> -->
</form>
