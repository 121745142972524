import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropDownControl } from './dropdown control';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() meta!: DropDownControl;
  @Input() form!: FormGroup;
  @Input() formMarkAsTouched!: boolean;
  options: any[] = [];
  nestedOptions: { item1: string, item2: string }[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.meta?.optionsToSelect) {
      this.options = this.meta.optionsToSelect;
    }

    if (this.meta && this.meta.fieldId) {
      this.selectChange(this.selfControl);
    }
  }

  selectChange(e: any) {
    if (this.meta.nestedField !== null && this.getControlValue(this.meta.fieldId) !== null) {
      this.nestedOptions = this.getNestedOptions(e.value);
    }
  }

  getControlValue(controlId: string) {
    return this.form.controls[`${controlId}`].value;
  }

  getNestedOptions(id: string) {
    let CITIES: { item1: string, item2: string }[] = [];
    this.meta.nestedField.optionsToSelect.forEach((option: { item1: string, item2: [] }) => {
      if (id == option.item1) {
        CITIES = [...option.item2];
      }
    });
    return CITIES;
  }

  get selfControl() {
    return this.form.controls[`${this.meta.fieldId}`];
  }

  get selfNestedFieldControl() {
    return this.form.controls[`${this.meta.nestedField.fieldId}`];
  }
}
