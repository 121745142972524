import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {Address, Card, CardNewIntegration, RevalidateCard} from "../models";
import {Observable, of, retry} from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {CardsResponse, WalletResponse} from "../models";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class CardService {
  private resource = '/storefront/account/credit_cards'; // peticion para /api/v2
  private resourceNew = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentCards`; // api/v1
  private resourceNewEdit = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentCards/Address`; // api/v1
  private resourceDelete = '/storefront/account/credit_cards/destroy_cards';
  private resourceCreditCardsByPaymentMethodId = '/storefront/account/credit_cards/saved'; // peticion para /api/v2
  maxRetries = environment.retry_on_fail;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
  }

  createCard(data: any): Observable<WalletResponse> {
    // @ts-ignore
    return this.http.post(this.resourceNew, data, {headers: this.getHeader()})
      .pipe(
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        tap((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
        })
      );
  }

  updateCard(data: any): Observable<WalletResponse> {
    // @ts-ignore
    return this.http.put(this.resourceNewEdit, data, {headers: this.getHeader()})
      .pipe(
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        tap((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
        })
      );
  }

  getCardsByPaymentMethodId(paymentMethodId: string) {
    const httpParams = new HttpParams()
      .set('payment_method_id', paymentMethodId);

    return this.apiService.get(this.resourceCreditCardsByPaymentMethodId, httpParams)
      .pipe(map(response => {
        return this.mapCardResponse(response.cards.data);
      }));
  }

  /**
   * @description Obtener todas las tarjetas del usuario
   */
  getCards(): Observable<CardsResponse> {
    return this.http.get(`${this.resourceNew}`, {headers: this.getHeader()})
      .pipe(
        retry(this.maxRetries),
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        map((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
          return <CardsResponse>response.data;
        })
      );
  }

  getSavedCards() {
    const httpParams = new HttpParams();
    return this.apiService.get(this.resource + '/saved', httpParams)
      .pipe(map(response => {
        return this.mapSavedCardResponse(response);
      }));
  }

  /**
   * @description Obtener todas las tarjetas del usuario
   * @param include
   */
  getDefaultCard(
    include = 'payment_method',
  ) {
    const httpParams = new HttpParams()
      .set('include', include);

    return this.apiService.get(`${this.resource}/default`, httpParams)
      .pipe(map(cardR => {
        return <Card>{
          id: cardR.data.id,
          type: cardR.data.type,
          cc_type: cardR.data.attributes.cc_type,
          last_digits: cardR.data.attributes.last_digits,
          month: cardR.data.attributes.month,
          year: cardR.data.attributes.year,
          name: cardR.data.attributes.name,
          default: cardR.data.attributes.default,
        };
      }));
  }

  /**
   * @description Obtener todas las tarjetas del usuario
   * @param cardId
   */
  removeCard(
    cardId: string,
  ) {
    return this.http.delete(this.resourceNew, {body: {id: cardId}, headers: this.getHeader()})
      .pipe(
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        tap((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
        })
      );
  }

  /**
   * @description Revalidar tarjeta
   * @param cardData
   */
  revalidateCard(
    cardData: RevalidateCard,
  ): Observable<WalletResponse> {
    return this.http.post(`${this.resourceNew}/Revalidate`, cardData, {headers: this.getHeader()})
      .pipe(
        retry(this.maxRetries),
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        tap((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
        })
      );
  }

  private mapCardResponseNewIntegration(response: CardsResponse) {
    return <CardsResponse>{
      abilityToAdd: response.abilityToAdd,
      remainingSlots: response.remainingSlots,
      cards: <CardNewIntegration[]>response.cards
    }
  }

  private mapCardResponse(response: any) {
    return <Card[]>response.map((cardR: any) => {
      return <Card>{
        id: cardR.id,
        type: cardR.type,
        cc_type: cardR.attributes.cc_type,
        last_digits: cardR.attributes.last_digits,
        month: cardR.attributes.month,
        year: cardR.attributes.year,
        name: cardR.attributes.name,
        default: cardR.attributes.default,
        gateway_customer_profile_id: cardR.attributes.gateway_customer_profile_id,
        gateway_payment_profile_id: cardR.attributes.gateway_payment_profile_id,
      }
    });
  }

  private mapSavedCardResponse(response: any) {
    return {
      card_limit: response.card_limit,
      current_used_cards: response.current_used_cards,
      cards: <Card[]>response.cards.data.map((cardR: any) => {
        return <Card>{
          id: cardR.id,
          type: cardR.type,
          cc_type: cardR.attributes.cc_type,
          last_digits: cardR.attributes.last_digits,
          month: cardR.attributes.month,
          year: cardR.attributes.year,
          name: cardR.attributes.name,
          default: cardR.attributes.default,
          gateway_customer_profile_id: cardR.attributes.gateway_customer_profile_id,
          gateway_payment_profile_id: cardR.attributes.gateway_payment_profile_id,
        }
      })
    };

    // return <Card[]>response.map((cardR: any) => {
    //   return <Card>{
    //     id: cardR.id,
    //     type: cardR.type,
    //     cc_type: cardR.attributes.cc_type,
    //     last_digits: cardR.attributes.last_digits,
    //     month: cardR.attributes.month,
    //     year: cardR.attributes.year,
    //     name: cardR.attributes.name,
    //     default: cardR.attributes.default,
    //     gateway_customer_profile_id: cardR.attributes.gateway_customer_profile_id,
    //     gateway_payment_profile_id: cardR.attributes.gateway_payment_profile_id,
    //   }
    // });
  }

  /**
   * Get billing address by card id
   * @param cardId
   */
  getCardBillingAddress(cardId: string): Promise<Address> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.resourceNew}/BillingAddress`, {
        headers: this.getHeader(),
        params: new HttpParams().set('CardId', cardId).set('locale', this.translateService.currentLang)
      })
        .pipe(
          retry(this.maxRetries),
          // @ts-ignore
          catchError((error) => {
            // return this.apiService.handleErrors(error, true);
            this.apiService.handleErrors(error, true);
            reject(error);
          }),
          // map((response: WalletResponse) => {
        ).subscribe({
          next: (response: WalletResponse) => {
            // console.log('llega hasta aqui...  => ', response);
            if (response.fails) {
              this.apiService.showError(response.message, true);
              reject(response.message);
            }
            // return <Address>response.data;
            resolve(<Address>response.data);
          },
      });
    });
  }

  // todo remover cuando estén los enpoints correctamente...
  /**
   * @description Obtener todas las tarjetas del usuario
   */
  getMockedCards() {

    return of({
      "data": [
        {
          "id": "1",
          "type": "credit_card",
          "attributes": {
            "cc_type": "visa",
            "last_digits": "1111",
            "month": 12,
            "year": 2026,
            "name": "John Doe",
            "default": true
          },
          "relationships": {
            "payment_method": {
              "data": {
                "id": "string",
                "type": "string"
              }
            }
          }
        },
        {
          "id": "2",
          "type": "credit_card",
          "attributes": {
            "cc_type": "mastercard",
            "last_digits": "6695",
            "month": 12,
            "year": 2030,
            "name": "John Doe",
            "default": false
          },
          "relationships": {
            "payment_method": {
              "data": {
                "id": "string",
                "type": "string"
              }
            }
          }
        },
        {
          "id": "3",
          "type": "credit_card",
          "attributes": {
            "cc_type": "american_express",
            "last_digits": "2034",
            "month": 12,
            "year": 2028,
            "name": "John Doe",
            "default": false
          },
          "relationships": {
            "payment_method": {
              "data": {
                "id": "string",
                "type": "string"
              }
            }
          }
        }
      ],
      "included": [
        {
          "id": "1",
          "type": "payment_method",
          "attributes": {
            "type": "Spree::Gateway::StripeGateway",
            "name": "Stripe",
            "description": "Stripe Payments",
            "preferences": {}
          }
        }
      ],})
      .pipe(map(response => {
        return response; //this.mapCardResponse(response.data);
      }));
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders.set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang)
      // .set('ngrok-skip-browser-warning', 'web');
    return  httpHeaders;
  }
}
