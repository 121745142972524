import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "./notification.service";
import {empty, Observable, of, tap, throwError} from "rxjs";
import {NewCart, NewCartResponse} from "../models/new-cart.model";
import {catchError, map} from "rxjs/operators";
import {
  AddressItem,
  GetBillingAddressResponse,
  GetDeliveryCountriesResponse, GetDeliveryStatesResponse,
  NewAddAddressRequest,
  NewAddAddressResponse,
  NewAddressResponse
} from "../models/new-address.model";
import {LegacyAddressResponse} from "../models/new-legacy-address.model";

@Injectable({
  providedIn: 'root'
})
export class NewAddressService {

  private resource: string = `${environment.wallet_api_url}${environment.v1}/Orders/StoreShippingAddresses`;  // api/v1
  private resourceBillingAddress: string = `${environment.wallet_api_url}${environment.v1}/Payment/BillingAddress`;  // api/v1

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private httpService: HttpClient,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
  }

  getAddresses(data: { Filters?: string, Sorts?: string, Page: number, PageSize: number, OnlyValidForMyCart: boolean }): Observable<NewAddressResponse> {
    // @ts-ignore
    return this.httpService.get<NewAddressResponse>(this.resource, {
      headers: this.getHeader(),
      params: new HttpParams({fromObject: data})
    }).pipe(
      tap((addressesResponse: NewAddressResponse) => {
        if (addressesResponse.fails) {
          this.apiService.handleErrors(new Error(addressesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  addAddresses(data: NewAddAddressRequest): Observable<NewAddAddressResponse> {
    // @ts-ignore
    return this.httpService.post<NewAddAddressResponse>(this.resource, data, {
      headers: this.getHeader(),
    }).pipe(
      tap((addressesResponse: NewAddAddressResponse) => {
        if (addressesResponse.fails) {
          this.apiService.handleErrors(new Error(addressesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  updateAddresses(data: { id: string } & NewAddAddressRequest): Observable<NewAddAddressResponse> {
    // @ts-ignore
    return this.httpService.put<NewAddAddressResponse>(this.resource, data, {
      headers: this.getHeader(),
    }).pipe(
      tap((addressesResponse: NewAddAddressResponse) => {
        if (addressesResponse.fails) {
          this.apiService.handleErrors(new Error(addressesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  getDeliveryCountries(OnlyValidFromMyCart: boolean = false): Observable<GetDeliveryCountriesResponse> {
    // @ts-ignore
    return this.httpService.get<GetDeliveryCountriesResponse>(`${this.resource}/DeliveryCountries`, {
      headers: this.getHeader(),
      params: new HttpParams().set('OnlyValidFromMyCart', OnlyValidFromMyCart)
    }).pipe(
      tap((deliveryCountriesResponse: GetDeliveryCountriesResponse) => {
        if (deliveryCountriesResponse.fails) {
          this.apiService.handleErrors(new Error(deliveryCountriesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  getDeliveryStates(CountryIso2Code: string): Observable<GetDeliveryStatesResponse> {
    // @ts-ignore
    return this.httpService.get<GetDeliveryStatesResponse>(`${this.resource}/DeliveryStates`, {
      headers: this.getHeader(),
      params: new HttpParams().set('CountryIso2Code', CountryIso2Code)
    }).pipe(
      tap((deliveryStatesResponse: GetDeliveryStatesResponse) => {
        if (deliveryStatesResponse.fails) {
          this.apiService.handleErrors(new Error(deliveryStatesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  getBillingCountries(): Observable<GetDeliveryCountriesResponse> {
    // @ts-ignore
    return this.httpService.get<GetDeliveryCountriesResponse>(`${this.resourceBillingAddress}/BillingCountries`, {
      headers: this.getHeader(),
    }).pipe(
      tap((billingCountriesResponse: GetDeliveryCountriesResponse) => {
        if (billingCountriesResponse.fails) {
          this.apiService.handleErrors(new Error(billingCountriesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  getBillingStates(CountryIso2Code: string): Observable<GetDeliveryStatesResponse> {
    // @ts-ignore
    return this.httpService.get<GetDeliveryStatesResponse>(`${this.resourceBillingAddress}/BillingStates`, {
      headers: this.getHeader(),
      params: new HttpParams().set('CountryIso2Code', CountryIso2Code)
    }).pipe(
      tap((billingStatesResponse: GetDeliveryStatesResponse) => {
        if (billingStatesResponse.fails) {
          this.apiService.handleErrors(new Error(billingStatesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  deleteAddress(id: string) {
    return this.httpService.put<GetDeliveryStatesResponse>(`${this.resource}/HideForMe`, {id}, {
      headers: this.getHeader(),
    }).pipe(
      tap((deliveryStatesResponse: GetDeliveryStatesResponse) => {
        if (deliveryStatesResponse.fails) {
          this.apiService.handleErrors(new Error(deliveryStatesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  getBillingAddresses(data: { Filters?: string, Sorts?: string, Page?: number, PageSize?: number }): Observable<GetBillingAddressResponse> {
    // @ts-ignore
    return this.httpService.get<GetBillingAddressResponse>(this.resourceBillingAddress, {
      headers: this.getHeader(),
      params: new HttpParams({fromObject: data})
    }).pipe(
      tap((addressesResponse: GetBillingAddressResponse) => {
        if (addressesResponse.fails) {
          this.apiService.handleErrors(new Error(addressesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );
  }

  setBillingAddresses(data: NewAddAddressRequest): Observable<GetBillingAddressResponse> {
    // @ts-ignore
    return this.httpService.post<GetBillingAddressResponse>(`${this.resourceBillingAddress}/SetAddress`, data, {
      headers: this.getHeader(),
    }).pipe(
      tap((addressesResponse: GetBillingAddressResponse) => {
        if (addressesResponse.fails) {
          this.apiService.handleErrors(new Error(addressesResponse.message), true);
        }
      }),
      catchError((error: any) => {
        return this.apiService.handleErrors(error, true);
      })
    );

  }

  getLegacyAddress(OnlyValidForMyCart: boolean = false) {
      // @ts-ignore
      return this.httpService.get<LegacyAddressResponse>(`${this.resource}/LegacyAddress`, {
          headers: this.getHeader(),
          params: new HttpParams({ fromObject: { OnlyValidForMyCart } })
      }).pipe(
          tap((addressesResponse: LegacyAddressResponse) => {
              if (addressesResponse.fails) {
                  this.apiService.handleErrors(new Error(addressesResponse.message), true);
              }
          }),
          catchError((error: any) => {
              return this.apiService.handleErrors(error, true);
          })
      );
  }


  /**
   * Get headers
   * @private
   */
  getHeader(): HttpHeaders {
    let headers = this.authService.getHeader();
    headers = headers
      .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang);
    return headers;
  }
}
