<div class="relative w-full flex justify-between p-3 bg-white animate-pulse">
    <div class="relative w-100px h-100px bg-gray-#f2f2f2"></div>
    <div class="relative w-3/12 flex flex-col justify-between">
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
    </div>
    <div class="relative w-1/6 flex flex-col justify-between us:hidden md:flex">
        <div class="relative w-full h-1/4 bg-gray-#f2f2f2 rounded-full"></div>
    </div>
    <div class="relative w-2/12 flex flex-col justify-between us:hidden us-landscape:hidden lg:flex lg-landscape:flex">
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
        <div class="relative w-full h-1/5 bg-gray-#f2f2f2"></div>
    </div>
    <div class="relative w-1/6 flex flex-col justify-between">
        <div class="relative w-full h-2/6 bg-gray-#f2f2f2 rounded-full"></div>
    </div>
</div>