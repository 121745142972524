import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { of, Subject, Subscription } from 'rxjs';
import { Product, SelectedZone } from 'src/app/shared/models';
import { map, takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { StoreService } from "../../../../shared/services/store.service";
import { FormControl, FormGroup } from "@angular/forms";
import {SEARCH_FILTER_DEFAULT_VALUE, SEARCH_FILTERS, SearchFiltersType} from "../../../../shared/filters/searc-filters";


@Component({
  selector: 'app-featured-products-grid',
  templateUrl: './featured-products-grid.component.html',
  styleUrls: ['./featured-products-grid.component.scss']
})
export class FeaturedProductsGridComponent implements OnInit, OnDestroy {
  searchSkeletons: any = [];
  languageServiceSubscription!: Subscription;
  langu = 'ES';
  destroy$: Subject<boolean> = new Subject<boolean>();
  product_page = 1;
  totalPages: number = 2;
  actualPage: number = 1;
  products: Product[] = [];
  filterForm: FormGroup;
  order: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE;
  filters: { taxon?: number, price?: string, name?: string } = { name: '__featured__' };

  perPageCount: number = 24;

  orderByArray: SearchFiltersType[] = SEARCH_FILTERS;

  constructor(
    private productsService: ProductsService,
    private languageService: LanguageService,
    private activeRoute: ActivatedRoute,
    private zonesService: ZonesService,
    private storeService: StoreService
  ) {
    this.order = SEARCH_FILTER_DEFAULT_VALUE;
    this.filterForm = new FormGroup({
      order: new FormControl({ value: this.order, disabled: !this.getSelectedZone }, {
        validators: []
      }),
    });
    this.filterForm.get('order')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.getSelectedZone) {
          this.order = this.filterForm.get('order')?.value;
          this.search();
        }
      });
  }

  ngOnInit(): void {
    this.languageServiceSubscription = this.languageService.selectedLanguage.subscribe(lang => {
      this.langu = lang;
      this.zonesService.subjectSelectedZones$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.getSelectedZone) {
            this.filterForm.get('order')?.enable();
          } else {
            this.filterForm.get('order')?.disable();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  search() {
    window.scroll({ top: 0 });
      this.searchSkeletons = Array(this.perPageCount);
      this.products = [];
      this.searchProducts(this.langu);
  }

  searchProducts(lang: string) {
    this.searchSkeletons = Array(this.perPageCount);
    this.products = [];
    this.productsService.searchProducts(
      this.product_page,
      this.perPageCount,
      this.getSelectedZone.zone || 6,
      // 1,
      this.getSelectedZone.municipality || 37,
      lang,
      `default_variant,variants,images,variants.images,variants.option_values,data_extra_types`,
      this.order,
      this.filters
    )
      .pipe(
        map((pdts): Product[] => {
          this.searchSkeletons = [];
          this.totalPages = pdts.meta.total_pages;
          return this.productsService.mapProductsFromResponse(pdts);
        })
      ).subscribe((products: Product[]) => {
        this.products = [...products];
      });
  }

  loadMore() {
    this.searchSkeletons = Array(this.perPageCount);
    this.productsService.searchProducts(
      ++this.actualPage,
      this.perPageCount,
      this.getSelectedZone.zone || 6,
      // 1,
      this.getSelectedZone.municipality || 37,
      this.langu,
      `default_variant,variants,images,variants.images,variants.option_values,data_extra_types`,
      this.order,
      this.filters
    )
      .pipe(
        map((pdts): Product[] => {
          this.searchSkeletons = [];
          return this.productsService.mapProductsFromResponse(pdts);
        })
      ).subscribe((products: Product[]) => {
        this.products = [...this.products, ...products];
      });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  findProductsByFilter(filter: any) {
    this.filters.price = filter?.data?.price;
    this.searchProducts(this.langu);
  }

}
