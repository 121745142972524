import { Component, OnInit, Input } from '@angular/core';
import { ControlBase } from '../controls-base';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss']
})
export class BooleanInputComponent implements OnInit {
  @Input() meta!: ControlBase;
  @Input() form!: FormGroup;
  @Input() formMarkAsTouched!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getValue(e: any) {
    console.log(e);
  }

}
