export const stores: any = {
    "headers": [
        {
            "text": "STORES"
        },
        {
            "text": "STORES_DIRECTORY",
            "link": "/stores/directory"
        },
        {
            "text": "IWANT_TO_SELL_IN_KATAPULK",
            "link": "/stores/k-seller"
        }
    ],
    "subheaders": [
        {
            "text": "STORES_LIST"
        },
    ],
    "links": [
        {
            "text": "FCBC",
            "route": "",
            "links": []
        },
        {
            "text": "Lola Dulcería - Café",
            "route": "",
            "links": []
        },
        {
            "text": "La Casona",
            "route": "",
            "links": []
        },
        {
            "text": "Jardín Angélica",
            "route": "",
            "links": []
        },
        {
            "text": "El Bodegón del Asado",
            "route": "",
            "links": []
        },
        {
            "text": "Casa Mia Paladar",
            "route": "",
            "links": []
        },
        {
            "text": "Dolce Tentazione",
            "route": "",
            "links": []
        },
        {
            "text": "Home Deli",
            "route": "",
            "links": []
        },
        {
            "text": "El Idilio",
            "route": "",
            "links": []
        },
        {
            "text": "Chic Habana - Centro de Belleza",
            "route": "",
            "links": []
        },
        {
            "text": "Deco Cerámica",
            "route": "",
            "links": []
        },
        {
            "text": "Ándale",
            "route": "",
            "links": []
        },
        {
            "text": "PekeArt",
            "route": "",
            "links": []
        },
    ],
    "links2": [
        {
            "text": "Magoz - Servicios Fotográficos",
            "route": "",
            "links": []
        },
        {
            "text": "Tic Tac Boquitas",
            "route": "",
            "links": []
        },
        {
            "text": "La Guarida",
            "route": "",
            "links": []
        },
        {
            "text": "Tu Brands",
            "route": "",
            "links": []
        },
        {
            "text": "Ya Voy Delivery",
            "route": "",
            "links": []
        },
        {
            "text": "Implementos Deportivos",
            "route": "",
            "links": []
        },
        {
            "text": "La Caja",
            "route": "",
            "links": []
        },
        {
            "text": "Brisca",
            "route": "",
            "links": []
        },
        {
            "text": "Reyes Manso Pre-Elaborados",
            "route": "",
            "links": []
        },
        {
            "text": "Humidores Habana",
            "route": "",
            "links": []
        },
        {
            "text": "Zig Zag",
            "route": "",
            "links": []
        },
        {
            "text": "DFORJA",
            "route": "",
            "links": []
        },
    ]
}