import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuxRoutesService {
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.currentUrl !== this.previousUrl) {
          sessionStorage.setItem('currentUrl', this.currentUrl);
          sessionStorage.setItem('previousUrl', this.previousUrl);
        }
      };
    });
  }

  public getPreviousUrl() {
    let final_route: string | null = '/';

    if (sessionStorage.getItem('previousUrl')) {
      final_route = sessionStorage.getItem('previousUrl');
    }
    return final_route;
  }

  public getActualUrl() {
    return sessionStorage.getItem('currentUrl');
  }
}
