import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: 'app-produc-detail-imgs-slider',
  templateUrl: './produc-detail-imgs-slider.component.html',
  styleUrls: ['./produc-detail-imgs-slider.component.scss']
})
export class ProducDetailImgsSliderComponent implements OnInit {
  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;
  @Input() imgs: any = [];
  slider: any = {};
  currentSlide: any;
  dotHelper: Array<Number> = []

  constructor() { }

  ngOnInit(): void {
    this.currentSlide = 1;
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();
  }

  ngAfterViewInit(): void {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: true,
      slides: {
        perView: 1,
      },
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
    })
    this.dotHelper = [
      ...Array(this.slider?.track?.details?.slides?.length).keys(),
    ]
  }

}
