<section class="relative w-full h-auto flex justify-center items-start bg-white">
    <div
        class="relative flex overflow-hidden box
    us:w-full us:h-auto us:flex-col us:px-0
    lg-landscape:w-1291px lg-landscape:flex-row lg-landscape:h-316px lg-landscape:px-60px lg-landscape:justify-between lg-landscape:items-center ">
        <div class="us:mt-2 lg-landscape:mt-0 z-10">
            <app-single-img-slider [imagesDirs]="imagesDir" *ngIf="imagesDir?.length !== 0"></app-single-img-slider>
            <div *ngIf="imagesDir?.length == 0" class="slider-skeleton
            us:w-screen us:min-h-113px us:px-2
            us-landscape:min-h-113px
            lg-landscape:w-915px lg-landscape:h-268px">
                <div class="animate-pulse flex space-x-4
                us:w-full us:min-h-113px
                us-landscape:min-h-113px
                md:h-268px
                md-landscape:h-268px
                lg-landscape:w-full lg-landscape:h-full">
                    <div class="rounded-lg bg-gray-300
                    us:w-full us:min-h-113px
                    us-landscape:min-h-113px
                    md:h-268px
                    md-landscape:h-268px
                    lg-landscape:w-full lg-landscape:h-full"></div>
                </div>
            </div>
        </div>
        <div class="relative w-full flex justify-between
        us:flex-row us:mt-4 us:px-2
        lg-landscape:h-268px lg-landscape:flex-col lg-landscape:mt-0">
            <!--            item && item.BackgroundColor ?? {'background-color': item.BackgroundColor}-->
            <div [ngStyle]="{'background-color': item && item.BackgroundColor ?? item.BackgroundColor}"
                *ngFor="let item of buttons"
                class="relative flex items-center box-border overflow-hidden rounded-3px
                        us:w-31% us:h-100px us:justify-center us:flex-col
                        lg-landscape:w-245px lg-landscape:justify-start lg-landscape:h-83.67px lg-landscape:flex-row hover:cursor-pointer"
                routerLink="{{'/'+item?.Route?.data?.attributes?.Route}}">
                <img [src]="item?.Leading?.data?.attributes?.url"
                    [ngClass]="item?.Label ? 'lg-landscape:ml-17px w-67px h-67px' : 'w-52 px-3'" class="relative"
                    alt="">

                <img *ngIf="!item?.Label" class="relative
                            us:hidden
                            lg-landscape:inline lg-landscape:w-27px" [src]="item?.Trailing?.data?.attributes?.url"
                    alt="">

                <div *ngIf="item?.Label" class="flex lg-landscape:flex-1 justify-between pl-3 pr-2">
                    <p class="relative font-Lato-Regular font-bold uppercase spaced
                          us:text-xs
                          sm:text-sm
                          lg-landscape:tracking-1.75px lg-landscape:text-sm">{{item?.Label}}</p>
                    <img class="relative
                            us:hidden
                            lg-landscape:inline lg-landscape:w-27px" [src]="item?.Trailing?.data?.attributes?.url"
                        alt="">
                </div>
            </div>
            <!-- <div class="relative fixedbtn flex justify-start items-center box-border overflow-hidden
            us:w-31% us:h-100px us:flex-col
            lg-landscape:w-245px  lg-landscape:h-83.67px lg-landscape:flex-row hover:cursor-pointer"
                [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'recargas-a-cuba']">
                <img src="../../../assets/imgs/home-slider/icon1-recargas2.svg" class="relative w-67px h-67px
                lg-landscape:ml-17px" alt="">
                <p class="relative font-Lato-Regular font-bold uppercase spaced
                us:text-xs
                sm:text-sm
                lg-landscape:tracking-1.75px lg-landscape:text-sm lg-landscape:ml-13px">{{'RECHARGES' | translate}}</p>
                <img class="relative
                us:hidden
                lg-landscape:inline lg-landscape:w-27px" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                    alt="">
            </div>
            <div class="relative fixedbtn flex justify-start items-center box-border overflow-hidden
            us:w-31% us:h-100px us:flex-col
            lg-landscape:w-245px lg-landscape:h-83.67px lg-landscape:flex-row hover:cursor-pointer"
                [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores','k-wholesaler']">
                <img src="../../../assets/imgs/home-slider/icon2-mayorista2.svg" class="relative w-67px h-67px
                lg-landscape:ml-15px" alt="">
                <p class="relative font-Lato-Regular font-bold uppercase spaced
                us:text-xs
                sm:text-sm
                lg-landscape:tracking-1.75px lg-landscape:text-sm lg-landscape:ml-13px">{{'WHOLESALERS' | translate}}
                </p>
                <img class="relative
                us:hidden
                lg-landscape:inline lg-landscape:w-27px" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                    alt="">
            </div>
            <div class="relative fixedbtn flex justify-start items-center box-border overflow-hidden
            us:w-31% us:h-100px us:flex-col
            lg-landscape:w-245px lg-landscape:h-83.67px lg-landscape:flex-row hover:cursor-pointer"
                [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores','directory']">
                <img src="../../../assets/imgs/home-slider/icon3-tiendas2.svg" class="relative w-67px h-67px
                lg-landscape:ml-17px" alt="">
                <p class="relative font-Lato-Regular font-bold uppercase spaced
                us:text-xs
                sm:text-sm
                lg-landscape:tracking-1.75px lg-landscape:text-sm lg-landscape:ml-13px">{{'STORES' | translate}}</p>
                <img class="relative
                us:hidden
                lg-landscape:inline lg-landscape:w-27px" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                    alt="">
            </div> -->
        </div>
    </div>
</section>