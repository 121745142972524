<div class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4
        mt-4" *ngIf="grid.attributes.Type === 'Products'">
    <app-product-card *ngFor="let product of loadedProducts" [productId]="product.id"
                      [imageUrl]="product.imagesDto['image600']" [name]="product.name"
                      [price]="product.defaultPrice" [slug]="product.slug"
                      [compare_at_price]="product.previous_price" [currency]="product.currency"
                      [isLowStock]="product.isLowStock" [quantity]="product.quantity"
                      [discount]="product.discount"
                      [variantId]="product.defaultVariantId" [shipping_category]="product?.deliveryTime"
                      [data_extra_types]="product.extraData!" [store]="product.store" [resize]="true"
                      [turn]="true"
                      [top]="product?.top" [age_valid]="product.ageValid"
                      [queryId]="product.queryId" [queryPosition]="product.queryPosition"
    >
    </app-product-card>
    <app-product-card-skeleton *ngFor="let skeleton of skeletons" [resize]="true" [turn]="true">
    </app-product-card-skeleton>
</div>

<div *ngIf="grid.attributes.Type === 'Stores'" class="grid us:grid-cols-2 sm:grid-cols-3 md:grid-cols-3
        lg:grid-cols-4 xl:grid-cols-5 grid-rows-1 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 pt-3
        lg-landscape:gap-x-4mt-4">
    <app-square-card class="us:min-w-220px us-landscape:min-w-220px lg-landscape:w-220px"
                     *ngFor="let store of loadedProducts; let i= index" [logoUrl]="store.image"
                     [storeName]="store.name"
                     [metaKeywords]="store.tags" [id]="store.id" [code]="store.code">
    </app-square-card>
    <div class="relative items-center justify-self-center h-auto" *ngIf="loadedProducts.length === 0">
        <p class="text-2xl text-gray-#727272 font-Lato-Regular font-semibold">{{'NO_RESULTS_FOUND' | translate}}</p>
    </div>
</div>
