import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../../../shared/services/notification.service";
import {WalletService} from "../../../../shared/services/wallet.service";
import {takeUntil} from "rxjs/operators";
import {faShareFromSquare, faSpinner, faWallet} from '@fortawesome/free-solid-svg-icons';
import {WalletResponse} from "../../../../shared/models";
import {ErrorsEnum} from "../../../../shared/enum/errors.enum";

@Component({
  selector: 'app-accept-terms-and-conditions',
  templateUrl: './accept-terms-and-conditions.component.html',
  styleUrls: ['./accept-terms-and-conditions.component.scss']
})
export class AcceptTermsAndConditionsComponent implements OnInit, OnDestroy {


  faShareFromSquare = faShareFromSquare;
  faSpinner = faSpinner;
  faWallet = faWallet;

  termsForm: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving = false;

  constructor(
    private dialogRef: MatDialogRef<AcceptTermsAndConditionsComponent>,
    private notificationService: NotificationService,
    private walletService: WalletService,
  ) {
    this.termsForm = new FormGroup({
      terms_cond: new FormControl(null, {
        validators: [Validators.required]
      }),
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit() {
    this.isSaving = true;
    this.termsForm.disable();
   this.walletService.activateWallet()
     .pipe(takeUntil(this.destroy$))
     .subscribe({
       next: (response: WalletResponse) => {
         if (response.success) {
           this.notifyAndCloseModal('WALLET_ACCOUNT_ACTIVATED_SUCCESSFULLY', true, true);
         } else {
           if (response.fails && response.responseCode === ErrorsEnum.UNVERIFIED_ACCOUNT) {
             this.dialogRef.close({modified: false, error: response.message});
           } else {
             this.notifyAndCloseModal(response.message, false, true);
           }
         }
       },
       error: () => {
         this.notifyAndCloseModal('WALLET_ACCOUNT_ACTIVATION_ERROR', false);
       },
       complete: () => {
         this.isSaving = false;
       }
     })
  }

  get termsConditions(): boolean {
    return this.termsForm.get('terms_cond')?.value || false;
  }

  notifyAndCloseModal(message: string, modified: boolean, closed = false) {
    this.notificationService.showAndSubscribe(message, 'CLOSE');
    if (closed) {
      this.dialogRef.close({modified});
    }
    this.isSaving = false;
    this.termsForm.enable();
  }

}
