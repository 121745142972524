export class RegisterModel {
  email: string;
  name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  public_metadata: {user_segment: string};
  private_metadata: {has_abandoned_cart: boolean};
  captcha_token: string | null;


  constructor(email: string, name: string, last_name: string, password: string, password_confirmation: string, public_metadata: { user_segment: string }, private_metadata: { has_abandoned_cart: boolean }, captcha_token: string) {
    this.email = email;
    this.name = name;
    this.last_name = last_name;
    this.password = password;
    this.password_confirmation = password_confirmation;
    this.public_metadata = public_metadata;
    this.private_metadata = private_metadata;
    this.captcha_token = captcha_token;
  }
}
