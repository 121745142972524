import { Component, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  Router, ActivatedRoute, Event, NavigationStart, NavigationEnd,
  NavigationError
} from "@angular/router";
import { ZonesService } from 'src/app/shared/services/zones.service';
import { SelectedZone } from 'src/app/shared/models';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email = faEnvelope;
  facebook = faFacebookF;
  instagram = faInstagram;
  showFooter = true;
  footer_data: any = null;

  constructor(
    private dynamicService: DynamicCOnfigService,
    private router: Router,
    private zonesService: ZonesService
  ) {
    if (this.router.url == '/passport' || this.router.url == '/he11' || this.router.url == '/visa') {
      this.showFooter = false;
    }
    this.dynamicService.dynamic_settings_subject.subscribe((data: any) => {
      this.getGLobalConfig();
    })
  }

  ngOnInit(): void {
    this.getGLobalConfig();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // if (event.url == '/passport' || event.url == '/he11' || event.url == '/visa') {
        //   this.showFooter = false;
        // } else {
        //   this.showFooter = true;
        // }
        // // Show progress spinner or progress bar
        // console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        if (event.url == '/passport' || event.url == '/he11' || event.url == '/visa') {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        // console.log(event.error);
      }
    });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  getGLobalConfig() {
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      this.footer_data = JSON.parse(aux)['FRONT_SETTINGS']?.data[0]?.attributes?.footer_definition?.data?.attributes;
    }
  }
}
