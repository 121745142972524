export const frontendSettingsMock = {
    "data": [
        {
            "id": 1,
            "attributes": {
                "Name": "All Front Settings",
                "Env": "All",
                "createdAt": "2023-05-09T00:52:08.815Z",
                "updatedAt": "2023-06-01T00:01:47.232Z",
                "publishedAt": "2023-05-09T00:52:11.279Z",
                "locale": "es",
                "global_contents": {
                    "data": [
                        {
                            "id": 2,
                            "attributes": {
                                "createdAt": "2023-05-09T00:47:21.858Z",
                                "updatedAt": "2023-05-09T01:29:50.599Z",
                                "publishedAt": "2023-05-09T00:47:23.113Z",
                                "InternalName": "Faq",
                                "Key": "faq",
                                "front_route": {
                                    "data": {
                                        "id": 1,
                                        "attributes": {
                                            "Route": "contents/faq",
                                            "createdAt": "2023-05-09T01:22:32.833Z",
                                            "updatedAt": "2023-05-09T01:22:34.405Z",
                                            "publishedAt": "2023-05-09T01:22:34.396Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 5,
                            "attributes": {
                                "createdAt": "2023-05-09T00:48:59.025Z",
                                "updatedAt": "2023-05-09T01:30:17.476Z",
                                "publishedAt": "2023-05-09T00:49:00.729Z",
                                "InternalName": "Politica entrega",
                                "Key": "delivery-policy",
                                "front_route": {
                                    "data": {
                                        "id": 3,
                                        "attributes": {
                                            "Route": "contents/delivery-policy",
                                            "createdAt": "2023-05-09T01:23:09.229Z",
                                            "updatedAt": "2023-05-09T01:23:11.404Z",
                                            "publishedAt": "2023-05-09T01:23:11.398Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 4,
                            "attributes": {
                                "createdAt": "2023-05-09T00:48:30.224Z",
                                "updatedAt": "2023-05-09T01:30:01.450Z",
                                "publishedAt": "2023-05-09T00:48:31.711Z",
                                "InternalName": "Politica de devolución",
                                "Key": "return-policy",
                                "front_route": {
                                    "data": {
                                        "id": 4,
                                        "attributes": {
                                            "Route": "contents/return-policy",
                                            "createdAt": "2023-05-09T01:23:35.125Z",
                                            "updatedAt": "2023-05-09T01:23:36.946Z",
                                            "publishedAt": "2023-05-09T01:23:36.942Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 1,
                            "attributes": {
                                "createdAt": "2023-05-09T00:46:09.551Z",
                                "updatedAt": "2023-05-09T01:30:33.809Z",
                                "publishedAt": "2023-05-09T00:46:55.678Z",
                                "InternalName": "Sobre Katapulk",
                                "Key": "about-us",
                                "front_route": {
                                    "data": {
                                        "id": 5,
                                        "attributes": {
                                            "Route": "contents/about-us",
                                            "createdAt": "2023-05-09T01:24:04.606Z",
                                            "updatedAt": "2023-05-09T01:24:06.425Z",
                                            "publishedAt": "2023-05-09T01:24:06.416Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 3,
                            "attributes": {
                                "createdAt": "2023-05-09T00:47:48.341Z",
                                "updatedAt": "2023-05-09T01:30:49.555Z",
                                "publishedAt": "2023-05-09T00:47:49.985Z",
                                "InternalName": "Términos y Condiciones",
                                "Key": "terms",
                                "front_route": {
                                    "data": {
                                        "id": 2,
                                        "attributes": {
                                            "Route": "contents/terms",
                                            "createdAt": "2023-05-09T01:22:43.654Z",
                                            "updatedAt": "2023-05-09T01:22:45.923Z",
                                            "publishedAt": "2023-05-09T01:22:45.918Z"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "global_images": {
                    "data": [
                        {
                            "id": 1,
                            "attributes": {
                                "Key": "404-cart-img",
                                "createdAt": "2023-05-09T00:33:52.498Z",
                                "updatedAt": "2023-05-09T00:33:54.752Z",
                                "publishedAt": "2023-05-09T00:33:54.745Z",
                                "Image": {
                                    "data": {
                                        "id": 34,
                                        "attributes": {
                                            "name": "404-cart-img.png",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 513,
                                            "height": 416,
                                            "formats": {
                                                "small": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/small_404_cart_img_c2e74bb161.png",
                                                    "hash": "small_404_cart_img_c2e74bb161",
                                                    "mime": "image/png",
                                                    "name": "small_404-cart-img.png",
                                                    "path": null,
                                                    "size": 55.73,
                                                    "width": 500,
                                                    "height": 405
                                                },
                                                "thumbnail": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_404_cart_img_c2e74bb161.png",
                                                    "hash": "thumbnail_404_cart_img_c2e74bb161",
                                                    "mime": "image/png",
                                                    "name": "thumbnail_404-cart-img.png",
                                                    "path": null,
                                                    "size": 17.23,
                                                    "width": 192,
                                                    "height": 156
                                                }
                                            },
                                            "hash": "404_cart_img_c2e74bb161",
                                            "ext": ".png",
                                            "mime": "image/png",
                                            "size": 9.31,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/404_cart_img_c2e74bb161.png",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:33:39.011Z",
                                            "updatedAt": "2023-05-09T00:33:39.011Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 2,
                            "attributes": {
                                "Key": "404-img",
                                "createdAt": "2023-05-09T00:34:23.507Z",
                                "updatedAt": "2023-05-09T00:34:24.928Z",
                                "publishedAt": "2023-05-09T00:34:24.923Z",
                                "Image": {
                                    "data": {
                                        "id": 35,
                                        "attributes": {
                                            "name": "404-img.png",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 513,
                                            "height": 416,
                                            "formats": {
                                                "small": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/small_404_img_974b53b9f1.png",
                                                    "hash": "small_404_img_974b53b9f1",
                                                    "mime": "image/png",
                                                    "name": "small_404-img.png",
                                                    "path": null,
                                                    "size": 101.57,
                                                    "width": 500,
                                                    "height": 405
                                                },
                                                "thumbnail": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_404_img_974b53b9f1.png",
                                                    "hash": "thumbnail_404_img_974b53b9f1",
                                                    "mime": "image/png",
                                                    "name": "thumbnail_404-img.png",
                                                    "path": null,
                                                    "size": 29.7,
                                                    "width": 192,
                                                    "height": 156
                                                }
                                            },
                                            "hash": "404_img_974b53b9f1",
                                            "ext": ".png",
                                            "mime": "image/png",
                                            "size": 19.03,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/404_img_974b53b9f1.png",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:34:17.584Z",
                                            "updatedAt": "2023-05-09T00:34:17.584Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 36,
                            "attributes": {
                                "Key": "coming-soon",
                                "createdAt": "2023-05-09T00:37:03.111Z",
                                "updatedAt": "2023-08-21T19:28:14.710Z",
                                "publishedAt": "2023-05-09T00:37:04.410Z",
                                "Image": {
                                    "data": {
                                        "id": 992,
                                        "attributes": {
                                            "name": "COMING SOON mas garnde.png",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 2146,
                                            "height": 2831,
                                            "formats": {
                                                "large": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/large_COMING_SOON_mas_garnde_534a771188.png",
                                                    "hash": "large_COMING_SOON_mas_garnde_534a771188",
                                                    "mime": "image/png",
                                                    "name": "large_COMING SOON mas garnde.png",
                                                    "path": null,
                                                    "size": 234.85,
                                                    "width": 758,
                                                    "height": 1000
                                                },
                                                "small": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/small_COMING_SOON_mas_garnde_534a771188.png",
                                                    "hash": "small_COMING_SOON_mas_garnde_534a771188",
                                                    "mime": "image/png",
                                                    "name": "small_COMING SOON mas garnde.png",
                                                    "path": null,
                                                    "size": 97.2,
                                                    "width": 379,
                                                    "height": 500
                                                },
                                                "medium": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/medium_COMING_SOON_mas_garnde_534a771188.png",
                                                    "hash": "medium_COMING_SOON_mas_garnde_534a771188",
                                                    "mime": "image/png",
                                                    "name": "medium_COMING SOON mas garnde.png",
                                                    "path": null,
                                                    "size": 158.37,
                                                    "width": 569,
                                                    "height": 750
                                                },
                                                "thumbnail": {
                                                    "ext": ".png",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_COMING_SOON_mas_garnde_534a771188.png",
                                                    "hash": "thumbnail_COMING_SOON_mas_garnde_534a771188",
                                                    "mime": "image/png",
                                                    "name": "thumbnail_COMING SOON mas garnde.png",
                                                    "path": null,
                                                    "size": 23.03,
                                                    "width": 118,
                                                    "height": 156
                                                }
                                            },
                                            "hash": "COMING_SOON_mas_garnde_534a771188",
                                            "ext": ".png",
                                            "mime": "image/png",
                                            "size": 135.16,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/COMING_SOON_mas_garnde_534a771188.png",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-08-21T19:27:57.891Z",
                                            "updatedAt": "2023-08-21T19:27:57.891Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 37,
                            "attributes": {
                                "Key": "icon-arrowright",
                                "createdAt": "2023-05-09T00:37:43.321Z",
                                "updatedAt": "2023-05-09T00:37:44.749Z",
                                "publishedAt": "2023-05-09T00:37:44.745Z",
                                "Image": {
                                    "data": {
                                        "id": 70,
                                        "attributes": {
                                            "name": "icon-arrowright.svg",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 24,
                                            "height": 27,
                                            "formats": null,
                                            "hash": "icon_arrowright_d1b6773c42",
                                            "ext": ".svg",
                                            "mime": "image/svg+xml",
                                            "size": 0.31,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/icon_arrowright_d1b6773c42.svg",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:37:38.393Z",
                                            "updatedAt": "2023-05-09T03:11:17.116Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 34,
                            "attributes": {
                                "Key": "quieroserproveedor-img",
                                "createdAt": "2023-05-09T00:35:04.793Z",
                                "updatedAt": "2023-05-09T00:35:05.720Z",
                                "publishedAt": "2023-05-09T00:35:05.715Z",
                                "Image": {
                                    "data": {
                                        "id": 67,
                                        "attributes": {
                                            "name": "quieroserproveedor-img.jpg",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 500,
                                            "height": 478,
                                            "formats": {
                                                "thumbnail": {
                                                    "ext": ".jpg",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_quieroserproveedor_img_890258607f.jpg",
                                                    "hash": "thumbnail_quieroserproveedor_img_890258607f",
                                                    "mime": "image/jpeg",
                                                    "name": "thumbnail_quieroserproveedor-img.jpg",
                                                    "path": null,
                                                    "size": 5.63,
                                                    "width": 163,
                                                    "height": 156
                                                }
                                            },
                                            "hash": "quieroserproveedor_img_890258607f",
                                            "ext": ".jpg",
                                            "mime": "image/jpeg",
                                            "size": 30.1,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/quieroserproveedor_img_890258607f.jpg",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:35:00.300Z",
                                            "updatedAt": "2023-05-09T00:35:00.300Z"
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "id": 35,
                            "attributes": {
                                "Key": "travel-img",
                                "createdAt": "2023-05-09T00:35:46.149Z",
                                "updatedAt": "2023-05-09T00:35:47.506Z",
                                "publishedAt": "2023-05-09T00:35:47.500Z",
                                "Image": {
                                    "data": {
                                        "id": 68,
                                        "attributes": {
                                            "name": "travel-img.jpg",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 513,
                                            "height": 416,
                                            "formats": {
                                                "small": {
                                                    "ext": ".jpg",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/small_travel_img_6d39d1e9f7.jpg",
                                                    "hash": "small_travel_img_6d39d1e9f7",
                                                    "mime": "image/jpeg",
                                                    "name": "small_travel-img.jpg",
                                                    "path": null,
                                                    "size": 30.05,
                                                    "width": 500,
                                                    "height": 405
                                                },
                                                "thumbnail": {
                                                    "ext": ".jpg",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_travel_img_6d39d1e9f7.jpg",
                                                    "hash": "thumbnail_travel_img_6d39d1e9f7",
                                                    "mime": "image/jpeg",
                                                    "name": "thumbnail_travel-img.jpg",
                                                    "path": null,
                                                    "size": 7.37,
                                                    "width": 192,
                                                    "height": 156
                                                }
                                            },
                                            "hash": "travel_img_6d39d1e9f7",
                                            "ext": ".jpg",
                                            "mime": "image/jpeg",
                                            "size": 31.87,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/travel_img_6d39d1e9f7.jpg",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:35:40.888Z",
                                            "updatedAt": "2023-05-09T00:35:40.888Z"
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "query_discoveries": {
                    "data": [
                        {
                            "id": 1,
                            "attributes": {
                                "InternalName": "Contenidos simples",
                                "Key": "contents",
                                "createdAt": "2023-05-09T00:50:54.621Z",
                                "updatedAt": "2023-06-01T01:43:20.377Z",
                                "publishedAt": "2023-05-09T00:50:56.409Z",
                                "Query": {
                                    "id": 46,
                                    "Variables": [
                                        "contentKey",
                                        "locale"
                                    ],
                                    "Uri": "api/simple-content-pages?filters[Key][$eq]={{contentKey}}&locale={{locale}}",
                                    "Context": "Strapi"
                                }
                            }
                        },
                        {
                            "id": 34,
                            "attributes": {
                                "InternalName": "Store Banner",
                                "Key": "store-banner",
                                "createdAt": "2023-05-09T03:43:47.715Z",
                                "updatedAt": "2023-06-01T01:43:42.876Z",
                                "publishedAt": "2023-05-09T03:43:50.205Z",
                                "Query": {
                                    "id": 68,
                                    "Variables": [
                                        "storeSlug"
                                    ],
                                    "Uri": "api/store-banners?populate=*&filters[$or][0][Key][$eq]={{storeSlug}}&filters[$or][1][Key][$eq]=default&sort=Ignore&pagination[limit]=1",
                                    "Context": "Strapi"
                                }
                            }
                        },
                        {
                            "id": 35,
                            "attributes": {
                                "InternalName": "Country Home",
                                "Key": "country-home",
                                "createdAt": "2023-05-09T03:56:38.825Z",
                                "updatedAt": "2023-11-20T20:14:06.749Z",
                                "publishedAt": "2023-05-09T03:57:59.843Z",
                                "Query": {
                                    "id": 69,
                                    "Variables": [
                                        "countryCode",
                                        "locale",
                                        "env"
                                    ],
                                    "Uri": "api/country-homes?filters[$or][0][Env][$eq]={{env}}&filters[$or][1][Env][$eq]=All&sort=Env%3Adesc&pagination[limit]=1&filters[CountryCode][$eq]={{countryCode}}&locale={{locale}}&populate=StoresDirBanner&populate=StoresDirBanner.Button.Leading&populate=StoresDirBanner.Button.Trailing&populate=StoresDirBanner.Button.Route&populate=StoresDirBanner.ResponsiveImage.Image&populate=StoresDirBanner.Image.Image&populate=MainBanner&populate=MainBanner.Buttons.Leading&populate=MainBanner.Buttons.Trailing&populate=MainBanner.Buttons.Route&populate=MainBanner.Images.Image&populate=TopBar&populate=TopBar.ExtraItems&populate=TopBar.ExtraItems.Route&populate=TopBar.TrailingItems&populate=TopBar.TrailingItems.Route&populate=TopBar.Others.Route",
                                    "Context": "Strapi"
                                }
                            }
                        }
                    ]
                },
                "footer_definition": {
                    "data": {
                        "id": 1,
                        "attributes": {
                            "Name": "Default",
                            "createdAt": "2023-05-07T01:33:59.156Z",
                            "updatedAt": "2023-05-15T13:54:59.099Z",
                            "publishedAt": "2023-05-08T23:59:23.342Z",
                            "locale": "es",
                            "DownloadAppLabel": "Descarga la App Katapulk",
                            "FollowUsLable": "Síguenos en:",
                            "RightsLabel": "Derechos de Autor Katapulk © 2023 | Todos los derechos reservados",
                            "DownloadAppButtons": [
                                {
                                    "id": 71,
                                    "NavLink": "https://play.google.com/store/apps/details?id=com.katapulk.app&hl=en_US&gl=US",
                                    "Image": {
                                        "data": {
                                            "id": 11,
                                            "attributes": {
                                                "name": "google-play.png",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 225,
                                                "height": 67,
                                                "formats": null,
                                                "hash": "google_play_62d1c2afa7",
                                                "ext": ".png",
                                                "mime": "image/png",
                                                "size": 2.88,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/google_play_62d1c2afa7.png",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-07T01:37:00.339Z",
                                                "updatedAt": "2023-05-07T01:37:00.339Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 72,
                                    "NavLink": "https://apps.apple.com/us/app/katapulk/id1551270790",
                                    "Image": {
                                        "data": {
                                            "id": 12,
                                            "attributes": {
                                                "name": "app-store.png",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 200,
                                                "height": 67,
                                                "formats": null,
                                                "hash": "app_store_d533cb0167",
                                                "ext": ".png",
                                                "mime": "image/png",
                                                "size": 1.86,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/app_store_d533cb0167.png",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-07T01:38:00.907Z",
                                                "updatedAt": "2023-05-07T02:49:12.813Z"
                                            }
                                        }
                                    }
                                }
                            ],
                            "FollowButtons": [
                                {
                                    "id": 74,
                                    "NavLink": "https://www.facebook.com/katapulk.marketplace",
                                    "Image": {
                                        "data": {
                                            "id": 13,
                                            "attributes": {
                                                "name": "Facebook F.png",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 48,
                                                "height": 48,
                                                "formats": null,
                                                "hash": "Facebook_F_508390a7c9",
                                                "ext": ".png",
                                                "mime": "image/png",
                                                "size": 0.24,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/Facebook_F_508390a7c9.png",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-07T02:48:52.540Z",
                                                "updatedAt": "2023-05-07T02:48:52.540Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 73,
                                    "NavLink": "https://www.instagram.com/katapulk.marketplace",
                                    "Image": {
                                        "data": {
                                            "id": 14,
                                            "attributes": {
                                                "name": "Instagram.png",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 48,
                                                "height": 48,
                                                "formats": null,
                                                "hash": "Instagram_c4097b791c",
                                                "ext": ".png",
                                                "mime": "image/png",
                                                "size": 0.41,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/Instagram_c4097b791c.png",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-07T02:49:42.025Z",
                                                "updatedAt": "2023-05-07T02:49:42.025Z"
                                            }
                                        }
                                    }
                                }
                            ],
                            "text_cmp_columns": {
                                "data": [
                                    {
                                        "id": 1,
                                        "attributes": {
                                            "InternalName": "Primera Columna",
                                            "Title": "Atención al Cliente",
                                            "createdAt": "2023-05-08T23:46:21.122Z",
                                            "updatedAt": "2023-09-08T21:04:20.680Z",
                                            "publishedAt": "2023-05-08T23:47:44.826Z",
                                            "locale": "es",
                                            "text_components": {
                                                "data": [
                                                    {
                                                        "id": 1,
                                                        "attributes": {
                                                            "Label": "Política de Entregas",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:04:09.703Z",
                                                            "updatedAt": "2023-06-02T20:10:37.453Z",
                                                            "publishedAt": "2023-05-08T23:04:11.327Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 3,
                                                                    "attributes": {
                                                                        "Route": "contents/delivery-policy",
                                                                        "createdAt": "2023-05-09T01:23:09.229Z",
                                                                        "updatedAt": "2023-05-09T01:23:11.404Z",
                                                                        "publishedAt": "2023-05-09T01:23:11.398Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 3,
                                                        "attributes": {
                                                            "Label": "Política de Devolución",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:06:26.251Z",
                                                            "updatedAt": "2023-06-02T20:10:26.793Z",
                                                            "publishedAt": "2023-05-08T23:06:28.701Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 4,
                                                                    "attributes": {
                                                                        "Route": "contents/return-policy",
                                                                        "createdAt": "2023-05-09T01:23:35.125Z",
                                                                        "updatedAt": "2023-05-09T01:23:36.946Z",
                                                                        "publishedAt": "2023-05-09T01:23:36.942Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 5,
                                                        "attributes": {
                                                            "Label": "Preguntas Frecuentes",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:08:44.657Z",
                                                            "updatedAt": "2023-06-02T20:11:21.919Z",
                                                            "publishedAt": "2023-05-08T23:08:46.623Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 1,
                                                                    "attributes": {
                                                                        "Route": "contents/faq",
                                                                        "createdAt": "2023-05-09T01:22:32.833Z",
                                                                        "updatedAt": "2023-05-09T01:22:34.405Z",
                                                                        "publishedAt": "2023-05-09T01:22:34.396Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 9,
                                                        "attributes": {
                                                            "Label": "Términos y Condiciones",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:11:05.853Z",
                                                            "updatedAt": "2023-06-06T14:41:38.810Z",
                                                            "publishedAt": "2023-05-08T23:11:07.214Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 2,
                                                                    "attributes": {
                                                                        "Route": "contents/terms",
                                                                        "createdAt": "2023-05-09T01:22:43.654Z",
                                                                        "updatedAt": "2023-05-09T01:22:45.923Z",
                                                                        "publishedAt": "2023-05-09T01:22:45.918Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 166,
                                                        "attributes": {
                                                            "Label": "Política de Privacidad",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-09-08T21:02:40.244Z",
                                                            "updatedAt": "2023-09-08T21:03:07.361Z",
                                                            "publishedAt": "2023-09-08T21:02:43.397Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": false,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 166,
                                                                    "attributes": {
                                                                        "Route": "contents/politica-privacidad",
                                                                        "createdAt": "2023-09-08T21:01:22.628Z",
                                                                        "updatedAt": "2023-09-08T21:01:24.838Z",
                                                                        "publishedAt": "2023-09-08T21:01:24.834Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 7,
                                                        "attributes": {
                                                            "Label": "info@katapulk.com",
                                                            "Action": "SendEmail",
                                                            "createdAt": "2023-05-08T23:10:16.310Z",
                                                            "updatedAt": "2023-05-09T01:32:32.500Z",
                                                            "publishedAt": "2023-05-08T23:10:18.462Z",
                                                            "locale": "es",
                                                            "Extra": "info@katapulk.com",
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": null
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    },
                                    {
                                        "id": 35,
                                        "attributes": {
                                            "InternalName": "Segunda Columna",
                                            "Title": "Sobre Katapulk",
                                            "createdAt": "2023-05-08T23:49:39.596Z",
                                            "updatedAt": "2023-05-08T23:51:05.832Z",
                                            "publishedAt": "2023-05-08T23:49:40.996Z",
                                            "locale": "es",
                                            "text_components": {
                                                "data": [
                                                    {
                                                        "id": 11,
                                                        "attributes": {
                                                            "Label": "¿Qué es Katapulk Marketplace?",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:12:26.860Z",
                                                            "updatedAt": "2023-06-02T20:11:35.204Z",
                                                            "publishedAt": "2023-05-08T23:12:28.142Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 5,
                                                                    "attributes": {
                                                                        "Route": "contents/about-us",
                                                                        "createdAt": "2023-05-09T01:24:04.606Z",
                                                                        "updatedAt": "2023-05-09T01:24:06.425Z",
                                                                        "publishedAt": "2023-05-09T01:24:06.416Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    },
                                    {
                                        "id": 67,
                                        "attributes": {
                                            "InternalName": "Destacadas",
                                            "Title": "Destacadas",
                                            "createdAt": "2023-05-15T13:52:25.048Z",
                                            "updatedAt": "2023-07-13T17:35:25.652Z",
                                            "publishedAt": "2023-05-15T13:52:27.419Z",
                                            "locale": "es",
                                            "text_components": {
                                                "data": [
                                                    {
                                                        "id": 13,
                                                        "attributes": {
                                                            "Label": "Mercado Único",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:14:58.919Z",
                                                            "updatedAt": "2023-05-09T01:35:28.515Z",
                                                            "publishedAt": "2023-05-08T23:15:00.501Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 6,
                                                                    "attributes": {
                                                                        "Route": "cu/mercado-unico",
                                                                        "createdAt": "2023-05-09T01:24:23.031Z",
                                                                        "updatedAt": "2023-05-09T01:24:24.246Z",
                                                                        "publishedAt": "2023-05-09T01:24:24.242Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 15,
                                                        "attributes": {
                                                            "Label": "Recargas Cubacel",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:17:02.401Z",
                                                            "updatedAt": "2023-12-19T04:31:27.219Z",
                                                            "publishedAt": "2023-05-08T23:17:03.689Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 7,
                                                                    "attributes": {
                                                                        "Route": "cu/recargas-a-cuba",
                                                                        "createdAt": "2023-05-09T01:24:38.511Z",
                                                                        "updatedAt": "2023-05-09T01:24:40.214Z",
                                                                        "publishedAt": "2023-05-09T01:24:40.209Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 17,
                                                        "attributes": {
                                                            "Label": "La Criolla",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-08T23:17:48.000Z",
                                                            "updatedAt": "2023-05-09T01:33:34.618Z",
                                                            "publishedAt": "2023-05-08T23:17:49.946Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 8,
                                                                    "attributes": {
                                                                        "Route": "cu/la-criolla",
                                                                        "createdAt": "2023-05-09T01:24:53.101Z",
                                                                        "updatedAt": "2023-05-09T01:24:55.185Z",
                                                                        "publishedAt": "2023-05-09T01:24:55.181Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 34,
                                                        "attributes": {
                                                            "Label": "La Reforma",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-09T02:47:52.547Z",
                                                            "updatedAt": "2023-05-09T02:48:11.957Z",
                                                            "publishedAt": "2023-05-09T02:47:57.406Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 10,
                                                                    "attributes": {
                                                                        "Route": "cu/reforma",
                                                                        "createdAt": "2023-05-09T01:25:23.337Z",
                                                                        "updatedAt": "2023-05-09T01:25:24.614Z",
                                                                        "publishedAt": "2023-05-09T01:25:24.609Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 36,
                                                        "attributes": {
                                                            "Label": "Viajes y Servicios Consulares",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-09T02:48:48.732Z",
                                                            "updatedAt": "2023-05-09T02:49:26.623Z",
                                                            "publishedAt": "2023-05-09T02:48:50.480Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 11,
                                                                    "attributes": {
                                                                        "Route": "travel-services/main",
                                                                        "createdAt": "2023-05-09T01:25:38.084Z",
                                                                        "updatedAt": "2023-05-09T01:25:39.379Z",
                                                                        "publishedAt": "2023-05-09T01:25:39.374Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 38,
                                                        "attributes": {
                                                            "Label": "Mayorista",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-09T02:50:01.045Z",
                                                            "updatedAt": "2024-04-12T14:01:15.189Z",
                                                            "publishedAt": "2023-05-09T02:50:04.901Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 232,
                                                                    "attributes": {
                                                                        "Route": "/cu/mayorista",
                                                                        "createdAt": "2024-04-12T14:00:14.954Z",
                                                                        "updatedAt": "2024-04-12T14:00:16.662Z",
                                                                        "publishedAt": "2024-04-12T14:00:16.653Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    },
                                    {
                                        "id": 69,
                                        "attributes": {
                                            "InternalName": "Más Tiendas",
                                            "Title": "Más Tiendas",
                                            "createdAt": "2023-05-15T13:53:56.648Z",
                                            "updatedAt": "2023-05-15T13:54:16.829Z",
                                            "publishedAt": "2023-05-15T13:53:58.281Z",
                                            "locale": "es",
                                            "text_components": {
                                                "data": [
                                                    {
                                                        "id": 40,
                                                        "attributes": {
                                                            "Label": "Directorio de Tiendas",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-09T02:51:25.621Z",
                                                            "updatedAt": "2023-05-09T02:51:50.910Z",
                                                            "publishedAt": "2023-05-09T02:51:29.133Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 13,
                                                                    "attributes": {
                                                                        "Route": "cu/stores/directory",
                                                                        "createdAt": "2023-05-09T01:26:22.935Z",
                                                                        "updatedAt": "2023-06-06T15:57:08.005Z",
                                                                        "publishedAt": "2023-05-09T01:26:25.291Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "id": 42,
                                                        "attributes": {
                                                            "Label": "Quiero Vender en Katapulk",
                                                            "Action": "Navigate",
                                                            "createdAt": "2023-05-09T02:52:25.704Z",
                                                            "updatedAt": "2023-06-06T14:41:20.803Z",
                                                            "publishedAt": "2023-05-09T02:52:28.750Z",
                                                            "locale": "es",
                                                            "Extra": null,
                                                            "RequireLogin": null,
                                                            "Route": {
                                                                "data": {
                                                                    "id": 14,
                                                                    "attributes": {
                                                                        "Route": "cu/stores/k-seller",
                                                                        "createdAt": "2023-05-09T01:26:43.977Z",
                                                                        "updatedAt": "2023-05-09T01:26:45.903Z",
                                                                        "publishedAt": "2023-05-09T01:26:45.899Z"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "start": 0,
            "limit": 1,
            "total": 1
        }
    }
};
