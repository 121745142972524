<section id="confirm" class="relative w-full h-auto flex justify-center bg-gray-#f2f2f2 min-h-screen-115px">
  <div class="relative overflow-hidden justify-center text-yellow-#FF6D03
        us:px-5
        md:px-14
        lg:px-16
        xl:w-1291px py-8">
    <p *ngIf="hasError" class="relative overflow-hidden text-4xl">{{error}}</p>
    <div *ngIf="responseOk" class="">
      <h3 class="text-4xl">{{'REGISTER_SUCCESS' | translate}}</h3>
      <br>
      <a class="text-2xl cursor-pointer" (click)="navigateHome()">{{'REGISTER_SUCCESS_TEXT' | translate}}</a><br>

      <button routerLink="/auth/signIn" class="relative capitalize bg-white border-2 border-yellow-#FF6D03 font-Lato-Regular font-bold text-yellow-#FF6D03
                w-201px h-49px rounded-3xl mt-7 text-19px">
        {{'LOG_IN' | translate}}
      </button>
    </div>
  </div>
</section>
