import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ControlBase } from '../../controls/controls-base';
import { GenericFormsService } from 'src/app/shared/services/generic-forms.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from "moment";
import {debounceTime} from "rxjs/operators";
import {ReceiverDataInterface, ReceiverInterface} from "../../../../../remittance/interfaces/receiver.interface";

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {
  @Output() formValueToParent = new EventEmitter<any>();
  @Input() meta: any = null;
  @Input() data: any = {};
  @Input() widthFull: boolean = false;
  @Input() formMarkAsTouched!: boolean;
  @Input() selectedReceiverData: any[] = [];

  // @Input() isEdit: boolean = false;
  // @Input() selectedReceiver!: ReceiverInterface;
  @Input() form!: FormGroup;
  // form!: FormGroup;
  payload = '';
  groups: any = [];

  constructor(private service: GenericFormsService) { }

  ngOnInit(): void {
    // this.form = new FormGroup({});
    // console.log(this.meta);
  }

  // ngOnChanges(changes: any): void {
  //   console.log(changes);
  //   if (this.isEdit) {
  //     if (changes && changes.selectedReceiver) {}
  //   } else {
  //     if (this.meta.length > 0 && changes && changes.meta) {
  //       this.meta?.forEach((section: any) => {
  //         this.groups = { ...this.groups, ...this.service.toFormGroup(section.section, this.data) };
  //       });
  //       this.form = new FormGroup({ ...this.groups });
  //       console.log(this.form);
  //
  //       this.form.valueChanges.pipe(debounceTime(300)).subscribe((dataForm: any) => {
  //         this.onSubmit();
  //       });
  //       this.form.updateValueAndValidity();
  //       this.onSubmit();
  //     }
  //   }
  // }

  onSubmit(dataForm: any = null) {
    if (dataForm) {
      this.formValueToParent.emit(dataForm);
    } else {
      this.formValueToParent.emit(this.form);
    }
  }

  testthis(data: any) {
    // const control = this.service.toFormSingle(data, this.data);
    // console.log(control);

    // this.form.addControl(data.fieldId, control);
    // this.form.controls[`${data.fieldId}`].updateValueAndValidity();
    // return true;
  }

  getControlValue(controlId: string) {
    return this.form.controls[`${controlId}`].value;
  }

  submit() {
    this.formValueToParent.emit(this.form);
  }

}
