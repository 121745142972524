import {Component, OnInit, Input} from '@angular/core';
import {ControlBase} from '../controls-base';
import {FormGroup} from '@angular/forms';
import {RemittanceService} from "../../../../../../shared/services/remittance.service";

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
    @Input() meta!: ControlBase;
    @Input() form!: FormGroup;
    @Input() formMarkAsTouched!: boolean;

    constructor(
        private remittanceService: RemittanceService
    ) {
    }

    ngOnInit(): void {
    }

    onPaste(event: ClipboardEvent) {
        if (this.meta.regexIgnore && this.meta.regexIgnore !== '') {
            event.preventDefault();

            let paste: string = '';
            if (event && event.clipboardData) {
                paste = event.clipboardData.getData("text");

                const regExp = new RegExp(this.meta.regexIgnore, "g");
                paste = paste.replace(regExp, '');
                this.selfControl.patchValue(paste);
            }
        }
    }

    keyup(e: any) {
        if (this.meta.regexIgnore && this.meta.regexIgnore !== '' && e.target.value && e.target.value !== '') {
            // const regExp = new RegExp(this.meta.regexIgnore);
            // const regExp = new RegExp(/[()\s+]/g); //FUNCIONA
            // const regExpString = '/[()\\s+]/g'   //ASI ES COMO LLEGA
            // const pattern = '[()\\s+]';  // -\\s
            // const regExp = new RegExp("[" + pattern + "]", "g");
            // const regExp = new RegExp(pattern, "g");

            const regExp = new RegExp(this.meta.regexIgnore, "g");
            e.target.value = e.target.value.replace(regExp, '');
            this.selfControl.patchValue(e.target.value);
        }
    }


    get selfControl() {
        return this.form.controls[`${this.meta.fieldId}`];
    }

}
