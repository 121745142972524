import {Component, OnInit, Inject, OnDestroy, HostListener} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from "../../shared/services/notification.service";
import {faClose, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {takeUntil} from "rxjs/operators";
import {interval, Subject, Subscription, takeWhile, tap} from "rxjs";
import * as moment from "moment/moment";
import {CartService} from "../../shared/services/cart.service";

@Component({
  selector: "app-continue-buying-dialog-component",
  templateUrl: "./continue-buying-dialog.component.html",
  styleUrls: ["./continue-buying-dialog.component.scss"],
})
export class ContinueBuyingDialogComponent implements OnInit, OnDestroy {
  faClose = faClose;
  faSpinner = faSpinner;
  isLoading: boolean = false;
  isLoadingCancel: boolean = false;
  title: string;
  action: any;
  message: string;
  messageBlack: boolean;
  subMessage: string;
  confirmAction: string;
  cancelAction: string;
  notificationSuccess: string;
  isWarnBotton: boolean;
  multipleAction = false;
  textCenterTitle = false;
  messageUppercase = true;
  notificationFailMultiple: string;

  destroy$: Subject<boolean> = new Subject<boolean>();
  cancelActionFunction: any;

  modalFinishDate: Date | null;
  finishModalSubs$: Subject<boolean> = new Subject<boolean>();
  modalCounterTimer$: Subscription | undefined;
  modalTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };

  constructor(
    private dialogRef: MatDialogRef<ContinueBuyingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private _formBuilder: FormBuilder,
    private cartService: CartService,
  ) {
    this.title = data.title;
    this.action = data.action;
    this.multipleAction = data.multipleAction;
    this.message = data.message ? data.message : "";
    this.messageUppercase = data.messageUppercase ? data.messageUppercase : false;
    this.messageBlack = !!data.messageBlack;
    this.textCenterTitle = !!data.textCenterTitle;
    this.subMessage = data.subMessage ? data.subMessage : "";
    this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
    this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
    this.isWarnBotton = data.isWarnBotton ? data.isWarnBotton : false;
    this.notificationSuccess = data.notificationSuccess
      ? data.notificationSuccess
      : "ACTION_COMPLETED_SUCCESSFULLY";
    this.notificationFailMultiple = data.notificationFailMultiple;
    this.cancelActionFunction = data.cancelActionFunction;

    this.modalTime = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    this.modalFinishDate = moment(Date.now()).add(2, 'minutes').toDate();
  }

  ngOnInit(): void {
    this.initModalData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well

    this.finishModalSubs$.next(true);
    this.finishModalSubs$.unsubscribe();

    this.modalCounterTimer$?.unsubscribe();

  }

  @HostListener('document:visibilitychange', ['$event.target'])
  stopModalTimeIfExpired(event: FocusEvent) {
    if (this.modalFinishDate === null) {
      this.stopModalTime(true);
    }
  }

  initModalData() {
    this.modalFinishDate = moment(Date.now()).add(2, 'minutes').toDate();
    this.initModalTime();
  }

  onSubmit(): void {
    this.isLoading = true;
    this.stopModalTime();
    this.action.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (this.multipleAction) {
        const responseArray: any[] = Object.values(response);
        if (responseArray.reduce((acc, cur) => acc.success && cur.success)) {
          this.notificationService.success(this.notificationSuccess);
        } else {
          this.notificationService.error(this.notificationFailMultiple);
        }
      }
      // else {
      //     if (!response || response.success) {
      //         this.notificationService.success(this.notificationSuccess);
      //     } else {
      //         this.notificationService.error(response.message);
      //     }
      // }

      this.isLoading = false;
      this.dialogRef.close(Object.assign({success: true}, response));
    }, (error: any) => {
      this.isLoading = false;
      this.initModalData();
    });
  }

  close(): void {
    if (!this.isLoading && !this.isLoadingCancel) {
      if (!this.cancelActionFunction) {
        this.dialogRef.close(null);
      } else {
        this.isLoadingCancel = true;
        this.cancelActionFunction.subscribe({
          complete: () => {
            this.isLoadingCancel = false;
            this.stopModalTime();
            this.dialogRef.close(null);
          }
        })
      }
    }
  }

  updateModalTime() {
    if (this.modalFinishDate) {
      const now = new Date();
      const expirationModalDate = this.modalFinishDate;
      const diff = expirationModalDate.getTime() - now.getTime();
      // console.log(diff);
      if (diff <= 0) {
        this.isLoadingCancel = true;
        this.stopModalTime();
        return;
      }
      // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor(diff / (1000 * 60));
      const secs = Math.floor(diff / 1000);

      // La diferencia que se asignará para mostrarlo en la pantalla
      this.modalTime.days = days;
      this.modalTime.hours = hours - days * 24;
      this.modalTime.minutes = mins - hours * 60;
      this.modalTime.seconds = secs - mins * 60;
    } else {
      // console.log('NO HAY CARRITO PARA ACTUALIZAR TIEMPO');
      this.stopModalTime(true);
    }
  }

  // Ejecutamos la acción cada segundo, para obtener la diferencia entre el momento actual y el objetivo
  startModalTime() {
    return interval(1000).pipe(
      takeWhile(() => this.modalFinishDate !== null && this.modalTime.minutes >= 0 && this.modalTime.seconds >= 0),
      tap(() => {
        this.updateModalTime();
      }),
    );
  }

  initModalTime() {
    setTimeout(() => {
      if (this.modalFinishDate !== null) {
        this.finishModalSubs$ = new Subject<boolean>();
        // console.log('existe carrito');
        // Inicializando fecha de expiracion del carrito a partir de los datos de session storage
        if (moment(new Date()).diff(new Date(this.modalFinishDate)) < 0) {
          // console.log('existe carrito y no ha expirado');
          this.modalCounterTimer$ = this.startModalTime()
            .pipe(takeUntil(this.finishModalSubs$))
            .subscribe((_) => {
              if (this.modalTime.minutes === 0 && this.modalTime.seconds === 0) {
                // console.log('tiempo expirado: munitos  => ' + this.cartTime.minutes + '---- segundos => ' + this.cartTime.seconds)
                this.stopModalTime(true);
              }
            });
        } else {
          // console.log('diferencia de timepo >= 0')
          this.stopModalTime(true);
        }
      } else {
        // console.log('no existe modal');
        this.isLoadingCancel = true;
        this.stopModalTime();
      }
    }, 0);
  }

  stopModalTime(autoClosed = false) {
    this.modalTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      days: 0
    };

    this.modalFinishDate = null;
    this.modalCounterTimer$?.unsubscribe();
    if (autoClosed) {
      this.isLoadingCancel = true;
      if (!this.finishModalSubs$.closed) {
        this.cartService.emptyCart().pipe(takeUntil(this.finishModalSubs$)).subscribe({
          complete: () => {
            this.isLoadingCancel = false;
            this.dialogRef?.close({closed: true});
          }
        });
      }
    }
  }
}
