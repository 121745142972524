export const globalCategoriesMock = {
    categories: [
        {
            "8647": {
                "category": {
                    "id": 8647,
                    "parent_id": null,
                    "position": 0,
                    "taxonomy_id": 4593,
                    "lft": 3081,
                    "rgt": 3258,
                    "created_at": "2023-07-07T11:13:41.812-04:00",
                    "updated_at": "2024-04-12T12:44:07.071-04:00",
                    "depth": 0,
                    "hide_from_nav": false,
                    "name": "Nuevas Categorías",
                    "description": null,
                    "meta_title": null,
                    "meta_description": null,
                    "meta_keywords": null,
                    "permalink": "nuevas-categorias"
                },
                "children": [
                    {
                        "8746": {
                            "category": {
                                "id": 8746,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3082,
                                "rgt": 3091,
                                "created_at": "2023-07-12T11:35:53.848-04:00",
                                "updated_at": "2024-04-04T11:00:05.363-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Packs y Combos",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/packs"
                            },
                            "children": [
                                {
                                    "8962": {
                                        "category": {
                                            "id": 8962,
                                            "parent_id": 8746,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3083,
                                            "rgt": 3084,
                                            "created_at": "2023-07-19T16:31:35.082-04:00",
                                            "updated_at": "2024-04-03T00:48:04.414-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Packs de Mercado",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/packs/mercado"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8963": {
                                        "category": {
                                            "id": 8963,
                                            "parent_id": 8746,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3085,
                                            "rgt": 3086,
                                            "created_at": "2023-07-19T16:32:06.447-04:00",
                                            "updated_at": "2023-08-09T20:35:16.389-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Packs de Aseo y Limpieza",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/packs/aseo-y-limpieza"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8964": {
                                        "category": {
                                            "id": 8964,
                                            "parent_id": 8746,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3087,
                                            "rgt": 3088,
                                            "created_at": "2023-07-19T16:32:42.105-04:00",
                                            "updated_at": "2024-04-04T11:00:05.363-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Packs Varios",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/packs/otros"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "9010": {
                                        "category": {
                                            "id": 9010,
                                            "parent_id": 8746,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3089,
                                            "rgt": 3090,
                                            "created_at": "2023-07-21T16:51:31.369-04:00",
                                            "updated_at": "2023-12-20T10:57:06.890-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Packs con Cigarrillos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/packs/packs-con-cigarrillos"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8648": {
                            "category": {
                                "id": 8648,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3092,
                                "rgt": 3105,
                                "created_at": "2023-07-07T11:13:58.758-04:00",
                                "updated_at": "2024-04-12T11:34:37.681-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Alimentos Frescos",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/new-node"
                            },
                            "children": [
                                {
                                    "8650": {
                                        "category": {
                                            "id": 8650,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3093,
                                            "rgt": 3094,
                                            "created_at": "2023-07-07T11:16:22.539-04:00",
                                            "updated_at": "2024-03-27T14:34:31.130-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Viandas, Frutas y Verduras",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/frutas-y-verduras"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8654": {
                                        "category": {
                                            "id": 8654,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3095,
                                            "rgt": 3096,
                                            "created_at": "2023-07-07T11:22:07.211-04:00",
                                            "updated_at": "2024-04-12T11:34:37.681-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Productos Lácteos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/productos-lacteos-frescos-leche-queso-yogur-etc"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8653": {
                                        "category": {
                                            "id": 8653,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3097,
                                            "rgt": 3098,
                                            "created_at": "2023-07-07T11:20:44.925-04:00",
                                            "updated_at": "2024-03-23T15:39:15.650-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Cakes, Dulces y Panes",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/panaderia-y-productos-de-pan-frescos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8945": {
                                        "category": {
                                            "id": 8945,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3099,
                                            "rgt": 3100,
                                            "created_at": "2023-07-19T13:17:53.404-04:00",
                                            "updated_at": "2024-01-29T13:24:30.643-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Pizzas y Comida Rápida",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/pizzas-y-comida-rapida"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8946": {
                                        "category": {
                                            "id": 8946,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3101,
                                            "rgt": 3102,
                                            "created_at": "2023-07-19T13:18:12.628-04:00",
                                            "updated_at": "2024-04-04T12:56:09.243-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Almuerzos y Cenas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/almuerzos-y-cenas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8947": {
                                        "category": {
                                            "id": 8947,
                                            "parent_id": 8648,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3103,
                                            "rgt": 3104,
                                            "created_at": "2023-07-19T13:18:37.933-04:00",
                                            "updated_at": "2023-08-04T13:44:44.249-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Menús para Eventos o Fiestas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/new-node/menus-para-eventos-o-fiestas"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8673": {
                            "category": {
                                "id": 8673,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3106,
                                "rgt": 3121,
                                "created_at": "2023-07-07T11:29:11.724-04:00",
                                "updated_at": "2024-04-12T12:44:07.071-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Productos Congelados",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/productos-congelados"
                            },
                            "children": [
                                {
                                    "8676": {
                                        "category": {
                                            "id": 8676,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3107,
                                            "rgt": 3108,
                                            "created_at": "2023-07-07T11:30:15.256-04:00",
                                            "updated_at": "2024-04-12T12:44:07.071-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Carnes y Aves",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/carnes-y-aves-congeladas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8911": {
                                        "category": {
                                            "id": 8911,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3109,
                                            "rgt": 3110,
                                            "created_at": "2023-07-17T09:21:26.185-04:00",
                                            "updated_at": "2024-01-29T14:35:45.531-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Pescados y Mariscos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/pescados-y-mariscos-congelados"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8812": {
                                        "category": {
                                            "id": 8812,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3111,
                                            "rgt": 3112,
                                            "created_at": "2023-07-12T14:58:49.429-04:00",
                                            "updated_at": "2024-04-10T01:19:17.257-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Embutidos y Conformados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/embutidos-y-conformados"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8674": {
                                        "category": {
                                            "id": 8674,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3113,
                                            "rgt": 3114,
                                            "created_at": "2023-07-07T11:29:15.472-04:00",
                                            "updated_at": "2024-03-13T14:03:59.928-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Alimentos Pre-Cocinados Congelados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/new-node"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8675": {
                                        "category": {
                                            "id": 8675,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3115,
                                            "rgt": 3116,
                                            "created_at": "2023-07-07T11:29:40.565-04:00",
                                            "updated_at": "2023-08-09T22:42:08.741-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Vegetales y Frutas Congelados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/vegetales-y-frutas-congeladas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8677": {
                                        "category": {
                                            "id": 8677,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3117,
                                            "rgt": 3118,
                                            "created_at": "2023-07-07T11:30:24.457-04:00",
                                            "updated_at": "2024-01-29T15:12:03.048-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Helados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/helados-y-postres-congelados"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8678": {
                                        "category": {
                                            "id": 8678,
                                            "parent_id": 8673,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3119,
                                            "rgt": 3120,
                                            "created_at": "2023-07-07T11:30:36.777-04:00",
                                            "updated_at": "2024-02-26T21:37:05.460-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Panes y Postres Congelados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-congelados/pan-y-productos-de-panaderia-congelados"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8655": {
                            "category": {
                                "id": 8655,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3122,
                                "rgt": 3131,
                                "created_at": "2023-07-07T11:22:25.744-04:00",
                                "updated_at": "2024-04-12T11:34:37.671-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Alimentos Envasados",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/alimentos-envasados"
                            },
                            "children": [
                                {
                                    "8657": {
                                        "category": {
                                            "id": 8657,
                                            "parent_id": 8655,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3123,
                                            "rgt": 3124,
                                            "created_at": "2023-07-07T11:22:59.400-04:00",
                                            "updated_at": "2024-04-08T17:35:39.861-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Productos Enlatados y en Conserva",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/alimentos-envasados/productos-enlatados-y-en-conserv"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8658": {
                                        "category": {
                                            "id": 8658,
                                            "parent_id": 8655,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3125,
                                            "rgt": 3126,
                                            "created_at": "2023-07-07T11:24:41.637-04:00",
                                            "updated_at": "2024-04-11T15:09:31.852-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Aceites y Condimentos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/alimentos-envasados/aceites-y-condimentos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8659": {
                                        "category": {
                                            "id": 8659,
                                            "parent_id": 8655,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3127,
                                            "rgt": 3128,
                                            "created_at": "2023-07-07T11:25:33.259-04:00",
                                            "updated_at": "2024-02-15T15:16:00.441-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Salsas y Aderezos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/alimentos-envasados/salsas-y-aderezos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8660": {
                                        "category": {
                                            "id": 8660,
                                            "parent_id": 8655,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3129,
                                            "rgt": 3130,
                                            "created_at": "2023-07-07T11:26:03.401-04:00",
                                            "updated_at": "2024-04-12T11:34:37.671-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Dulces y Confituras",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/alimentos-envasados/dulces-y-chocolates"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8661": {
                            "category": {
                                "id": 8661,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3132,
                                "rgt": 3141,
                                "created_at": "2023-07-07T11:26:26.961-04:00",
                                "updated_at": "2024-04-04T11:00:05.363-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Productos de Despensa",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/productos-de-despensa"
                            },
                            "children": [
                                {
                                    "8662": {
                                        "category": {
                                            "id": 8662,
                                            "parent_id": 8661,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3133,
                                            "rgt": 3134,
                                            "created_at": "2023-07-07T11:26:34.576-04:00",
                                            "updated_at": "2024-04-03T20:59:04.702-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Arroces, Pastas y Cereales",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-despensa/new-node"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8663": {
                                        "category": {
                                            "id": 8663,
                                            "parent_id": 8661,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3135,
                                            "rgt": 3136,
                                            "created_at": "2023-07-07T11:27:04.201-04:00",
                                            "updated_at": "2024-03-29T02:02:07.039-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Legumbres y Frijoles",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-despensa/legumbres-y-frijoles"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8664": {
                                        "category": {
                                            "id": 8664,
                                            "parent_id": 8661,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3137,
                                            "rgt": 3138,
                                            "created_at": "2023-07-07T11:27:11.997-04:00",
                                            "updated_at": "2024-02-15T07:44:33.895-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Harinas y Azúcar",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-despensa/harinas-y-azucar"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8666": {
                                        "category": {
                                            "id": 8666,
                                            "parent_id": 8661,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3139,
                                            "rgt": 3140,
                                            "created_at": "2023-07-07T11:27:42.184-04:00",
                                            "updated_at": "2024-02-15T15:33:50.992-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Sopas y Caldos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-despensa/sopas-y-caldos"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8667": {
                            "category": {
                                "id": 8667,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3142,
                                "rgt": 3151,
                                "created_at": "2023-07-07T11:27:53.770-04:00",
                                "updated_at": "2024-04-12T10:19:49.582-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Bebidas",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/bebidas"
                            },
                            "children": [
                                {
                                    "8668": {
                                        "category": {
                                            "id": 8668,
                                            "parent_id": 8667,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3143,
                                            "rgt": 3144,
                                            "created_at": "2023-07-07T11:28:01.676-04:00",
                                            "updated_at": "2024-04-10T23:45:05.024-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Aguas, Jugos y Refrescos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/bebidas/aguas-y-refrescos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8670": {
                                        "category": {
                                            "id": 8670,
                                            "parent_id": 8667,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3145,
                                            "rgt": 3146,
                                            "created_at": "2023-07-07T11:28:22.885-04:00",
                                            "updated_at": "2024-04-04T12:55:22.904-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Bebidas Energéticas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/bebidas/bebidas-energeticas-y-deportivas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8671": {
                                        "category": {
                                            "id": 8671,
                                            "parent_id": 8667,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3147,
                                            "rgt": 3148,
                                            "created_at": "2023-07-07T11:28:30.448-04:00",
                                            "updated_at": "2024-04-12T10:19:49.582-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Café y Té",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/bebidas/cafe-y-te"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8672": {
                                        "category": {
                                            "id": 8672,
                                            "parent_id": 8667,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3149,
                                            "rgt": 3150,
                                            "created_at": "2023-07-07T11:28:53.843-04:00",
                                            "updated_at": "2024-02-05T15:08:24.257-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Bebidas Alcohólicas y Cigarillos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/bebidas/bebidas-alcoholicas-cervezas-vinos-licores"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8685": {
                            "category": {
                                "id": 8685,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3152,
                                "rgt": 3163,
                                "created_at": "2023-07-07T11:44:57.807-04:00",
                                "updated_at": "2024-04-12T12:44:07.051-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Productos de Aseo e Higiene",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/productos-de-higiene-personal"
                            },
                            "children": [
                                {
                                    "8687": {
                                        "category": {
                                            "id": 8687,
                                            "parent_id": 8685,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3153,
                                            "rgt": 3154,
                                            "created_at": "2023-07-07T11:45:41.771-04:00",
                                            "updated_at": "2024-02-20T16:39:44.631-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Cuidado de la Piel",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-higiene-personal/cuidado-de-la-piel-cremas-lociones-protector-solar"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8688": {
                                        "category": {
                                            "id": 8688,
                                            "parent_id": 8685,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3155,
                                            "rgt": 3156,
                                            "created_at": "2023-07-07T11:45:57.813-04:00",
                                            "updated_at": "2024-04-04T11:00:05.363-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Cuidado del Cabello",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-higiene-personal/cuidado-personal-champu-acondicionador-jabon-etc-dot"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8686": {
                                        "category": {
                                            "id": 8686,
                                            "parent_id": 8685,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3157,
                                            "rgt": 3158,
                                            "created_at": "2023-07-07T11:45:31.607-04:00",
                                            "updated_at": "2024-04-04T11:00:05.363-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Higiene Bucal",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-higiene-personal/cuidado-bucal-cepillos-de-dientes-pasta-dental-enjuague-bucal"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8689": {
                                        "category": {
                                            "id": 8689,
                                            "parent_id": 8685,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3159,
                                            "rgt": 3160,
                                            "created_at": "2023-07-07T11:46:46.348-04:00",
                                            "updated_at": "2024-02-20T16:39:44.631-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Maquillajes y Cosméticos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-higiene-personal/new-node"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8691": {
                                        "category": {
                                            "id": 8691,
                                            "parent_id": 8685,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3161,
                                            "rgt": 3162,
                                            "created_at": "2023-07-07T11:47:12.830-04:00",
                                            "updated_at": "2024-03-17T17:41:05.712-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Perfumes y Fragancias",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-de-higiene-personal/perfumes-y-fragancias"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8949": {
                            "category": {
                                "id": 8949,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3164,
                                "rgt": 3177,
                                "created_at": "2023-07-19T15:31:50.578-04:00",
                                "updated_at": "2024-03-27T15:28:05.265-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Ferretería",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/ferreteria"
                            },
                            "children": [
                                {
                                    "8878": {
                                        "category": {
                                            "id": 8878,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3165,
                                            "rgt": 3166,
                                            "created_at": "2023-07-16T12:53:08.590-04:00",
                                            "updated_at": "2024-03-27T15:28:05.265-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Electricidad y Luminarias",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/electricidad"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8817": {
                                        "category": {
                                            "id": 8817,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3167,
                                            "rgt": 3168,
                                            "created_at": "2023-07-12T16:17:48.497-04:00",
                                            "updated_at": "2024-03-17T09:11:32.136-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Plomería",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/plomeria"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8819": {
                                        "category": {
                                            "id": 8819,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3169,
                                            "rgt": 3170,
                                            "created_at": "2023-07-12T16:19:13.484-04:00",
                                            "updated_at": "2024-03-18T07:04:50.882-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Pinturas y Vinilos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/pinturas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8818": {
                                        "category": {
                                            "id": 8818,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3171,
                                            "rgt": 3172,
                                            "created_at": "2023-07-12T16:19:02.101-04:00",
                                            "updated_at": "2024-01-29T12:07:04.315-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Materiales de Construcción",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/materiales-de-construccion"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8684": {
                                        "category": {
                                            "id": 8684,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3173,
                                            "rgt": 3174,
                                            "created_at": "2023-07-07T11:44:00.581-04:00",
                                            "updated_at": "2024-03-17T09:11:32.136-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Herramientas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/herramientas-y-productos-de-bricolaje"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8961": {
                                        "category": {
                                            "id": 8961,
                                            "parent_id": 8949,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3175,
                                            "rgt": 3176,
                                            "created_at": "2023-07-19T16:20:50.642-04:00",
                                            "updated_at": "2023-08-09T21:50:54.061-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Partes y Piezas para Autos y Motos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/ferreteria/partes-y-piezas-de-autos-y-motos"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8957": {
                            "category": {
                                "id": 8957,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3178,
                                "rgt": 3185,
                                "created_at": "2023-07-19T16:14:11.985-04:00",
                                "updated_at": "2024-03-29T16:19:48.034-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Artículos de Oficina",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/materiales-de-oficina"
                            },
                            "children": [
                                {
                                    "8958": {
                                        "category": {
                                            "id": 8958,
                                            "parent_id": 8957,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3179,
                                            "rgt": 3180,
                                            "created_at": "2023-07-19T16:14:34.797-04:00",
                                            "updated_at": "2023-11-28T12:02:37.568-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Mobiliario",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/materiales-de-oficina/mobiliario"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8959": {
                                        "category": {
                                            "id": 8959,
                                            "parent_id": 8957,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3181,
                                            "rgt": 3182,
                                            "created_at": "2023-07-19T16:14:48.869-04:00",
                                            "updated_at": "2023-07-28T11:03:39.512-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Equipos Electrónicos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/materiales-de-oficina/equipos-electronicos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8960": {
                                        "category": {
                                            "id": 8960,
                                            "parent_id": 8957,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3183,
                                            "rgt": 3184,
                                            "created_at": "2023-07-19T16:15:20.055-04:00",
                                            "updated_at": "2024-03-29T16:19:48.034-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Materiales de Oficina",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/materiales-de-oficina/insumos-de-oficina"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8700": {
                            "category": {
                                "id": 8700,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3186,
                                "rgt": 3195,
                                "created_at": "2023-07-07T11:51:10.978-04:00",
                                "updated_at": "2024-03-16T22:28:13.579-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Artículos de Limpieza y Suministros",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/articulos-de-limpieza-y-suministros"
                            },
                            "children": [
                                {
                                    "8701": {
                                        "category": {
                                            "id": 8701,
                                            "parent_id": 8700,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3187,
                                            "rgt": 3188,
                                            "created_at": "2023-07-07T11:51:30.008-04:00",
                                            "updated_at": "2024-03-16T22:28:13.579-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Detergentes y Lavavajillas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/articulos-de-limpieza-y-suministros/detergentes-para-ropa"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8702": {
                                        "category": {
                                            "id": 8702,
                                            "parent_id": 8700,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3189,
                                            "rgt": 3190,
                                            "created_at": "2023-07-07T11:51:41.686-04:00",
                                            "updated_at": "2023-08-10T13:26:09.295-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Limpiadores Multiusos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/articulos-de-limpieza-y-suministros/limpiadores-nultiusos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8706": {
                                        "category": {
                                            "id": 8706,
                                            "parent_id": 8700,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3191,
                                            "rgt": 3192,
                                            "created_at": "2023-07-07T11:52:59.033-04:00",
                                            "updated_at": "2023-07-28T11:03:39.512-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Utensilios de Limpieza",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/articulos-de-limpieza-y-suministros/utensilios-de-limpieza-escobas-trapeadores-etc-dot"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8705": {
                                        "category": {
                                            "id": 8705,
                                            "parent_id": 8700,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3193,
                                            "rgt": 3194,
                                            "created_at": "2023-07-07T11:52:19.012-04:00",
                                            "updated_at": "2023-08-09T18:51:56.618-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Insumos Desechables",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/articulos-de-limpieza-y-suministros/bolsas-de-basura-y-contenedores"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8679": {
                            "category": {
                                "id": 8679,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3196,
                                "rgt": 3207,
                                "created_at": "2023-07-07T11:42:10.810-04:00",
                                "updated_at": "2024-04-10T11:12:55.475-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Productos del Hogar",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado"
                            },
                            "children": [
                                {
                                    "8682": {
                                        "category": {
                                            "id": 8682,
                                            "parent_id": 8679,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3197,
                                            "rgt": 3198,
                                            "created_at": "2023-07-07T11:43:32.067-04:00",
                                            "updated_at": "2024-04-10T11:12:55.475-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Electrodomésticos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/electrodomesticos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8950": {
                                        "category": {
                                            "id": 8950,
                                            "parent_id": 8679,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3199,
                                            "rgt": 3200,
                                            "created_at": "2023-07-19T15:43:13.395-04:00",
                                            "updated_at": "2023-11-28T12:02:37.568-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Muebles y Colchones",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/electrodomesticos/muebles-y-colchones"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8680": {
                                        "category": {
                                            "id": 8680,
                                            "parent_id": 8679,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3201,
                                            "rgt": 3202,
                                            "created_at": "2023-07-07T11:42:44.388-04:00",
                                            "updated_at": "2024-03-17T17:41:05.658-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Utensilios de Cocina",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/new-node"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8948": {
                                        "category": {
                                            "id": 8948,
                                            "parent_id": 8679,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3203,
                                            "rgt": 3204,
                                            "created_at": "2023-07-19T15:26:47.455-04:00",
                                            "updated_at": "2024-02-05T16:01:48.011-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Ropa de Cama y Toallas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/accesorios-para-el-bano"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8683": {
                                        "category": {
                                            "id": 8683,
                                            "parent_id": 8679,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3205,
                                            "rgt": 3206,
                                            "created_at": "2023-07-07T11:43:46.086-04:00",
                                            "updated_at": "2024-02-21T08:14:23.972-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Artículos Decorativos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/articulos-de-decoracion"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8693": {
                            "category": {
                                "id": 8693,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3208,
                                "rgt": 3217,
                                "created_at": "2023-07-07T11:48:25.789-04:00",
                                "updated_at": "2024-04-03T11:56:03.735-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Productos para Niños",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/productos-para-bebes"
                            },
                            "children": [
                                {
                                    "8694": {
                                        "category": {
                                            "id": 8694,
                                            "parent_id": 8693,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3209,
                                            "rgt": 3210,
                                            "created_at": "2023-07-07T11:48:31.493-04:00",
                                            "updated_at": "2024-04-03T11:56:03.735-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Alimentos para Bebés",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/new-node"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8696": {
                                        "category": {
                                            "id": 8696,
                                            "parent_id": 8693,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3211,
                                            "rgt": 3212,
                                            "created_at": "2023-07-07T11:48:57.944-04:00",
                                            "updated_at": "2023-07-21T16:51:31.404-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Artículos para el Cuidado de Bebés",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/productos-de-cuidado-para-bebes-lociones-jabones-etc-dot"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8699": {
                                        "category": {
                                            "id": 8699,
                                            "parent_id": 8693,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3213,
                                            "rgt": 3214,
                                            "created_at": "2023-07-07T11:50:14.821-04:00",
                                            "updated_at": "2023-08-09T04:51:06.175-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Ropa y Accesorios para Niños y Bebés",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/ropa-y-accesorios-para-bebes"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8951": {
                                        "category": {
                                            "id": 8951,
                                            "parent_id": 8693,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3215,
                                            "rgt": 3216,
                                            "created_at": "2023-07-19T16:07:54.262-04:00",
                                            "updated_at": "2024-01-03T22:22:15.933-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Juguetes",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/ropa-y-accesorios-para-bebes/juguetes"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8709": {
                            "category": {
                                "id": 8709,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3218,
                                "rgt": 3223,
                                "created_at": "2023-07-07T12:02:45.583-04:00",
                                "updated_at": "2024-03-13T07:18:43.571-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Mascotas",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/mascotas"
                            },
                            "children": [
                                {
                                    "8710": {
                                        "category": {
                                            "id": 8710,
                                            "parent_id": 8709,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3219,
                                            "rgt": 3220,
                                            "created_at": "2023-07-07T12:02:54.175-04:00",
                                            "updated_at": "2024-03-13T07:17:55.303-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Alimentos para Mascotas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/alimentos-para-mascotas"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8711": {
                                        "category": {
                                            "id": 8711,
                                            "parent_id": 8709,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3221,
                                            "rgt": 3222,
                                            "created_at": "2023-07-07T12:03:04.039-04:00",
                                            "updated_at": "2023-07-21T16:51:31.404-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Productos de Higiene para Mascotas",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/productos-de-higiene-de-mascotas"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8952": {
                            "category": {
                                "id": 8952,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3224,
                                "rgt": 3231,
                                "created_at": "2023-07-19T16:10:33.571-04:00",
                                "updated_at": "2023-11-17T12:18:47.552-05:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Moda y Vestuario",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/productos-para-bebes/vestuario"
                            },
                            "children": [
                                {
                                    "8953": {
                                        "category": {
                                            "id": 8953,
                                            "parent_id": 8952,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3225,
                                            "rgt": 3226,
                                            "created_at": "2023-07-19T16:11:18.082-04:00",
                                            "updated_at": "2023-08-09T16:05:34.425-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Ropa y Calzado",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/vestuario/ropa-para-mujeres-y-hombres"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8955": {
                                        "category": {
                                            "id": 8955,
                                            "parent_id": 8952,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3227,
                                            "rgt": 3228,
                                            "created_at": "2023-07-19T16:12:34.732-04:00",
                                            "updated_at": "2023-11-17T12:18:47.552-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Carteras y Accesorios",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/vestuario/ropa-para-mujeres-y-hombres/calzado/carteras-y-accesorios"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8956": {
                                        "category": {
                                            "id": 8956,
                                            "parent_id": 8952,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3229,
                                            "rgt": 3230,
                                            "created_at": "2023-07-19T16:13:25.553-04:00",
                                            "updated_at": "2023-11-17T06:17:33.805-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Joyería",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/productos-para-bebes/vestuario/joyeria"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8973": {
                            "category": {
                                "id": 8973,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3232,
                                "rgt": 3239,
                                "created_at": "2023-07-19T16:50:46.533-04:00",
                                "updated_at": "2024-04-04T12:22:37.118-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Flores y Regalos",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/flores-y-regalos"
                            },
                            "children": [
                                {
                                    "8975": {
                                        "category": {
                                            "id": 8975,
                                            "parent_id": 8973,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3233,
                                            "rgt": 3234,
                                            "created_at": "2023-07-19T16:55:04.941-04:00",
                                            "updated_at": "2024-02-21T08:14:23.972-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Arreglos Florales",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/flores-y-regalos/arreglos-florales-de-ocasion"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8976": {
                                        "category": {
                                            "id": 8976,
                                            "parent_id": 8973,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3235,
                                            "rgt": 3236,
                                            "created_at": "2023-07-19T16:55:38.041-04:00",
                                            "updated_at": "2023-07-21T16:51:31.404-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Productos Personalizados",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/flores-y-regalos/productos-personalizados"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8977": {
                                        "category": {
                                            "id": 8977,
                                            "parent_id": 8973,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3237,
                                            "rgt": 3238,
                                            "created_at": "2023-07-19T16:56:57.622-04:00",
                                            "updated_at": "2024-04-04T12:22:37.118-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Regalos de Ocasión",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/pan-y-productos-de-panaderia-congelado/flores-y-regalos/regalos"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8967": {
                            "category": {
                                "id": 8967,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3240,
                                "rgt": 3251,
                                "created_at": "2023-07-19T16:45:54.456-04:00",
                                "updated_at": "2023-11-17T06:10:39.283-05:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Servicios",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/mascotas/servicios"
                            },
                            "children": [
                                {
                                    "8968": {
                                        "category": {
                                            "id": 8968,
                                            "parent_id": 8967,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3241,
                                            "rgt": 3242,
                                            "created_at": "2023-07-19T16:46:27.133-04:00",
                                            "updated_at": "2023-07-21T16:51:31.404-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Organización de Fiestas y Eventos",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/servicios/organizacion-de-fiestas-y-eventos"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8969": {
                                        "category": {
                                            "id": 8969,
                                            "parent_id": 8967,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3243,
                                            "rgt": 3244,
                                            "created_at": "2023-07-19T16:46:40.851-04:00",
                                            "updated_at": "2023-11-17T06:10:36.536-05:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Fotografía",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/servicios/fotografia"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8970": {
                                        "category": {
                                            "id": 8970,
                                            "parent_id": 8967,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3245,
                                            "rgt": 3246,
                                            "created_at": "2023-07-19T16:46:59.906-04:00",
                                            "updated_at": "2023-07-28T16:57:53.386-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Peluquería",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/servicios/peluqueria"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8971": {
                                        "category": {
                                            "id": 8971,
                                            "parent_id": 8967,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3247,
                                            "rgt": 3248,
                                            "created_at": "2023-07-19T16:47:24.338-04:00",
                                            "updated_at": "2023-07-28T16:57:53.386-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Manicure y Pedicure",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/servicios/manicure-y-pedicure"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8972": {
                                        "category": {
                                            "id": 8972,
                                            "parent_id": 8967,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3249,
                                            "rgt": 3250,
                                            "created_at": "2023-07-19T16:47:45.321-04:00",
                                            "updated_at": "2023-09-18T22:51:46.262-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Lavandería y Limpieza",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/mascotas/servicios/lavanderia-y-limpieza"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "8714": {
                            "category": {
                                "id": 8714,
                                "parent_id": 8647,
                                "position": 0,
                                "taxonomy_id": 4593,
                                "lft": 3252,
                                "rgt": 3257,
                                "created_at": "2023-07-11T12:56:10.107-04:00",
                                "updated_at": "2024-04-12T12:44:07.051-04:00",
                                "depth": 1,
                                "hide_from_nav": false,
                                "name": "Ofertas y Rebajas",
                                "description": null,
                                "meta_title": null,
                                "meta_description": null,
                                "meta_keywords": null,
                                "permalink": "nuevas-categorias/rebajas"
                            },
                            "children": [
                                {
                                    "8965": {
                                        "category": {
                                            "id": 8965,
                                            "parent_id": 8714,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3253,
                                            "rgt": 3254,
                                            "created_at": "2023-07-19T16:35:51.290-04:00",
                                            "updated_at": "2024-04-12T12:44:07.051-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Rebajas de Precios",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/rebajas/rebajas-de-precios"
                                        },
                                        "children": []
                                    }
                                },
                                {
                                    "8966": {
                                        "category": {
                                            "id": 8966,
                                            "parent_id": 8714,
                                            "position": 0,
                                            "taxonomy_id": 4593,
                                            "lft": 3255,
                                            "rgt": 3256,
                                            "created_at": "2023-07-19T16:36:05.096-04:00",
                                            "updated_at": "2024-04-08T17:35:39.861-04:00",
                                            "depth": 2,
                                            "hide_from_nav": false,
                                            "name": "Envío Gratis",
                                            "description": null,
                                            "meta_title": null,
                                            "meta_description": null,
                                            "meta_keywords": null,
                                            "permalink": "nuevas-categorias/rebajas/envio-gratis"
                                        },
                                        "children": []
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
