<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white">
    <div
        class="relative
    us:pt-3 us:w-full us:pl-5.3vw us:pr-5.3vw
    us-landscape:pt-3 us-landscape:w-full us-landscape:pl-5.3vw us-landscape:pr-0
    md:pt-3 md:w-full md:px-5.3vw
    md-landscape:w-full md-landscape:px-5.3vw
    lg:w-full lg:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px overflow-hidden box-border lg-landscape:h-auto lg-landscape:pt-0">
        <p class="relative font-Lato-Regular font-bold text-black mt-4 w-full text-center">
            {{TITLE}}
        </p>
        <div [innerHTML]="DP" class="relative w-full h-auto">

        </div>

        <app-text-skeleton *ngIf="DP == null"></app-text-skeleton>
    </div>
</section>