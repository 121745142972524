<div *ngIf="form" [formGroup]="form" class="relative flex flex-col justify-start items-start w-full mt-4">
    <label *ngIf="meta.label" class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
      {{meta.label}}
    </label>
    <div class="relative w-full box-border pb-5">
        <textarea [placeholder]="meta.placeHolder" [id]="meta.fieldId" [formControlName]="meta.fieldId"
                  [ngClass]="{
                       'border-none': selfControl?.valid || (selfControl?.invalid && !formMarkAsTouched && !(selfControl?.dirty || selfControl?.touched)),
                       'border-red-500': (selfControl?.invalid && formMarkAsTouched) || (selfControl?.invalid && (selfControl?.dirty || selfControl?.touched))
                   }"
                  class="relative px-3 bg-white text-gray-#252525 font-Lato-Regular w-full active:ring-0
                         placeholder-gray-#9D9D9D active:outline-none focus:ring-0 active:border-none
                         focus:outline-none outline-none ring-0 border-none min-h-47px text-base">
        </textarea>
        <div *ngIf="selfControl?.invalid && formMarkAsTouched || (selfControl?.invalid && !formMarkAsTouched && (selfControl?.dirty || selfControl?.touched))"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="selfControl?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
        </div>
    </div>
</div>
