import {Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, Input} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit, OnDestroy {
  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;
  @Input() resize: boolean = false;
  slider: any = {};
  currentSlide: number = 1;
  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      if (this.slider) this.slider.destroy();
  }

  ngAfterViewInit(): void {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      slides: {
        perView: 5,
        spacing: 15,
      },
      breakpoints: {
        "(min-width: 300px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 400px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 500px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 550px)": {
          slides: { perView: 2, spacing: 20 },
        },
        "(min-width: 600px)": {
          slides: { perView: 3, spacing: 5 },
        },
        "(min-width: 750px)": {
          slides: { perView: 3, spacing: 18 },
        },
        "(min-width: 900px)": {
          slides: { perView: 3, spacing: 30 },
        },
        "(min-width: 930px)": {
          slides: { perView: 4, spacing: 5 },
        },
        "(min-width: 1100px)": {
          slides: { perView: 4, spacing: 15 },
        },
        "(min-width: 1200px)": {
          slides: { perView: 5, spacing: 5 },
        },
      },
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
        // console.log(this.currentSlide);
        // console.log(s.track?.details?.slides?.length);
      },
    })
  }
}
