import {Component, OnDestroy, OnInit} from '@angular/core';
import { Observable, Subject, Subscription} from 'rxjs';
import {GroupedStore, Product, SelectedZone} from "../../shared/models";
import { map, takeUntil} from "rxjs/operators";
import {Store} from "../../shared/models";
import {StoreService} from "../../shared/services/store.service";
import {LanguageService} from "../../shared/services/language.service";
import {ZonesService} from "../../shared/services/zones.service";
import {environment} from "../../../environments/environment";
import {DynamicCOnfigService} from "../../shared/services/dynamic-config.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
    subscription: any;
    storesObs!: Observable<Store[]>;
    languageServiceSubscription!: Subscription;

    destroy$: Subject<boolean> = new Subject<boolean>();
    defaultStoreObject: Store | undefined;

    specialProductsObs!: Observable<Product[]>;

    hideDefaultStoreSection = false;
    stores: any = [];
    grids: any;
    variables: any;
    facets: any;


    constructor(
        private storeService: StoreService,
        private router: Router,
        private zonesService: ZonesService,
        private dynamicService: DynamicCOnfigService,
    ) {
    }

    ngOnInit(): void {
        if (localStorage.getItem('GLOBAL_CONFIGS')) {
            let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
            this.grids = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.Grids?.data
        }
        this.dynamicService.dynamic_settings_subject
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
                let aux: any = response['HOME_SETTINGS'];
                this.grids = aux?.data[0]?.attributes?.Grids?.data;
        })
        this.goToTop();
        this.storeService.getStoreById(this.defaultStore)
            .pipe(takeUntil(this.destroy$))
            .subscribe((store) => {
                this.defaultStoreObject = store;
            });
        this.zonesService.subjectSelectedZones$
            .pipe(takeUntil(this.destroy$))
            .subscribe((zone) => {

                this.storesObs = this.storeService.getStoresByZoneId(zone?.zone || 6)
                    .pipe(
                        takeUntil(this.destroy$),
                        map((groupedStore?: GroupedStore): Store[] => {
                            const stores = groupedStore?.stores;
                            setTimeout(() => {
                                this.hideDefaultStoreSection = !stores?.some((store) => store.id === this.defaultStore) || false;
                            }, 0);
                            return stores?.filter((store) => store.id !== this.defaultStore)?.slice(0, 5) || [];
                        })
                    );
            });
    }

    ngOnDestroy(): void {
        this.languageServiceSubscription?.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }

    get getSelectedZones() {
        return this.zonesService.getZonesValuesStorage()?.municipality_name || 'DELIVERY_ZONE';
    }

    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    get defaultStore() {
        return environment.default_store;
    }

    get defaultStoreCode() {
        return environment.default_store_code;
    }

    private goToTop() {
        window.scroll({top: 0});
    }

    navegateTo(url:string) {
        this.router.navigateByUrl(url);
    }
}
