import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, of, Subject, Subscription, takeUntil } from 'rxjs';
import { Product, SelectedZone, Taxonomy } from 'src/app/shared/models';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { MenuService } from "../../../../shared/services/menu.service";
import { StoreService } from "../../../../shared/services/store.service";
import { SEARCH_FILTER_DEFAULT_VALUE, SEARCH_FILTERS, SearchFiltersType } from "../../../../shared/filters/searc-filters";
import { AlgoliaService } from 'src/app/shared/services/algolia.service';

@Component({
  selector: 'app-categories-search-grid',
  templateUrl: './categories-search-grid.component.html',
  styleUrls: ['./categories-search-grid.component.scss']
})
export class CategoriesSearchGridComponent implements OnInit, OnDestroy {

  subscription: any;
  searchSkeletons: any = [];
  show_search = false;
  products: any = [];
  skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  category: any;
  languageServiceSubscription!: Subscription;
  langu = 'ES';
  product_page = 1;
  first = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  actualPage: number = 1;
  totalPages: number = 2;
  category_name: string = '';
  zone: any = {};
  first_time = 0;
  obs: any;
  lan_obs: any;
  zone_ob: any;
  products_suscription: any;
  filterForm: FormGroup;
  order: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE;
  filters: { taxon?: number, price?: string, name?: string } = {};

  isLoadingProducts = false;

  isLoadingTaxonomy = true;

  orderByArray: SearchFiltersType[] = SEARCH_FILTERS;
  products_observer: any;
  hits_per_page: number = 24;
  current_page: number = 1;
  next_page = false;

  constructor(
    private productsService: ProductsService,
    private languageService: LanguageService,
    private activeRoute: ActivatedRoute,
    private zonesService: ZonesService,
    private menuService: MenuService,
    private storeService: StoreService,
    private algoliaService: AlgoliaService
  ) {
    this.category = this.activeRoute.snapshot.params['id'];
    this.filters.taxon = this.category;
    this.order = SEARCH_FILTER_DEFAULT_VALUE;
    this.filterForm = new FormGroup({
      order: new FormControl({ value: this.order, disabled: !this.getSelectedZone }, {
        validators: []
      }),
    });
    this.filterForm.get('order')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.getSelectedZone) {
          this.order = this.filterForm.get('order')?.value;
          this.search(this.category);
        }
      })
  }

  ngOnInit(): void {
    this.search(this.category);

    this.obs = this.activeRoute.params.subscribe(routeParams => {
      this.category = routeParams['id'];
      this.filters.taxon = this.category;
      if (this.first_time == 1) {
        this.search(this.category);
      }
      document.querySelectorAll('.general-category').forEach((element: any) => {
        element?.classList.remove('text-orange-500');
        element?.classList.add('text-gray-#252525');
      });
      document.querySelectorAll(`.general-cat${this.category}`).forEach((element: any) => {
        element?.classList.add('text-orange-500');
        element?.classList.remove('text-gray-#252525');
      });
    });

    this.lan_obs = this.languageService.selectedLanguage.subscribe(lang => {
      this.langu = lang;
      if (this.first_time == 1) {
        this.search(this.category);
      }
    });

    this.zone_ob = this.zonesService.subjectSelectedZones$.subscribe((zone) => {
      this.zone = zone;
      if (this.first_time == 1) {
        this.search(this.category);
        if (this.getSelectedZone) {
          this.filterForm.get('order')?.enable();
        } else {
          this.filterForm.get('order')?.disable();
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.obs.unsubscribe();
    this.lan_obs.unsubscribe();
    this.zone_ob.unsubscribe();
    document.querySelectorAll('.general-category').forEach((element: any) => {
      element?.classList.remove('text-orange-500');
      element?.classList.add('text-gray-#252525');
    });
  }

  search(category: string) {
    window.scroll({ top: 0 });
    if (category.length > 0) {
      this.show_search = true;
      this.products = [];
      this.current_page = 1;
      this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      this.isLoadingTaxonomy = true;
      const ZONE = this.getSelectedZone.zone;
      const MUNICIPALITY_ID = this.getSelectedZone.municipality;
      this.getCategoryName(this.category).then((name) => {
        this.category_name = name!;
        this.isLoadingTaxonomy = false;
      });
      if (this.products_observer) {
        this.products_observer?.unsubscribe();
        this.fetch_products(this.category, `${ZONE}`, MUNICIPALITY_ID);
      } else {
        this.fetch_products(this.category, `${ZONE}`, MUNICIPALITY_ID);
      }
    } else {
      this.show_search = false;
    }
  }

  hideSkeletons() {
    this.show_search = false;
    this.searchSkeletons = [];
  }

  private fetch_products(category_id: string, zone?: string, municipality?: number) {
    let FILTERS: any = { CategoryIds: category_id };
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (zone && zone !== 'undefined') FILTERS['ZoneIds'] = zone;
    if (municipality) FILTERS['MunicipalityId'] = municipality;
    this.order?.text == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    this.order?.text == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    this.order?.text == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    this.order?.text == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;

    this.products_observer = this.algoliaService.searchProducts(this.hits_per_page, this.current_page, this.langu, FILTERS).subscribe({
      next: (products: any) => {
        this.products = products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: product?.stores[0]?.id ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined)
          }
        });
        this.hideSkeletons();
        this.first_time = 1;
        this.next_page = products?.data?.hasNext;
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  fetch_more() {
    this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let FILTERS: any = { CategoryIds: this.category };
    const ZONE = this.getSelectedZone?.zone;
    const MUNICIPALITY_ID = this.getSelectedZone?.municipality;
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (ZONE && ZONE !== undefined) FILTERS['ZoneIds'] = ZONE;
    if (MUNICIPALITY_ID) FILTERS['MunicipalityId'] = MUNICIPALITY_ID;
    this.order?.text == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    this.order?.text == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    this.order?.text == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    this.order?.text == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;

    this.algoliaService.searchProducts(this.hits_per_page, this.current_page += 1, this.langu, FILTERS).subscribe({
      next: (products: any) => {
        this.products = [...this.products,
        ...products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: product?.stores[0]?.id ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined)
          }
        })];
        this.next_page = products?.data?.hasNext;
        this.hideSkeletons();
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  searchProducts(lang: string) {
    if (!this.isLoadingProducts) {
      this.isLoadingProducts = true; // para evitar que se haga el request mas de una vez
      this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      this.products_suscription = this.productsService.searchByCategory(
        this.product_page,
        25,
        this.getSelectedZone?.zone || 6,
        // 1,
        this.getSelectedZone?.municipality || 37,
        lang,
        `default_variant,variants,images,variants.images,variants.option_values,data_extra_types`,
        this.order,
        this.filters
      )
        .pipe(
          map((pdts): Product[] => {
            this.searchSkeletons = [];
            this.totalPages = pdts.meta.total_pages;
            return this.productsService.mapProductsFromResponse(pdts);
          })
        ).pipe(takeUntil(this.destroy$)).subscribe((products: Product[]) => {
          this.first_time = 1;
          this.products = [...products];
          this.products_suscription.unsubscribe();
          this.products_suscription = null;
          this.isLoadingProducts = false;
        })
    }

  }

  loadMore() {
    this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.productsService.searchByCategory(
      ++this.actualPage,
      25,
      this.getSelectedZone?.zone || 6,
      // 1,
      this.getSelectedZone?.municipality || 37,
      this.langu,
      `default_variant,variants,images,variants.images,variants.option_values,data_extra_types`,
      this.order,
      this.filters
    )
      .pipe(
        map((pdts): Product[] => {
          this.searchSkeletons = [];
          return this.productsService.mapProductsFromResponse(pdts);
        })
      ).subscribe((products: Product[]) => {
        this.products = [...this.products, ...products];
      })
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  async getCategoryName(id: any): Promise<string | undefined> {
    const taxonomy: Taxonomy | null = await this.menuService.getTaxonomyById(id, this.languageService.selectedLanguage.getValue());
    return taxonomy?.name;
  }

  findProductsByFilter(filter: any) {
    this.filters.price = filter?.data?.price;
    filter?.data?.price ? this.filters.price = filter?.data?.price : this.filters = { taxon: this.category };
    this.search(this.category);
  }

}
