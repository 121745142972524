export interface ShippingRatesRelationsInterfaceModel {
  id: string;
  type: string;
}

export interface ShipmentInterfaceModel {
  id: string;
  store_ids: number[];
  final_price: string;
  display_final_price: string;
  number: string;
  free: boolean;
  shipping_rates: ShippingRatesRelationsInterfaceModel[];
}

export interface ShippingRateInterfaceModel {
  id: string;
  cost: string;
  display_cost: string;
  display_final_price: string;
  display_tax_amount: string;
  final_price: string;
  free: boolean;
  name: string;
  selected: boolean;
  shipping_method_id: number;
  tax_amount: string;
}
