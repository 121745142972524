export const categories: any = {
    "headers": [
        {
            "text": "ALL_CATEGORIES"
        }
    ],
    "links": [
        {
            "text": "OFERTS",
            "route": "/category/2",
            "links": []
        },
        {
            "text": "PERSONAL_HY",
            "route": "/category/4",
            "links": []
        },
        {
            "text": "HOME_AP",
            "route": "/category/5",
            "links": []
        },
        {
            "text": "EQUIPMENT_AND_TOOLS",
            "route": "/category/6",
            "links": []
        },
        {
            "text": "CLEANING",
            "route": "/category/440",
            "links": []
        },
        {
            "text": "SANITARY_MASK",
            "route": "/category/441",
            "links": []
        },
        {
            "text": "PRECOOKED",
            "route": "/category/498",
            "links": []
        },
        {
            "text": "FOOD_PRODUCTS",
            "route": "/category/3",
            "links": [
                // {
                //     "text": "Arroces, Granos y Arinas",
                //     "route": "",
                //     "headers": ["Productos Alimenticios"]
                // },
            ]
        },
    ]
}