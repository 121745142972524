import { MultipleWalletAccount,WalletAccount } from "./wallet-account.model";
import { ErrorsEnum } from "../enum/errors.enum";
import { CardNewIntegration } from "./card.model";
import { Transaction } from "./transaction.model";
import { Address } from "./address.model";
import { OnfidoResponseModel } from "./onfido-response.model";

export type CardsResponse = { abilityToAdd: boolean, remainingSlots: number, cards: CardNewIntegration[], billingCountriesToHide: string[] };
export type TransactionType = { items: Transaction[], pageSize: number, currentPage: number, totalCount: number, totalPages: number, hasPrev: boolean };
export type DebitDepositType = {
  paymentCardId: string,
  amount: number,
  fingerprintTraceDto: {visitorId: string, requestId: string} | null
};
export type ACHDepositType = {
  amount: number,
  bankAccount: string,
  agree: boolean,
  bankAccountType: number,
  bankAccountOwnershipType: number,
  bankRoute: string,
  checkNumber: string,
  firstName: string,
  lastName: string,
  company: string,
  fingerprintTraceDto: {visitorId: string, requestId: string} | null
};
export type EnumType = {
  id: number,
  name: string
};
export type RevalidateCard = {
  paymentCardId: string,
  cardNumber: string,
  cvv: string,
  expMonth: number,
  expYear: number,
  paymentRoute: string
};
export type RevalidateResponse = { abilityToAdd: boolean, remainingSlots: number, paymentCardId: string };

export type PaymentMethodConfigurationVariant = {
  "paymentVariant": string,
  "paymentVariantFee": string,
  "paymentVariantFeeLabelColor": string,
  "paymentVariantId": number,
  "title": string,
  "message": string,
  "disabled": boolean,
  "visible": boolean,
  "showBillingToSelect": boolean,
  "agreementQuery": string,
  "logo": {
    "url": string,
    "mime": string
  },
  "combination": any[],
  "isCombined": boolean,
  "isExternalPayment": boolean,
  "walletBehaviour": number
}
export type PaymentMethodConfiguration = {
  "userMessage": string,
  "allowedPaymentVariants": PaymentMethodConfigurationVariant[]
};
export type StrapiResponseData = {
  "id": number,
  "attributes": {
    "InternalName": string,
    "Key": string,
    "Text": string,
    "createdAt": Date,
    "updatedAt": Date,
    "publishedAt": Date,
    "locale": string,
    "Env": string
  }
};

export interface WalletResponse {
  fails: boolean;
  success: boolean;
  message: string;
  data: TransactionType | WalletAccount | CardsResponse | EnumType | RevalidateResponse | PaymentMethodConfiguration | Address | StrapiResponseData[] | OnfidoResponseModel | MultipleWalletAccount,
  responseCode: ErrorsEnum
}
