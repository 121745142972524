import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable, of, retry, tap} from 'rxjs';
import {CacheResolverService} from "../services/cache-resolver.service";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {countriesMock} from "../mocks/countries";
import {multiStoresMock} from "../mocks/multi_stores";
import {groupedStoresMock} from "../mocks/grouped-stores";
import {frontendSettingsMock} from "../mocks/front-settings";
import {globalCategoriesMock} from "../mocks/global-categories";
import {homeSettingsMock} from "../mocks/home-settings";
import {ApiService} from "../services/api.service";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    excludedResources = [
        '/multi_stores',
        '/storefront/countries',
        '/storefront/grouped_stores',
        '/frontend-settings',
        '/global-categories',
        '/home-settings',
    ];
    constructor(
        private cacheResolver: CacheResolverService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method !== 'GET') {
            return next.handle(request);
        }

        if (this.hasRefreshHeader(request)) {
            return this.sendRequest(request, next);
        }

        let cachedData = this.cacheResolver.get(request.url);
        return cachedData ? of(cachedData) : this.sendRequest(request, next);
    }

    private sendRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let indexExcluded: number = -1;
        const excluded = this.excludedResources.some((re: string, index: number) => {
            const isInclude: boolean = request.url.includes(re);
            if (isInclude) {
                indexExcluded = index;
            }
            return isInclude;
        });
        if (!excluded) {
            return next.handle(request).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse && !this.hasRefreshHeader(request)) {
                        this.cacheResolver.set(request.url, event, environment.cacheExpirationTime);
                    }
                })
            );
        } else {
            return next.handle(request).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse && !this.hasRefreshHeader(request)) {
                        this.cacheResolver.set(request.url, event, environment.cacheExpirationTime);
                    }
                }),
                retry(environment.retry_on_fail),
                catchError((error) => {
                    let body;
                    if (indexExcluded != -1) {
                        switch (indexExcluded) {
                            case 0:
                                body = multiStoresMock;
                                break;
                            case 1:
                                body = countriesMock;
                                break;
                            case 2:
                                body = groupedStoresMock;
                                break;
                            case 3:
                                body = frontendSettingsMock;
                                break;
                            case 4:
                                body = globalCategoriesMock;
                                break;
                            case 5:
                                body = homeSettingsMock;
                                break;
                            default:
                                body = null;
                                break;
                        }
                        if (body) {
                            this.cacheResolver.set(request.url, Object.assign(error, {body: body}), environment.cacheExpirationTime);
                        }
                    }
                    return of(body ? body : error);
                })
            );
        }
    }

    hasRefreshHeader(request: HttpRequest<unknown>): boolean {
        return !!request.headers.get('x-refresh');
    }

}
