<div class="relative bg-white box-border cursor-pointer
us:min-h-169px us:w-full us:p-2
us-landscape:w-full
md:w-full
md-landscape:w-full
lg-landscape:w-full lg-landscape:min-h-169px lg-landscape:p-4"
  [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', code]">
  <div class="hov relative w-full flex justify-center items-center overflow-hidden box-border
    us:h-104px us:w-full
    lg-landscape:h-104px lg-landscape:bg-white">
    <img class="relative w-full h-full object-contain" [src]="logoUrl" [alt]="storeName">
    <p class="absolute w-full text-center px-1 h-auto font-Lato-Regular text-white z-20
        lg-landscape:text-sm">{{storeName}}</p>
    <div class="mask z-10"></div>
  </div>
  <div class="relative w-full flex justify-start items-center flex-wrap gap-x-6px gap-y-2 overflow-hidden h-5
  us:mt-4
    lg-landscape:mt-4">
    <p class="relative line-clamp-1 overflow-hidden font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full capitalize
        us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:h-5
        lg-landscape:text-13px lg-landscape:pl-2 lg-landscape:pr-2 lg-landscape:rounded-10px lg-landscape:h-5"
      *ngFor="let keyword of metaKeywords; index as i">
      <span *ngIf="i < 2 else moreKeywords">{{keyword}}</span>
      <ng-template #moreKeywords>
        <span>+</span>
      </ng-template>
    </p>
    <!--        <p class="relative font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full capitalize-->
    <!--        lg-landscape:text-13px lg-landscape:pl-6px lg-landscape:pr-2 lg-landscape:rounded-10px lg-landscape:h-5">-->
    <!--            postres</p>-->
    <!--        <p class="relative font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full capitalize-->
    <!--        lg-landscape:text-13px lg-landscape:pl-2 lg-landscape:pr-2 lg-landscape:rounded-10px lg-landscape:h-5">-->
    <!--            +</p>-->
  </div>
</div>