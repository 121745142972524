import { Component, Input, OnInit } from '@angular/core';
import { SelectedZone } from 'src/app/shared/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-square-card',
  templateUrl: './square-card.component.html',
  styleUrls: ['./square-card.component.scss']
})
export class SquareCardComponent implements OnInit {
  @Input() logoUrl: string = '../../../../../assets/imgs/product-card/no-product-image.png';
  @Input() storeName: string = '';
  @Input() metaKeywords: string[] = [];
  @Input() id: number = -1;
  @Input() code: string = '';

  constructor(private zonesService: ZonesService) { }

  ngOnInit(): void {
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
