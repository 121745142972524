import {Pipe, PipeTransform, SecurityContext} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: "escape"
})
export class EscapePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(text: string) {
    const replacedText = text.valueOf().replace(/(\r\n)/gm, "<br>");
    return this.sanitizer.sanitize(SecurityContext.HTML, replacedText);
  }
}
