export interface User {
  id?: number;
  age_valid?: number;
  email?: string;
  image?: string;
  permissions?: string[];
  role?: string;
  store_credits?: number;
  firstName?: string;
  lastName?: string;
  billAddress?: string;
  shipAddress?: string;
  verified_at?: Date;
  verification_status?: string;

  useNewCart?: boolean,
  readyToUse?: boolean,

  useNewPayment?: any;
}
