<section *ngIf="true"
    class="relative w-full us:mt-163px us-landscape:mt-117px md:mt-117px lg-landscape:mt-114px h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5 us:pt-5">
    <div class="relative overflow-hidden
    us:w-full us:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-7 lg-landscape:min-h-462px">
        <div *ngIf="!resp_filters" class="relative w-full flex justify-between items-center
        us:px-3
        lg-landscape:px-0">
            <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                    lg-landscape:text-19px">{{'RESULTS' | translate}}
                <!-- <span class="relative text-yellow-#ff4103">Mercado
                    Único</span> -->
            </p>
            <!--            <p routerLink="/stores/directory" class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold uppercase-->
            <!--            lg-landscape:text-sm">{{'SEE_MORE' | translate}} ></p>-->
        </div>

        <div class="relative w-full flex justify-end items-center
        us:mb-5
        lg-landscape:hidden">
            <div (click)="closeFilters()"
                class="relative h-10 w-auto px-3 flex justify-center items-center border border-gray-#9D9D9D">
                <label class="relative text-gray-#727272 font-Lato-Regular font-semibold
        us:text-sm
        lg-landscape:text-sm" for="">{{'FILTERS' | translate}}:</label>
            </div>
        </div>

        <div class="relative w-auto flex justify-end items-center
    us:mb-5 us:mt-5 us:hidden
    lg-landscape:mb-5 lg-landscape:mt-0 lg-landscape:flex">
            <div class="relative w-auto" [formGroup]="filterForm">
                <label class="relative text-gray-#727272 font-Lato-Regular font-semibold
            us:text-sm
            lg-landscape:text-sm" for="">{{'ORDER_BY' | translate}}:</label>
                <mat-select class="relative text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB bg-gray-#f2f2f2
            us:text-sm us:w-auto us:ml-1
            lg-landscape:text-sm lg-landscape:w-auto lg-landscape:ml-2
            px-3 py-2 bg-white  active:ring-0 focus:ring-0
                        ring-0 h-41px text-base col-span-1
            " formControlName="order">
                    <mat-option *ngFor="let order of orderByArray" [value]="order">{{order.text |
                        translate}}</mat-option>
                </mat-select>
            </div>
            <!--        <div class="relative w-auto us:ml-2 lg-landscape:ml-8">-->
            <!--            <label class="relative text-gray-#727272 font-Lato-Regular font-semibold-->
            <!--            us:text-sm-->
            <!--            lg-landscape:text-sm" for="">Ver como:</label>-->
            <!--            <select disabled class="relative cursor-not-allowed text-gray-#727272 font-Lato-Regular font-semibold border-2 rounded-3px border-solid border-gray-#ABABAB bg-gray-#f2f2f2-->
            <!--            us:text-sm us:w-auto us:ml-1-->
            <!--            lg-landscape:text-sm lg-landscape:w-auto lg-landscape:ml-2" name="" id="">-->
            <!--                <option value="">Grid</option>-->
            <!--            </select>-->
            <!--        </div>-->
        </div>
        <div class="relative flex justify-between
        us:flex-col
        lg-landscape:flex-row">
            <div [ngClass]="resp_filters ? 'us:flex' : 'us:hidden'"
                class="relative flex flex-col overflow-hidden box-border z-40
                       lg-landscape:flex lg-landscape:w-220px lg-landscape:px-0 lg-landscape:min-h-screen lg-landscape:relative lg-landscape:bg-transparent">

                <div class="relative w-full overflow-hidden
                       us:mt-3
                       lg-landscape:mt-12">
                    <button (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center
                    cursor-pointer font-Lato-Regular font-semibold
                       text-base w-auto h-41px rounded-3px px-3 mt-4">
                        {{'RESET_FILTERS' | translate}}
                    </button>
                </div>

                <div class="relative w-full overflow-hidden
                       us:mt-3
                       lg-landscape:mt-4">
                    <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase
                   us:text-13px us:tracking-0.7px us:mb-5
                   lg-landscape:text-13px lg-landscape:tracking-0.7px lg-landscape:mb-5">
                        {{'CATEGORIES'| translate}}
                    </p>
                    <ul class="relative w-full space-y-1 font-medium">
                        <li *ngFor="let link of categories" class="relative group h-auto">
                            <div class="flex justify-between w-full items-center pl-1 pr-1">
                                <a (click)="emitFilterData(link)" [ngClass]="'store-cat' + link?.id" class="flex general-store-category cursor-pointer justify-between w-full relative items-center text-gray-#252525 font-Lato-Regular
                                     font-medium rounded-lg"> {{link?.text | translate}}
                                </a>
                                <span class="relative flex">
                                    <span
                                        class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium text-white bg-yellow-#FFA101 rounded-full">{{link?.ammount}}</span>
                                    <div *ngIf="link?.links?.length > 0"
                                        class="relative z-10 flex justify-center items-center w-5 h-5 cursor-pointer">
                                        <div class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                                        </div>
                                        <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                                        <div (click)="showSubElement($event)" class="absolute w-full h-full z-10"></div>
                                    </div>
                                </span>
                                <!-- <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                                w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                                    alt=""> -->
                            </div>
                            <ul class="submenu overflow-hidden rounded-sm h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002
                                min-w-max duration-300">
                                <li class="my-3" *ngFor="let sublink of link?.links">
                                    <div class="flex justify-between w-full items-center">
                                        <a (click)="emitFilterData(sublink)" [ngClass]="'store-cat' + sublink?.id"
                                            class="text-dark general-store-category cursor-pointer font-Lato-Regular font-normal text-base capitalize transition-all">
                                            {{sublink?.text | translate}}
                                            <span
                                                class="inline-flex items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium text-white bg-yellow-#FFA101 rounded-full">
                                                {{sublink?.ammount}}</span>
                                        </a>
                                        <img *ngIf="sublink?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                              w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                                    </div>
                                    <ul class="submenu overflow-hidden rounded-sm h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002
                                         min-w-max duration-300">
                                        <li class="my-3" *ngFor="let subblink of sublink?.links">
                                            <div class="flex justify-between w-full items-start">
                                                <a (click)="emitFilterData(subblink)" [ngClass]="'store-cat' + subblink?.id"
                                                    class="text-dark font-Lato-Regular general-store-category cursor-pointer font-normal text-base capitalize transition-all">
                                                    {{subblink?.text | translate}}
                                                    <span
                                                        class="inline-flex items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium text-white bg-yellow-#FFA101 rounded-full">
                                                        {{subblink?.ammount}}</span>
                                                </a>
                                                <img *ngIf="subblink?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                                      w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                                                    alt="">
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>


                <app-price-range (change)="findProductsByFilter($event)"></app-price-range>
            </div>

            <div class="relative flex flex-col overflow-hidden box-border
            us:w-full
            us-landscape:w-full
            lg-landscape:w-933px lg-landscape:px-0">
                <div class="relative w-full">
                    <div *ngIf="products?.length !== 0;"
                        class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-4">
                        <app-product-card *ngFor="let product of products" [productId]="product.id"
                            [slug]="product.slug" [imageUrl]="product?.imagesDto?.image600" [name]="product.name"
                            [compare_at_price]="product.previous_price" [price]="product.defaultPrice"
                            [currency]="product.currency" [isLowStock]="product.isLowStock"
                            [quantity]="product.quantity" [discount]="product.discount"
                            [variantId]="product?.defaultVariantId" [data_extra_types]="product.extraData"
                            [shipping_category]="product?.deliveryTime" [store]="product.store" [resize]="true"
                            [turn]="true" [top]="product.top" [age_valid]="product?.ageValid"
                            [queryId]="product.queryId" [queryPosition]="product.queryPosition"
                        >
                        </app-product-card>
                    </div>
                    <div class="relative items-center justify-self-center h-auto w-full us:col-span-1 us-landscape:col-span-3 md:col-span-3 lg-landscape:col-span-3"
                        *ngIf="searchSkeletons.length === 0 && products?.length === 0">
                        <p class="us:text-lg lg-landscape:text-2xl text-gray-#727272 font-Lato-Regular font-semibold">
                            {{'NO_RESULTS_FOUND' |
                            translate}}</p>
                    </div>
                    <div
                        class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4 mt-4">
                        <app-product-card-skeleton *ngFor="let skeleton of searchSkeletons" [resize]="true"
                            [turn]="true">
                        </app-product-card-skeleton>
                    </div>
                </div>
                <div class="realtive w-full flex justify-center" *ngIf="this.next_page && products?.length !== 0">
                    <button
                        class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                    us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:mt-5
                    lg-landscape:h-41px lg-landscape:w-250px lg-landscape:text-19px lg-landscape:mt-10 lg-landscape:ml-0 lg-landscape:px-4"
                        (click)="loadMore()" [disabled]="searchSkeletons.length !== 0">
                        {{'LOAD_MORE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
