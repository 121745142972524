<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px md:max-h-max
md-landscape:w-528px md-landscape:max-h-max
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px">
  <p class="relative uppercase font-Lato-Regular font-semibold
    us:text-sm us:tracking-1.75px
    lg-landscape:text-sm lg-landscape:tracking-1.75px">{{'HELLO' | translate}}</p>
  <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
  <div class="relative flex justify-start">
    <div class="relative
        us:w-auto
        lg-landscape:w-full">
      <p class="relative w-full font-Lato-Regular font-bold
                      us:text-sm us:leading-5 us:mt-3
                      us-landscape:text-sm
                      md:text-base
                      md-landscape:text-base
                      lg-landscape:text-base lg-landscape:leading-5 lg-landscape:mt-7">{{'ZONE_TEXT_1' | translate}}
      </p>
      <p class="relative w-full font-Lato-Regular
                      us:text-sm us:leading-5 us:mt-3
                      us-landscape:text-sm
                      md:text-base
                      md-landscape:text-base
                      lg-landscape:text-base lg-landscape:leading-5 lg-landscape:mt-3">{{'ZONE_TEXT_2' | translate}}
      </p>
    </div>
    <img class="relative" src="" alt="">
  </div>
  <form class="relative w-full grid
                us:grid-cols-1 us:gap-y-6 us:mt-6
                us-landscape:grid-cols-1 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-1 md:gap-x-1.5 md:mt-10
                 lg-landscape:grid-cols-1 lg-landscape:gap-x-6px lg-landscape:mt-7 " [formGroup]="zoneForm">
    <!--        <div class="relative w-full">-->
    <!--            <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px-->
    <!--            us:text-15px us:ml-3 us:mb-1-->
    <!--            md:mb-2.5-->
    <!--            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">País*</p>-->
    <!--            <select class="relative font-Lato-Regular w-full-->
    <!--            us:text-15px us:h-12 us:rounded-3px-->
    <!--            us-landscape:h-auto-->
    <!--            md:h-auto-->
    <!--            lg-landscape:text-15px" name="" id="" value="">-->
    <!--                <option value="null">Seleccione</option>-->
    <!--            </select>-->
    <!--        </div>-->


    <div class="relative w-full">
      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for=""></p>
      <mat-select class="relative font-Lato-Regular w-full
                  us:text-15px us:h-12 us:rounded-3px
                  us-landscape:h-auto
                  md:h-auto
                  lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
            focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'SELECT' | translate}}" formControlName="area"
            *ngIf="!isLoadingAreas"
      >
        <mat-option *ngFor="let area of areas" [value]="area.iso">{{area.iso_name}}</mat-option>
      </mat-select>
      <div *ngIf="isLoadingAreas" class="relative flex flex-col flex-1 justify-start pb-6">
        <div class="bg-gray-#f2f2f2 flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="relative w-full
                us:grid-cols-1 us:gap-y-3 us:mt-0
                us-landscape:grid-cols-2 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-2 md:gap-x-1.5 md:mt-6
                 lg-landscape:grid-cols-2 lg-landscape:gap-x-6px lg-landscape:mt-5"
         [ngClass]="{'grid': area === 'CU'}"
    >
      <div *ngIf="!isLoadingZones && !isLoadingAreas" class="relative w-full pb-6">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
            us:text-15px us:ml-3 us:mb-1
            md:mb-2.5
            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'ZONE' | translate}}*</p>
        <mat-select required class="relative font-Lato-Regular w-full
            us:text-15px us:h-auto us:rounded-3px
            us-landscape:h-auto
            md:h-auto
            lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
            focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'SELECT' | translate}}" formControlName="zone">
          <mat-option *ngFor="let zon of zones" [value]="zon">{{zon.name}}</mat-option>
        </mat-select>
        <div *ngIf="zone?.invalid && (zone?.dirty || zone?.touched)" class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="zone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>
      <div *ngIf="isLoadingZones && !isLoadingAreas" class="relative flex flex-col flex-1 justify-start pb-6">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'ZONE' | translate}}*
        </p>
        <div class="bg-gray-#f2f2f2 flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>
      </div>


      <div class="relative w-full pb-6" *ngIf="!isLoadingMunicipalities && area === 'CU' && !isLoadingAreas">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
            us:text-15px us:ml-3 us:mb-1
            md:mb-2.5
            lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'MUNICIPALITY' | translate}}*</p>
        <mat-select class="relative font-Lato-Regular w-full
            us:text-15px us:h-12 us:rounded-3px
            us-landscape:h-auto
            md:h-auto
            lg-landscape:text-15px px-4 py-3 bg-gray-#f2f2f2
            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
            focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1
            " placeholder="{{'SELECT' | translate}}" formControlName="municipality">
          <mat-option *ngFor="let mun of municipalities" [value]="mun">{{mun.name}}</mat-option>
        </mat-select>
        <div *ngIf="(municipality?.invalid && (municipality?.dirty || municipality?.touched)) || (municipalities.length == 0 && zone?.valid)"
          class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="municipalities.length && municipality?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          <p *ngIf="municipalities.length == 0">{{ 'EMPTY_OPTION_LIST' | translate }}</p>
        </div>
      </div>
      <div *ngIf="isLoadingMunicipalities && area === 'CU' && !isLoadingAreas" class="relative flex flex-col flex-1 justify-start pb-6">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:ml-3 us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:ml-18px lg-landscape:mb-3" for="">{{'MUNICIPALITY' | translate}}*
        </p>
        <div class="bg-gray-#f2f2f2 flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>
      </div>
    </div>
  </form>

  <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-7 lg-landscape:justify-end">
    <button class="relative bg-yellow-#FFC35D flex items-center
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button"
      [ngClass]="{
            'opacity-50': zoneForm.invalid || isChangingZone,
            'cursor-not-allowed': zoneForm.invalid || isChangingZone,
            'cursor-pointer': zoneForm.valid || !isChangingZone,
            'us:w-116px lg-landscape:w-116px': isChangingZone
        }"
      [disabled]="zoneForm.invalid|| isChangingZone" (click)="onSubmit()">
      <img class="relative
            us:w-22% us:ml-2
         lg-landscape:w-22% lg-landscape:ml-2" src="../../../assets/menu/icon-location.svg" alt="">
      <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         lg-landscape:text-15px lg-landscape:leading-4">{{'ACCEPT' | translate}}</p>
        <fa-icon *ngIf="isChangingZone"
                 class="relative flex justify-center items-center animate-spin rounded-full w-9 h-9 text-xl text-Lato-Bold"
                 [icon]="faSpinner">
        </fa-icon>
    </button>

    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
      mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'CANCEL' | translate}}</p>
    </button>
  </div>
</section>
