export const environment = {
  production: false,
  name: 'dev_cloud',
  api_channel: 'dev.api.katapulk.com',
  base_route: 'https://dev.api.katapulk.com',
  v1: '/api/v1',
  v2: '/api/v2',

  token_resource: '/spree_oauth/token',
  refresh_token_resource: '/spree_oauth/token',
  recaptcha: {
    siteKey: '6LeZN4YhAAAAAIusZxPjoJlrvuvKivJMUxosGSpC'
  },
  promotion_images_url: "https://katapulk-banners.s3.amazonaws.com/banners.json",
  promotion_images_url_country_iso: "https://katapulk-banners-dev.s3.amazonaws.com/{country_iso}/banners.json",
  cart_time: 60,
  default_store: 1,
  default_store_code: 'mercado-unico',
  fingerprint_endpoint: 'https://fku.katapulk.com',
  fingerprint_api_key: 'Ldn2fEkae4kqrrgFKXK6',
  sentry_dsn: 'https://09275ff967d349cd95ffc8799b60f3b2@o4504119300063232.ingest.sentry.io/4504119794270208',
  isLocal: false,
  useLookUp: false,
  cacheExpirationTime: 10, // minutes
  wallet_api_url: 'https://devapi-services.katapulk.com',
  xApiClient: '63eacf1eac1e33221cf28f18',
  retry_on_fail: 2,
  mqtt: {
    server: 'dev.broker.katapulk.com',
    port: 8084,
    protocol: 'wss',
    path: '/mqtt'
  },
  strapi_route: 'https://strapi.katapulk.com',
  strapi_config_service: 'https://strapi.katapulk.com',
  remittance_api_url: 'https://api-services.katapulk.com',
  services_api_url: 'https://devapi-services.katapulk.com',
  base_app_route: 'https://dev.katapulk.com',
  smart_search_url: 'https://devapi-services.katapulk.com',
  captcha_key: '3x00000000000000000000FF',
  new_cart_verification_time_in_hours: 0.03,
  auth_resource: 'https://dev.auth.katapulk.com',
  payments_resource: 'https://dev.payments.katapulk.com',
};
