export const profile: any = {
    "headers": [
        {
            "text": "WELCOME"
        }
    ],
    "links": [
        {
            "text": "YOUR_ACCOUNT",
            "route": "/profile/account",
            "links": []
        },
        {
            "text": "ORDERS",
            "route": "/profile/orders",
            "links": []
        },
        // {
        //     "text": "Pagos",
        //     "route": "/profile/payments/123456",
        //     "links": []
        // },
        {
            "text": "PROFILE_PASS",
            "route": "/profile/user",
            "links": []
        },
        {
            "text": "PROFILE_ADDRESS",
            "route": "/profile/address/123456",
            "links": []
        },
        {
            "text": "PROFILE_CARDS",
            "route": "/profile/payments/cards",
            "links": []
        },
        {
            "text": "PROFILE_PAYMENT_REQUESTS",
            "route": "/profile/payment-requests",
            "links": []
        },
        // {
        //     "text": "Tus Recargas",
        //     "route": "/profile/recharges",
        //     "links": []
        // },
        // {
        //     "text": "Favoritos",
        //     "route": "",
        //     "links": []
        // },

        // {
        //     "text": "PROFILE_MESSAGES",
        //     "route": "/profile/messages",
        //     "links": []
        // },
        {
            "text": "CUSTOMER_SERVICE",
            "route": "/contents/customer-support",
            "links": []
        },
    ]
}
