import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validator, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-phone-recharge-selection',
  templateUrl: './phone-recharge-selection.component.html',
  styleUrls: ['./phone-recharge-selection.component.scss']
})
export class PhoneRechargeSelectionComponent implements OnInit {

  phoneForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<PhoneRechargeSelectionComponent>,
  ) {
    this.phoneForm = new FormGroup({
      phone: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(8),
          Validators.pattern(/^[0-9 ()+-]*$/)
        ]
      }),
    });
  }

  ngOnInit(): void {
  }


  get phone() { return this.phoneForm.get('phone'); }

  onSubmit() {
    const values = this.phoneForm.value;
    this.dialogRef.close({modified: true, data: values.phone});

  }

}
