import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable, retry, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { environment } from "../../../environments/environment";
import { NotificationService } from "./notification.service";
import { TranslateService } from "@ngx-translate/core";

export enum FRONTEND_FEATURE {
    GIFT_CARD_VERIFICATION = 'GiftCardOrder.VerificationRequired',
    ORDERS_CART_V3 = 'Orders.CartV3',
    PAYMENT_KATAPULK_PAYMENTS = 'Payment.KatapulkPayments',
}

@Injectable({
    providedIn: 'root'
})
export class FrontendFeatureService {

    private resource: string = `${environment.wallet_api_url}${environment.v1}/Shared/FrontendFeatures`;
    maxRetries = environment.retry_on_fail;

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private authService: AuthService,
    ) { }

    private getHeader(): HttpHeaders {
        let httpHeaders = this.authService.getHeader();
        httpHeaders = httpHeaders
            .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', this.translateService.currentLang);
        return httpHeaders;
    }

    private handleErrors(error: any, showError: boolean = true): any {
        const msg = error?.error?.error || error?.error?.message || null;
        this.showError(msg, showError);

        window.scrollTo(0, 0);
        return throwError(error);
    }

    private showError(msg: string, show: boolean): void {
        if (show && msg) {
            this.notificationService.showAndSubscribe(msg, 'CLOSE');
        } else {
            if (show) {
                this.notificationService.showAndSubscribe('GENERIC_ERROR', 'CLOSE');
            }
        }
    }

    getFrontendFeature(feature: FRONTEND_FEATURE | null = null): Observable<any> {
        let httpParams = new HttpParams();
        if (feature) {
            httpParams = httpParams.set('Key', feature);
        }
        return this.http.get(`${this.resource}`, { headers: this.getHeader(), params: httpParams })
            .pipe(
                map((response: any) => {
                    if (response.fails) {
                        this.showError(response.message, true);
                    }
                    return response.data;
                }),
                retry(this.maxRetries),
                catchError((error) => {
                    return this.handleErrors(error, true);
                })
            );
    }
}
