import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  url: string = '';
  grid: any;

  destroy$: Subject<boolean> = new Subject<boolean>();


    constructor(
    private dynamicService: DynamicCOnfigService
  ) {
  }

  ngOnInit(): void {
    this.getDynamicSettings();
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
        let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
        this.grid = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.MiscQueries.data[0].attributes;
    }
    this.dynamicService.dynamic_settings_subject
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
        let aux: any = response['HOME_SETTINGS'];
        this.grid = aux?.data[0]?.attributes?.MiscQueries.data[0].attributes;
    });
    window.scroll({ top: 0 });
  }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

  getDynamicSettings() {
    if (!localStorage.getItem('GLOBAL_CONFIGS')) return this.url = '../../../../../assets/imgs/404-img.png';
    return this.findImageInGlobalSettings();
  }

  findImageInGlobalSettings() {
    let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
    const GLOBAL_IMAGES = JSON.parse(aux)['FRONT_SETTINGS']?.data[0]?.attributes?.global_images?.data;
    const IMAGE = GLOBAL_IMAGES.find((image: any) => image?.attributes?.Key == '404-img');
    this.url = IMAGE?.attributes?.Image?.data?.attributes?.url
  }

}
