import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { BehaviorSubject, empty, EMPTY, Observable, switchMap, take, throwError } from "rxjs";
import { catchError, filter } from "rxjs/operators";
import { Token } from "../models";
import { Router } from "@angular/router";
import { CONFIG } from "../../../assets/config";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  excludedResources = [
    '/storefront/products',
    '/multi_stores',
    '/banners.json',
    '/storefront/countries',
    '/zones/zones_with_countries',
    '/municipality/get_all_municipalities',
    '/storefront/grouped_stores',
    '/strapi.katapulk.com/api',
    '/Catalog/Search/Products',
    '/GetBoughtTogether',
    '/GetRelatedProducts',
    '/frontend-settings',
    '/country-homes'
  ];

  excludedResourcesFor401 = ['/spree_oauth/token'];

  constructor(public authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excluded = this.excludedResources.some((re) => request.url.includes(re));

    if (this.authService.getCurrentToken() && !excluded) {
      request = this.addToken(request, this.authService.getCurrentToken());
    }

    request = this.setWebHeaders(request);

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && error.headers.get('reason') === 'Invalid/Expired Token') {
        return this.handleAuthenticationError(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private setWebHeaders(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'X-K-App': '1',
        'X-K-Ver': CONFIG.version
      }
    });
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((resp) => {
          this.isRefreshing = false;
          const access_token = resp.data?.tokens?.accessToken;
          this.refreshTokenSubject.next(access_token!);
          return next.handle(this.addToken(request, access_token!));
        }),
        catchError((error) => {
          // todo notificar que ha expirado la session
          this.isRefreshing = false;
          if (error.status !== 404) {
            this.authService.logout();
            this.router.navigate(['/auth/signIn']);
          }
          return EMPTY;
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
