<!--w-220px-->
<div class="flex bg-white box-border cursor-pointer flex-col sm:flex-col justify-between p-17px gap-4"
    [ngClass]="{'w-220px': !resize, 'us:flex-row h-auto sm:h-362px': turn, 'h-362px': !turn }">
    <div class="relative animate-pulse"
        [ngClass]="{'h-100px w-100px sm:w-full sm:h-149px': turn, 'w-full h-149px': !turn}">
        <div class="relative object-cover bg-gray-300"
            [ngClass]="{'h-100px w-100px sm:w-full sm:h-149px': turn, 'w-full h-149px': !turn}" alt="">
        </div>
    </div>
    <div class="flex flex-col animate-pulse"
        [ngClass]="turn == true ? 'us:w-64% us-landscape:w-full md:w-full lg-landscape:w-full' : 'w-full'">
        <div class="relative w-full h-4 my-2 bg-gray-300"></div>
        <div class="relative w-full h-4 my-2 bg-gray-300"></div>
        <div class="relative w-full h-4 my-2 bg-gray-300"></div>
        <div class="relative flex justify-between w-full h-5 my-2">
            <div class="relative w-2/4 h-full rounded-full bg-gray-300"></div>
            <div class="relative w-5 h-5 rounded-full bg-gray-300"></div>
        </div>
    </div>
</div>