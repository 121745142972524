import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-card-skeleton',
  templateUrl: './product-card-skeleton.component.html',
  styleUrls: ['./product-card-skeleton.component.scss']
})
export class ProductCardSkeletonComponent implements OnInit {
  @Input() resize: boolean = false;
  @Input() turn: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
