<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-auto us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-auto md:max-h-max
md-landscape:w-auto md-landscape:max-h-max
lg-landscape:w-168px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px lg-landscape:pt-0 flex-col justify-center rounded-3px">
  <div class="relative w-full
                flex flex-col items-center justify-center gap-2 lg-landscape:mt-7">
            <div class="relative items-center justify-center">
              <qr-code [value]="joinedValues" centerImageSrc="../../../favicon.ico" centerImageSize="24"></qr-code>
            </div>


      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:mb-3">
        {{title | translate}}
      </p>

      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:mb-3">
        {{qrValue | translate}}
      </p>
  </div>
</section>
