<form #section class="relative bg-gray-#f2f2f2 overflow-y-auto rounded-3px
  us:w-90vw us:max-h-81vh us:pb-20
  us-landscape:w-auto us-landscape:h-screen us-landscape:pb-14
  md:w-673px md:h-auto
  md-landscape:w-673px md-landscape:h-auto
  lg-landscape:w-673px lg-landscape:h-auto lg-landscape:pb-5
  xl-landscape:w-673px xl-landscape:h-auto xl-landscape:pb-5" [formGroup]="cardForm">
  <div class="relative bg-white w-full flex items-center h-55px pl-23px pr-23px">
    <p class="relative font-Lato-Regular font-bold text-gray-#252525
        text-17px">
      {{(isRevalidate ? 'REVALIDATE_PAYMENT_CARD' : (isModeEdit ? isFilterByDebits ? 'EDIT_DEBIT_CARD' : 'EDIT_CREDIT_CARD' : isFilterByDebits ? 'ADD_DEBIT_CARD' : 'ADD_CREDIT_CARD')) | translate}}:
    </p>
  </div>

  <div [ngClass]="{'pb-18px': isModeEdit}" class="relative w-full flex justify-between items-start pl-23px pr-23px pt-18px">
    <div class="flex flex-col flex-1 gap-1">
      <div *ngIf="!isRevalidate" class="flex flex-col xl:flex-row gap-1 xl:gap-2">
        <div *ngIf="!isModeEdit" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'CARD_NAME' | translate }}*" formControlName="name" id="name"
                 [ngClass]="{
                   'border-none': name?.valid || (name?.invalid && !(name?.dirty || name?.touched)),
                   'border-red-500': (name?.invalid && formMarkAsTouched) || (name?.invalid && (name?.dirty || name?.touched))
                 }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="name?.invalid && (name?.dirty || name?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="name?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="!name?.hasError('required') && name?.hasError('pattern')">{{ 'INVALID_FIELD' | translate }}</p>
          </div>
        </div>
        <div *ngIf="!isModeEdit" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'CARD_LASTNAME' | translate }}*" formControlName="lastname" id="lastname"
                 [ngClass]="{
                   'border-none': lastname?.valid || (lastname?.invalid && !(lastname?.dirty || lastname?.touched)),
                   'border-red-500': (lastname?.invalid && formMarkAsTouched) || (lastname?.invalid && (lastname?.dirty || lastname?.touched))
                 }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="lastname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="!lastname?.hasError('required') && lastname?.hasError('pattern')">{{ 'INVALID_FIELD' | translate }}</p>
          </div>
        </div>
      </div>

      <div *ngIf="!isModeEdit" class="flex flex-1 flex-col relative pb-6">
        <input placeholder="{{ 'CARD_NUMBER' | translate }}*" formControlName="number" id="number"
               (keyup)="keyupNumberCard($event)" [maxLength]="largeMaxLength"
               [ngClass]="{
                   'border-none': number?.valid || (number?.invalid && !(number?.dirty || number?.touched)),
                   'border-red-500': (number?.invalid && formMarkAsTouched) || (number?.invalid && (number?.dirty || number?.touched))
                }"
               class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                 focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
        <img *ngIf="cardLogo != '' && number?.value && number?.value?.length" class="absolute w-10 right-2 top-3"
             [src]="cardLogo" id="logo" alt="">
        <div *ngIf="number?.invalid && (number?.dirty || number?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="number?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          <p *ngIf="number?.hasError('pattern')">{{ 'ONLY_NUMBERS' | translate }}</p>
          <p *ngIf="!number?.hasError('pattern') && number?.hasError('checkLuhnError')">{{ 'INVALID_FORMAT' | translate }}</p>
        </div>
      </div>

      <div class="flex flex-col xl:flex-row gap-1 xl:gap-2">
        <div *ngIf="!isModeEdit" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'EXPIRATION_CARD' | translate }}*" formControlName="expiration" id="expiration"
                 (keypress)="allowNumbersAndSlash($event)" (keyup)="keyupExpirationCard($event)"
                 [ngClass]="{
                   'border-none': expiration?.valid || (expiration?.invalid && !(expiration?.dirty || expiration?.touched)),
                   'border-red-500': (expiration?.invalid && formMarkAsTouched) || (expiration?.invalid && (expiration?.dirty || expiration?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="(expiration?.invalid || wrongDate) && (expiration?.dirty || expiration?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="expiration?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="wrongDate || expiration?.hasError('pattern')">{{ 'INVALID_DATE' | translate }}</p>
          </div>
        </div>
        <div *ngIf="!isModeEdit" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'CVV' | translate }}*" formControlName="verification_value" [maxLength]="4"
                 id="verification_value"
                 [ngClass]="{
                   'border-none': verification_value?.valid || (verification_value?.invalid && !(verification_value?.dirty || verification_value?.touched)),
                   'border-red-500': (verification_value?.invalid && formMarkAsTouched) || (verification_value?.invalid && (verification_value?.dirty || verification_value?.touched))
                 }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="verification_value?.invalid && (verification_value?.dirty || verification_value?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="verification_value?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="verification_value?.hasError('pattern')">{{ 'ONLY_NUMBERS' | translate }}</p>
            <p *ngIf="verification_value?.hasError('minlength') && !verification_value?.hasError('pattern')">
              {{ 'MIN_LENGTH' | translate: {minLength: 3} }}
            </p>
            <p *ngIf="verification_value?.hasError('maxlength') && !verification_value?.hasError('pattern')">
              {{ 'MAX_LENGTH' | translate: {maxLength: 4} }}
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="!isRevalidate" class="flex flex-1 flex-col relative pb-6">
          <textarea placeholder="{{ 'ADDRESS' | translate }}*" formControlName="addressLine" id="addressLine"
                    [ngClass]="{
                     'border-none': addressLine?.valid || (addressLine?.invalid && !(addressLine?.dirty || addressLine?.touched)),
                     'border-red-500': (addressLine?.invalid && formMarkAsTouched) || (addressLine?.invalid && (addressLine?.dirty || addressLine?.touched))
                    }"
                    class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                 focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base">
          </textarea>
        <div *ngIf="addressLine?.invalid && (addressLine?.dirty || addressLine?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="addressLine?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>

      <div *ngIf="!isRevalidate" class="flex flex-1 flex-col gap-1 xl:gap-2 xl:flex-row">

        <div *ngIf="!isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
          <mat-select placeholder="{{ 'COUNTRY' | translate }}*" formControlName="country_iso" required
                      [ngClass]="{
                       'border-none': country_iso?.valid || (country_iso?.invalid && !(country_iso?.dirty || country_iso?.touched)),
                       'border-red-500': (country_iso?.invalid && formMarkAsTouched) || (country_iso?.invalid && (country_iso?.dirty || country_iso?.touched))
                      }"
                      (selectionChange)="changeCountry($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
            <mat-option *ngFor="let country of countries" [value]="country.iso">{{country.name}}</mat-option>
          </mat-select>
          <div *ngIf="country_iso?.invalid && (country_iso?.dirty || country_iso?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="country_iso?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>

        <div *ngIf="isLoadingCountries" class="relative flex flex-1 justify-start items-center pb-6">
          <div
            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
            {{ 'COUNTRY' | translate }}*
          </div>
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>

        <div *ngIf="!isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
          <mat-select placeholder="{{ 'STATE_OR_PROVINCE' | translate }}*" formControlName="state_name" required
                      [ngClass]="{
                       'border-none': state_name?.valid || (state_name?.invalid && !(state_name?.dirty || state_name?.touched)),
                       'border-red-500': (state_name?.invalid && formMarkAsTouched) || (state_name?.invalid && (state_name?.dirty || state_name?.touched))
                      }"
                      (selectionChange)="changeState($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
            <mat-option *ngFor="let state of states" [value]="state.abbr">{{state.name}}</mat-option>
          </mat-select>
          <div *ngIf="state_name?.invalid && (state_name?.dirty || state_name?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="state_name?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>

        <div *ngIf="isLoadingStates" class="relative flex flex-1 justify-start items-center pb-6">
          <div
            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
            {{ 'STATE_OR_PROVINCE' | translate }}*
          </div>
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>

        <!--              <div class="flex flex-1 flex-col relative pb-6">-->
        <!--                <input placeholder="{{ 'CITY' | translate }}*" formControlName="city" id="city"-->
        <!--                       class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0-->
        <!--                h-47px rounded-3px pl-4 text-base" type="text">-->
        <!--                <div *ngIf="city?.invalid && (city?.dirty || city?.touched)" class="absolute bottom-0.5 text-sm text-red-500">-->
        <!--                  <p *ngIf="city?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>-->
        <!--                </div>-->
        <!--              </div>-->

        <div *ngIf="!isLoadingCities && showSelectInput" class="relative flex flex-1 justify-start items-center pb-6">
          <mat-select placeholder="{{ 'CITY' | translate }}*" formControlName="city" required
                      [ngClass]="{
                       'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                       'border-red-500': (city?.invalid && formMarkAsTouched) || (city?.invalid && (city?.dirty || city?.touched))
                      }"
                      (selectionChange)="changeCity($event)" class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                          focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
            <mat-option *ngFor="let city of cities" [value]="city.name">{{city.name}}</mat-option>
          </mat-select>
          <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="city?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>

        <div *ngIf="!isLoadingCities && !showSelectInput" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'CITY' | translate }}*" formControlName="city" id="city" required
                 [ngClass]="{
                   'border-none': city?.valid || (city?.invalid && !(city?.dirty || city?.touched)),
                   'border-red-500': (city?.invalid && formMarkAsTouched) || (city?.invalid && (city?.dirty || city?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="city?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>

        <div *ngIf="isLoadingCities" class="relative flex flex-1 justify-start items-center pb-6">
          <div
            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                          focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
            {{ 'CITY' | translate }}*
          </div>
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>
      </div>

      <div *ngIf="!isRevalidate" class="flex flex-1 flex-col xl:flex-row gap-0 xl:gap-2">
        <div *ngIf="!isLoadingCities && showSelectInput" class="relative flex flex-1 justify-start items-center pb-6">
          <mat-select placeholder="{{ 'POSTAL_CODE' | translate }}*" formControlName="zipcode" required
                      [ngClass]="{
                       'border-none': zipcode?.valid || (zipcode?.invalid && !(zipcode?.dirty || zipcode?.touched)),
                       'border-red-500': (zipcode?.invalid && formMarkAsTouched) || (zipcode?.invalid && (zipcode?.dirty || zipcode?.touched))
                      }"
                      class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
            <mat-option *ngFor="let postalCode of postalCodes" [value]="postalCode">{{postalCode}}</mat-option>
          </mat-select>
          <div *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="zipcode?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="zipcode?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>
          </div>
        </div>

        <div *ngIf="!isLoadingCities && !showSelectInput" class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'POSTAL_CODE' | translate }}*" formControlName="zipcode" id="zipcode" required
                 [ngClass]="{
                   'border-none': zipcode?.valid || (zipcode?.invalid && !(zipcode?.dirty || zipcode?.touched)),
                   'border-red-500': (zipcode?.invalid && formMarkAsTouched) || (zipcode?.invalid && (zipcode?.dirty || zipcode?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="zipcode?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="zipcode?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>
          </div>
        </div>

        <div *ngIf="isLoadingCities" class="relative flex flex-1 justify-start items-center pb-6">
          <div
            class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
            {{ 'POSTAL_CODE' | translate }}*
          </div>
          <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
          <div *ngIf="!isLoadingPhones" class="flex">
            <ngx-mat-intl-tel-input
              [ngClass]="{
                  'border-none': phone?.valid || (phone?.invalid && !(phone?.dirty || phone?.touched)),
                  'border-red-500': (phone?.invalid && formMarkAsTouched) || (phone?.invalid && (phone?.dirty || phone?.touched))
              }"
              class="border mat-intl-tel-input-class h-47px relative bg-white rounded-3px flex flex-1 items-center"
              [onlyCountries]="[countryIsoSelected]"
              inputPlaceholder="{{'PHONE' | translate}}*"
              [enablePlaceholder]="true"
              name="phone"
              formControlName="phone"
              (countryChanged)="changePhone($event)">
            </ngx-mat-intl-tel-input>
            <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
                 class="absolute bottom-0.5 text-sm text-red-500">
              <p *ngIf="phone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
              <p *ngIf="phone?.hasError('validatePhoneNumber')">{{ 'INVALID_PHONE_NUMBER' | translate }}</p>
            </div>
          </div>

          <div *ngIf="isLoadingPhones" class="relative flex flex-1 justify-start items-center">
            <div
              class="flex flex-1 pr-4 pl-100px py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1 text-gray-#727272">
              {{ 'PHONE' | translate }}*
            </div>
            <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
          </div>

        </div>
      </div>

    </div>

    <!--        <div class="relative w-120px">-->
    <!--            <p class="relative text-gray-#727272 font-Lato-Regular-->
    <!--            text-sm">{{'KATAPULK_CARD_TEXT' | translate}}:</p>-->

    <!--            <div class="relative grid grid-cols-2-->
    <!--            mt-15px">-->
    <!--                <img class="relative w-60px h-10 bg-red-200" src="" alt="">-->
    <!--                <img class="relative w-60px h-10 bg-red-200" src="" alt="">-->
    <!--                <img class="relative w-60px h-10 bg-red-200" src="" alt="">-->
    <!--                <img class="relative w-60px h-10 bg-red-200" src="" alt="">-->
    <!--            </div>-->
    <!--        </div>-->
  </div>

  <div *ngIf="!isModeEdit" class="relative w-full h-20 items-start pl-23px pr-23px flex justify-start">
    <app-captcha class="relative w-full" (onCaptchaCheck)="captchaCapture($event)"></app-captcha>
  </div>

  <div class="relative w-full flex justify-start items-center pl-23px pr-23px">
    <button class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px px-4"
            [ngClass]="{
              'opacity-50 cursor-not-allowed': (!isModeEdit && !is_human) || isLoading || isLoadingPhones || isLoadingAllCountries || isLoadingCountries || isLoadingCities || isLoadingStates,
              'pr-1': isLoading
            }"
            type="button" (click)="onSubmit()"
            [disabled]="(!isModeEdit && !is_human) || isLoading || isLoadingPhones || isLoadingAllCountries || isLoadingCountries || isLoadingCities || isLoadingStates">
      <p class="relative font-Lato-Bold text-15px leading-4">
        {{(isRevalidate ? 'REVALIDATE_YOUR_CARD' : isModeEdit ? 'EDIT_YOUR_CARD' : 'ADD_YOUR_CARD') | translate}}
      </p>
      <fa-icon *ngIf="isLoading"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D w-40 h-41px rounded-3px ml-2 hover:bg-gray-#EEEEEE"
            type="button" mat-dialog-close="close">
      <p class="relative font-Lato-Bold text-15px leading-4">{{'CANCEL' | translate}}</p>
    </button>
  </div>
</form>
