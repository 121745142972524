import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../shared/services/user.service";
import {catchError, map, takeUntil} from "rxjs/operators";
import {of, Subject, throwError} from "rxjs";
import {SelectedZone} from "../../../../shared/models";
import {ZonesService} from "../../../../shared/services/zones.service";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  confirmationToken = '';
  confirmed: boolean;
  error: string = '';
  hasError: boolean = false;
  responseOk: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private zonesService: ZonesService
  ) {
    this.confirmed =false;
  }

  ngOnInit(): void {
    this.confirmationToken = this.activeRoute.snapshot.params['register_token'];
    this.confirmUser(this.confirmationToken);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  navigateHome() {
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/home`]);
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  confirmUser(confirmationToken: string): void {
    if (confirmationToken) {
      this.userService.confirmUser(confirmationToken)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
        (response: {state?: string, error?: string}) => {
          if (response.error) {
            this.error = response.error;
            this.hasError = true;
          } else {
            this.responseOk = true;
          }
        },
        (error) => {
          this.error = error.error.error;
          this.hasError = true;
        }, () => {

          }
      );
    }
  }

}
