// import {ProductInCart} from "./product_in_cart.model";
import {CartStoreInterface} from "./checkout.model";
import {MessageInterface} from "./message.model";


export interface Cart {
    id: number;
    payment_state?: string;
    shipment_state?: string;
    token: string;
    state?: string;
    currency: string;
    number: string;
    total: number;
    item_total?: number;
    item_count: number;
    // items?: ProductInCart[];
    items?: Promise<CartStoreInterface[]>;
    tax_total?: number;
    ship_total?: number;
    created_at?: Date;
    updated_at?: Date;
    coupons?: string[];
    adjustment_total?: number;
    visitorId?: string;
    requestId?: string;
    expiration_date?: Date;
    messages?: MessageInterface[];
}
