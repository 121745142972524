<div class="flex flex-1 pb-7 relative">
  <div [ngClass]="formMarkAsTouched && !selectedAddress ? 'border-red-500' : 'border-gray-#D6D6D6'"
       class="flex flex-1 flex-col border">
    <div class="flex flex-1 flex-col" [ngClass]="{'border-b': i != addresses.length - 1}" *ngFor="let address of addresses; index as i">
      <div class="flex flex-col px-4 py-3 bg-white">
        <div class="flex flex-row items-center gap-2">
          <input [checked]="address.id === selectedAddress?.id"
                 (change)="emitAddress(address)"
                 class="relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
                 type="radio"
                 [name]="isShipping ? 'shipping-addresses' : 'billing-addresses'"
          >
          <!--      <p class="relative font-Lato-Regular text-gray-#727272 ml-3">-->
          <!--        {{ address.label! | translate }}-->
          <!--      </p>-->
          <!-- remove this part on any change, it's the same code as below     -->
          <div class="flex-flex-col">
            <p class="name-class flex flex-col font-Lato-Regular text-gray-#252525">
              {{ address.firstname + ' ' + address.lastname }}
              <span class="font-Lato-Regular font-normal text-gray-#5d5d5d break-words">
                {{ address.address1 + (address.address2 ? (', '+address.address2) : '') + ', ' + address.country_name + ', ' + address.state_name
              + ', ' + address.city + ', ' + address.zipcode }}
              </span>
            </p>

            <div class="flex flex-row">
              <p class="font-Lato-Regular cursor-pointer text-sky-700 text-sm underline" (click)="openEditAddressModal(address, i)">
                {{ 'EDIT_SAVED_ADDRESS' | translate }}</p>
            </div>
          </div>

        </div>

        <!--    <div class="flex flex-col ml-7 xl:flex-row leading-4">-->
        <!--      <p class="name-class font-Lato-Regular text-gray-#252525">-->
        <!--        {{ address.firstname + ' ' + address.lastname }}-->
        <!--        <span class="font-Lato-Regular font-normal text-gray-#5d5d5d ml-1">-->
        <!--                {{ address.address1 + ', ' + address.country_name + ', ' + address.state_name-->
        <!--        + ', ' + address.city + ', ' + address.zipcode }}-->
        <!--              </span>-->
        <!--      </p>-->
        <!--    </div>-->
<!--        <p class="text-gray-#727272 ml-6 font-Lato-Regular text-14px">-->
<!--          {{ address.email_receiver }}-->
<!--        </p>-->

<!--        <div class="flex flex-row ml-6">-->
<!--          <p class="font-Lato-Regular cursor-pointer text-sky-700 text-sm underline" (click)="openEditAddressModal(address, i)">-->
<!--            {{ 'EDIT_SAVED_ADDRESS' | translate }}</p>-->
<!--        </div>-->
      </div>
    </div>
  </div>

  <div *ngIf="formMarkAsTouched && !selectedAddress"
       class="absolute bottom-0.5 text-sm text-red-500">
    <p>{{ errorText | translate }}</p>
  </div>
</div>
