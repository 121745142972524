import {Component, OnInit, Inject} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {faClose, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";
import {SelectedZone} from "../../../../shared/models";
import {ZonesService} from "../../../../shared/services/zones.service";

@Component({
  selector: 'app-login-to-add-cart-dialog-component',
  templateUrl: "./login-to-add-cart-dialog.component.html",
  styleUrls: ["./login-to-add-cart-dialog.component.scss"],
})
export class LoginToAddCartDialogComponent implements OnInit {
  faClose = faClose;
  faSpinner = faSpinner;
  isLoading: boolean = false;
  title: string;
  message: string;
  messageBlack: boolean;
  isRedirectToWallet: boolean = false;
  subMessage: string;
  confirmAction: string;
  cancelAction: string;
  messageUppercase: string;
  redirectToUrl: string;

  constructor(
    private dialogRef: MatDialogRef<LoginToAddCartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private zonesService: ZonesService,
  ) {
    this.title = data.title;
    this.message = data.message ? data.message : "";
    this.messageUppercase = data.messageUppercase ? data.messageUppercase : false;
    this.messageBlack = !!data.messageBlack;
    this.subMessage = data.subMessage ? data.subMessage : "";
    this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
    this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
    this.isRedirectToWallet = !!data.isRedirectToWallet;
    this.redirectToUrl = data.redirectToUrl ? data.redirectToUrl : null;
  }

  ngOnInit(): void {}

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  onSubmit(): void {
    if (this.isRedirectToWallet) {
      const redirectURL = `/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`;
      this.router.navigate(['/auth/signIn'], { queryParams: { redirectURL: redirectURL } });
      this.close();
    } else {
      if (this.redirectToUrl) {
        this.router.navigate(['/auth/signIn'], { queryParams: { redirectURL: this.redirectToUrl } });
        this.close();
      } else {
        const redirectURL = this.router.url;
        this.router.navigate(['/auth/signIn'], { queryParams: { redirectURL: redirectURL } });
        this.close();
      }
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
