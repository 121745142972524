import { Component, Input, OnInit } from '@angular/core';
import { ControlBase } from '../controls-base';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multiline-input',
  templateUrl: './multiline-input.component.html',
  styleUrls: ['./multiline-input.component.scss']
})
export class MultilineInputComponent implements OnInit {
  @Input() meta!: ControlBase;
  @Input() form!: FormGroup;
  @Input() formMarkAsTouched!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  get selfControl() {
    return this.form.controls[`${this.meta.fieldId}`];
  }
}
