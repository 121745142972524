import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {OnfidoService} from "../services/onfido.service";

@Injectable({
  providedIn: 'root'
})
export class ClearRoutesStackGuard implements CanActivate {
  /**
   * Constructor
   */
  constructor(private onfidoService: OnfidoService,) {
  }

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.onfidoService.clearRouteStack();
    return of(true);
  }
}
