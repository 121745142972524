import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import {Observable, of, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot,
    ):
        | boolean
        | UrlTree
        | Promise<boolean | UrlTree>
        | Observable<boolean | UrlTree> {

        const urlTree = this.router.createUrlTree(["/auth/signIn"], {queryParams: {redirectURL: router.url}});
        const currentUser = this.authService.getCurrentUser();

        if (!currentUser) {
            return urlTree;
        } else {
            // Check the access token availability
            if (!this.authService.getCurrentToken()) {
                return urlTree;
            }

            // Check the access token expire date
            const tokenExpired = this.authService.isTokenExpired();
            if (tokenExpired && !this.authService.isAuthenticated()) {
                return urlTree;
            }

            // Check if the user is logged in
            if (!tokenExpired && this.authService.isAuthenticated()) {
                return of(true);
            }

            // If the access token exists, and it didn't expire, sign in using it
            return this.authService.refreshToken().pipe(
                switchMap(() => of(true)),
                catchError(() => of(urlTree))
            );
        }
    }
}
