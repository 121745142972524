import {Municipality} from "./municipality.model";
import {Store} from "./store.model";

export interface Zone {
    id: number;
    name: string;
    zoneableIds: number[];
    municipalities?: Municipality[];
    stores?: Store[];
    countries_iso?: string;
}
