export const countriesMock = {
    data: [
        {
            "id": "233",
            "type": "country",
            "attributes": {
                "iso": "US",
                "iso3": "USA",
                "iso_name": "UNITED STATES",
                "name": "United States",
                "states_required": true,
                "zipcode_required": true,
                "default": false,
                "available_for_shipping": true,
                "available_for_billing": true
            },
            "relationships": {}
        },
        {
            "id": "51",
            "type": "country",
            "attributes": {
                "iso": "CU",
                "iso3": "CUB",
                "iso_name": "CUBA",
                "name": "Cuba",
                "states_required": true,
                "zipcode_required": true,
                "default": false,
                "available_for_shipping": true,
                "available_for_billing": true
            },
            "relationships": {}
        }
    ]
};
