import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RemittanceService} from "../../../../../../shared/services/remittance.service";
// import { DropDownControl } from './dropdown control';

@Component({
  selector: 'app-dropdown-with-selection-dependant',
  templateUrl: './dropdown-with-selection-dependant.component.html',
})
export class DropdownWithSelectionDependantComponent implements OnInit, OnChanges {
  @Input() meta!: any; //DropDownControl
  @Input() form!: FormGroup;
  @Input() formMarkAsTouched!: boolean;
  @Input() selectedReceiverData: any[] = [];
  options: any[] = [];
  nestedOptions: { item1: string, item2: string }[] = [];

  field: any = null;
  constructor(private remittanceService: RemittanceService) {

  }

  ngOnChanges(changes: any): void {
    if (changes && changes.form) {
      if (this.meta && this.meta.selectionDependantNestedField) {
        this.selectChange(this.selfControl, true);
      }
    }
  }

  ngOnInit(): void {
    if (this.meta?.optionsToSelect) {
      this.options = this.meta.optionsToSelect;
    }

    if (this.meta && this.meta.selectionDependantNestedField) {
      this.selectChange(this.selfControl, true);
    }
  }

  selectChange(e: any, init: boolean = false) {
    this.field = null;
    if (this.meta.selectionDependantNestedField && this.meta.selectionDependantNestedField.length) {
      const field = this.getField(e.value);
      if (field && field.receiverFormFieldType === 'SingleLineText') {
        let value = '';
        if (init) {
          if (this.selectedReceiverData.length) {
            const objData = this.selectedReceiverData.find(it => it.key === field.fieldId);
            if (objData && objData.value) {
              value = objData.value;
            }
          }
        } else {
          this.removeControlsOld(field.fieldId);
        }
        this.addControl(field, value);
      }
      setTimeout(() => {
        this.field = field;
      }, 100)
    }
  }

  addControl(field: any, value: string = '') {
    const validators = [];
    if (field.required) {
      validators.push(Validators.required);
    }
    if (field.regexValidator && field.regexValidator !== '') {
      // Validators.pattern(/^[0-9\s]*$/)
      validators.push(Validators.pattern(field.regexValidator));
    }
    this.form.addControl(field.fieldId, new FormControl(value, validators));
    this.form.get(field.fieldId)?.updateValueAndValidity();
  }

  removeControlsOld(fieldId: string) {
    this.meta.selectionDependantNestedField.forEach((it: any) => {
      if (it && it.item2 && it.item2.fieldId && it.item2.fieldId !== fieldId) {
        this.form.removeControl(it.item2.fieldId)
      }
    });
  }

  getControlValue(controlId: string) {
    return this.form.controls[`${controlId}`].value;
  }

  getField(value: string) {
    const field = this.meta.selectionDependantNestedField.find((it: any) => it.item1 === value);
    if (field && field.item2) {
      return field.item2;
    } else {
      return null;
    }
  }

  get selfControl() {
    return this.form.controls[`${this.meta.fieldId}`];
  }

  get selfNestedFieldControl() {
    return this.form.controls[`${this.meta.nestedField.fieldId}`];
  }
}
