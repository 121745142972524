import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { of, retry, Subject, tap } from 'rxjs';
import { Product, Taxon } from "../models";
import { StoreService } from "./store.service";
import { catchError, map } from "rxjs/operators";
import { SEARCH_FILTER_DEFAULT_VALUE, SearchFiltersType } from "../filters/searc-filters";
import { environment } from "../../../environments/environment";
import { AuthService } from "./auth.service";
import { LanguageService } from "./language.service";
import { replaceParamsInUri } from "../utils/utils";
import { AlgoliaService } from "./algolia.service";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    product_search = new Subject<string>();

    private resource = '/storefront/products';
    private product_resource = 'api/v1/Catalog/Search/ProductDetails';

    constructor(
        private apiService: ApiService,
        private storeService: StoreService,
        private authService: AuthService,
        private languageService: LanguageService,
        private http: HttpClient,
        private algoliaService: AlgoliaService,
    ) {
    }


    /**
     zone_id: 6
     store_id: 1
     include: default_variant,variants,images,variants.images,variants.option_values,data_extra_types
     sort: { filter: string, filterBy: string, text: string }
     page: 1
     per_page: 12
     locale: es
     municipality: 37
     filters: {taxon?: number, price?: string, name?: string} | undefined
     */
    getProducts(
        page = 1,
        per_page = 10,
        zone_id = 6,
        store_id: number | number[] | undefined,
        municipality = 37,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types',
        sort: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE,
        filters?: { taxon?: number, price?: string, name?: string, sku?: string }
    ) {
        let httpParams = new HttpParams()
            .set('page', page)
            .set('per_page', per_page)
            .set('zone_id', zone_id)
            .set('municipality', municipality)
            .set('locale', locale)
            .set('include', include)
            .set('sort', sort.filter)
            .set('sort_by', sort.filterBy);

        if (filters) {
            if (filters.taxon) {
                httpParams = httpParams.set('filter[taxons]', filters.taxon);
            }
            if (filters.price) {
                httpParams = httpParams.set('filter[price]', filters.price);
            }
            if (filters.name) {
                httpParams = httpParams.set('filter[name]', filters.name);
            }
            if (filters.sku) {
                httpParams = httpParams.set('filter[skus]', filters.sku);
            }
        }
        if (store_id) {
            if (store_id instanceof Array) {
                httpParams = httpParams.set(`store_id`, store_id.join(','));
            } else {
                httpParams = httpParams.set('store_id', store_id);
            }
        }

        return this.apiService.get(this.resource, httpParams);
    }

    getProduct(
        id: number,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,taxons'
        // include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,taxons'
    ) {
        const httpParams = new HttpParams()
            .set('locale', locale)
            .set('include', include);
        return this.apiService.get(`${this.resource}/${id}`, httpParams);
    }

    globalSearchProduct(filter: string) {
        return this.product_search.next(filter);
    }

    getCatalogSearchProducts(url: string) {
        // let httpParams: HttpParams = new HttpParams({fromObject: data});
        return this.http.request(
            'GET',
            `${environment.wallet_api_url}${url}`,
            { headers: this.getHeader() }
        )
            .pipe(
                map((response: any) => {
                    console.log(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    searchProducts(
        page: number,
        per_page = 10,
        zone_id: number,
        // store_id = 1,
        municipality: number,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,relationships',
        sort: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE,
        filters?: { taxon?: number, price?: string, name?: string, sku?: string }
    ) {
        let httpParams = new HttpParams()
            .set('page', page)
            .set('per_page', per_page)
            .set('zone_id', zone_id)
            // .set('store_id', store_id)
            .set('municipality', municipality)
            .set('locale', locale)
            .set('include', include)
            .set('sort', sort.filter)
            .set('sort_by', sort.filterBy);

        if (filters) {
            if (filters.taxon) {
                httpParams = httpParams.set('filter[taxons]', filters.taxon);
            }
            if (filters.price) {
                httpParams = httpParams.set('filter[price]', filters.price);
            }
            if (filters.name) {
                httpParams = httpParams.set('filter[name]', filters.name);
            }
            if (filters.sku) {
                httpParams = httpParams.set('filter[skus]', filters.sku);
            }
        }
        return this.apiService.get(this.resource, httpParams);
    }

    searchByCategory(
        page = 1,
        per_page = 10,
        zone_id: number,
        // store_id = 1,
        municipality: number,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,relationships',
        sort: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE,
        filters?: { taxon?: number, price?: string, name?: string, sku?: string }
    ) {
        let httpParams = new HttpParams()
            .set('page', page)
            .set('per_page', per_page)
            .set('zone_id', zone_id)
            // .set('store_id', store_id)
            .set('municipality', municipality)
            .set('locale', locale)
            .set('include', include)
            .set('sort', sort.filter)
            .set('sort_by', sort.filterBy);
        if (filters) {
            if (filters.taxon) {
                httpParams = httpParams.set('filter[taxons]', filters.taxon);
            }
            if (filters.price) {
                httpParams = httpParams.set('filter[price]', filters.price);
            }
            if (filters.name) {
                httpParams = httpParams.set('filter[name]', filters.name);
            }
            if (filters.sku) {
                httpParams = httpParams.set('filter[skus]', filters.sku);
            }
        }

        return this.apiService.get(this.resource, httpParams);
    }

    getRelatedProducts(
        filters?: { taxon?: number, price?: string, name?: string },
        page = 1,
        per_page = 10,
        zone_id = 6,
        municipality = 37,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types',
        sort = '-updated_at'
    ) {
        let httpParams = new HttpParams()
            .set('page', page)
            .set('per_page', per_page)
            .set('zone_id', zone_id)
            .set('municipality', municipality)
            .set('locale', locale)
            .set('include', include)
            .set('sort', sort);

        if (filters) {
            if (filters.taxon) {
                httpParams = httpParams.set('filter[taxons]', filters.taxon);
            }
            if (filters.price) {
                httpParams = httpParams.set('filter[price]', filters.price);
            }
            if (filters.name) {
                httpParams = httpParams.set('filter[name]', filters.name);
            }
        }

        return this.apiService.get(this.resource, httpParams);
    }

    getProductBySlug(
        slug: string,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,taxons'
        // include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,taxons'
    ) {
        const httpParams = new HttpParams()
            .set('locale', locale)
            .set('include', include);
        // .set('filter[slug]', slug);
        return this.apiService.get(`${this.resource}/${slug}`, httpParams);
    }

    getProductsMapped(
        page = 1,
        per_page = 10,
        zone_id = 6,
        store_id: number | number[] | undefined,
        municipality = 37,
        locale = 'es',
        include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types',
        sort = '-updated_at',
        filters?: { taxon?: number, price?: string, name?: string, sku?: string }
    ) {
        let httpParams = new HttpParams()
            .set('page', page)
            .set('per_page', per_page)
            .set('zone_id', zone_id)
            .set('municipality', municipality)
            .set('locale', locale)
            .set('include', include)
            .set('sort', sort);

        if (filters) {
            if (filters.taxon) {
                httpParams = httpParams.set('filter[taxons]', filters.taxon);
            }
            if (filters.price) {
                httpParams = httpParams.set('filter[price]', filters.price);
            }
            if (filters.name) {
                httpParams = httpParams.set('filter[name]', filters.name);
            }
            if (filters.sku) {
                httpParams = httpParams.set('filter[skus]', filters.sku);
            }
        }
        if (store_id) {
            if (store_id instanceof Array) {
                httpParams = httpParams.set(`store_id`, store_id.join(','));
            } else {
                httpParams = httpParams.set('store_id', store_id);
            }
        }

        return this.apiService.get(this.resource, httpParams)
            .pipe(map((pdts): Product[] => (console.log({ pdts }), this.mapProductsFromResponse(pdts))));
    }

    /**
     *
     * @param pdts
     * @param prod
     * @param {boolean} allImages
     */
    mapProduct(pdts: any, prod: any, allImages: boolean = false): Product {
        let discount: any;
        let compare_at_price: number = 0;
        let previous_price: number = 0;
        if (allImages) {
            if (prod.data.attributes.compare_at_price !== null && Number(prod.data.attributes.compare_at_price) !== 0) {
                // discount = ((prod.data.attributes.compare_at_price - prod.data.attributes.price) * 100) / prod.data.attributes.compare_at_price;
                compare_at_price = Number(prod.data.attributes.compare_at_price);
            }
            if (prod.data.attributes.previous_price !== null && Number(prod.data.attributes.previous_price) !== 0) {
                discount = ((prod.data.attributes.previous_price - prod.data.attributes.price) * 100) / prod.data.attributes.previous_price;
                previous_price = Number(prod.data.attributes.previous_price);
            }
        } else {
            if (prod.attributes.compare_at_price !== null && Number(prod.attributes.compare_at_price) !== 0) {
                // discount = ((prod.attributes.compare_at_price - prod.attributes.price) * 100) / prod.attributes.compare_at_price;
                compare_at_price = Number(prod.attributes.compare_at_price);
            }
            if (prod.attributes.previous_price !== null && Number(prod.attributes.previous_price) !== 0) {
                discount = ((prod.attributes.previous_price - prod.attributes.price) * 100) / prod.attributes.previous_price;
                previous_price = Number(prod.attributes.previous_price);
            }
        }


        let allImagesUrl: string[] = [];
        let imagesUrl: string[] = [];
        let variantData;
        let variantsAux;
        let variants: Product[] = [];
        let imageUrl;

        const variantIds: { id: string, type: string }[] = [];

        if (allImages) {
            allImagesUrl = this.findImagesFromResponse(prod?.data.relationships?.images?.data, prod.included);
            variantData = prod?.data.relationships?.variants.data;
            variantsAux = prod?.included.filter((it: any) => it.type == 'variant');
        } else {
            variantData = prod?.relationships?.variants.data;
            variantsAux = pdts?.included.filter((it: any) => it.type == 'variant');
            imageUrl = this.findImageFromResponse(prod?.relationships?.images?.data[0]?.id, pdts.included);
        }

        if (variantData && variantData.length == 0) {
            variantsAux.forEach((it: any) => {
                variantIds.push({
                    id: it.id,
                    type: 'variant'
                });
            });
        }

        if (allImages) {
            variants = this.findVariantsFromResponse(variantData && variantData.length ? variantData : variantIds, prod.included);
        } else {
            variants = this.findVariantsFromResponse(variantData && variantData.length ? variantData : variantIds, pdts.included);
        }

        let variantsImages: any[] = [];
        variants.map((variant: Product) => variant.imagesUrl).forEach((vi) => {
            variantsImages = [...variantsImages, ...vi || []];
        });

        if (allImages) {
            imagesUrl = allImagesUrl.filter((iu) => variantsImages.findIndex((vi) => vi === iu) === -1);
            imageUrl = imagesUrl && imagesUrl.length ? imagesUrl[0] : variants && variants.length ? variants[0].imageUrl : '../../../../../assets/imgs/product-card/no-product-image.png';
        }

        if (variants.length) {
            const variantDefaultAux = variants.find(it => it.id == prod?.relationships?.default_variant?.data?.id);
            if (variantDefaultAux) {
                if (compare_at_price == 0) {
                    compare_at_price = Number(variantDefaultAux.compare_at_price);
                }
                if (previous_price == 0) {
                    previous_price = Number(variantDefaultAux.previous_price);
                }
            }
        }

        if (allImages) {
            return {
                id: prod.data.id,
                imageUrl,
                isFavorite: false,
                isLowStock: (!prod.data.attributes.in_stock && prod.data.attributes.purchasable),
                name: prod.data.attributes.name,
                currency: prod.data.attributes.currency,
                price: prod.data.attributes.price,
                inStock: prod.data.attributes.in_stock,
                quantity: 1,
                discount: {
                    hasDiscount: discount ? true : false,
                    labelLineThrough: discount ? previous_price : null,
                    labelPercent: discount
                },
                description: prod.data.attributes.description,
                variants,
                imagesUrl,
                categories: this.findTaxonsFromResponse(prod?.data.relationships?.taxons.data, prod.included),
                notes: prod.data.attributes.notes,
                slug: prod.data.attributes.slug,
                shipping_category: prod.data.attributes.shipping_category.name,
                data_extra_types: prod.data.relationships.data_extra_types.data,
                store: prod.data.attributes.store_ids ? this.storeService.getStoreById(prod.data.attributes.store_ids[0]) : of(undefined),
                top: prod.data.attributes.top,
                compare_at_price,
                previous_price,
                age_valid: prod.data.attributes.age_valid,
            };
        }

        const defaultVariant = this.findDefaultVariantFromResponse(prod?.relationships?.default_variant?.data?.id, pdts.included);

        return {
            id: prod.id,
            imageUrl,
            isFavorite: false,
            isLowStock: (!prod.attributes.in_stock && prod.attributes.purchasable),
            name: prod.attributes.name,
            currency: prod.attributes.currency,
            price: defaultVariant?.price,
            inStock: prod.attributes.in_stock,
            quantity: 1,
            discount: {
                hasDiscount: defaultVariant?.discount ? true : false,
                labelLineThrough: defaultVariant?.discount ? defaultVariant?.previous_price : null,
                labelPercent: defaultVariant?.discount
            },
            defaultVariant: prod?.relationships?.default_variant?.data?.id,
            shipping_category: prod.attributes?.shipping_category?.name,
            data_extra_types: prod.relationships.data_extra_types.data,
            slug: prod.attributes.slug,
            store: prod.attributes.store_ids ? this.storeService.getStoreById(prod.attributes.store_ids[0]) : of(undefined),
            top: prod?.attributes?.top,
            compare_at_price,
            previous_price: defaultVariant?.previous_price,
            meta_description: prod.attributes.meta_description,
            age_valid: prod?.attributes?.age_valid,
        };
    }

    mapProductsFromResponse(pdts: any): Product[] {
        return pdts.data.map((prod: any) => {
            return this.mapProduct(pdts, prod);
        });
    }

    private findVariantsFromResponse(variants: any[], dataArray: any[]) {
        if (variants.length !== 0) {
            return dataArray
                .filter(
                    (obj: any) => obj.type === 'variant' && variants.findIndex(variant => variant.id === obj.id) !== -1
                )
                .map(prod => {
                    let discount = 0;
                    let compare_at_price: number = 0;
                    let previous_price: number = 0;
                    if (prod.attributes.compare_at_price !== null && Number(prod.attributes.compare_at_price) !== 0) {
                        // discount = ((prod.attributes.compare_at_price - prod.attributes.price) * 100) / prod.attributes.compare_at_price;
                        compare_at_price = Number(prod.attributes.compare_at_price);
                    }
                    if (prod.attributes.previous_price !== null && Number(prod.attributes.previous_price) !== 0) {
                        discount = ((prod.attributes.previous_price - prod.attributes.price) * 100) / prod.attributes.previous_price;
                        previous_price = Number(prod.attributes.previous_price);
                    }
                    const imagesUrl: string[] = this.findImagesFromResponse(prod?.relationships?.images?.data, dataArray);
                    return <Product>{
                        id: prod.id,
                        imageUrl: imagesUrl[0] || '../../../../../assets/imgs/product-card/no-product-image.png',
                        isFavorite: false,
                        isLowStock: (!prod.attributes.in_stock && prod.attributes.purchasable),
                        name: '',
                        currency: prod.attributes.currency,
                        price: prod.attributes.price,
                        inStock: prod.attributes.in_stock,
                        quantity: 1,
                        discount,
                        optionsText: prod.attributes.options_text,
                        imagesUrl,
                        weight: Number(prod.attributes.weight),
                        height: Number(prod.attributes.height),
                        sku: prod.attributes.sku,
                        width: Number(prod.attributes.width),
                        slug: prod.attributes.slug,
                        compare_at_price,
                        previous_price,
                        age_valid: prod?.attributes?.age_valid,
                    }
                });
        }
        return [];
    }

    private findImageFromResponse(imageId: string, dataArray: any[]) {
        if (imageId) {
            return dataArray.find((obj: any) => obj.type === 'image' && obj.id === imageId)?.attributes?.styles[9]?.url
                || '../../../../../assets/imgs/product-card/no-product-image.png';
        }
        return '../../../../../assets/imgs/product-card/no-product-image.png';
    }

    private findImagesFromResponse(images: any[], dataArray: any[]): string[] {
        if (images.length > 0) {
            return dataArray
                .filter((obj: any) => obj.type === 'image' && images.findIndex(image => image.id === obj.id) !== -1)
                .map(
                    (image: any) => image?.attributes?.styles[9]?.url || '../../../../../assets/imgs/product-card/no-product-image.png'
                )
        }
        return [];
    }

    private findDefaultVariantFromResponse(variantId: string, dataArray: any[]): Product {
        const variant = dataArray.find((obj: any) => obj.type === 'variant' && obj.id === variantId);
        let discount: number = 0;
        let compare_at_price: number = 0;
        let previous_price: number = 0;
        if (variant.attributes.compare_at_price !== null && Number(variant.attributes.compare_at_price) !== 0) {
            // discount = (variant.attributes.compare_at_price * 100) / variant.attributes.price;
            compare_at_price = Number(variant.attributes.compare_at_price);
        }
        if (variant.attributes.previous_price !== null && Number(variant.attributes.previous_price) !== 0) {
            discount = ((variant.attributes.previous_price - variant.attributes.price) * 100) / variant.attributes.previous_price;
            previous_price = Number(variant.attributes.previous_price);
        }
        return {
            id: variant.id,
            imageUrl: '',
            isFavorite: false,
            isLowStock: (!variant.attributes.in_stock && variant.attributes.purchasable),
            name: variant.attributes.name,
            currency: variant.attributes.currency,
            price: variant.attributes.price,
            inStock: variant.attributes.in_stock,
            quantity: 1,
            discount,
            slug: variant.attributes.slug,
            compare_at_price,
            previous_price,
        }
    }

    /**
     * Obtner las categorias asociadas al producto
     * @param taxons
     * @param dataArray
     */
    private findTaxonsFromResponse(taxons: any[], dataArray: any[]): Taxon[] {
        if (taxons.length > 0) {
            return dataArray
                .filter((obj: any) => obj.type === 'taxon' && taxons.findIndex(taxon => taxon.id === obj.id) !== -1)
                .map(
                    (taxon: any) => ({
                        id: taxon?.id,
                        name: taxon?.attributes?.name,
                        permalink: taxon?.attributes?.permalink
                    })
                )
        }
        return [];
    }

    private getHeader(): HttpHeaders {
        let httpHeaders = this.authService.getHeader();
        const lang = this.languageService.selectedLanguage.getValue();
        httpHeaders = httpHeaders
            .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', lang);
        return httpHeaders;
    }


    async fetch_products(store_id: any, lang: string, list: any, zone: number, variables: any, municipality?:
        number, exclude_facet?: boolean,) {
        let uri = list?.Query?.Uri;
        let loadedProducts = [];
        let products: any;
        if (uri) {
            let modifiedUri = replaceParamsInUri(uri, lang, variables, zone || 6,
                municipality || 37);
            let FILTERS: any = { StoreIds: store_id, };
            FILTERS['ZoneIds'] = zone;
            if (municipality) FILTERS['MunicipalityId'] = municipality;
            exclude_facet ? FILTERS['ExcludeFacets'] = true : FILTERS['ExcludeFacets'] = false;
            try {
                products = await this.algoliaService.searchProducts(25, 1,
                    lang, FILTERS, modifiedUri).toPromise();
                loadedProducts = products?.data !== null ? products?.data?.items?.map((product: any, index: number) => {
                    return {
                        ...product,
                        ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
                        currency: product.currency || 'USD',
                        store: product?.stores ?
                            (product?.stores[0]?.id) ?
                                this.storeService.getStoreById(product?.stores[0]?.id)
                                : of(undefined)
                            : of(undefined)
                    }
                }) : [];
            } catch (e) {
                console.log(e);
            }
        }
        return loadedProducts;
    }

    getProductBySlugNew(
        slug: string,
        // locale = 'es',
        // include = 'default_variant,variants,images,variants.images,taxons'
        // include = 'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,taxons'
    ) {
        const httpParams = new HttpParams()
            .set('slug', slug)
        // .set('include', include);
        // .set('filter[slug]', slug);
        let httpHeaders = this.authService.getHeader();
        const lang = this.languageService.selectedLanguage.getValue();
        httpHeaders = httpHeaders
            // .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', lang);
        return this.http.get(`${environment.services_api_url}/${this.product_resource}`, { params: httpParams, headers: httpHeaders });
    }

}
