<!--w-220px-->
<!-- [ngClass]="{'w-220px': !resize, 'us:flex-row h-auto sm:h-362px': turn, 'h-362px': !turn }" -->
<div class="flex bg-white box-border cursor-pointer justify-between items-start p-17px gap-4
us:w-full us:h-auto">
  <!-- [ngClass]="{'h-100px w-100px sm:w-full sm:h-149px': turn, 'w-full h-149px': !turn}" -->
  <a [routerLink]="cardNavigation()" [queryParams]="queryParams" class="relative
    us:w-100px us:h-100px
    lg-landscape:w-100px lg-landscape:h-100px">
    <!-- <img class="absolute w-6 top-4 right-4 z-10" src="../../../../../assets/imgs/product-card/icon-wish.svg" alt=""> -->
    <img class="relative object-contain
    us:w-full us:h-fullfull
    lg-landscape:w-full lg-landscape:h-full"
      src="{{imageUrl || '../../../../../assets/imgs/product-card/no-product-image'}}" alt="">
  </a>

  <div class="flex flex-col
  us:w-64% us:flex
  us-landscape:w-full us-landscape:flex
  md:w-full
  lg-landscape:w-full lg-landscape:hidden">
    <a class="relative w-full font-Lato-Regular text-blue-#18396C line-clamp-3 text-sm leading-tight"
      [routerLink]="cardNavigation()" [queryParams]="queryParams">
      {{name}}
    </a>
    <a class="relative font-Lato-Regular font-semibold text-10px" [style.color]="(store | async)?.favorite_color"
      [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', (store | async)?.code]">
      {{(store | async)?.name}}
    </a>
    <a *ngIf="product_type == 0" class="relative w-full font-Lato-Regular text-gray-#212121 font-bold text-xl"
      [routerLink]="cardNavigation()" [queryParams]="queryParams">
      {{price | currency: currency}}
      <span class="relative text-sm ml-2 text-red-400 line-through font-normal" *ngIf="price < compare_at_price">
        {{compare_at_price | currency: currency}}
      </span>
    </a>
    <a *ngIf="product_type == 0" class="relative w-full flex justify-start items-center h-5 mt-3px"
      [routerLink]="cardNavigation()" [queryParams]="queryParams">
      <p *ngIf="isLowStock"
        class="relative font-Lato-Regular font-medium bg-green-#78840D text-white h-full text-13px pl-2 pr-2 rounded-10px">
        low stock</p>
      <p *ngIf="discount && discount > 0"
        class="relative font-Lato-Regular font-medium bg-yellow-#FFA101 text-white h-full text-13px pl-2 pr-2 rounded-10px m-1">
        -{{discount | number:'1.0-0'}}%</p>
      <p *ngIf="false"
        class="relative font-Lato-Regular font-medium bg-blue-#089CE3 text-white h-full text-13px pl-2 pr-2 rounded-10px m-1">
        best
        seller</p>
      <p *ngIf="shipping_category"
        class="relative font-Lato-Regular font-medium bg-yellow-#FFA101 text-white h-full text-13px pl-2 pr-2 rounded-10px">
        {{shipping_category}}</p>
    </a>
    <div *ngIf="product_type == 0" class="relative w-full flex justify-between items-center mt-3">
      <div
        class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border rounded-l-20px rounded-r-20px h-35px">
        <button (click)="decreaseQuantity()" [disabled]="data_extra_types?.length !== 0" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
          -
        </button>
        <p
          class="relative h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d w-38px text-19px">
          {{quantity}}</p>
        <button (click)="increaseQuantity()" [disabled]="data_extra_types?.length !== 0" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
          +
        </button>
      </div>
      <fa-icon *ngIf="spin"
        class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
        [icon]="faSpinner">
      </fa-icon>
      <fa-icon *ngIf="!spin" [ngClass]="{'opacity-50 cursor-not-allowed': isBlockAddToCard}"
        class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-9 h-9 text- text-white"
        [icon]="cart_icon" (click)="addToCart()">
      </fa-icon>
    </div>
  </div>

  <div class="relative flex-col py-2
  us:hidden
  us-landscape:hidden
  lg-landscape:w-215px lg-landscape:flex lg-landscape:ml-4">
    <a class="relative w-full font-Lato-Regular text-blue-#18396C line-clamp-3 text-sm leading-tight"
      [routerLink]="cardNavigation()" [queryParams]="queryParams">
      {{name}}
    </a>
    <a class="relative font-Lato-Regular font-semibold text-10px mt-1" [style.color]="(store | async)?.favorite_color"
      [routerLink]="cardNavigation()" [queryParams]="queryParams">
      {{(store | async)?.name}}
    </a>
    <!-- {{(store | async)?.name}} -->
  </div>

  <div *ngIf="product_type == 0" class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border rounded-l-20px rounded-r-20px h-35px
    us:hidden
    us-landscape:hidden
    lg-landscape:flex lg-landscape:ml-12 lg-landscape:mt-2">
    <button (click)="decreaseQuantity()" [disabled]="data_extra_types?.length !== 0" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
      -
    </button>
    <p (click)="setCustomQuantity()"
      class="overflow-hidden relative h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d w-38px text-19px">
      {{quantity}}</p>
    <button (click)="increaseQuantity()"
      [ngClass]="this.quantity >= compare_top && compare_top !== null && compare_top !== undefined ? 'cursor-not-allowed':'cursor-pointer'"
      [disabled]="data_extra_types?.length !== 0" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
      +
    </button>
  </div>

      <div class="relative flex flex-col justify-start items-end
      us:hidden
      lg-landscape:w-auto lg-landscape:ml-0 lg-landscape:mt-2 lg-landscape:flex">
        <a *ngIf="product_type == 0" class="relative w-auto font-Lato-Regular text-gray-#212121 font-bold text-xl"
          [routerLink]="cardNavigation()" [queryParams]="queryParams">
          {{price | currency: currency}}
          <span class="relative text-sm ml-2 text-red-400 line-through font-normal" *ngIf="price < compare_at_price">
            {{compare_at_price | currency: currency}}
          </span>
        </a>
        <a class="relative w-auto flex flex-col gap-2 justify-start items-center h-5 mt-3px"
          [routerLink]="cardNavigation()" [queryParams]="queryParams">
          <p *ngIf="isLowStock"
            class="relative font-Lato-Regular font-medium bg-green-#78840D text-white h-full text-13px pl-2 pr-2 rounded-10px">
            low stock</p>
          <p *ngIf="discount && discount > 0"
            class="relative font-Lato-Regular font-medium bg-yellow-#FFA101 text-white h-full text-13px pl-2 pr-2 rounded-10px m-1">
            -{{discount | number:'1.0-0'}}%</p>
          <p *ngIf="false"
            class="relative font-Lato-Regular font-medium bg-blue-#089CE3 text-white h-full text-13px pl-2 pr-2 rounded-10px m-1">
            best
            seller</p>
          <p *ngIf="shipping_category"
            class="relative font-Lato-Regular font-medium bg-yellow-#FFA101 text-white h-full text-13px pl-2 pr-2 rounded-10px">
            {{shipping_category}}</p>
        </a>
      </div>
    <div *ngIf="product_type == 0">

        <button  class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                        us:hidden
                        xl:h-41px xl:w-auto xl:text-base xl:ml-3 xl:px-4 xl:flex xl:mt-2" (click)="addToCart()"
                 [disabled]="quantity === 0 || spin || isBlockAddToCard"
                 [ngClass]="{'opacity-50':  spin, 'cursor-not-allowed ': spin}">
            <fa-icon *ngIf="spin"
                     class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
            <fa-icon *ngIf="!spin" [ngClass]="{'opacity-50 cursor-not-allowed': isBlockAddToCard}"
                     class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-white"
                     [icon]="cart_icon">
            </fa-icon>
            {{'ADD_TO_CART' | translate}}
        </button>
    </div>

    <div *ngIf="product_type == 1" (click)="navigateTo()" class="p-5 cursor-pointer w-20% bg-yellow-#FF6D03 font-Lato-Regular text-white h-9 w-40 us:w-60 rounded-full
      flex justify-center items-center">
        <p class="relative text-base leading-none flex justify-center items-center">{{navigation.labelText | translate}}</p>
    </div>
</div>
