import { Pipe, PipeTransform } from '@angular/core';
import {Section, ShipmentOption} from "../models/new-cart.model";

@Pipe({
  name: 'getOptionSelected'
})

export class GetOptionSelectedPipe implements PipeTransform {

  constructor() {

  }

  transform(shipmentOptions: ShipmentOption[]): ShipmentOption | undefined {
      return shipmentOptions.find(sO => sO.selected);
  }
}
