import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-services',
  templateUrl: './travel-services.component.html',
  styleUrls: ['./travel-services.component.scss']
})
export class TravelServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll({ top: 0 });
  }

}
