export interface WalletAccount {
  isActive: boolean,
  publicAddress: string,
  userId: string,
  isBlocked: boolean,
  balance: number,
  pendingBalance: number,
  reservedBalance: number,
}

export type UserSettingsDto = {
  walletOperationConfirmationType: string,
  walletOperationConfirmationTypeId: number,
  biometricToken: string
}

export type LogoType = {
  url: string,
  mime: string
}

export type BalanceType = {
  balanceType: number,
  label: string,
  value: string,
  tooltip: string
}

export type WalletTierType = {
  name: string,
  id: number
}

export type RenderNavigationDto = {
  web: string,
  app: string
}

export type ActionType = {
  text: string,
  actionType: number,
  hidden: boolean,
  disabled: boolean,
  tooltip: string,
  messages: string[],
  renderNavigationDto: RenderNavigationDto,
  logo: LogoType,
  max: number,
  min: number
}

export type FeatureType = {
  featureName: string,
  icon: LogoType
}

export type LayoutType = {
  title: string,
  label: string,
  showWalletAddressComponent: boolean,
  showLoadingButton: boolean,
  showVerifyAccountLink: boolean,
  actionsLabel: string,
  walletTier: WalletTierType,
  messages: string[],
  actions: ActionType[],
  balances: BalanceType[],
  features: FeatureType[],
  logo: LogoType
}

export type ProfileWalletDataType = {
  kWalletTier: string,
  kWalletTierId: number,
  requireUserVerification: boolean,
  layout: LayoutType,
  walletData: WalletAccount,
  receivedCount: number
}

export interface MultipleWalletAccount {
  profileWalletData: ProfileWalletDataType[],
  userSettingsDto: UserSettingsDto
}
