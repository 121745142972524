import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, Observable, switchMap, take, tap, throwError} from "rxjs";
import {filter} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable()
export class StoreInterceptor implements HttpInterceptor {

    private isGetStores = false;
    private refreshStoresSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    multiStoresUrl = environment.services_api_url + '/api/proxy/spree' + environment.v1 + '/multi_stores'; //'https://api.katapulk.com/api/v1/multi_stores'
    count: number = 0;

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

        if (request.url === this.multiStoresUrl) {
            if (!this.isGetStores) {
                this.isGetStores = true;
                this.refreshStoresSubject.next(null);

                return next.handle(request).pipe(
                    tap((stores: any) => {
                        if (stores && stores.type != 0) {
                            this.isGetStores = false;
                            this.refreshStoresSubject.next(stores);
                        }
                    })
                );
            } else {
                return this.refreshStoresSubject.pipe(
                    filter(stores => stores != null),
                    take(1),
                    switchMap(jwt => {
                        return next.handle(request);
                    }));
            }
        } else {
            // if (request.url.includes('/storefront/countries')) {
            //     return throwError({status: 500});
            // } else {
            //     return next.handle(request);
            // }
            return next.handle(request);
        }
    }
}
