import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
    interface Window {
        _turnstileCb?: any;
        turnstile: any;
    }
}

@Component({
    selector: 'app-payment-captcha',
    templateUrl: './payment-captcha.component.html',
})
export class PaymentCaptchaComponent implements OnInit {
    @Output() onCaptchaCheck: EventEmitter<string> = new EventEmitter<string>();
    // show_skeleton: boolean = true;

    constructor() { }

    ngOnInit(): void {
        if (window.turnstile) {
            this.render()
        } else {
            window._turnstileCb = () => {
                this.render();
            }
        }
    }

    render() {
        // this.show_skeleton = false;
        window.turnstile.render('#myPaymentWidget', {
            sitekey: environment.captcha_key,
            theme: 'light',
            callback: (token: any) => {
                this.sendToPArent(token);
            },
        });
    }

    destroyWidget() {
        // this.show_skeleton = true;
        window.turnstile.remove();
    }

    sendToPArent(token: string) {
        this.onCaptchaCheck.emit(token);
        // this.destroyWidget();
    }
}
