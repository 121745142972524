<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white">
    <div
        class="relative
    us:pt-3 us:w-full us:pl-5.3vw us:pr-5.3vw
    us-landscape:pt-3 us-landscape:w-full us-landscape:pl-5.3vw us-landscape:pr-0
    md:pt-3 md:w-full md:pl-5.3vw md:pr-0
    md-landscape:w-full md-landscape:px-5.3vw
    lg:w-full lg:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px overflow-hidden box-border lg-landscape:h-auto lg-landscape:pt-0 min-h-screen">
        <div class="relative w-full h-auto">
            <p class="relative font-Lato-Regular font-bold text-black mt-4">
                Atención al Cliente
            </p>

            <p class="relative font-Lato-Regular text-black mt-4">¿Necesita asistencia con una compra Katapulk? El
                equipo de Atención al Cliente puede ayudarle. Contáctenos a través del botón amarillo de chat que
                aparece en la página web o escriba un mensaje al correo electrónico <a
                    class="text-blue-#18396C font-semibold" href="mailto:info@katapulk.com">info@katapulk.com</a>. Puede
                comunicarse
                con nosotros en el horario de lunes a viernes, de 9:00am a 5:00pm.
                Si tiene preguntas sobre el servicio de Katapulk, chequee nuestra sección de Preguntas Frecuentes (<a
                    class="text-blue-#18396C font-semibold" routerLink="/customer/help">
                    Preguntas Frecuentes
                </a>).
            </p>
        </div>
    </div>
</section>