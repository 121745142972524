import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {ZonesService} from "./zones.service";
import {Country} from "../models";

@Injectable({
  providedIn: 'root'
})
export class ShippableCountryResolver implements Resolve<Country | undefined> {
  constructor(private zonesService: ZonesService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Country | undefined> {
    const countryIso: string = route.params['country_iso2']?.toUpperCase();
    return this.zonesService.getShippableCountryByIso2Resolver(countryIso);
  }
}
