import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {catchError, mergeMap, of, tap} from "rxjs";
import {SelectedZone, User} from "../models";
import {AuthService} from "./auth.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {FRONTEND_FEATURE, FrontendFeatureService} from "./frontend-feature.service";
import {LanguageService} from "./language.service";
import {CartService} from "./cart.service";
import {ZonesService} from "./zones.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private account_resource = '/identity/account';

  private userInfoResource = '/info';

  private resource = '/storefront/account';
  private resourceConfirmation = '/storefront/account_confirmations';
  private pass_recovery = '/storefront/passwords';
  private resend_confirmation_email = '/storefront/resend_confirmation_email';

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,
    private frontendFeatureService: FrontendFeatureService,
    private cartService: CartService,
    private zonesService: ZonesService,
  ) { }

  /**
   * get user data
   *
   */
  getUser() {
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.userInfoResource;
    let headers = new HttpHeaders({
        'accept': 'application/json',
        'x-refresh': 'true',
    })
    return this.httpClient.get(url, {headers})
      .pipe(
          tap((userResponse: any) => {
        const user: User = {
          id: userResponse?.data?.userId,
          age_valid: userResponse?.data?.age_valid,
          email: userResponse?.data?.email,
          store_credits: userResponse?.data?.store_credits,
          firstName: userResponse?.data?.firstname,
          lastName: userResponse?.data?.lastname,
          verified_at: userResponse?.data?.verified_at,
          verification_status: userResponse?.data?.verification_status,
          readyToUse: false,
        };
        this.authService.saveUser(user);
      }),
      catchError((err, caught) => {
          return of(err);
      }));
  }

  /**
   * Register user
   * @param data
   */
  registerUser(data: any) {
    return this.apiService.post(this.resource, data);
  }

  /**
   * Confirm registration token to validate user
   * @param token
   */
  confirmUser(token: string) {
    return this.apiService.get(
      `${this.resourceConfirmation}/${token}`,
      new HttpParams().set('locale', this.translateService.currentLang),
      true,
      this.authService.getHeader(),
      false
    );
  }

  /**
   * Captcha api validation
   * @param token Captcha token
   */
  // todo poner la direccion correcta de la api
  confirmTokenReCaptcha(token: string) {
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Accept', 'application/json;');
    return this.httpClient.post(`http://localhost:3000/check-recaptcha`, { token }, { headers });
  }

  /**
   * Actualizar datos del usuario
   * @param user
   */
  updateUser(user: any) {
    return this.apiService.patch(this.resource, { user });
  }

  recoverPass(form_value: any) {
    return this.apiService.post(this.pass_recovery, { user: form_value });
  }

  resetPassword(form_value: any, token: string) {
    return this.apiService.patch(`${this.pass_recovery}/${token}`, { user: form_value });
  }


  /**
   * Resend verification email
   * @param user
   */
  resendVerificationEmail(user: string) {
    return this.apiService.post(this.resend_confirmation_email, {email: user});
  }
}
