<div class="relative w-full overflow-hidden
    us:mt-12
    lg-landscape:mt-12">
    <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase
        us:text-13px us:tracking-0.7px us:mb-5
        lg-landscape:text-13px lg-landscape:tracking-0.7px lg-landscape:mb-5">
        {{ 'PRICE' | translate }}
    </p>

    <div class="list pl-2">
        <p class="relative filter-p cursor-pointer w-full font-Lato-Regular text-gray-600
            us:text-sm us:mt-2 us:h-5 us:pl-1
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
            (click)="emitFilterData(undefined, '0,25')">
            {{ 'LESS_THAN' | translate }} $25</p>
        <p class="relative filter-p cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
              us:text-sm us:mt-2 us:h-5 us:pl-1
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
            (click)="emitFilterData(undefined, '25,50')">
            $25-$50</p>
        <p class="relative filter-p cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
              us:text-sm us:mt-2 us:h-5 us:pl-1
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
            (click)="emitFilterData(undefined, '50,75')">
            $50-$75</p>
        <p class="relative filter-p cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
              us:text-sm us:mt-2 us:h-5 us:pl-1
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
            (click)="emitFilterData(undefined, '75,100')">
            $75-$100</p>
        <p class="relative filter-p cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
              us:text-sm us:mt-2 us:h-5 us:pl-1
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
            (click)="emitFilterData(undefined, '100')">
            {{ 'MORE_OF' | translate }} $100</p>
        <div class="relative flex
            us:mt-2
            lg-landscape:mt-2" [formGroup]="filterPriceForm">
            <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7
                us:text-sm lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Min" formControlName="min">
            <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7 lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Max" formControlName="max">
            <button class="relative bg-gray-#979797 text-white font-Lato-Regular
                    us:w-9 us:text-sm
                    lg-landscape:w-9 lg-landscape:text-sm" type="button" (click)="emitByPriceFields()"
                [disabled]="!filterPriceForm.value.min && !filterPriceForm.value.max">Ir</button>
        </div>
        <button (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
        text-base w-135px h-41px rounded-3px px-3 mt-4">
            {{'RESET_FILTERS' | translate}}
        </button>
    </div>
</div>