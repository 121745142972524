<section class="relative w-full h-auto rounded-3px bg-white mt-3">
    <div (click)="expand()" class="relative cursor-pointer prevent-select w-full flex justify-between items-center
                    h-auto min-h-50px pl-5 pr-5 rounded-3px pb-5 pt-5">
        <p class="reative font-Lato-Regular font-bold text-gray-#252525
                        text-15px">{{question}}</p>
        <fa-icon *ngIf="!show_message" class="relative cursor-pointer flex justify-center items-center rounded-full bg-gray-#252525
                text-gray-#D8D8D8 text-sm" [icon]="faCirclePlus"></fa-icon>
        <fa-icon *ngIf="show_message" class="relative cursor-pointer flex justify-center items-center rounded-full bg-gray-#252525
                text-gray-#D8D8D8 text-sm" [icon]="faCircleMinus"></fa-icon>
    </div>

    <p [innerHtml]="text" [ngClass]="show_message == true ? 'h-auto pb-5' : 'h-0 pb-0'" class="relative prevent-select overflow-hidden font-Lato-Regular text-gray-#5d5d5d
                    text-15px pl-5 pr-5 transition-all ease-in-out">
    </p>
</section>