<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white">
    <div
        class="relative
    us:pt-3 us:w-full us:pl-5.3vw us:pr-5.3vw
    us-landscape:pt-3 us-landscape:w-full us-landscape:pl-5.3vw us-landscape:pr-0
    md:pt-3 md:w-full md:pl-5.3vw md:pr-0
    md-landscape:w-full md-landscape:px-5.3vw
    lg:w-full lg:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px overflow-hidden box-border lg-landscape:h-auto lg-landscape:pt-0">
        <div class="relative w-full h-auto pb-5">
            <p class="relative font-Lato-Regular font-bold text-black mt-4 w-full text-center">
                AVISO LEGAL Y POLÍTICA DE PRIVACIDAD
            </p>

            <p class="relative font-Lato-Regular text-black mt-5">Este aviso legal recoge las condiciones generales de
                acceso, navegación, y uso del sitio web mercadounico.katapulk.com / katapulk.com; así como los detalles
                del tratamiento de los datos de nuestros clientes. Es necesario que acepte este aviso legal para navegar
                las páginas de mercadounico.katapulk.com y katapulk.com, así como para realizar una compra.</p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">1) Introducción</p>

            <p class="relative font-Lato-Regular text-black mt-2">a. El acceso al portal mercadounico.katapulk.com /
                katapulk.com está condicionado a la previa lectura y aceptación del usuario de las condiciones,
                términos, comunicaciones, advertencias, avisos legales y demás información contenida en estos Términos y
                Condiciones. El acceso al mismo tiene carácter gratuito, su visualización no requiere previa suscripción
                o registro alguno, y su utilización por parte del usuario implica su conformidad de forma expresa, plena
                y sin reserva, con la totalidad de su contenido.</p>

            <p class="relative font-Lato-Regular text-black mt-2">b. Si el Usuario no estuviera de acuerdo con el
                contenido de las presentes Condiciones Generales de acceso de navegación y uso de esta web no podrá
                acceder ni comprar los productos y servicios que esta ofrece.</p>

            <p class="relative font-Lato-Regular text-black mt-2">c. Aceptando estos términos y condiciones, el usuario
                afirma y declara ser mayor de edad y disponer de la capacidad jurídica suficiente para registrarse en
                esta web. Igualmente, acepta de forma expresa y sin excepciones que el acceso y la utilización del
                presente sitio Web, de sus servicios y de los contenidos de dichos servicios tienen lugar bajo su única
                y exclusiva responsabilidad, no pudiendo garantizar mercadounico.katapulk.com / katapulk.com que en el
                acceso directo a otros sitios Web a través de enlaces (links) pueda visualizar información que pudiera
                herir su sensibilidad o le provoque daños.</p>

            <p class="relative font-Lato-Regular text-black mt-2">d. Las conexiones con otros sitios Web que pudieran
                existir, así como el uso que el Usuario pueda hacer de las mismas, está sujeto a estas condiciones, así
                como a los eventuales condicionantes específicos que los citados sitios Web requieran. Cualquier
                utilización distinta a la autorizada está expresamente prohibida.</p>

            <p class="relative font-Lato-Regular text-black mt-2">e. <span class="font-bold">Katapulk Marketplace LLC &
                    Universus Infiniti European 20.20 S.L.</span> podrá modificar esta Web de modo unilateral y en
                cualquier momento que estime oportuno, actualizando su configuración, los términos y condiciones de los
                servicios y su contenido, eliminarlos, limitarlos o suspenderlos de manera temporal o definitiva, así
                como impedir el acceso a los mismos procurando informar al Usuario de dicho cambio, siempre y cuando las
                circunstancias así se lo permitan, a través de su publicación en el sitio Web, motivo por el cual se
                recomienda a los Usuarios que consulten en cada acceso a la Web las CG, las Condiciones Generales de
                Venta, Avisos Legales y Condiciones particulares enunciadas en las distintas páginas que componen el
                sitio Web.</p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">2) Características de la web y navegabilidad
            </p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Diseño de web adaptado. La Web de
                mercadounico.katapulk.com / katapulk.com tiene un diseño responsive que permite al cliente acceder a la
                web desde diferentes dispositivos, adaptando la imagen y resolución de la página Web a cada dispositivo.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. Alta en cuenta. Existe un Registro, al que se
                accede en el apartado MI CUENTA, que el usuario completará con los datos requeridos y con la aceptación
                del aviso legal para poder acceder a los servicios de compra online de mercadounico.katapulk.com /
                katapulk.com.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">c. Posibilidad de gestión de datos de perfil. Existe
                un apartado de INFORMACIÓN, al que se accede a través de MI CUENTA, en que el usuario puede:
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">i. Gestionar sus datos personales </p>
            <p class="relative font-Lato-Regular text-black mt-2">ii. Adicionar destinatarios </p>
            <p class="relative font-Lato-Regular text-black mt-2">iii. Consultar crédito o bonificaciones disponibles
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">iv. Gestionar las ordenes </p>
            <p class="relative font-Lato-Regular text-black mt-2">v. Visualizar los productos añadidos a FAVORITOS </p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">3) Derechos y obligaciones del prestador de
                servicios
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">a. mercadounico.katapulk.com / katapulk.com se
                encuentra obligada a prestar los servicios ofertados en la Web y garantizar las comunicaciones que
                pudieran existir con el cliente, así como a responder a las reclamaciones y consultas que puedan surgir.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. mercadounico.katapulk.com / katapulk.com tiene el
                derecho a suspender temporalmente y sin necesidad de pre-aviso la accesibilidad a la presente Web, así
                como a reservarse el derecho de prestación o la cancelación de los servicios, en caso de efectuar
                operaciones de mantenimiento, reparación, actualización o mejora de los servicios.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">c. Los derechos de propiedad intelectual sobre el
                sitio Web son titularidad de <span class="relative font-bold">Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.</span> Los derechos de reproducción, distribución, comunicación pública
                y transformación pertenecen a la misma compañía. </p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">4) Derechos y obligaciones del usuario
            </p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Los contenidos de esta web están a disposición del
                usuario para su utilización sin ningún fin comercial y exclusivamente para su uso personal; y sólo con
                esos fines el usuario puede transferir los contenidos de esta Web. El usuario no puede, en consecuencia,
                distribuir, modificar, transmitir, reenviar o usar en forma alguna, dichos contenidos con fines
                comerciales.</p>

            <p class="relative font-Lato-Regular text-black mt-2">b. En todo momento el Usuario debe realizar un uso
                lícito de los servicios de esta Web de acuerdo con la legalidad vigente, respetando los derechos de
                propiedad intelectual de <span class="relative font-bold">Katapulk Marketplace LLC & Universus Infiniti
                    European 20.20 S.L.</span> y de posibles terceros.</p>

            <p class="relative font-Lato-Regular text-black mt-2">c. El Usuario no realizará a través de los servicios
                que pone a su disposición mercadounico.katapulk.com / katapulk.com ninguna acción que cause daño o
                alteraciones de los contenidos u obstaculice el buen funcionamiento de la Web, comprometiéndose
                expresamente por el solo uso de la misma a no causar problemas técnicos de cualquier índole, transferir
                elementos susceptibles de portar virus informáticos o dañar, interferir o interceptar total o
                parcialmente la Web, así como a no intervenir o alterar el correo electrónico de otros Usuarios.</p>
            <p class="relative font-Lato-Regular text-black mt-2">d. La responsabilidad del Usuario sobre la veracidad
                de los datos introducidos, en el caso de que lo hiciera por cualquier circunstancia, es exclusiva del
                mismo Usuario; por lo que, en caso de haber facilitado datos falsos o inexactos,
                mercadounico.katapulk.com / katapulk.com y, en su caso, las sociedades colaboradoras de <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span>, se reservan el derecho a prohibirle el acceso a esta Web.</p>
            <p class="relative font-Lato-Regular font-bold text-black mt-4">5) Exclusión de garantías y de
                responsabilidad del funcionamiento del sitio web
            </p>

            <p class="relative font-Lato-Regular text-black mt-2">a. mercadounico.katapulk.com / katapulk.com se
                compromete a trabajar para mantener la disponibilidad continuada de la presente Web, pero ni
                mercadounico.katapulk.com / katapulk.com ni las sociedades colaboradoras de <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span>, garantizan la disponibilidad y continuidad del funcionamiento
                del presente sitio Web y de sus servicios. De esta forma, se excluye cualquier responsabilidad por los
                daños y perjuicios de cualquier naturaleza que puedan derivarse de la falta de disponibilidad o de
                continuidad del funcionamiento del sitio Web y de sus servicios y la utilidad que los Usuarios hubieran
                podido atribuir al presente sitio Web.</p>
            <p class="relative font-Lato-Regular text-black mt-2">b. mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> ponen a
                disposición de los Usuarios los sistemas de privacidad de datos personales que impidan el acceso a los
                mismos por parte de terceros. mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras
                deo <span class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span> han implantado todas las medidas
                de seguridad técnicas y organizativas necesarias que garanticen la integridad, confidencialidad y
                disponibilidad de los datos personales facilitados por el Usuario. En este sentido, las citadas
                sociedades se eximen de toda responsabilidad por los daños y perjuicios ocasionados en caso de
                producirse tal conocimiento.</p>
            <p class="relative font-Lato-Regular text-black mt-2">c. Pese a que mercadounico.katapulk.com / katapulk.com
                han implantado todas aquellas medidas adecuadas para garantizar la seguridad en esta materia, no
                controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos de la presente página
                Web que puedan producir alteraciones en el sistema informático (software y hardware) del Usuario o en
                los documentos electrónicos y ficheros almacenados en su sistema informático.</p>
            <p class="relative font-Lato-Regular text-black mt-2">d. mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> se eximen
                de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la
                presencia de virus en los contenidos que puedan producir alteraciones en el sistema informático,
                documentos electrónicos, ficheros, etc…</p>
            <p class="relative font-Lato-Regular text-black mt-2">e. mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> no tienen
                obligación de controlar, y no controlan, la utilización que los Usuarios hacen de este sitio Web, de los
                servicios que este sitio Web provee, ni de sus contenidos. En particular, mercadounico.katapulk.com /
                katapulk.com no garantiza que los Usuarios utilicen el sitio Web, sus servicios y sus contenidos de
                conformidad con los términos y condiciones bajo dominio mercadounico.katapulk.com / katapulk.com que en
                cada momento resulten de aplicación, ni que lo hagan de forma diligente y prudente.</p>

            <p class="relative font-Lato-Regular text-black mt-2">f. mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> tampoco
                tienen la obligación de verificar, y no verifican, la identidad de los Usuarios de este sitio Web; ni la
                veracidad, de los datos que los mismos proporcionan sobre sí mismos o destinatarios.</p>

            <p class="relative font-Lato-Regular text-black mt-2">g. El Usuario acepta que el sitio Web ha sido creado y
                desarrollado de buena fe por mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de
                <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> con información procedente de fuentes
                internas y externas y lo ofrece en su estado actual a los Usuarios, pudiendo contener imprecisiones o
                erratas por lo cual el Usuario exonera a mercadounico.katapulk.com / katapulk.com y las sociedades
                colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> de cualquier
                responsabilidad en relación con la fiabilidad, utilidad o falsas expectativas que el sitio Web pudiera
                producirle durante su navegación por el mismo.
            </p>
            <p class="relative font-Lato-Regular font-bold text-black mt-4">6) Propiedad intelectual </p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Todos los derechos sobre el contenido de la
                presente Web pertenecen a mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de
                <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span> ; salvo aquéllos de empresas con las que haya firmado el
                correspondiente contrato para la provisión de contenidos.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. mercadounico.katapulk.com / katapulk.com no
                transfiere al Usuario de esta Web propiedad alguna sobre su propio software o de terceros; el Usuario es
                el responsable del soporte en el cual el software es grabado, reteniendo mercadounico.katapulk.com /
                katapulk.com y las sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace
                    LLC & Universus Infiniti European 20.20 S.L. </span> todos los derechos de propiedad industrial e
                intelectual incluyendo el software que le pudiera corresponder.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">c. El diseño, imágenes, mapas, gráficos, marcas,
                rótulos, signos distintivos o logos de mercadounico.katapulk.com / katapulk.com, frames, banners, el
                software y sus distintos códigos fuente y objeto, etc., de esta Web son titularidad de
                mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span> , quienes poseen legítimamente y en exclusiva los derechos de explotación sobre los mismos.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">d. Los logos o signos distintivos de las sociedades
                del Grupo que pudieran aparecer en esta Web durante la navegación del Usuario son titularidad de cada
                una de ellas, disponiendo legítimamente en exclusiva de los derechos de explotación sobre los mismos,
                consintiendo expresamente que los mismos sean incorporados, en su caso, a esta Web.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">e. El Usuario que acceda a esta Web no puede copiar,
                modificar, distribuir, transmitir, reproducir, publicar, ceder ni vender los elementos anteriormente
                mencionados; ni crear nuevos productos o servicios derivados de la información obtenida a través de esta
                Web.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">f. Únicamente queda autorizada la visualización y
                carga para el uso personal y no comercial del Usuario,
                sin que pueda hacerlo extensivo a terceras personas o entidades. En el caso de gráficos o diseños que
                aparezcan en esta Web cuya titularidad sean de empresas colaboradoras de mercadounico.katapulk.com /
                katapulk.com y las sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace
                    LLC & Universus Infiniti European 20.20 S.L. </span>
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">g. Queda terminantemente prohibida cualquier
                alteración de esta Web o de su contenido por parte del Usuario; así como la instalación de enlaces
                (hiperenlaces, vínculos o hipervínculos), links o similares no autorizados previa y expresamente por
                mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span>
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">h. mercadounico.katapulk.com / katapulk.com velará
                para que los contenidos de esta Web no sean pornográficos, xenófobos, discriminatorios, racistas,
                difamatorios, o fomenten cualquier género de violencia.
            </p>
            <p class="relative font-Lato-Regular font-bold text-black mt-4">7) Protección de datos de carácter personal.
                En cumplimiento con las normativas vigentes de protección de datos de carácter personal de los usuarios,
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">a. mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L.
                </span> respetarán, en todo momento, lo dispuesto en la normativa vigente sobre protección de datos de
                carácter personal; y garantizan la confidencialidad de los datos personales. En cumplimiento con la
                normativa vigente sobre protección de datos de carácter personal, le informamos de que los datos
                aportados serán tratados por mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de
                Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L. en calidad de Responsables del
                Fichero.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. La aportación de datos personales, propios o de
                terceros, a través de los formularios dispuestos al efecto en el Sitio Web de mercadounico.katapulk.com
                / katapulk.com sólo podrán realizarla personas mayores de edad.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">c. Las entidades del Grupo con acceso a los datos
                personales de los Usuarios de mercadounico.katapulk.com / katapulk.com son <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span> (comercio), <span class="relative font-bold"> Fuego
                    Enterprises, Inc. - OnCuba News / OnCuba Magazine </span> (comunicaciones) y OnCuba Travel LLC
                (agencia de viajes)
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">d. Mediante la aceptación de la presente Información
                Legal, el Usuario autoriza a mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de
                <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span> y a todas las sociedades del Grupo a
                tratar los datos aportados en este sitio web con cualquiera de las siguientes finalidades:
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">i. Gestionar los datos para tramitar sus pedidos de
                compra on-line.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">ii. Gestionar la participación del usuario en posibles
                eventos y acciones promocionales
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">iii. Gestionar los procesos de selección de la Entidad
                <span class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span> y las sociedades del Grupo
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">iv. Gestionar las posibles relaciones entre el Usuario
                del Sitio Web y mercadounico.katapulk.com / katapulk.com.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">v. Utilizar los datos para remitir al Usuario de
                mercadounico.katapulk.com / katapulk.com a encuestas e información y/o documentación de los productos
                y/o servicios solicitados por el Usuario y/o comercializados por mercadounico.katapulk.com /
                katapulk.com a través de diversos medios, inclusive los electrónicos (correo electrónico y telefonía
                móvil).
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">vi. El consentimiento para la utilización de los datos
                del Usuario para el envío de comunicaciones comerciales a través de medios electrónicos (correo
                electrónico u otros actuales o futuros) podrá ser revocada por el Usuario en cualquier momento en el
                tiempo, a través del apartado “MI PERFIL” del Sitio Web de mercadounico.katapulk.com / katapulk.com
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">vii. En todo caso, para el ejercicio de los derechos
                de acceso rectificación, cancelación u oposición, deberá remitirse un escrito identificado, en el que se
                concrete la solicitud correspondiente y al que acompañe fotocopia del Carnet de Identidad del
                interesado, a la siguiente dirección: C/ BARBASTRO 4 1 A HUESCA 22002-HUESCA, España.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">e. Cuando el Usuario facilite sus datos a
                mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de <span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span> , a través de alguno de los formularios de la Web, el Usuario
                estará autorizando a las referidas sociedades para que puedan tratar sus datos de conformidad con la
                finalidad de la recogida. Los datos facilitados formarán parte de un fichero, creado bajo la
                responsabilidad de la sociedad a la que el Usuario facilite sus datos.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">f. mercadounico.katapulk.com / katapulk.com informa al
                Usuario de la existencia en este sitio Web de enlaces que permiten el acceso a diferentes páginas Web de
                determinadas entidades del Grupo, así como a diversas organizaciones o entidades de terceros cuya
                titularidad no es de mercadounico.katapulk.com / katapulk.com ni sociedades del Grupo
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">g. El Usuario podrá ejercitar los derechos de acceso,
                rectificación, cancelación y oposición en la dirección y ante el responsable del fichero que se indique
                en cada caso concreto. De esta forma, mercadounico.katapulk.com / katapulk.com y las sociedades
                colaboradoras de <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span> pretenden garantizar
                a los Usuarios, el derecho a la información en el momento en que se lleve a cabo la recogida de sus
                datos.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">h. El Usuario será el único responsable en caso de que
                cumplimente los formularios con datos falsos, inexactos, incompletos o no actualizados. Todos los datos
                facilitados por el Usuario serán tratados de forma confidencial.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">i. La sociedad a la que el Usuario haya podido
                facilitar sus datos, sólo podrá cederlos.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">j. Parte de los servicios de mercadounico.katapulk.com
                / katapulk.com utilizan "cookies". Para más información, consulte nuestra política de cookies.
            </p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">8) Vínculos o enlaces</p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Sin el expreso consentimiento de
                mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de<span
                    class="relative font-bold"> Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L.
                </span>, no se podrá establecer vínculo a este sitio web desde cualquier otra web.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. Sí la web tuviese links o vínculos a otros
                portales, no gestionados por <span class="relative font-bold"> Katapulk Marketplace LLC & Universus
                    Infiniti European 20.20 S.L. </span>, se excluye toda responsabilidad por parte de la compañía sobre
                el control y responsabilidad de los productos o servicios ofertados, así como los contenidos y
                propietarios.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">c. <span class="relative font-bold"> Katapulk
                    Marketplace LLC & Universus Infiniti European 20.20 S.L. </span>, se exoneran de toda
                responsabilidad en relación con los contenidos y servicios prestados por terceros frente a cualquier
                reclamación de cualquier naturaleza y demandas que pudieran interponerse en relación con los mismos.
            </p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">9) Nulidad e ineficacia de las cláusulas</p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Si se diera el caso de que cualquier contenido de
                estos términos y condiciones fuera declarado nulo o ineficaz, sólo afectará a al contenido indicado o
                parte del mismo.
            </p>

            <p class="relative font-Lato-Regular font-bold text-black mt-4">10) Ley aplicable y jurisdicción</p>

            <p class="relative font-Lato-Regular text-black mt-2">a. Los Términos y Condiciones de este sitio web se
                regirán por la legislación Española.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2">b. En caso de que cualquier Usuario o tercero afectado
                considere que existen hechos o circunstancias que revelen el carácter ilícito deberá enviar una
                comunicación en la que se contengan los siguientes extremos:
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-1">i. Datos personales completos del reclamante:
                Nombre,
                apellidos, dirección, número de teléfono y dirección de correo electrónico.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-1">ii. Especificación, de manera pormenorizada, de
                los siguientes extremos: En qué consiste el supuesto contenido ilícito o la ilicitud del uso del
                contenido. Si se es titular del contenido ilícitamente usado.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-1">iii. Cuando se trate de una supuesta lesión de
                bienes o derechos susceptibles de indemnización, indicación precisa y concreta de los contenidos
                protegidos, así como de su localización en la Web.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-1">iv. El reclamante habrá de remitir por correo
                postal a Universus Infiniti European 20.20 S.L., ubicada en C/ BARBASTRO 4 1 A HUESCA 22002-HUESCA,
                España la siguiente documentación
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-2">1. Comunicación firmada a mano, del titular de
                los derechos supuestamente infringidos o de la persona autorizada para actuar en su nombre y por su
                cuenta a la que se acompañe documentación acreditativa de la titularidad del bien o derecho
                presuntamente lesionado, en la que se refiera de manera precisa los datos de la página Web, contenido, o
                hipervínculo objeto de queja.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-2">2. En caso de no ser titular del derecho o bien
                lesionado, declaración jurada, expresa, clara y bajo la responsabilidad del reclamante de que la
                información proporcionada en la notificación online es exacta y que el contenido o actividad referida es
                ilícita. El documento habrá de ir firmado a mano y acompañar copia del Carnet de Identidad o documento
                de identificación equivalente.
            </p>
            <p class="relative font-Lato-Regular text-black mt-2 pl-2">3. NO SE ADMITIRÁN COMUNICACIONES ANÓNIMAS COMO
                NOTIFICACIÓN SUFICIENTE PARA PONER EN CONOCIMIENTO mercadounico.katapulk.com / katapulk.com y las
                sociedades colaboradoras de Katapulk Marketplace LLC & Universus Infiniti European 20.20 S.L. LA
                ILICITUD DE CONTENIDOS O ACTIVIDADES.
            </p>
            <p class="relative font-Lato-Regular text-black mt-4"><span class="relative font-bold"> Katapulk
                    Marketplace LLC & Universus Infiniti European 20.20 S.L. </span> © Copyright 2020
                mercadounico.katapulk.com / katapulk.com y las sociedades colaboradoras de
                Prohibida la reproducción total o parcial.
            </p>
        </div>
    </div>
</section>