<div *ngIf="form" [formGroup]="form" class="relative flex flex-col justify-start items-start w-full col-span-2">
    <label *ngIf="meta.placeHolder" class="relative font-Lato-Regular font-semibold text-gray-#5d5d5d text-sm leading-21px mb-1" for="">
      {{meta.placeHolder}}
    </label>
    <div class="bg-white box-border pb-2 pl-3 pt-2 relative rounded w-full">
        <p (change)="getValue($event)" class="relative flex justify-start font-Lato-Regular text-gray-#727272 leading-5">
            <input [formControlName]="meta.fieldId"
                   class="focus:ring-offset-0 relative border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0 mr-3 mt-1"
                   type="checkbox" value="false"
                   [name]="meta.fieldId">
            {{ meta.label }}
<!--            {{ 'YES' | translate }}-->
        </p>
    </div>
</div>
