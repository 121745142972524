import {ShipmentInterfaceModel} from "./shipping.model";

export interface ProductInCart {
    id: number;
    imageUrl?: string;
    isFavorite?: boolean;
    name: string;
    price: number;
    quantity: number;
    currency: string;
    discount?: number;
    optionsText?: string;
    imagesUrl?: string[];
    store_id?: number;
    included_tax_total?: number;
    total?: number;
    shipment?: ShipmentInterfaceModel;
    slug?: string;
    data_extras?: { id: number, value: string }[],
    inventory_unit?: {quantity: number, shipment_id: number}
}
