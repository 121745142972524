import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address, SelectedZone} from "../../../../shared/models";
import {AddAddressComponent} from "../../../profile/components/add-address/add-address.component";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {ZonesService} from "../../../../shared/services/zones.service";

@Component({
  selector: 'app-checkout-address-manager',
  templateUrl: './checkout-address-manager.component.html',
  styleUrls: ['./checkout-address-manager.component.scss']
})
export class CheckoutAddressManagerComponent implements OnInit {

  @Input() addresses: Address[] = [];
  @Input() isShipping: boolean = true;
  @Input() selectedAddress: Address|undefined;
  @Input() formMarkAsTouched: boolean = false;
  @Input() errorText: string = 'SELECTION_REQUIRED';
  @Output() onAddressSelect: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() onAddressUpdate: EventEmitter<Address> = new EventEmitter<Address>();

  constructor(
    private dialog: MatDialog,
    private zonesService: ZonesService,
  ) { }

  ngOnInit(): void {
  }

  emitAddress(address: Address) {
    this.selectedAddress = address;
    this.onAddressSelect.emit(address);
  }

  openEditAddressModal(address: Address, index: number) {
    this.dialog.open(AddAddressComponent, {
      disableClose: false,
      position: {top: '30px'},
      data: {
        isModeEdit: true,
        // isCheckout: true,
        data: address,
        isShippingAddress: this.isShipping
      },
    })
      .afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response?.modified) {
          this.onAddressUpdate.emit(response);
        }
      });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
