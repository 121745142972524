import {Component, OnDestroy, OnInit} from '@angular/core';
import { LanguageService } from "../../../../shared/services/language.service";
import {Subscription} from "rxjs";
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  TITLE = null;
  TERMS = null;

  constructor(private languageService: LanguageService, private dynamicSerice: DynamicCOnfigService) { }

  ngOnInit(): void {
    window.scroll({ top: 0 });
    this.languageService.selectedLanguage.subscribe(() => {
      setTimeout(() => {
        this.getDynamicSettings();
      });
    })
  }

  getDynamicSettings() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es'; // this.translateService.getBrowserLang();
      this.getGLobalConfig(language, 'terms');
    } else {
      this.getGLobalConfig(language, 'terms');
    }
  }

  getGLobalConfig(lang: string, content_key: string) {
    let TERMS_VARIABLES: any = [];
    let QUERY: any = null;
    const VARIABLES: any = { locale: lang, contentKey: content_key };
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');

      JSON.parse(aux)?.FRONT_SETTINGS?.data[0]?.attributes?.query_discoveries?.data?.forEach((query: any) => {
        if (query?.attributes?.Key == 'contents') {
          query?.attributes?.Query?.Variables.map((element: any) => {
            TERMS_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
          });
          TERMS_VARIABLES.push({ key: 'query_discovery_key', value: 'contents' });
          QUERY = query?.attributes?.Query?.Uri;
          this.dynamicSerice.doDynamicQuery(QUERY, TERMS_VARIABLES)
            .subscribe((data: any) => {
              this.TITLE = data?.data[0]?.attributes?.Title;
              this.TERMS = data?.data[0]?.attributes?.Content;
            });
        }
      });
    }
  }
}
