import { Component, Input, OnInit } from '@angular/core';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-custom-desplegable',
  templateUrl: './custom-desplegable.component.html',
  styleUrls: ['./custom-desplegable.component.scss']
})
export class CustomDesplegableComponent implements OnInit {
  @Input('question') question: any = '';
  @Input('text') text: any = '';
  faCirclePlus = faCirclePlus;
  faCircleMinus = faCircleMinus;
  show_message = false;

  constructor() { }

  ngOnInit(): void {
  }

  expand() {
    if (this.show_message == true) {
      this.show_message = false;
    } else {
      this.show_message = true;
    }
  }
}
