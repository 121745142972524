import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from "../../../../shared/services/notification.service";
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subject } from "rxjs";
import { OnfidoService } from "../../../../shared/services/onfido.service";
import {REGULAR_EXPRESSION} from "../../../../shared/enum/regular-expression.enum";
import {AuthService} from "../../../../shared/services/auth.service";
import {environment} from "../../../../../environments/environment";
import {takeUntil} from "rxjs/operators";
import {ErrorsEnum} from "../../../../shared/enum/errors.enum";
import {Router} from "@angular/router";

@Component({
    selector: "app-confirm-dialog-with-title-image-description-component",
    templateUrl: "./confirm-dialog-with-title-image-description.component.html",
    styleUrls: ['./confirm-dialog-with-title-image-description.component.scss']
})
export class ConfirmDialogWithTitleImageDescriptionComponent implements OnDestroy {
    faClose = faClose;
    faSpinner = faSpinner;
    isLoading: boolean = false;
    isSaving: boolean = false;
    title: string;
    action: any;
    text: string;
    confirmAction: string;
    cancelAction: string;
    isWarnBotton: boolean;
    isVerificationAccount: boolean = false;
    image: string;
    form!: FormGroup;
    formMarkAsTouched: boolean = false;

    destroy$: Subject<boolean> = new Subject<boolean>();
    redirectUrl: any;

    responseCode: any;
    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogWithTitleImageDescriptionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationService: NotificationService,
        private onfidoService: OnfidoService,
        private authService: AuthService,
        private router: Router,
    ) {
        this.title = data.title;
        this.text = data.text ? data.text : "";
        this.image = data.image;
        this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
        this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
        this.isWarnBotton = data.isWarnBotton ? data.isWarnBotton : false;
        this.isVerificationAccount = data.isVerificationAccount ? data.isVerificationAccount : false;
        this.redirectUrl = data.redirectUrl;
        this.responseCode = data.responseCode;

        if (this.isVerificationAccount) {
            const currentUser = this.authService.getCurrentUser();
            this.form  = new FormGroup({
                firstname: new FormControl(currentUser.firstName, [
                    Validators.required,
                    Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
                    Validators.maxLength(35)
                ]),
                lastname: new FormControl(currentUser.lastName, [
                    Validators.required,
                    Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
                    Validators.maxLength(35)
                ]),
            });
        }
    }

    onSubmit(): void {
        if (this.isVerificationAccount) {
            this.form.markAllAsTouched();
            this.formMarkAsTouched = true;
            if (this.form.valid) {
                this.isSaving = true;
                const data = this.form.getRawValue();
                data.firstname = data.firstname.trim();
                data.lastname = data.lastname.trim();
                data.isMobileApp = false;
                data.redirect = this.redirectUrl && this.redirectUrl != '' ? this.redirectUrl : `${environment.base_app_route}/profile/account`;
                this.disableForm();
                this.onfidoService.startVerification(data)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: (response) => {
                            this.enableFormAndSetSaving();
                            this.dialogRef.close(Object.assign(response || {}, { modified: !!response}));
                            this.onfidoService.saveTopicOnStorage(this.onfidoService.ROUTE_VERIFICATION_BROKER_TOPIC, response.brokerTopic);
                            location.href = response.workflowRunUrl;
                        },
                        error: err => this.enableFormAndSetSaving(),
                        complete: () => this.enableFormAndSetSaving()
                    });
            }
        } else {
            this.isSaving = true;
            this.onfidoService.startFlow(this.responseCode, this.redirectUrl).subscribe((response: any) => {
                if (this.responseCode === ErrorsEnum.NEED_TO_DO_QUICK_SCAN) {
                    this.onfidoService.saveTopicOnStorage(this.onfidoService.ROUTE_REMITTANCE_VERIFICATION_FACE_BROKER_TOPIC, response.verificationFlowData.brokerTopic);
                    location.href = response.verificationFlowData.workflowRunUrl;
                } else if (this.responseCode === ErrorsEnum.ID_DOCUMENT_EXPIRED) {
                    this.onfidoService.saveTopicOnStorage(this.onfidoService.ROUTE_REMITTANCE_VERIFICATION_DOCUMENT_BROKER_TOPIC, response.verificationFlowData.brokerTopic);
                    location.href = response.verificationFlowData.workflowRunUrl;
                } else if (this.responseCode === ErrorsEnum.UNVERIFIED_ACCOUNT) {
                    const redirectURL = this.router.url;
                    this.router.navigate(['/profile/account'], { queryParams: { redirectURL: redirectURL } });
                } else {
                    this.onfidoService.saveTopicOnStorage(this.onfidoService.ROUTE_REMITTANCE_VERIFICATION_DOCUMENT_BROKER_TOPIC, response.verificationFlowData.brokerTopic);
                    location.href = response.verificationFlowData.workflowRunUrl;
                }
                this.isSaving = false;
                this.dialogRef.close({success: true});
            }, (error: any) => {
                this.isSaving = false;
                this.notificationService.showAndSubscribe(error.error.message, 'CLOSE');
            });
        }
    }

    disableForm() {
        this.form.get('firstname')?.disable();
        this.form.get('lastname')?.disable();
    }

    enableForm() {
        this.form.get('firstname')?.enable();
        this.form.get('lastname')?.enable();
    }

    private enableFormAndSetSaving(): void {
        this.enableForm();
        this.isSaving = false;
    }

    close(): void {
        if (!this.isLoading) {
            this.dialogRef.close(null);
        }
    }

    loadedImg(e: any) {
        let img = (e?.target as HTMLElement) as HTMLElement;
        let mask = (e?.target as HTMLElement)?.previousSibling as HTMLElement;
        img?.classList?.remove('opacity-0');
        img?.classList?.add('opacity-1');
        mask?.classList?.remove('opacity-70', 'min-h-100px');
        mask?.classList?.add('opacity-0');
    }

    get firstname() { return this.form.get('firstname'); }
    get lastname() { return this.form.get('lastname'); }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }
}
