import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { SelectedZone } from 'src/app/shared/models';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  imagesDir: { url: string, link: any }[] = [];
  buttons: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private zonesService: ZonesService, private dynamicConfigService: DynamicCOnfigService) {
    // this.zonesService.subjectSelectedZones$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((zone) => {
    //     this.imagesDir = [];
    //     let headers = new HttpHeaders()
    //       .set('Access-Control-Allow-Origin', '*')
    //       .set('Content-Type', 'application/json; charset=utf-8')
    //       .set('Accept', 'application/json;')
    //       .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
    //       .set('Pragma', 'no-cache')
    //       .set('Expires', '0')
    //       .set('x-refresh', 'true');

    //     const bannersUrl = environment.promotion_images_url_country_iso.replace('{country_iso}', zone?.area_selected || 'CU');

    //     this.http.get(bannersUrl, { headers })
    //       .pipe(takeUntil(this.destroy$))
    //       .subscribe(dirs => {
    //         this.imagesDir = <{ url: string }[]>dirs;
    //       });
    //   });
    this.dynamicConfigService.dynamic_settings_subject.subscribe((data: any) => {
      this.getGlobalConfig();
    })
  }

  ngOnInit(): void {
    this.getGlobalConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  getGlobalConfig() {
    this.imagesDir = [];

    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      this.buttons = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.MainBanner?.data?.attributes?.Buttons;
      this.imagesDir = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.MainBanner?.data?.attributes?.Images.map((data: any) => {
        return {
          url: data?.Image?.data?.attributes?.formats?.large?.url ?
            data?.Image?.data?.attributes?.formats?.large?.url : data?.Image?.data?.attributes?.formats?.medium?.url,
          link: data?.Link,
          button_bg_color: data?.ButtonBackgroundColor !== null ? data?.ButtonBackgroundColor : null,
          button_position: data?.ButtonPosition !== null ? this.setButtonPosition(data?.ButtonPosition) : null,
          button_text_color: data?.ButtonTextForegroundColor !== null ? data?.ButtonTextForegroundColor : null,
          button_text: JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.MainBanner?.data?.attributes?.SeeMoreButtonText
        }
      })
    }
  }

  setButtonPosition(position: string) {
    switch (position) {
      case POSITIONS.TOP_LEFT:
        return 'top-2 left-2';
      case POSITIONS.TOP_RIGHT:
        return 'top-2 right-2';
      case POSITIONS.BOTTOM_LEFT:
        return 'bottom-2 left-2';
      case POSITIONS.BOTTOM_RIGHT:
        return 'bottom-2 right-2';
      case POSITIONS.CENTER:
        return 'top-1/2 left-1/2';
      case POSITIONS.CENTER_LEFT:
        return 'top-1/2 left-2';
      case POSITIONS.CENTER_BOTTOM:
        return 'bottom-2 left-1/2';
      case POSITIONS.CENTER_RIGHT:
        return 'right-2 top-1/2';
      case POSITIONS.CENTER_TOP:
        return 'right-1/2 top-2';
      default:
        break;
    }
    return null;
  }
}

export enum POSITIONS {
  TOP_LEFT = 'TopLeft',
  TOP_RIGHT = 'TopRight',
  BOTTOM_LEFT = 'BottonLeft',
  BOTTOM_RIGHT = 'BottonRight',
  CENTER = 'Center',
  CENTER_TOP = 'CenterTop',
  CENTER_BOTTOM = 'CenterBotton',
  CENTER_LEFT = 'CenterLeft',
  CENTER_RIGHT = 'CenterRight'
}
