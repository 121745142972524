import {Component, OnInit, Output, EventEmitter, OnDestroy, Input, AfterViewInit} from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    _turnstileCb?: any;
    turnstile: any;
  }
}

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() captchaInstance: string = '';
  @Input() showCaptchaError: boolean = false;
  @Output() onCaptchaCheck: EventEmitter<string> = new EventEmitter<string>();
  // show_skeleton: boolean = true;

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit() {
      if (window.turnstile) {
          this.render()
      } else {
          window._turnstileCb = () => {
              this.render();
          }
      }
  }

  ngOnDestroy() {
      this.destroyWidget();
  }

    render() {
    // this.show_skeleton = false;
    window.turnstile.render(`#myWidget${this.captchaInstance}`, {
      sitekey: environment.captcha_key,
      theme: 'light',
      callback: (token: any) => {
        this.sendToPArent(token);
      },
    });
  }

  destroyWidget() {
    // this.show_skeleton = true;
    window.turnstile.remove();
  }

  resetWidget() {
    // this.show_skeleton = true;
    window.turnstile.reset();
  }

  sendToPArent(token: string) {
    this.onCaptchaCheck.emit(token);
    // this.destroyWidget();
  }
}
