import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ZonesService} from "../../../../shared/services/zones.service";
import {Subject} from "rxjs";
import {Country, Municipality, SelectedZone, StrapiResponseData, Zone} from "../../../../shared/models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {MunicipalitiesService} from "../../../../shared/services/municipalities.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../../../shared/services/notification.service";
import {Router} from "@angular/router";
import {CheckoutService} from "../../../../shared/services/checkout.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  isLoadingAgreement = true;
  agreementQuery: { uri: string,  variables: string[] };

  agreementData: StrapiResponseData | undefined;

  constructor(
    private dialogRef: MatDialogRef<AgreementDialogComponent>,
    private notificationService: NotificationService,
    private checkoutService: CheckoutService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.agreementQuery = data.agreementQuery;

  }

  ngOnInit(): void {
    this.checkoutService.getAgreementPaymentVariant(this.agreementQuery, this.translateService.currentLang)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: StrapiResponseData) => {
        this.isLoadingAgreement = false;
        this.agreementData = response;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit() {
    this.dialogRef.close();
  }
}
