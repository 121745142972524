<section class="relative bg-white
us:w-auto us:min-h-144px us:h-auto us:p-4
lg-landscape:w-auto lg-landscape:min-h-144px lg-landscape:h-auto lg-landscape:p-4">
  <fa-icon (click)="close()"
           class="absolute top-2 right-3 cursor-pointer flex justify-center items-center text-yellow-#ff4103 lg-landscape:text-base"
           [icon]="faClose"></fa-icon>
  <p class="relative font-Lato-Regular text-sm font-semibold px-2" [ngClass]="{'uppercase': messageUppercase}" >{{data.message | translate}}</p>
  <div class="relative w-full h-px bg-gray-300 my-4"></div>
  <div class="relative flex justify-start items-center mt-6 gap-4 w-full">
    <button (click)="onSubmit()" [disabled]="isLoading" [ngClass]="{'opacity-50': isLoading}"
            class="flex items-center justify-center relative bg-yellow-#FFC35D font-Lato-Regular font-semibold text-sm border-none active:ring-0
            active:border-none focus:ring-0 focus:border-none py-2 rounded-3px focus:outline-none">
      <div [ngClass]="{'px-6': !isLoading, 'pl-6': isLoading}">{{confirmAction | translate}}</div>
      <fa-icon *ngIf="isLoading"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <button (click)="close()" [disabled]="isLoading" [ngClass]="{'opacity-50 border': isLoading, 'hover:bg-gray-#EEEEEE': !isLoading}"
            class="flex items-center justify-center relative bg-white font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none px-3 py-2 rounded-3px">
      <div>{{cancelAction | translate}}</div>
    </button>
  </div>
</section>
