<section class="relative bg-white w-auto max-w-xl min-h-144px h-auto p-4 bg-gray-#f2f2f2">
    <fa-icon (click)="close()"
             class="absolute top-2 right-3 cursor-pointer flex justify-center items-center text-yellow-#ff4103 lg-landscape:text-base"
             [icon]="faClose"></fa-icon>
    <p class="relative font-Lato-Regular text-sm font-semibold px-2 uppercase">{{data.title | translate}}</p>
    <div class="relative w-full h-px bg-gray-300 my-4"></div>

    <div class="overflow-y-scroll relative font-Lato-Regular text-sm px-2" [ngClass]="isVerificationAccount ? 'max-h-60vh' : 'max-h-70vh'">
        <div *ngIf="image" class="flex items-center justify-center mb-2 h-44">
<!--            <img class="relative w-44" [src]="image" alt="">-->

            <div class="grid-img absolute w-44 h-44 z-10 opacity-70 transition-all ease-in-out duration-700">
            </div>
            <img (load)="loadedImg($event)" class="relative object-contain h-44 transition-all ease-in-out duration-700 opacity-0"
                 src="{{image || '../../../../../assets/imgs/product-card/no-product-image'}}" alt="">
        </div>

        <div [innerHTML]="text" class="relative w-full h-auto text-justify"></div>
        <div class="leading-4 text-right text-10px text-blue-#203868">Powered By Onfido</div>
    </div>

    <div *ngIf="isVerificationAccount" class="flex gap-2 mt-3" [formGroup]="form">
        <div class="flex flex-1 flex-col relative pb-6">
            <input placeholder="{{ 'NAME' | translate }}*" formControlName="firstname" id="firstname"
                   [ngClass]="{
                  'border-none': firstname?.valid || (firstname?.invalid && !(firstname?.dirty || firstname?.touched)),
                  'border-red-500': (firstname?.invalid && formMarkAsTouched) || (firstname?.invalid && (firstname?.dirty || firstname?.touched))
                  }"
                   class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
            <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)"
                 class="absolute bottom-0.5 text-sm text-red-500">
                <p *ngIf="firstname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="firstname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
                <p *ngIf="firstname?.hasError('pattern') && !firstname?.hasError('maxlength')">
                    {{ 'CHECK_WORK_SPACING' | translate }}
                </p>
            </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
            <input placeholder="{{ 'SURNAMES' | translate }}*" formControlName="lastname" id="lastname"
                   [ngClass]="{
                  'border-none': lastname?.valid || (lastname?.invalid && !(lastname?.dirty || lastname?.touched)),
                  'border-red-500': (lastname?.invalid && formMarkAsTouched) || (lastname?.invalid && (lastname?.dirty || lastname?.touched))
                  }"
                   class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                        focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
            <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)"
                 class="absolute bottom-0.5 text-sm text-red-500">
                <p *ngIf="lastname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="lastname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
                <p *ngIf="lastname?.hasError('pattern') && !lastname?.hasError('maxlength')">{{ 'CHECK_WORK_SPACING' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="relative flex justify-start items-center gap-4" [ngClass]="isVerificationAccount ? 'mt-1' : 'mt-3'">
        <button (click)="onSubmit()" [disabled]="isSaving" [ngClass]="{'opacity-50': isSaving}"
                class="flex items-center justify-center relative bg-yellow-#FFC35D font-Lato-Regular font-semibold text-sm border-none active:ring-0
            active:border-none focus:ring-0 focus:border-none py-2 rounded-3px focus:outline-none">
            <div [ngClass]="{'px-6': !isSaving, 'pl-6': isSaving}">{{confirmAction | translate}}</div>
            <fa-icon *ngIf="isSaving"
                     class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </button>
        <button [disabled]="isSaving" mat-dialog-close="close" [ngClass]="{'opacity-50': isSaving, 'hover:bg-gray-#EEEEEE': !isSaving}"
                class="relative bg-white font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none px-3 py-2 rounded-3px">
            {{cancelAction | translate}}
        </button>
    </div>
</section>
