import {Component, Input, OnChanges, OnDestroy, OnInit} from "@angular/core";
import {SEARCH_FILTER_DEFAULT_VALUE, SEARCH_FILTERS, SearchFiltersType} from "../../../../shared/filters/searc-filters";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SelectedZone} from "../../../../shared/models";
import {ActivatedRoute, Router} from "@angular/router";
import {ZonesService} from "../../../../shared/services/zones.service";

@Component({
  selector: 'app-stores-filters-sort-and-view',
  templateUrl: './stores-filters-sort-and-view.component.html'
})
export class StoresFiltersSortAndViewComponent implements OnDestroy, OnChanges {
  filterForm: FormGroup;
  @Input() order: any = SEARCH_FILTER_DEFAULT_VALUE;
  @Input() products: any[] = [];
  @Input() showGrid: boolean = true;
  show_grid = true;

  selected_view = "grid";
  isInitChange: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  orderByArray: SearchFiltersType[] = SEARCH_FILTERS;

  viewModeArray: { filter: string, text: string }[] = [
    {filter: 'grid', text: 'GRID'},
    {filter: 'list', text: 'LIST'}
  ];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private zonesService: ZonesService
  ) {
    this.order = SEARCH_FILTER_DEFAULT_VALUE;
    this.filterForm = new FormGroup({
      order: new FormControl({value: this.order, disabled: !this.getValidSelectedZone}),
    });
    this.filterForm.get('order')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.getSelectedZone && !this.isInitChange) {
          this.router.navigate(
            [],
            {
              relativeTo: this.activeRoute,
              queryParams: {order: this.filterForm.get('order')?.value?.text},
              queryParamsHandling: 'merge'
            }
          );
        }
        if (this.isInitChange) {
          this.isInitChange = false;
        }
      })
  }

  ngOnChanges(changes: any): void {
    if (changes.products = null) {
      this.products = [];
    }
    if (changes && changes.order && changes.order.currentValue && changes.order.previousValue === undefined) {
      this.filterForm.patchValue({
        order: SEARCH_FILTERS.find((filter: any) => filter?.text === changes.order.currentValue) || SEARCH_FILTER_DEFAULT_VALUE
      });
    }
    if (changes && changes.showGrid) {
      this.selected_view = changes.showGrid.currentValue === true ? 'grid' : 'list';
      ['true', true].includes(changes?.showGrid?.currentValue) ? this.show_grid = true : this.show_grid = false;
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  get getValidSelectedZone(): boolean {
    const selectedZone = this.getSelectedZone;
    if (!selectedZone) return false;
    if (selectedZone?.area_selected) {
      if (selectedZone?.area_selected === 'CU') {
        return !!selectedZone?.zone && !!selectedZone?.municipality;
      }
      return !!selectedZone?.zone;
    }
    return false;
  }

  viewMode(e: any) {
    const value = e?.value;
    let params: any = {};
    switch (value) {
      case 'grid':
        params.show_grid = true;
        this.router.navigate(
          [],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
        break;
      case value:
        params.show_grid = false;
        this.router.navigate(
          [],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }
}
