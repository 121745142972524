import {State} from "./state.model";

export interface Country {
  id: number,
  iso_name: string,
  iso: string,
  iso3: string,
  name: string,
  numcode: number,
  states?: State[],
}
