import { Taxon } from "./taxon.model";
import { Observable } from "rxjs";
import { Store } from "./store.model";

export interface Product {
    id: number;
    imageUrl: any;
    isFavorite: boolean;
    name: string;
    price: number;
    isLowStock: boolean;
    inStock: boolean;
    quantity: number;
    currency: string;
    discount: any;
    top?: number;
    description?: string;
    optionsText?: string;
    variants?: any;
    imagesUrl?: any;
    categories?: Taxon[];
    defaultVariant?: number;
    notes?: any;
    slug?: string;
    weight?: number;
    width?: number;
    height?: number;
    sku?: string;
    shipping_category?: string;
    data_extra_types?: { id: number, value: string }[];
    store?: any;
    compare_at_price: number;
    previous_price: number;
    meta_description?: string;
    age_valid?: any;
    productType?: number;
    displayDefaultPrice?: number;
    isOutStock?: boolean;
    shippingLabel?: string;
    images?: any;
    queryId?: string
    queryPosition?: number
}
