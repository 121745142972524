import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SelectedZone } from 'src/app/shared/models';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-gift-product-card',
  templateUrl: './gift-product-card.component.html',
  styleUrls: ['./gift-product-card.component.scss']
})
export class GiftProductCardComponent implements OnInit {

  @Input() imageUrl: string = '../../../../../assets/lanave.png';
  @Input() turn: boolean = false;
  @Input() slug: any = '';
  @Input() id: any = '';
  @Input() display_usd_amount: number = 0;
  @Input() usd_amount: number = 0;
  @Input() name: string = '';
  @Input() resize: boolean = false;
  // @Input() usd_amount: boolean = false;
  // @Input() cup_amount: boolean = false;
  // @Input() display_cup_amount: boolean = false;
  // @Output() send_data = new EventEmitter<any>();
  @Output() send_data = new EventEmitter<any>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private zonesService: ZonesService,
    private dialog: MatDialog,
    private checkoutService: CheckoutService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  loadedImg(e: any) {
    let img = (e?.target as HTMLElement) as HTMLElement;
    let mask = (e?.target as HTMLElement)?.previousSibling as HTMLElement;
    img?.classList?.remove('opacity-0')
    img?.classList?.add('opacity-1');
    mask?.classList?.remove('opacity-70', 'min-h-100px');
    mask?.classList?.add('opacity-0');
  }

  buyGiftCard() {
    this.send_data.next({ paymentAmount: this.usd_amount, display_usd: this.display_usd_amount, giftCardProductId: this.id });
  }

}
