import { Component, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { NotificationModalComponent } from "src/app/modules/shared/components/notification-modal/notification-modal.component";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) { }

  success(message: string = "success"): void {
    this.translateService
      .get(message)
      .subscribe((msg) => this.showSuccessNotification(msg));
  }

  error(message: string): void {
    if (!message || message.length === 0) {
      this.translateService
        .get("error")
        .subscribe((msg) => this.showErrorNotification(msg));
    } else {
      this.showErrorNotification(message);
    }
  }

  notificationComponent(componentNotification: any, durationMilliseconds: number = 0) {
    this.snackBar.openFromComponent(componentNotification, {
      duration: durationMilliseconds,
      verticalPosition: 'top'
    });
  }

  private showErrorNotification(message: string) {
    this.snackBar.open(message, "Ok", {
      duration: 5000,
      panelClass: "error-notification-snackbar",
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  private showSuccessNotification(message: string) {
    this.snackBar.open(message, "Ok", {
      duration: 5000,
      panelClass: "success-notification-snackbar",
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  showAndSubscribe(msg: string, btn_ok_text: string, btn_cancel_txt?: string, disableClose: boolean = false, redirect?: string, isCloseAll: boolean = false) {
    if (isCloseAll) {
      this.dialog.closeAll();
    }
    return this.dialog.open(NotificationModalComponent, {
      // backdropClass: '',
      disableClose: disableClose,
      width: 'auto',
      data: {
        msg: msg,
        btn_ok_text: btn_ok_text,
        btn_cancel_txt: btn_cancel_txt,
        redirect: redirect,
        disableClose
      },
    })
  }

}
