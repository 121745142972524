export const homeSettingsMock = {
    "data": [
        {
            "id": 1,
            "attributes": {
                "CountryCode": "cu",
                "Env": "Development",
                "createdAt": "2023-05-09T03:14:50.624Z",
                "updatedAt": "2024-04-01T21:18:16.115Z",
                "publishedAt": "2023-05-09T03:15:59.869Z",
                "locale": "es",
                "StoresDirBanner": {
                    "data": {
                        "id": 1,
                        "attributes": {
                            "InternalName": "Directorio Tiendas Cuba",
                            "createdAt": "2023-05-09T03:23:34.057Z",
                            "updatedAt": "2023-05-18T18:25:06.946Z",
                            "publishedAt": "2023-05-09T03:23:36.900Z",
                            "locale": "es",
                            "Image": {
                                "id": 12,
                                "Link": null,
                                "ButtonPosition": null,
                                "ButtonBackgroundColor": null,
                                "ButtonTextForegroundColor": null,
                                "Image": {
                                    "data": {
                                        "id": 233,
                                        "attributes": {
                                            "name": "tiendas-desktop.webp",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 905,
                                            "height": 116,
                                            "formats": {
                                                "small": {
                                                    "ext": ".webp",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/small_tiendas_desktop_efbc0bea1e.webp",
                                                    "hash": "small_tiendas_desktop_efbc0bea1e",
                                                    "mime": "image/webp",
                                                    "name": "small_tiendas-desktop.webp",
                                                    "path": null,
                                                    "size": 4.77,
                                                    "width": 500,
                                                    "height": 64
                                                },
                                                "medium": {
                                                    "ext": ".webp",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/medium_tiendas_desktop_efbc0bea1e.webp",
                                                    "hash": "medium_tiendas_desktop_efbc0bea1e",
                                                    "mime": "image/webp",
                                                    "name": "medium_tiendas-desktop.webp",
                                                    "path": null,
                                                    "size": 7.16,
                                                    "width": 750,
                                                    "height": 96
                                                },
                                                "thumbnail": {
                                                    "ext": ".webp",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_tiendas_desktop_efbc0bea1e.webp",
                                                    "hash": "thumbnail_tiendas_desktop_efbc0bea1e",
                                                    "mime": "image/webp",
                                                    "name": "thumbnail_tiendas-desktop.webp",
                                                    "path": null,
                                                    "size": 2.22,
                                                    "width": 245,
                                                    "height": 31
                                                }
                                            },
                                            "hash": "tiendas_desktop_efbc0bea1e",
                                            "ext": ".webp",
                                            "mime": "image/webp",
                                            "size": 9.31,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/tiendas_desktop_efbc0bea1e.webp",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-18T18:25:01.659Z",
                                            "updatedAt": "2023-05-18T18:25:01.659Z"
                                        }
                                    }
                                }
                            },
                            "ResponsiveImage": {
                                "id": 34,
                                "Link": null,
                                "ButtonPosition": null,
                                "ButtonBackgroundColor": null,
                                "ButtonTextForegroundColor": null,
                                "Image": {
                                    "data": {
                                        "id": 232,
                                        "attributes": {
                                            "name": "tiendas-mobile.webp",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 476,
                                            "height": 200,
                                            "formats": {
                                                "thumbnail": {
                                                    "ext": ".webp",
                                                    "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_tiendas_mobile_dd54ce0a15.webp",
                                                    "hash": "thumbnail_tiendas_mobile_dd54ce0a15",
                                                    "mime": "image/webp",
                                                    "name": "thumbnail_tiendas-mobile.webp",
                                                    "path": null,
                                                    "size": 3.44,
                                                    "width": 245,
                                                    "height": 103
                                                }
                                            },
                                            "hash": "tiendas_mobile_dd54ce0a15",
                                            "ext": ".webp",
                                            "mime": "image/webp",
                                            "size": 7.42,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/tiendas_mobile_dd54ce0a15.webp",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-18T18:24:27.317Z",
                                            "updatedAt": "2023-05-18T18:24:27.317Z"
                                        }
                                    }
                                }
                            },
                            "Button": {
                                "id": 7,
                                "Label": "Quiero vender en Katapulk",
                                "BackgroundColor": null,
                                "RouteMobile": null,
                                "Leading": {
                                    "data": {
                                        "id": 102,
                                        "attributes": {
                                            "name": "icon3-tiendas2.svg",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 1200,
                                            "height": 1200,
                                            "formats": null,
                                            "hash": "icon3_tiendas2_57f3496924",
                                            "ext": ".svg",
                                            "mime": "image/svg+xml",
                                            "size": 6.74,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/icon3_tiendas2_57f3496924.svg",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T03:04:32.104Z",
                                            "updatedAt": "2023-05-09T03:11:08.897Z"
                                        }
                                    }
                                },
                                "Trailing": {
                                    "data": {
                                        "id": 70,
                                        "attributes": {
                                            "name": "icon-arrowright.svg",
                                            "alternativeText": null,
                                            "caption": null,
                                            "width": 24,
                                            "height": 27,
                                            "formats": null,
                                            "hash": "icon_arrowright_d1b6773c42",
                                            "ext": ".svg",
                                            "mime": "image/svg+xml",
                                            "size": 0.31,
                                            "url": "https://strapi-katapulk.s3.amazonaws.com/icon_arrowright_d1b6773c42.svg",
                                            "previewUrl": null,
                                            "provider": "aws-s3",
                                            "provider_metadata": null,
                                            "createdAt": "2023-05-09T00:37:38.393Z",
                                            "updatedAt": "2023-05-09T03:11:17.116Z"
                                        }
                                    }
                                },
                                "Route": {
                                    "data": {
                                        "id": 14,
                                        "attributes": {
                                            "Route": "cu/stores/k-seller",
                                            "createdAt": "2023-05-09T01:26:43.977Z",
                                            "updatedAt": "2023-05-09T01:26:45.903Z",
                                            "publishedAt": "2023-05-09T01:26:45.899Z"
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "MainBanner": {
                    "data": {
                        "id": 1,
                        "attributes": {
                            "InternalName": "Default Cuba Dev",
                            "createdAt": "2023-05-09T03:06:49.206Z",
                            "updatedAt": "2024-04-12T03:14:34.752Z",
                            "publishedAt": "2023-05-09T03:11:35.438Z",
                            "locale": "es",
                            "SeeMoreButtonText": "Ver más",
                            "Images": [
                                {
                                    "id": 1326,
                                    "Link": "https://www.katapulk.com/cu/products/papel-sanitario-4-rollos-060-001-00341",
                                    "ButtonPosition": "TopLeft",
                                    "ButtonBackgroundColor": "#7a9614",
                                    "ButtonTextForegroundColor": "#ffffff",
                                    "Image": {
                                        "data": {
                                            "id": 336,
                                            "attributes": {
                                                "name": "89833132-ef6a-11ed-9e80-0242ac130002.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1830,
                                                "height": 536,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3.webp",
                                                        "hash": "large_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3",
                                                        "mime": "image/webp",
                                                        "name": "large_89833132-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 36.31,
                                                        "width": 1000,
                                                        "height": 293
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3.webp",
                                                        "hash": "small_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3",
                                                        "mime": "image/webp",
                                                        "name": "small_89833132-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 14.99,
                                                        "width": 500,
                                                        "height": 146
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3.webp",
                                                        "hash": "medium_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3",
                                                        "mime": "image/webp",
                                                        "name": "medium_89833132-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 25.41,
                                                        "width": 750,
                                                        "height": 220
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3.webp",
                                                        "hash": "thumbnail_89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_89833132-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 5.41,
                                                        "width": 245,
                                                        "height": 72
                                                    }
                                                },
                                                "hash": "89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 79.8,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/89833132_ef6a_11ed_9e80_0242ac130002_14b49a55f3.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-22T18:09:46.694Z",
                                                "updatedAt": "2023-11-14T18:54:12.717Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 1327,
                                    "Link": "https://www.katapulk.com/cu/products/papel-sanitario-4-rollos-060-001-00341",
                                    "ButtonPosition": "TopRight",
                                    "ButtonBackgroundColor": "#cd0000",
                                    "ButtonTextForegroundColor": "#ffffff",
                                    "Image": {
                                        "data": {
                                            "id": 335,
                                            "attributes": {
                                                "name": "8982931c-ef6a-11ed-9e80-0242ac130002.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1830,
                                                "height": 536,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639.webp",
                                                        "hash": "large_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639",
                                                        "mime": "image/webp",
                                                        "name": "large_8982931c-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 43.31,
                                                        "width": 1000,
                                                        "height": 293
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639.webp",
                                                        "hash": "small_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639",
                                                        "mime": "image/webp",
                                                        "name": "small_8982931c-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 16.74,
                                                        "width": 500,
                                                        "height": 146
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639.webp",
                                                        "hash": "medium_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639",
                                                        "mime": "image/webp",
                                                        "name": "medium_8982931c-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 29.35,
                                                        "width": 750,
                                                        "height": 220
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639.webp",
                                                        "hash": "thumbnail_8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_8982931c-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 6.07,
                                                        "width": 245,
                                                        "height": 72
                                                    }
                                                },
                                                "hash": "8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 111.03,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/8982931c_ef6a_11ed_9e80_0242ac130002_0d06167639.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-22T18:09:46.132Z",
                                                "updatedAt": "2023-05-22T18:09:46.132Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 1328,
                                    "Link": "https://www.katapulk.com/cu/mercado-unico",
                                    "ButtonPosition": "BottonLeft",
                                    "ButtonBackgroundColor": "#ffffff",
                                    "ButtonTextForegroundColor": "#cc8400",
                                    "Image": {
                                        "data": {
                                            "id": 334,
                                            "attributes": {
                                                "name": "89827b3e-ef6a-11ed-9e80-0242ac130002.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1830,
                                                "height": 536,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0.webp",
                                                        "hash": "large_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0",
                                                        "mime": "image/webp",
                                                        "name": "large_89827b3e-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 32.54,
                                                        "width": 1000,
                                                        "height": 293
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0.webp",
                                                        "hash": "small_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0",
                                                        "mime": "image/webp",
                                                        "name": "small_89827b3e-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 14.05,
                                                        "width": 500,
                                                        "height": 146
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0.webp",
                                                        "hash": "medium_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0",
                                                        "mime": "image/webp",
                                                        "name": "medium_89827b3e-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 23.23,
                                                        "width": 750,
                                                        "height": 220
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0.webp",
                                                        "hash": "thumbnail_89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_89827b3e-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 5.4,
                                                        "width": 245,
                                                        "height": 72
                                                    }
                                                },
                                                "hash": "89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 74.67,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/89827b3e_ef6a_11ed_9e80_0242ac130002_142058a2e0.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-22T18:09:45.538Z",
                                                "updatedAt": "2023-05-22T18:09:45.538Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 1329,
                                    "Link": "https://www.katapulk.com/cu/mercado-unico",
                                    "ButtonPosition": "BottonRight",
                                    "ButtonBackgroundColor": "#f1bfbf",
                                    "ButtonTextForegroundColor": "#000000",
                                    "Image": {
                                        "data": {
                                            "id": 333,
                                            "attributes": {
                                                "name": "8982b90a-ef6a-11ed-9e80-0242ac130002.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1830,
                                                "height": 536,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9.webp",
                                                        "hash": "large_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9",
                                                        "mime": "image/webp",
                                                        "name": "large_8982b90a-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 38.3,
                                                        "width": 1000,
                                                        "height": 293
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9.webp",
                                                        "hash": "small_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9",
                                                        "mime": "image/webp",
                                                        "name": "small_8982b90a-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 14.95,
                                                        "width": 500,
                                                        "height": 146
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9.webp",
                                                        "hash": "medium_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9",
                                                        "mime": "image/webp",
                                                        "name": "medium_8982b90a-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 26.33,
                                                        "width": 750,
                                                        "height": 220
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9.webp",
                                                        "hash": "thumbnail_8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_8982b90a-ef6a-11ed-9e80-0242ac130002.webp",
                                                        "path": null,
                                                        "size": 5.43,
                                                        "width": 245,
                                                        "height": 72
                                                    }
                                                },
                                                "hash": "8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 87.57,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/8982b90a_ef6a_11ed_9e80_0242ac130002_99ce8969d9.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-22T18:09:44.260Z",
                                                "updatedAt": "2023-05-30T15:25:13.647Z"
                                            }
                                        }
                                    }
                                }
                            ],
                            "Buttons": [
                                {
                                    "id": 1,
                                    "Label": null,
                                    "BackgroundColor": "#F6C46E",
                                    "RouteMobile": null,
                                    "Leading": {
                                        "data": {
                                            "id": 2014,
                                            "attributes": {
                                                "name": "pictogramas sitio.png",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1676,
                                                "height": 532,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".png",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_pictogramas_sitio_377253e88b.png",
                                                        "hash": "large_pictogramas_sitio_377253e88b",
                                                        "mime": "image/png",
                                                        "name": "large_pictogramas sitio.png",
                                                        "path": null,
                                                        "size": 28.26,
                                                        "width": 1000,
                                                        "height": 317
                                                    },
                                                    "small": {
                                                        "ext": ".png",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_pictogramas_sitio_377253e88b.png",
                                                        "hash": "small_pictogramas_sitio_377253e88b",
                                                        "mime": "image/png",
                                                        "name": "small_pictogramas sitio.png",
                                                        "path": null,
                                                        "size": 12.51,
                                                        "width": 500,
                                                        "height": 159
                                                    },
                                                    "medium": {
                                                        "ext": ".png",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_pictogramas_sitio_377253e88b.png",
                                                        "hash": "medium_pictogramas_sitio_377253e88b",
                                                        "mime": "image/png",
                                                        "name": "medium_pictogramas sitio.png",
                                                        "path": null,
                                                        "size": 19.54,
                                                        "width": 750,
                                                        "height": 238
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".png",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_pictogramas_sitio_377253e88b.png",
                                                        "hash": "thumbnail_pictogramas_sitio_377253e88b",
                                                        "mime": "image/png",
                                                        "name": "thumbnail_pictogramas sitio.png",
                                                        "path": null,
                                                        "size": 5.52,
                                                        "width": 245,
                                                        "height": 78
                                                    }
                                                },
                                                "hash": "pictogramas_sitio_377253e88b",
                                                "ext": ".png",
                                                "mime": "image/png",
                                                "size": 9.53,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/pictogramas_sitio_377253e88b.png",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2024-04-12T03:14:27.638Z",
                                                "updatedAt": "2024-04-12T03:14:27.638Z"
                                            }
                                        }
                                    },
                                    "Trailing": {
                                        "data": {
                                            "id": 70,
                                            "attributes": {
                                                "name": "icon-arrowright.svg",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 24,
                                                "height": 27,
                                                "formats": null,
                                                "hash": "icon_arrowright_d1b6773c42",
                                                "ext": ".svg",
                                                "mime": "image/svg+xml",
                                                "size": 0.31,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/icon_arrowright_d1b6773c42.svg",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-09T00:37:38.393Z",
                                                "updatedAt": "2023-05-09T03:11:17.116Z"
                                            }
                                        }
                                    },
                                    "Route": {
                                        "data": {
                                            "id": 7,
                                            "attributes": {
                                                "Route": "cu/recargas-a-cuba",
                                                "createdAt": "2023-05-09T01:24:38.511Z",
                                                "updatedAt": "2023-05-09T01:24:40.214Z",
                                                "publishedAt": "2023-05-09T01:24:40.209Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 2,
                                    "Label": null,
                                    "BackgroundColor": "#f6c46e",
                                    "RouteMobile": "/remittance_page_route_name",
                                    "Leading": {
                                        "data": {
                                            "id": 1420,
                                            "attributes": {
                                                "name": "wu_rem.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 6979,
                                                "height": 1621,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_wu_rem_36e7d055ea.webp",
                                                        "hash": "large_wu_rem_36e7d055ea",
                                                        "mime": "image/webp",
                                                        "name": "large_wu_rem.webp",
                                                        "path": null,
                                                        "size": 16.14,
                                                        "width": 1000,
                                                        "height": 232
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_wu_rem_36e7d055ea.webp",
                                                        "hash": "small_wu_rem_36e7d055ea",
                                                        "mime": "image/webp",
                                                        "name": "small_wu_rem.webp",
                                                        "path": null,
                                                        "size": 7.78,
                                                        "width": 500,
                                                        "height": 116
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_wu_rem_36e7d055ea.webp",
                                                        "hash": "medium_wu_rem_36e7d055ea",
                                                        "mime": "image/webp",
                                                        "name": "medium_wu_rem.webp",
                                                        "path": null,
                                                        "size": 12.06,
                                                        "width": 750,
                                                        "height": 174
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_wu_rem_36e7d055ea.webp",
                                                        "hash": "thumbnail_wu_rem_36e7d055ea",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_wu_rem.webp",
                                                        "path": null,
                                                        "size": 3.68,
                                                        "width": 245,
                                                        "height": 57
                                                    }
                                                },
                                                "hash": "wu_rem_36e7d055ea",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 59.67,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/wu_rem_36e7d055ea.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-12-05T03:24:02.014Z",
                                                "updatedAt": "2023-12-05T03:24:02.014Z"
                                            }
                                        }
                                    },
                                    "Trailing": {
                                        "data": {
                                            "id": 70,
                                            "attributes": {
                                                "name": "icon-arrowright.svg",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 24,
                                                "height": 27,
                                                "formats": null,
                                                "hash": "icon_arrowright_d1b6773c42",
                                                "ext": ".svg",
                                                "mime": "image/svg+xml",
                                                "size": 0.31,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/icon_arrowright_d1b6773c42.svg",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-09T00:37:38.393Z",
                                                "updatedAt": "2023-05-09T03:11:17.116Z"
                                            }
                                        }
                                    },
                                    "Route": {
                                        "data": {
                                            "id": 67,
                                            "attributes": {
                                                "Route": "remittance/main",
                                                "createdAt": "2023-06-27T16:33:48.494Z",
                                                "updatedAt": "2023-06-27T16:33:50.199Z",
                                                "publishedAt": "2023-06-27T16:33:50.124Z"
                                            }
                                        }
                                    }
                                },
                                {
                                    "id": 3,
                                    "Label": null,
                                    "BackgroundColor": "#f6c46e",
                                    "RouteMobile": "/stores",
                                    "Leading": {
                                        "data": {
                                            "id": 1454,
                                            "attributes": {
                                                "name": "tiendas completo new.webp",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 1676,
                                                "height": 531,
                                                "formats": {
                                                    "large": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/large_tiendas_completo_new_2332739761.webp",
                                                        "hash": "large_tiendas_completo_new_2332739761",
                                                        "mime": "image/webp",
                                                        "name": "large_tiendas completo new.webp",
                                                        "path": null,
                                                        "size": 14.98,
                                                        "width": 1000,
                                                        "height": 317
                                                    },
                                                    "small": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/small_tiendas_completo_new_2332739761.webp",
                                                        "hash": "small_tiendas_completo_new_2332739761",
                                                        "mime": "image/webp",
                                                        "name": "small_tiendas completo new.webp",
                                                        "path": null,
                                                        "size": 7.05,
                                                        "width": 500,
                                                        "height": 158
                                                    },
                                                    "medium": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/medium_tiendas_completo_new_2332739761.webp",
                                                        "hash": "medium_tiendas_completo_new_2332739761",
                                                        "mime": "image/webp",
                                                        "name": "medium_tiendas completo new.webp",
                                                        "path": null,
                                                        "size": 10.52,
                                                        "width": 750,
                                                        "height": 238
                                                    },
                                                    "thumbnail": {
                                                        "ext": ".webp",
                                                        "url": "https://strapi-katapulk.s3.amazonaws.com/thumbnail_tiendas_completo_new_2332739761.webp",
                                                        "hash": "thumbnail_tiendas_completo_new_2332739761",
                                                        "mime": "image/webp",
                                                        "name": "thumbnail_tiendas completo new.webp",
                                                        "path": null,
                                                        "size": 3.85,
                                                        "width": 245,
                                                        "height": 78
                                                    }
                                                },
                                                "hash": "tiendas_completo_new_2332739761",
                                                "ext": ".webp",
                                                "mime": "image/webp",
                                                "size": 17.28,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/tiendas_completo_new_2332739761.webp",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-12-05T17:05:46.062Z",
                                                "updatedAt": "2023-12-05T17:05:46.062Z"
                                            }
                                        }
                                    },
                                    "Trailing": {
                                        "data": {
                                            "id": 70,
                                            "attributes": {
                                                "name": "icon-arrowright.svg",
                                                "alternativeText": null,
                                                "caption": null,
                                                "width": 24,
                                                "height": 27,
                                                "formats": null,
                                                "hash": "icon_arrowright_d1b6773c42",
                                                "ext": ".svg",
                                                "mime": "image/svg+xml",
                                                "size": 0.31,
                                                "url": "https://strapi-katapulk.s3.amazonaws.com/icon_arrowright_d1b6773c42.svg",
                                                "previewUrl": null,
                                                "provider": "aws-s3",
                                                "provider_metadata": null,
                                                "createdAt": "2023-05-09T00:37:38.393Z",
                                                "updatedAt": "2023-05-09T03:11:17.116Z"
                                            }
                                        }
                                    },
                                    "Route": {
                                        "data": {
                                            "id": 13,
                                            "attributes": {
                                                "Route": "cu/stores/directory",
                                                "createdAt": "2023-05-09T01:26:22.935Z",
                                                "updatedAt": "2023-06-06T15:57:08.005Z",
                                                "publishedAt": "2023-05-09T01:26:25.291Z"
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    }
                },
                "TopBar": {
                    "data": {
                        "id": 34,
                        "attributes": {
                            "InternalName": "Cuba dev",
                            "createdAt": "2023-06-13T17:54:51.449Z",
                            "updatedAt": "2023-11-29T20:59:24.303Z",
                            "publishedAt": "2023-06-13T17:54:53.145Z",
                            "locale": "es",
                            "HideStores": false,
                            "OtherLabel": "Recargas",
                            "Others": {
                                "data": [
                                    {
                                        "id": 15,
                                        "attributes": {
                                            "Label": "Recargas Cubacel",
                                            "Action": "Navigate",
                                            "createdAt": "2023-05-08T23:17:02.401Z",
                                            "updatedAt": "2023-12-19T04:31:27.219Z",
                                            "publishedAt": "2023-05-08T23:17:03.689Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": null,
                                            "Route": {
                                                "data": {
                                                    "id": 7,
                                                    "attributes": {
                                                        "Route": "cu/recargas-a-cuba",
                                                        "createdAt": "2023-05-09T01:24:38.511Z",
                                                        "updatedAt": "2023-05-09T01:24:40.214Z",
                                                        "publishedAt": "2023-05-09T01:24:40.209Z"
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "id": 199,
                                        "attributes": {
                                            "Label": "La Nave",
                                            "Action": "Navigate",
                                            "createdAt": "2023-09-29T16:51:21.345Z",
                                            "updatedAt": "2023-09-29T16:51:41.363Z",
                                            "publishedAt": "2023-09-29T16:51:23.104Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": true,
                                            "Route": {
                                                "data": {
                                                    "id": 199,
                                                    "attributes": {
                                                        "Route": "lanave/home",
                                                        "createdAt": "2023-09-29T16:46:09.062Z",
                                                        "updatedAt": "2023-09-29T16:46:10.471Z",
                                                        "publishedAt": "2023-09-29T16:46:10.466Z"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            "TrailingItems": {
                                "data": [
                                    {
                                        "id": 46,
                                        "attributes": {
                                            "Label": "Ayuda",
                                            "Action": "Navigate",
                                            "createdAt": "2023-05-09T02:54:33.641Z",
                                            "updatedAt": "2023-05-09T02:54:47.325Z",
                                            "publishedAt": "2023-05-09T02:54:35.213Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": null,
                                            "Route": {
                                                "data": {
                                                    "id": 1,
                                                    "attributes": {
                                                        "Route": "contents/faq",
                                                        "createdAt": "2023-05-09T01:22:32.833Z",
                                                        "updatedAt": "2023-05-09T01:22:34.405Z",
                                                        "publishedAt": "2023-05-09T01:22:34.396Z"
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "id": 44,
                                        "attributes": {
                                            "Label": "Sobre Katapulk",
                                            "Action": "Navigate",
                                            "createdAt": "2023-05-09T02:53:30.652Z",
                                            "updatedAt": "2023-05-09T02:53:52.227Z",
                                            "publishedAt": "2023-05-09T02:53:34.331Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": null,
                                            "Route": {
                                                "data": {
                                                    "id": 5,
                                                    "attributes": {
                                                        "Route": "contents/about-us",
                                                        "createdAt": "2023-05-09T01:24:04.606Z",
                                                        "updatedAt": "2023-05-09T01:24:06.425Z",
                                                        "publishedAt": "2023-05-09T01:24:06.416Z"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            "ExtraItems": {
                                "data": [
                                    {
                                        "id": 133,
                                        "attributes": {
                                            "Label": "Autos",
                                            "Action": "OpenExternalTab",
                                            "createdAt": "2023-08-25T18:12:41.645Z",
                                            "updatedAt": "2023-08-25T18:15:20.794Z",
                                            "publishedAt": "2023-08-25T18:12:43.232Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": false,
                                            "Route": {
                                                "data": {
                                                    "id": 133,
                                                    "attributes": {
                                                        "Route": "https://autos.katapulk.com",
                                                        "createdAt": "2023-08-25T18:11:20.526Z",
                                                        "updatedAt": "2023-08-25T18:11:22.523Z",
                                                        "publishedAt": "2023-08-25T18:11:22.497Z"
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "id": 100,
                                        "attributes": {
                                            "Label": "Remesas",
                                            "Action": "Navigate",
                                            "createdAt": "2023-06-27T16:54:14.797Z",
                                            "updatedAt": "2023-10-18T13:55:07.309Z",
                                            "publishedAt": "2023-06-27T16:54:16.305Z",
                                            "locale": "es",
                                            "Extra": null,
                                            "RequireLogin": true,
                                            "Route": {
                                                "data": {
                                                    "id": 67,
                                                    "attributes": {
                                                        "Route": "remittance/main",
                                                        "createdAt": "2023-06-27T16:33:48.494Z",
                                                        "updatedAt": "2023-06-27T16:33:50.199Z",
                                                        "publishedAt": "2023-06-27T16:33:50.124Z"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    ],
    "meta": {
        "pagination": {
            "start": 0,
            "limit": 1,
            "total": 2
        }
    }
};
