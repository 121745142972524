export interface Transaction {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  deletedAt: Date,
  receiverType: string,
  amount: number,
  description: string,
  date: Date,
}
