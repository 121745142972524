import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { faShoppingCart, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CartService } from "../../../../shared/services/cart.service";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { SelectedZone, Store, User } from "../../../../shared/models";
import { ZonesService } from "../../../../shared/services/zones.service";
import { MatDialog } from "@angular/material/dialog";
import { PhoneRechargeSelectionComponent } from "../phone-recharge-selection/phone-recharge-selection.component";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CutomQuantityModalComponent } from '../cutom-quantity-modal/cutom-quantity-modal.component';
import { AgeModalComponent } from '../age-modal/age-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "../../../../shared/services/auth.service";
import { ContinueBuyingDialogComponent } from "../../../../components/continue-buying-dialog/continue-buying-dialog.component";
import { LoginToAddCartDialogComponent } from "../login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import {NewCartService} from "../../../../shared/services/newcart.service";
import {
    ChangeLocationResponse,
    ChangeLocationValidationResponse,
    ExtraData
} from "../../../../shared/models/new-cart.model";
import {PendingOrdersConfirmationComponent} from "../pending-orders-confirmation/pending-orders-confirmation.component";
import {Router} from "@angular/router";
import {CheckoutEnum, NewCheckoutEnum} from "../../../../shared/enum/checkout.enum";
import {ErrorsEnum} from "../../../../shared/enum/errors.enum";
import {FRONTEND_FEATURE, FrontendFeatureService} from "../../../../shared/services/frontend-feature.service";
import {StorageService} from "../../../../shared/services/storage.service";
import * as moment from 'moment';
import {NotificationService} from "../../../../shared/services/notification.service";
import {environment} from "../../../../../environments/environment";
import {CommonKeysEnum} from "../../../../shared/enum/common.keys.enum";
import {ApiService} from "../../../../shared/services/api.service";
import {ShoppingListQueryParams} from "../../../../shared/models/shopping-list-query-params";

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
    @Input() productId: number = 0;
    @Input() imageUrl: string = '../../../../../assets/imgs/product-card/no-product-image.png';
    @Input() name: string = 'Jamón barra Bravo (2kg/ 4,4lb)';
    @Input() price: number = 0;
    @Input() currency: string = 'USD';
    @Input() isLowStock: boolean = true;
    @Input() quantity: number = 0;
    @Input() discount: any;
    @Input() variantId: any = 0;
    @Input() resize: boolean = false;
    @Input() turn: boolean = false;
    @Input() slug: any = '';
    @Input() shipping_category: string = "";
    @Input() data_extra_types: { id: number, value: string }[] = [];
    @Input() store: Observable<Store | undefined> | undefined;
    @Input() newStore?: any;
    @Input() top: number | undefined = 0;
    @Input() compare_at_price: number = 0;
    @Input() age_valid: boolean = false;
    @Input() small_image_url: string = '';
    @Input() product_type: number = 0;
    @Input() navigation: any;
    @Input() queryId: string | undefined;
    @Input() queryPosition: number | undefined;
    compare_top: any = 0;

    cart_icon = faShoppingCart;
    faSpinner = faSpinner;
    spin = false;
    isBlockAddToCard: boolean = false;
    blockAddToCardSubscription: any;
    needAuthentication: boolean = true;
    urlZoneDependant: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  user: User | undefined;

  verificationTimeInHours = environment.new_cart_verification_time_in_hours;
  checkCartDate: Date = this.getVerificationDate(this.verificationTimeInHours);
  CHECK_CART_DATE_KEY = CommonKeysEnum.CHECK_CART_DATE_KEY;
  queryParams: ShoppingListQueryParams = {};

  constructor(
    private cartService: CartService,
    private newCartService: NewCartService,
    private zonesService: ZonesService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private authService: AuthService,
    private router: Router,
    private frontendFeatureService: FrontendFeatureService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private apiService: ApiService
  ) {
    this.authService.currentUserSubject.pipe(takeUntil(this.destroy$)).subscribe((user) => {
        this.user = user;
        if (user) {
            if (this.user.useNewCart) {
                this.blockAddToCardSubscription = this.newCartService.isBlockAddToCard.subscribe((value: boolean) => {
                    this.isBlockAddToCard = value;
                });
            } else {
                this.blockAddToCardSubscription = this.cartService.isBlockAddToCard.subscribe((value: boolean) => {
                    this.isBlockAddToCard = value;
                });
            }
        }
    });
    }

    ngOnInit(): void {
        this.compare_top = this.top;
        this.quantity = 1;
        if (this.navigation) {
            this.needAuthentication = this.navigation.needAuthentication;
            this.urlZoneDependant = this.navigation.urlZoneDependant;
        }
        this.queryParams = this.queryId && this.user?.useNewCart ? {queryId: this.queryId, queryPosition: this.queryPosition} : {};

    }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    this.blockAddToCardSubscription?.unsubscribe();
  }

    increaseQuantity() {
        if (this.getValidSelectedZone) {
            if ((this.quantity < this.compare_top && this.compare_top !== null)) {
                this.quantity += 1;
            }
            if (this.compare_top == null) {
                this.quantity += 1;
            }
        }
    }

    decreaseQuantity() {
        if (this.quantity > 1 && this.getValidSelectedZone) this.quantity -= 1;
    }


    addToCart() {
        if (this.authService.isAuthenticated()) {
            if (this.quantity !== 0 && this.getValidSelectedZone) {
                if (this.data_extra_types?.length !== 0) {
                    this.showPhoneNumberModal();
                } else if (this.age_valid && !this.cookieService.get('valid_age')) {
                    this.showAgeModal();
                } else {
                    this.generalAddToCart();
                }
            }
        } else {
            this.dialog.open(LoginToAddCartDialogComponent, {
                width: '400px',
                disableClose: true,
                data: {
                    messageUppercase: true,
                    cancelAction: 'CANCEL',
                    confirmAction: 'GO_TO_LOGIN',
                    message: "TO_PERFORM_THIS_ACTION_YOU_MUST_LOG_IN",
                }
            });
        }
    }

  private getVerificationDate(timeAdd: number): Date {
      return moment(Date.now()).add(timeAdd, 'hours').toDate()
  }

  private generalAddToCart(data_extras?: ExtraData) {
    if (!this.isBlockAddToCard) {
      this.spin = true;
      if (this.user?.id) {
              this.checkCartDate = this.storageService.get(this.CHECK_CART_DATE_KEY);
              const biggerThan = this.checkCartDate ? moment(Date.now()).diff(this.checkCartDate) > 0 :  true;
              if (biggerThan) {
                  this.checkCartDate = this.getVerificationDate(this.verificationTimeInHours);
                  this.storageService.set(this.CHECK_CART_DATE_KEY, this.checkCartDate);
                  this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.ORDERS_CART_V3)
                      .pipe(takeUntil(this.destroy$))
                      .subscribe((response: any) => {
                          const featureData = response.features;
                          if (featureData.length && featureData[0].enabled) {
                              const user = {...this.authService.getCurrentUser(), ...{useNewCart: true}};
                                this.authService.saveUser(user);
                              const hasOldCart = this.cartService.haveCart();
                              if (hasOldCart) {
                                  this.cartService.emptyCart().subscribe(() => {
                                      this.notificationService.showAndSubscribe('UPDATED_CART_CONFIGURATION_TEXT', 'OK');
                                      // this.generalAddToCart(data_extras);
                                      const isCartUrl = this.router.url === `/${this.getSelectedZone.area_selected.toLowerCase() || 'cu'}/cart`;
                                      this.addItemToCart(data_extras, isCartUrl);
                                  });
                              } else {
                                  // this.generalAddToCart(data_extras);
                                  this.addItemToCart(data_extras);
                              }
                          }  else {
                              const user = {...this.authService.getCurrentUser(), ...{useNewCart: false}};
                              this.authService.saveUser(user);
                              const hasNewCart = this.newCartService.haveCart();
                              if (hasNewCart) {
                                  this.newCartService.cleanCart().subscribe(() => {
                                      this.notificationService.showAndSubscribe('UPDATED_CART_CONFIGURATION_TEXT', 'OK');
                                      // this.generalAddToCart(data_extras);
                                      const isCartUrl = this.router.url === `/${this.getSelectedZone.area_selected.toLowerCase() || 'cu'}/cart/new`;
                                      this.addToOldCartLogic(data_extras, isCartUrl);
                                  });
                              } else {
                                  // this.generalAddToCart(data_extras);
                                  this.addToOldCartLogic(data_extras);
                              }
                          }
                      });
              } else {
                  if (this.user.useNewCart) {
                      this.addItemToCart(data_extras);
                  } else {
                      this.addToOldCartLogic(data_extras);
                  }
              }
      }
    }
  }

  private addToOldCartLogic(data_extras?: ExtraData, isCartUrl: boolean = false) {
      if (this.cartService.haveCart()) {
          this.addItemToCart(data_extras);
      } else {
          this.cartService.blockAddToCardEvent(true);
          this.cartService.createCart(this.translateService.currentLang, this.getSelectedZone.municipality)
              .then(() => {
                  this.cartService.blockAddToCardEvent(false);
                  this.addItemToCart(data_extras, isCartUrl);
              })
              .catch(() => {
                  this.cartService.blockAddToCardEvent(false);
                  this.spin = false;
              });
      }
  }

  private addItemToCart(data_extras?: ExtraData, isCartUrl: boolean = false) {
      if (this.user?.id) {
          if (this.user?.useNewCart) {
              this.newCartService.addItemToCart({
                  variantId: String(this.variantId),
                  count: this.quantity,
                  zoneId: `${this.getSelectedZone.zone}`,
                  municipalityId: `${this.getSelectedZone.municipality}`,
                  extraData: data_extras? [data_extras] : [],
                  queryId: this.queryId,
                  queryPosition: this.queryPosition,
              })
                  .pipe(takeUntil(this.destroy$))
                  .subscribe({
                      next: (response) => {
                          this.spin = false;
                          this.quantity = 1
                          this.newCartService.blockAddToCardEvent(false);
                          if (!response.data && response.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                              this.showPendingOrderModal();
                          } else {
                              if (isCartUrl) {
                                  this.navigateToNewCart();
                              }
                          }
                      },
                      error: (error) => {
                          this.spin = false;
                          this.newCartService.blockAddToCardEvent(false);
                          if (error?.error?.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                              return this.showPendingOrderModal();
                          }
                          return this.apiService.handleErrors(error, true);
                      },
                      complete: () => {
                          this.spin = false;
                          this.newCartService.blockAddToCardEvent(false);
                      }
                  });
          } else {
              this.cartService.addItemToCart({
                  variant_id: this.variantId,
                  quantity: this.quantity,
                  zone_id: this.getSelectedZone.zone || 6,
                  data_extras
              }, this.translateService.currentLang)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe({
                      next: () => {
                          this.spin = false;
                          this.quantity = 1;
                          if (isCartUrl) {
                              this.navigateToOldCart();
                          }
                      },
                      complete: () => {
                          this.spin = false;
                      }
                  });
          }
      }
  }

    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    get getValidSelectedZone(): boolean {
        const selectedZone = this.getSelectedZone;
        if (selectedZone.area_selected) {
            if (selectedZone.area_selected === 'CU') {
                return !!selectedZone.zone && !!selectedZone.municipality;
            }
            return !!selectedZone.zone;
        }
        return false;
    }

  showPhoneNumberModal() {
    let dialoRef = this.dialog.open(PhoneRechargeSelectionComponent, {
      disableClose: false,
      position: { top: '60px' }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data.modified) {
            this.generalAddToCart({ id: this.data_extra_types[0].id, value: data.data });
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      });
  }

    setCustomQuantity() {
        if (this.data_extra_types?.length == 0 && this.getValidSelectedZone) {
            let dialoRef = this.dialog.open(CutomQuantityModalComponent, {
                disableClose: false,
                data: this.top
                // position: { top: '60px' }
            });
            dialoRef.afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: ((data: any) => {
                        (data !== null && data !== undefined) ? this.quantity = data : null;
                    }),
                    error: (err => {
                        throw new Error(err);
                    })
                });
        }
    }

    get getFirstAction() {
        return localStorage.getItem('first_action');
    }

    showAgeModal() {
        if (this.getFirstAction === 'done') {
            let dialoRef = this.dialog.open(AgeModalComponent, {
                disableClose: false,
                // position: { top: '60px' }
            });
            dialoRef.afterClosed()
                // .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: ((data: any) => {
                        if (data) this.cookieService.set('valid_age', 'true', {path: '/'});
                    }),
                    error: (err => {
                        throw new Error(err);
                    })
                });
        }
    }

  loadedImg(e: any) {
    let img = (e?.target as HTMLElement) as HTMLElement;
    let mask = (e?.target as HTMLElement)?.previousSibling as HTMLElement;
    img?.classList?.remove('opacity-0')
    img?.classList?.add('opacity-1');
    mask?.classList?.remove('opacity-70', 'min-h-100px');
    mask?.classList?.add('opacity-0');
  }

  showPendingOrderModal() {
    let dialoRef = this.dialog.open(PendingOrdersConfirmationComponent, {
      disableClose: false,
      // position: { top: '60px' }
    });
    dialoRef.afterClosed()
      // .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data?.success && data?.type ) {
              switch (data.type) {
                case 'payment':
                  // const paymentData = undefined;
                  // if (!paymentData) {
                    this.newCartService.removeCartValuesInStorage(() => {
                      localStorage.setItem(NewCheckoutEnum.PAYMENT, JSON.stringify({data: false}));
                      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/checkout/payment`]);
                    });
                  // }
                  break;
                case 'cart':
                    this.navigateToNewCart();
                  break;
                case 'home':
                  this.navigateToHome();
                  break;
              }
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      });
  }

  private navigateToHome() {
      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/`]);
  }

  private navigateToNewCart() {
      this.router.navigate(['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new']);
  }

  private navigateToOldCart() {
      this.router.navigate(['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart']);
  }


  navigateTo() {
      let url = this.navigation.webNavigation;

      if (this.urlZoneDependant) {
          url = `${this.getSelectedZone?.area_selected?.toLowerCase()}${url}`;
      }
      if (this.needAuthentication && !this.authService.isAuthenticated()) {
          this.router.navigate([`/auth/signIn`],
              {
                  // relativeTo: this.activeRoute,
                  queryParams: {
                      redirectURL: url
                  },
              }
          );
      } else {
          this.router.navigateByUrl(url);
      }
  }

  cardNavigation() {
      return this.product_type == 1 ? null :
          this.getSelectedZone?.area_selected?.toLowerCase() ? `/${this.getSelectedZone?.area_selected?.toLowerCase()}/products/${this.slug}` : `/cu/products/${this.slug}`;
  }
}
