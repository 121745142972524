import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from "../../../../shared/services/language.service";
import { Subscription } from "rxjs";
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit, OnDestroy {
  TITLE = null;
  TERMS = null;
  key: string = '';
  routeSubscription!: Subscription;

  constructor(
    private languageService: LanguageService,
    private dynamicSerice: DynamicCOnfigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(routeParams => {
      window.scroll({ top: 0 });
      this.TERMS = null;
      this.key = routeParams['id'];
      this.languageService.selectedLanguage.subscribe(() => {
        setTimeout(() => {
          this.getDynamicSettings(this.key);
        });
      })
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  getDynamicSettings(key: string) {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es'; // this.translateService.getBrowserLang();
      this.getGLobalConfig(language, key);
    } else {
      this.getGLobalConfig(language, key);
    }
  }

  getGLobalConfig(lang: string, content_key: string) {
    let TERMS_VARIABLES: any = [];
    let QUERY: any = null;
    const VARIABLES: any = { locale: lang, contentKey: content_key };
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      console.log(JSON.parse(aux));

      JSON.parse(aux)?.FRONT_SETTINGS?.data[0]?.attributes?.query_discoveries?.data?.forEach((query: any) => {
        if (query?.attributes?.Key == 'contents') {
          query?.attributes?.Query?.Variables.map((element: any) => {
            TERMS_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
          });
          TERMS_VARIABLES.push({ key: 'query_discovery_key', value: 'contents' });
          QUERY = query?.attributes?.Query?.Uri;
          this.dynamicSerice.doDynamicQuery(QUERY, TERMS_VARIABLES, undefined, undefined, true)
            .subscribe((data: any) => {
              this.TITLE = data?.data[0]?.attributes?.Title;
              this.TERMS = data?.data[0]?.attributes?.Content;
            });
        }
      });
    }
  }
}
