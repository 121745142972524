import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable, of, retry} from 'rxjs';
import { Taxonomy } from '../models';
import { ApiService } from './api.service';
import { AuthService } from "./auth.service";
import {catchError} from "rxjs/operators";
import {globalCategoriesMock} from "../mocks/global-categories";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private resource = '/global-categories';

  globalCategoriesSubject: BehaviorSubject<Taxonomy[]> = new BehaviorSubject<Taxonomy[]>([]);

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }


  getParentTaxonomies(lang: string): Observable<Taxonomy[]> {
    return this.apiService.get(this.resource, new HttpParams().set('locale', lang), false, this.getHeader(), true, true).pipe(
      map((taxonomiesRes) => {
        const parentTaxonomies = this.orderParenTaxonomies(taxonomiesRes.categories);
        this.globalCategoriesSubject.next(parentTaxonomies);
        return parentTaxonomies;
      }),
      catchError( (err: any): Observable<Taxonomy[]>  =>  {
        const categories = globalCategoriesMock.categories;
        const parentTaxonomies = this.orderParenTaxonomies(categories);
        this.globalCategoriesSubject.next(parentTaxonomies);
        return of(parentTaxonomies);
      }),
    );
  }

  /**
   * Ordenar los taxonomies teniendo en cuenta lo que llega de la api
   * @param taxonomies
   */
  private orderParenTaxonomies(taxonomies: any[]) {
    const parentTaxonomies: Taxonomy[] = [];
    taxonomies.forEach((taxonomy: any) => {
      const keys = Object.keys({ ...taxonomy });
      const t = taxonomy[keys[0]];
      const parent: Taxonomy = {
        id: t.category.id,
        name: t.category.name,
        parentId: null,
        depth: t.category.depth,
        permalink: t.category.permalink,
        children: []
      }
      parentTaxonomies.push(this.getAllOrderedParentChildren(t.children, parent));
    });
    return parentTaxonomies;
  }

  /**
   * Ordena los taxonomies en padres e hijos recursivamente
   * @param children
   * @param parent
   */
  private getAllOrderedParentChildren(children: any[], parent: Taxonomy): Taxonomy {
    if (children.length === 0) {
      return parent;
    }
    children.forEach((taxonomy) => {
      const keys = Object.keys({ ...taxonomy });
      const t = taxonomy[keys[0]];
      const child: Taxonomy = {
        id: t.category.id,
        name: t.category.name,
        parentId: parent.id,
        depth: t.category.depth,
        permalink: t.category.permalink,
        children: []
      }
      parent.children.push(child);
      this.getAllOrderedParentChildren(t.children, child);
    });
    return parent;
  }

  getFinalCategories(lang: string) {
    let categories: any = {
      "headers": [
        {
          "text": "ALL_CATEGORIES"
        }
      ],
      "links": []
    };
    this.getParentTaxonomies(lang).subscribe((data: any) => {
      data[0]?.children.forEach((element: any) => {
        categories.headers[0].text = data[0]?.name;
        categories.links = [
          ...categories.links,
          {
            "id": element?.id,
            "text": `${element?.name}`,
            "route": `/category/${element?.id}`,
            "links": element?.children?.length > 0 ? [...this.transformSubCategory(element?.children, element?.name)] : []
          },
        ]
      });
    });
    return categories;
  }

  transformSubCategory(sub_categories: any[], parent_name: string) {
    let result: any = [];
    sub_categories.forEach((sub_category: any, index: number) => {
      result = [
        ...result,
        {
          "id": sub_category?.id,
          "text": sub_category?.name,
          "route": `/category/${sub_category?.id}`,
          "headers": index == 0 ? [parent_name] : [],
          "links": sub_category?.children?.length > 0 ? [...this.transformSubCategory(sub_category?.children, sub_category?.name)] : []
        }
      ]
    });
    return result;
  }

  /**
   *
   * @param taxonomyId
   * @param lang
   */
  async getTaxonomyById(taxonomyId: number, lang: string): Promise<Taxonomy | null> {
    let taxonomy: Taxonomy | null = null;
    let allTaxonomies = this.globalCategoriesSubject.getValue();
    if (allTaxonomies.length === 0) {
      allTaxonomies = await new Promise((resolve) => {
        this.getParentTaxonomies(lang).subscribe((pt) => resolve(pt));
      });
    }

    for (let tax of allTaxonomies) {
      if (tax.id === +taxonomyId) {
        taxonomy = tax;
      } else {
        taxonomy = this.getTaxonomyInChildrenById(taxonomyId, tax);
      }
      if (taxonomy !== null) {
        break;
      }
    }
    return taxonomy;
  }

  private getTaxonomyInChildrenById(taxonomyId: number, parent: Taxonomy): Taxonomy | null {
    let taxonomy: Taxonomy | null = null;
    if (+taxonomyId === parent.id) {
      return parent;
    }
    if (parent.children.length === 0) {
      return taxonomy;
    }

    for (let tax of parent.children) {
      taxonomy = this.getTaxonomyInChildrenById(taxonomyId, tax);
      if (taxonomy !== null) {
        break;
      }
    }

    return taxonomy;
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    return this.authService.getHeaderNoRefresh();
  }

  addAmmountToCategory(facet_id: string, ammount: number, categories: any): any {
    let cats: any = [];
    categories.forEach((category: any) => {
      if (category.id == facet_id) {
        cats.push({
          "id": category?.id,
          "text": `${category?.text}`,
          "route": `/category/${category?.id}`,
          "ammount": ammount,
          // "links": category?.links?.length > 0 ? [...this.addAmmountToCategory(facet_id, ammount, category.links)] : []
        });
      } else {
        cats.push({
          "id": category?.id,
          "text": `${category?.text}`,
          "route": `/category/${category?.id}`,
          "ammount": 0,
          // "links": category?.links?.length > 0 ? [...this.addAmmountToCategory(facet_id, ammount, category.links)] : []
        });
      }
    });
    return cats;
  }
}
