import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  faClose = faClose;
  msg: string;
  btn_ok_text: string;
  btn_cancel_txt: string;
  redirect: string;
  disableClose: boolean;

  constructor(
    private dialogRef: MatDialogRef<NotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.msg = data.msg;
    this.btn_ok_text = data && data.btn_ok_text ? data?.btn_ok_text : 'OK';
    this.btn_cancel_txt = data && data.btn_cancel_txt ? data.btn_cancel_txt : undefined;
    this.redirect = data && data.redirect ? data.redirect : undefined;
    this.disableClose = data.disableClose
  }

  ngOnInit(): void {
  }

  close(withAction?: boolean) {
    if (this.redirect) {
      this.router.navigate([this.redirect]);
      this.dialogRef.close(withAction);
    } else {
      this.dialogRef.close(withAction);
    }
  }

  closeAndRedirect() {
    this.dialogRef.close(true);
  }

}
