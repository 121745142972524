<div class="relative bg-white box-border cursor-pointer flex flex-col justify-between
w-207px h-352px p-17px">
    <img class="absolute w-6 top-4 right-4 z-10" src="../../../../../assets/imgs/product-card/icon-wish.svg" alt="">
    <img class="relative w-full h-149px object-cover"
        src="{{imageUrl || '../../../../../assets/imgs/product-card/no-product-image'}}" alt=""
        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'products', slug]">
    <a class="relative w-full font-Lato-Regular text-blue-#18396C line-clamp-3
    text-sm leading-tight"
        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'products', slug]">
        {{name}}
    </a>
    <a class="relative w-full font-Lato-Regular text-gray-#212121 font-bold
        text-xl" [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'products', slug]">
        {{price | currency: currency}}
    </a>

    <div class="relative w-full flex justify-between items-center
    mt-3">
        <div class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border
        rounded-l-20px rounded-r-20px
        h-35px">
            <button (click)="decreaseQuantity()" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
                -
            </button>
            <p class="relative h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d
            w-38px text-19px">{{quantity}}</p>
            <button (click)="increaseQuantity()" class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
            text-25px w-38px">
                +
            </button>
        </div>
        <fa-icon class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full
        w-9 h-9 text- text-white" [icon]="cart_icon" (click)="addToCart()">
        </fa-icon>
    </div>
</div>