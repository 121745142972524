<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-gray-#f2f2f2
lg-landscape:min-h-screen">
    <div class="relative flex pb-5
    us:w-full px-5.3vw us:mt-2.5 us:flex-col
    us-landscape:w-full us-landscape:px-5.3vw us-landscape:flex-row us-landscape:mt-10
    md:w-full md:px-5.3vw md:flex-row md:mt-10
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:mt-90px">
        <div class="col relative h-auto flex justify-end items-center
        us:flex us:items-center us:w-full
        us-landscape:inline-flex us-landscape:w-2/4 us-landscape:pr-6
        md:inline-flex md:w-2/4 md:pr-6 md:ml-28
        md-landscape:ml-28
        lg-landscape:inline-flex lg-landscape:pr-0 lg-landscape:ml-28">
            <img class="relative
            lg-landscape:w-513px" [src]="url" alt="">
        </div>

        <div class="col relative h-auto flex flex-col justify-center items-start
        us:w-full us:pl-0 us:pb-7 us:mt-5
        us-landscape:w-2/4 us-landscape:pl-6 us-landscape:mt-0
        md:w-2/4 md:pl-6 md:mt-0
        lg-landscape:pl-14 lg-landscape:w-2/4">

            <p class="relative font-Lato-Regular font-semibold text-19px text-gray-#252525 leading-21px">
                {{'SORRY_CANT_FIND' | translate}}<br> {{'SORRY_CANT_FIND_SUB' | translate}}
            </p>
            <p class="realtive font-Lato-Regular font-semibold text-sm mt-4">
                {{'LOOKING_FOR_PRODUCT' | translate}}
            </p>
            <p class="realtive font-Lato-Regular text-sm">
                {{'TRY_USING_SEARCH' | translate}}<br> {{'OR_GO_TO' | translate}} <a
                    class="relative underline text-blue-#18396C" routerLink="/">{{'HOME_PAGE' | translate}}</a>.
            </p>

            <button routerLink="/"
                class="relative bg-white border-yellow-#FF6D03 px-4 border-2 px-35px font-Lato-Regular font-semibold text-yellow-#FF6D03 flex justify-center items-center
                us:w-auto us:h-38px us:rounded-3xl us:mt-6 us:text-sm
                lg-landscape:w-auto lg-landscape:h-38px lg-landscape:rounded-3xl lg-landscape:mt-6 lg-landscape:text-sm">
                {{'GO_BACK_TO_HOME' | translate}}
            </button>

            <p class="realtive font-Lato-Regular text-sm mt-5"><a class="relative underline text-blue-#18396C"
                    routerLink="/contents/customer-support">{{'CUSTOMER_ATT' | translate}}</a>.
            </p>
        </div>
    </div>

    <app-mercado-unico-grid></app-mercado-unico-grid>

</section>