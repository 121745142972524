import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ControlBase } from 'src/app/modules/shared/components/generic-forms/controls/controls-base';
import {ReceiverDataInterface} from "../../modules/remittance/interfaces/receiver.interface";

@Injectable({
  providedIn: 'root'
})
export class GenericFormsService {

  constructor() { }

  getValidators(control: any): any[] {
    const validators: any[] = [];
    if (control.required) {
      validators.push(Validators.required);
    }
    if (control.maxLength) {
      validators.push(Validators.maxLength(control.maxLength));
    }
    if (control.minLength) {
      validators.push(Validators.minLength(control.minLength));
    }
    if (control.regexValidator) {
      validators.push(Validators.pattern(control.regexValidator));
    }
    return validators;
  }

  toFormGroup(controls: any[], data: ReceiverDataInterface[] = []) {

    let group: any = {};

    if (controls.length > 0) {
      controls?.forEach((control) => {
        let value: any = '';
        if (data.length) {
          const controlValue = data.find(it => it.key === control.fieldId);
          if (controlValue) {
            value = controlValue.value
          }
        }

        if (control.nestedField) {
          group[control.fieldId] = new FormControl(value, this.getValidators(control));

          let valueNestedField: any = '';
          if (data.length) {
            const controlValueNestedField = data.find(it => it.key === control.nestedField.fieldId);
            if (controlValueNestedField) {
              valueNestedField = controlValueNestedField.value
            }
          }
          group[control.nestedField.fieldId] = new FormControl(valueNestedField, this.getValidators(control.nestedField));
        } else {
          if (control.receiverFormFieldType.toLowerCase() === 'date') {
            group[control.fieldId] = new FormControl(new Date(value), this.getValidators(control));
          } else if (control.receiverFormFieldType.toLowerCase() === 'boolean') {
            group[control.fieldId] = new FormControl(value != '' && value === 'true', this.getValidators(control));
          } else {
            group[control.fieldId] = new FormControl(value, this.getValidators(control));
          }
        }

        if (control.selectionDependantNestedField && control.selectionDependantNestedField.length && control.selectionDependantNestedField[0] &&
          control.selectionDependantNestedField[0].item2 && control.selectionDependantNestedField[0].item2.receiverFormFieldType !== 'SingleLineText') {
          const groupAux = this.toFormGroup([control.selectionDependantNestedField[0].item2], data);
          group = Object.assign(group, groupAux);
        }
      });
      return group;
    }
  }

  toFormSingle(control: any, data: any) {

    const group: any = {};

    if (control) {
      // group[control.key] = control.required
      //   ? new FormControl(data[control.key] || '', Validators.required)
      //   : new FormControl(data[control.key] || '')
      group[control.fieldId] = control.required
        ? new FormControl('', Validators.required)
        : new FormControl('')

      return group;
    }
  }
}
