<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white">
    <div
        class="relative
    us:pt-3 us:w-full us:pl-5.3vw us:pr-0
    us-landscape:pt-3 us-landscape:w-full us-landscape:pl-5.3vw us-landscape:pr-0
    md:pt-3 md:w-full md:pl-5.3vw md:pr-0
    md-landscape:w-full md-landscape:px-5.3vw
    lg:w-full lg:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px overflow-hidden box-border lg-landscape:h-auto lg-landscape:py-5">
        <div class="relative w-full h-full flex justify-start items-center overflow-hidden
        us:items-start us:pb-5 us:flex-col
        us-landscape:flex-row
        md:items-center md:flex-row
        md-landscape:
        lg:
        lg-landscape:items-center">
            <div class="relative
            us:order-2
            us-landscape:order-1
            md:order-1">

                <p class="relative font-Lato-Bold text-gray-#252525 font-bold
                us:text-3xl us:w-auto us:leading-10
                us-landscape:mt-10 us-landscape:w-378px
                md:w-401px
                md-landscape:text-40px md-landscape:w-501px md:leading-10 md-landscape:mt-0
                lg:
                lg-landscape:text-40px lg-landscape:w-591px lg-landscape:leading-48px">
                    {{'TRAVELING_TO_CUBA' | translate}} </p>

                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-3
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-4 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-4">
                    {{'TRAVEL_TEXT_1' | translate}}<br>
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    {{'TRAVEL_TEXT_2' | translate}}<br>
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    -{{'TRAVEL_TEXT_3' | translate}}.<br>
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    -{{'TRAVEL_TEXT_4' | translate}}.<br>
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    {{'TRAVEL_TEXT_5' | translate}}<br>
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    {{'TRAVEL_TEXT_6' | translate}} info@oncubatravel.com {{'TRAVEL_TEXT_7' | translate}}
                </p>
                <p class="relative text-gray-#5d5d5d font-Lato-Regular
                us:text-base us:leading-21px us:w-auto us:mt-2
                us-landscape:w-378px
                md:text-17px md:leading-21px md:mt-2 w-401px
                md-landscape:w-501px
                lg:
                lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:w-552px lg-landscape:mt-2">
                    {{'TRAVEL_TEXT_8' | translate}}
                </p>
            </div>

            <img class="relative h-auto
            us:w-280px us:-mr-0 us:ml-0 us:order-1
            us-landscape:w-406px us-landscape:ml-0 us-landscape:order-2
            md:w-395px md:ml-16 md:mr-0 md:order-2
            md-landscape:w-422px md-landscape:mr-0 md-landscape:ml-16
            lg:w-422px lg:mr-0
            lg-landscape:ml-45px lg-landscape:w-500px lg-landscape:mr-0"
                src="../../../../../assets/imgs/travel-img.jpg" alt="">
        </div>
    </div>
    <div class="relative w-full bg-gray-#f2f2f2 flex justify-center items-center">
        <div
            class="relative
                            us:w-full us:pl-5.3vw us:pr-5.3vw us:box-border us:overflow-hidden
                            us-landscape:
                            md:w-full md:pl-5.3vw md:pr-5.3vw md:box-border md:overflow-hidden
                            md-landscape:
                            lg:
                            lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:overflow-hidden lg-landscape:box-border">
            <div class="relative w-full h-auto
                                us:pt-11 us:pb-10
                                us-landscape:
                                md:pt-20 md:pb-20
                                md-landscape:
                                lg:
                                lg-landscape:pt-20 lg-landscape:pb-20">
                <p class="relative w-full text-center text-gray-#252525 font-Lato-Regular font-bold
                                    us:text-3xl us:leading-48px
                                    us-landscape:
                                    md:
                                    md-landscape:text-40px md:leading-48px
                                    lg:
                                    lg-landscape:text-40px lg-landscape:leading-48px h">
                    {{'CONSULAR_TRAVEL_SERVICES' | translate}}
                </p>
                <div class="relative w-full h-auto grid
                                    us:grid-cols-1 us:mt-2 us:gap-y-4
                                    us-landscape:grid-cols-2 us-landscape:gap-y-5 us-landscape:gap-x-5
                                    md:grid-cols-2 md:gap-x-5 md:mt-9
                                    md-landscape:grid-cols-3 md-landscape:gap-x-9 md-landscape:mt-9
                                    lg:
                                    lg-landscape:grid-cols-4 lg-landscape:gap-x-3 lg-landscape:mt-9">
                    <div
                        class="relative seller-card bg-white flex flex-col justify-between
                                        us:w-auto us:h-auto us:px-7 us:py-35px us:rounded-5px
                                        us-landscape:
                                        md:w-auto md:h-auto md:p-22px md:rounded-5px
                                        md-landscape:w-auto md-landscape:h-auto md-landscape:p-35px md-landscape:rounded-5px
                                        lg:
                                        lg-landscape:w-auto lg-landscape:min-h-299px lg-landscape:p-35px lg-landscape:rounded-5px">
                        <div class="relative">
                            <img class="relative
                                            us:w-36 us:h-auto
                                            us-landscape:w-98px us-landscape:h-92px
                                            md:w-98px md:h-92px
                                            md-landscape:
                                            lg:
                                            lg-landscape:w-98px lg-landscape:h-92px"
                                src="../../../../../assets/imgs/icon-passport.svg" alt="">
                            <p class="relative w-full text-gray-#252525 font-Lato-Regular font-semibold
                                        us:text-22px us:leading-30px us:mt-2
                                        us-landscape:
                                        md:text-22px md:leading-30px md:mt-4
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-25px lg-landscape:leading-30px lg-landscape:mt-4">
                                {{'TOURIST_VISA_CARD' | translate}}</p>
                            <p class="relative font-Lato-Regular text-gray-#727272 min-h-48px
                                        us:text-base us:mt-2
                                        us-landscape:
                                        md:text-base md:mt-3
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-base lg-landscape:mt-3 lg-landscape:pr-9">
                                {{'TOURIST_VISA_CARD_TEXT' |
                                translate}}</p>
                        </div>
                        <button routerLink="/travel-services/visa" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                            text-base w-135px h-41px rounded-3px px-3 mt-4 ab">
                            {{'SEE_MORE' | translate}}
                        </button>
                    </div>
                    <div
                        class="relative seller-card bg-white flex flex-col justify-between
                                        us:w-auto us:h-auto us:px-7 us:py-35px us:rounded-5px
                                        us-landscape:
                                        md:w-auto md:h-auto md:p-22px md:rounded-5px
                                        md-landscape:w-auto md-landscape:h-auto md-landscape:p-35px md-landscape:rounded-5px
                                        lg:
                                        lg-landscape:w-auto lg-landscape:min-h-299px lg-landscape:p-35px lg-landscape:rounded-5px">
                        <div class="relative">
                            <img class="relative
                                        us:w-36 us:h-auto
                                        us-landscape:w-98px us-landscape:h-92px
                                        md:w-98px md:h-92px
                                        md-landscape:
                                        lg:
                                        lg-landscape:w-98px lg-landscape:h-92px"
                                src="../../../../../assets/imgs/icon-passport.svg" alt="">
                            <p class="relative w-full text-gray-#252525 font-Lato-Regular font-semibold
                                        us:text-22px us:leading-30px us:mt-2
                                        us-landscape:
                                        md:text-22px md:leading-30px md:mt-4
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-25px lg-landscape:leading-30px lg-landscape:mt-4">Visa
                                He-11</p>
                            <p class="relative font-Lato-Regular text-gray-#727272 min-h-48px
                                        us:text-base us:mt-2
                                        us-landscape:
                                        md:text-base md:mt-3
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-base lg-landscape:mt-3 lg-landscape:pr-9">
                                {{'HE_11_CARD_TEXT'|translate}}
                            </p>
                        </div>

                        <button routerLink="/travel-services/he11" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                        text-base w-135px h-41px rounded-3px px-3 mt-4">
                            {{'SEE_MORE' | translate}}
                        </button>
                    </div>
                    <div
                        class="relative seller-card bg-white flex flex-col justify-between
                                        us:w-auto us:h-auto us:px-7 us:py-35px us:rounded-5px
                                        us-landscape:
                                        md:w-auto md:h-auto md:p-22px md:rounded-5px
                                        md-landscape:w-auto md-landscape:h-auto md-landscape:p-35px md-landscape:rounded-5px
                                        lg:
                                        lg-landscape:w-auto lg-landscape:min-h-299px lg-landscape:p-35px lg-landscape:rounded-5px">
                        <div class="relative">
                            <img class="relative
                                            us:w-36 us:h-auto
                                            us-landscape:w-98px us-landscape:h-92px
                                            md:w-98px md:h-92px
                                            md-landscape:
                                            lg:
                                            lg-landscape:w-98px lg-landscape:h-92px"
                                src="../../../../../assets/imgs/icon-passport.svg" alt="">
                            <p class="relative w-full text-gray-#252525 font-Lato-Regular font-semibold
                                            us:text-22px us:leading-30px us:mt-2
                                            us-landscape:
                                            md:text-22px md:leading-30px md:mt-4
                                            md-landscape:
                                            lg:
                                            lg-landscape:text-25px lg-landscape:leading-30px lg-landscape:mt-4">
                                {{'CUBAN_PASSPORT' | translate}}</p>
                            <p class="relative font-Lato-Regular text-gray-#727272 min-h-48px
                                            us:text-base us:mt-2
                                            us-landscape:
                                            md:text-base md:mt-3
                                            md-landscape:
                                            lg:
                                            lg-landscape:text-base lg-landscape:mt-3 lg-landscape:pr-9">
                                {{'CUBAN_PASSPORT_CARD_TEXT' | translate}}</p>
                        </div>
                        <button routerLink="/travel-services/passport" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                            text-base w-135px h-41px rounded-3px px-3 mt-4">
                            {{'SEE_MORE' | translate}}
                        </button>
                    </div>
                    <div
                        class="relative seller-card bg-white flex flex-col justify-between
                                        us:w-auto us:h-auto us:px-7 us:py-35px us:rounded-5px
                                        us-landscape:
                                        md:w-auto md:h-auto md:p-22px md:rounded-5px
                                        md-landscape:w-auto md-landscape:h-auto md-landscape:p-35px md-landscape:rounded-5px
                                        lg:
                                        lg-landscape:w-auto lg-landscape:min-h-299px lg-landscape:p-35px lg-landscape:rounded-5px">
                        <div class="relative">
                            <img class="relative
                                            us:w-36 us:h-auto
                                            us-landscape:w-98px us-landscape:h-92px
                                            md:w-98px md:h-92px
                                            md-landscape:
                                            lg:
                                            lg-landscape:w-98px lg-landscape:h-92px"
                                src="../../../../../assets/imgs/icon-passport.svg" alt="">
                            <p class="relative w-full text-gray-#252525 font-Lato-Regular font-semibold
                                            us:text-22px us:leading-30px us:mt-2
                                            us-landscape:
                                            md:text-22px md:leading-30px md:mt-4
                                            md-landscape:
                                            lg:
                                            lg-landscape:text-25px lg-landscape:leading-30px lg-landscape:mt-4">
                                {{'MIGRATORY' | translate}}</p>
                            <p class="relative font-Lato-Regular text-gray-#727272 min-h-48px
                                            us:text-base us:mt-2
                                            us-landscape:
                                            md:text-base md:mt-3
                                            md-landscape:
                                            lg:
                                            lg-landscape:text-base lg-landscape:mt-3 lg-landscape:pr-9">
                                {{'MIGRATORY_TEXT' | translate}}</p>
                        </div>
                        <button routerLink="/travel-services/migratory-processes" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                            text-base w-135px h-41px rounded-3px px-3 mt-4">
                            {{'SEE_MORE' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>