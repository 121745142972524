export interface SelectedZone {
    area_selected: string;
    area_name: string;
    zone: number;
    zone_name: string;
    municipality: number;
    municipality_name: string;
    countries_iso: string;
}

export interface SelectedZoneOther {
  area_selected: string;
  area_name: string;
  zone?: number;
  zone_name?: string;
  municipality?: number;
  municipality_name?: string;
  countries_iso?: string;
}
