import {
    Directive,
    OnInit,
    ElementRef,
    TemplateRef,
    ViewContainerRef,
    Input,
} from '@angular/core';
import { AuthService } from "../services/auth.service";

@Directive({
    selector: "[hasPermission]",
})
export class HasPermissionDirective implements OnInit {
    private permissionsKey: string | undefined;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {}

    ngOnInit() {
        // this.updateView();
    }

    @Input()
    set hasPermission(val: string) {
        this.permissionsKey = val;
        this.updateView();
    }

    private updateView() {
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;

        if (this.permissionsKey) {
            hasPermission = this.authService.hasPermission(this.permissionsKey);
        }

        return hasPermission;
    }
}
