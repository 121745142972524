<div class="relative flex flex-row w-auto sm:w-440px h-82px bg-white rounded-3px shadow-2xl">
  <fa-icon class="absolute flex items-start text-yellow-#FF6D03 text-13px sm:text-15px right-3 top-2 cursor-pointer" (click)="dismiss()" [icon]="faClose"></fa-icon>

  <div class="flex items-center pl-2 sm:pl-4">
    <img class="relative w-14 h-14 bg-gray-300 rounded-3px" alt="">
  </div>
  <div class="flex flex-col justify-center pl-2 sm:pl-4">
    <p class="font-Lato-Bold font-semibold uppercase text-gray-#363636 text-13px sm:text-15px">Guardado en la lista predeterminada</p>
    <p class="font-Lato-Regular underline relative text-blue-#18396C text-sm cursor-pointer">¿Lista de cambios? Click aquí</p>
  </div>
</div>
