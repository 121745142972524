import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-visa-iframe',
  templateUrl: './visa-iframe.component.html',
  styleUrls: ['./visa-iframe.component.scss']
})
export class VisaIframeComponent implements OnInit {
  show = false;
  link: any;

  constructor(
    private languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    window.scroll({ top: 0 });
    let iframe = document.querySelector("#child-iframe") as any;

    this.languageService.selectedLanguage.subscribe(lang => {
      lang ?
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://oncubatravel.com/${lang}/landing_visas/`) :
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://oncubatravel.com/landing_visas/`);
      this.show = true;
    });
  }

}
