<section
    class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5 us:mt-5 us:pt-5 lg-landscape:mt-0"
    *ngIf="grid">
    <div class="relative overflow-hidden
    us:w-full us:px-5.3vw
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-7">
        <div class="relative w-full flex justify-between items-center
        us:px-3
        lg-landscape:px-0 hover:cursor-pointer"
            [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'mercado-unico']">
            <p class="relative flex flex-row font-Lato-Regular font-semibold text-gray-#252525
            us:text-sm
            us-landscape:text-base
            md:text-base
                    lg-landscape:text-19px">{{grid?.Title | translate}}
                <!-- <span class="relative text-yellow-#ff4103 pl-1"
                    *ngIf="defaultStoreObject">{{defaultStoreObject?.name}}</span>
                <span class="relative flex flex-row animate-pulse pl-5 h-33px w-168px pt-1" *ngIf="!defaultStoreObject">
                    <span class="relative w-full my-2 bg-gray-#D4E1EE h-2"></span>
                </span> -->
            </p>
            <p (click)="navegateTo(grid?.ButtonWebNav)" class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold uppercase
            us:text-xs
            lg-landscape:text-sm">{{grid?.ButtonText | translate}} ></p>
        </div>
        <div class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 lg-landscape:gap-x-4
        mt-4">
            <app-product-card *ngFor="let product of loadedProducts" [productId]="product?.id"
                [imageUrl]="product?.imagesDto['image600']" [name]="product?.name" [price]="product?.defaultPrice" [slug]="product?.slug"
                [compare_at_price]="product?.previous_price" [currency]="product?.currency"
                [isLowStock]="product?.isLowStock" [quantity]="product?.quantity" [discount]="product?.discount"
                [variantId]="product?.defaultVariantId" [shipping_category]="product?.deliveryTime"
                [data_extra_types]="product?.extraData!" [resize]="true" [turn]="true" [top]="product?.top"
                [age_valid]="product?.ageValid">
            </app-product-card>
            <app-product-card-skeleton *ngFor="let skeleton of skeletons" [resize]="true" [turn]="true">
            </app-product-card-skeleton>
        </div>
    </div>
</section>
