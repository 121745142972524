import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-skeleton',
  templateUrl: './text-skeleton.component.html',
  styleUrls: ['./text-skeleton.component.scss']
})
export class TextSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
