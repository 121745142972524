export interface Card {
  id: number;
  type: string;
  cc_type: string;
  last_digits: string;
  month: string;
  year: number;
  name: string;
  default: boolean;
  gateway_customer_profile_id?: string;
  gateway_payment_profile_id?: string;
}

export interface CardNewIntegration {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  deletedAt: Date,
  cardComposedId: string,
  lastFour: string,
  expirationMonth: 0,
  expirationYear: 0,
  scheme: string,
  addressLine: string,
  firstName: string,
  lastName: string,
  type: string
}

export interface CardNewModel {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  deletedAt: Date,
  cardComposedId: string,
  lastFour: string,
  expirationMonth: 0,
  expirationYear: 0,
  scheme: string,
  addressLine: string,
  firstName: string,
  lastName: string,
  type: string,
  state: string,
  city: string,
  phoneNumber: string,
  countryPrefix: string,
  countryIso2: string,
  fullPhone: string,
  zipCode: string
}
