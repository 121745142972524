import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Card, CardNewIntegration, CardNewModel, City, Country, State} from "../../../../shared/models";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CardService} from "../../../../shared/services/card.service";
import {AddressService} from "../../../../shared/services/address.service";
import {CARD_PREDICTORS_INFO, CARD_TYPES} from "../../../../shared/enum/card-info.enum";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {WalletResponse} from "../../../../shared/models";
import {PaymentRoutesEnum} from "../../../../shared/enum/payment-routes.enum";

@Component({
  selector: 'app-create-payment-method',
  templateUrl: './create-payment-method.component.html',
  styleUrls: ['./create-payment-method.component.scss']
})
export class CreatePaymentMethodComponent implements OnInit {
  @ViewChild('section') section: any;
  token: string | null = null;
  is_human = false;
  isModeEdit = false;
  debitCardOnly = false;
  isRevalidate = false;
  isRevalidateCheckout = false;
  paymentCardId: string = '';
  card: CardNewModel | undefined = undefined;
  isLoading: boolean = false;
  cardLogo = '';
  largeMaxLength: number = 19;
  faSpinner = faSpinner;
  wrongDate: boolean = false;
  isFilterByDebits: boolean = false;
  cardPredictors = CARD_PREDICTORS_INFO;

  formMarkAsTouched: boolean = false;

  countries: Country[] = [];
  billingCountriesToHide: string[] = [];
  states: State[] = [];
  cities: City[] = [];
  allCountries: Country[] = [];
  postalCodes: string[] = [];
  isLoadingCountries: boolean = true;
  isLoadingAllCountries: boolean = true;
  isLoadingStates: boolean = true;
  isLoadingCities: boolean = true;
  showSelectInput: boolean = true;
  isLoadingPhones: boolean = true;

  countryCu: Country = {
    id: 51,
    iso: 'CU',
    iso3: 'CUB',
    iso_name: 'CUBA',
    name: 'CUBA',
    numcode: 192
  };
  countryUs: Country = {
    id: 0,
    iso: 'US',
    iso3: '',
    iso_name: '',
    name: '',
    numcode: 0
  };
  countryIsoSelected = 'us';
  countryPrefix = '+1';
  countryIso2 = 'CU';

  cardForm: FormGroup = new FormGroup({
    expiration: new FormControl(null, [Validators.required, Validators.pattern(/^\d{2}\/\d{2}$/)]),
    name: new FormControl(null, [Validators.required, Validators.pattern(/^(?!\s*$).+$/)]),
    lastname: new FormControl(null, [Validators.required, Validators.pattern(/^(?!\s*$).+$/)]),
    addressLine: new FormControl(null, Validators.required),
    number: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9\s]*$/),
      this.customValidator().bind(this)
    ]),
    verification_value: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
      Validators.minLength(3),
      Validators.maxLength(4)
    ]),
    cc_type: new FormControl(null, Validators.required),

    city: new FormControl(null, Validators.required),
    state_name: new FormControl(null, Validators.required),
    country_iso: new FormControl(null, Validators.required),
    zipcode: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/)]),
    phone: new FormControl(null, Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<CreatePaymentMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cardService: CardService,
    private addressService: AddressService,
  ) {
    this.isModeEdit = data.isModeEdit;
    this.debitCardOnly = data.debitCardOnly;
    this.card = this.isModeEdit ? data.data : null;
    this.isRevalidate = data.isRevalidate || false;
    this.isFilterByDebits = data.isFilterByDebits || false;
    this.isRevalidateCheckout = data.isRevalidateCheckout || false;
    this.billingCountriesToHide = data.billingCountriesToHide || [];
    if (this.isRevalidate) {
      this.isLoadingPhones = this.isLoadingAllCountries = this.isLoadingCountries = this.isLoadingCities = this.isLoadingStates = false;

      this.paymentCardId = data.paymentCardId;
      this.cardForm = new FormGroup({
        expiration: new FormControl(null, [
          Validators.required,
          Validators.pattern(/^\d{2}\/\d{2}$/)
        ]),
        number: new FormControl(null, [
          Validators.required,
          Validators.pattern(/^[0-9\s]*$/)
        ]),
        verification_value: new FormControl(null, [
          Validators.required,
          Validators.pattern(/^[0-9]*$/),
          Validators.minLength(3),
          Validators.maxLength(4)
        ]),
        cc_type: new FormControl(null, Validators.required),
      });
    } else if (this.isModeEdit) {
      this.cardForm = new FormGroup({
        id: new FormControl(this.card?.id, Validators.required),
        state_name: new FormControl(this.card?.state),
        addressLine: new FormControl(this.card?.addressLine, Validators.required),
        city: new FormControl(this.card?.city, Validators.required),
        zipcode: new FormControl(this.card?.zipCode, Validators.required),
        phone: new FormControl('+' + this.card?.countryPrefix + this.card?.phoneNumber, Validators.required),
        // country_iso: new FormControl(this.card?.countryIso2, Validators.required),
        country_iso: new FormControl(null, Validators.required),
      });
    }
  }

  captchaCapture(token: any) {
    this.token = token;
    this.is_human = true;
    let el: HTMLElement = this.section?.nativeElement;
    el?.click();
  }

  ngOnInit(): void {
    if (!this.isRevalidate) {
      this.getAllCountries();
    }
  }

  customValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
          let value = null;
          if (control.value) {
              value = control.value.replace(/\s/g, '');
          }
          if (value && (value.length < 13 || value.length > 19 || !this.checkLuhn(value))) {
              return { 'checkLuhnError': true };
          }
          return null;
      }
  }

  checkLuhn(cardNumber: string): boolean {
      let sum = 0;

      let isEven = false;
      for (let i = cardNumber.length - 1; i >= 0; i--) {
          let digit = Number(cardNumber[i]);

          if (isEven) {
              digit *= 2;
              if (digit > 9) {
                  digit -= 9;
              }
          }

          sum += digit;
          isEven = !isEven;
      }

      return sum % 10 == 0;
  }

  onSubmit(): void {
    this.cardForm.markAllAsTouched();
    this.formMarkAsTouched = true;
    if (this.cardForm.valid && ((!this.isModeEdit && this.is_human) || (this.isModeEdit))) {
      this.isLoading = true;
      if (!this.isRevalidate) {
        if (!this.isModeEdit) {
          const form = this.cardForm.value;
          const splitArray = form.expiration.split('/');
          const year = splitArray[1];
          const month = splitArray[0];
          this.setDisableForm();
          const cardData = {
            debitCardOnly: this.debitCardOnly,
            captchaToken: this.token,
            cardNumber: this.setNumberWithoutSpace(form.number),
            expMonth: month,
            expYear: year,
            cvv: form.verification_value,
            firstName: form.name.trim(),
            lastName: form.lastname.trim(),
            addressLine: form.addressLine,
            state: form.state_name,
            city: form.city,
            zipCode: form.zipcode,
            phoneNumber: form.phone.replace(`+${this.countryPrefix}`, ''),
            countryPrefix: this.countryPrefix,
            countryIso2: this.countryIsoSelected,
          };
          this.cardService.createCard(cardData).subscribe(this.manageOperationResponse());
        } else {
          const form = this.cardForm.value;
          this.setDisableForm();
          const cardData = {
            id: form.id,
            addressLine: form.addressLine,
            state: form.state_name,
            city: form.city,
            zipCode: form.zipcode,
            phoneNumber: form.phone.replace(`+${this.countryPrefix}`, ''),
            countryPrefix: this.countryPrefix,
            countryIso2: this.countryIsoSelected
          };
          this.cardService.updateCard(cardData).subscribe(this.manageOperationResponse());
        }
      } else {
        const form = this.cardForm.value;
        const splitArray = form.expiration.split('/');
        const year = splitArray[1];
        const month = splitArray[0];
        this.setDisableForm();
        const cardData = {
          cardNumber: this.setNumberWithoutSpace(form.number),
          expMonth: month,
          expYear: year,
          cvv: form.verification_value,
          paymentCardId: this.paymentCardId,
          paymentRoute: this.isRevalidate && !this.isRevalidateCheckout ? PaymentRoutesEnum.REVALIDATE_DEBIT : PaymentRoutesEnum.PAYMENT_INTENTION,
        };

        this.cardService.revalidateCard(cardData).subscribe(this.manageOperationResponse());
      }
    }
  }

  keyupNumberCard(e: any) {
    const numberWithoutSpace = this.setNumberWithoutSpace(e.target.value);
    this.setCardType(numberWithoutSpace);
    const data = this.validateFormat(e.target.value);
    if (data.length && data.length % 5 == 0) {
      const chartIndex = data.length - 1;
      if (data.charAt(chartIndex) != ' ') {
        const chartData = data.charAt(chartIndex);
        const firstFours = data.substr(0, chartIndex);
        e.target.value = firstFours + ' ' + chartData;
      } else {
        e.target.value = data.substr(0, chartIndex);
      }
    } else {
      e.target.value = data;
    }
  }

  validateFormat(data: string): string {
    let result = data.split(' ').join('');

    if (result.length > 4) {
      const chopInto = Math.ceil(result.length / 4);

      const arraySubstr = [];
      for (let i = 0; i < chopInto; i++) {
        if (i == chopInto - 1) {
          arraySubstr.push(result.substr(i * 4));
        } else {
          arraySubstr.push(result.substr(i * 4, 4));
        }
      }
      let dataResult = '';
      arraySubstr.forEach((it, index) => {
        if (index == 0) {
          dataResult = it;
        } else {
          dataResult = dataResult + ' ' + it;
        }
      });
      return dataResult;
    }

    return result;
  }

  setNumberWithoutSpace(targetValue: string): string {
    let result = targetValue.split(' ').join('');
    return result;
  }

  setCardType(targetValue: string) {
    let num = targetValue[0];
    // this.fillLarge(targetValue);
    if (targetValue.length == 1) {
      this.verifyCard(num);
    } else {
      for (let k = 1; k < targetValue.length; k++) {
        num += targetValue[k];
        this.verifyCard(num);
      }
    }
  }

  verifyCard(digits: any): any {
    let rangeStart;
    let rangeEnd;
    let number;
    for (let index = 0; index < this.cardPredictors.length; index++) {
      for (let k = 0; k < this.cardPredictors[index].startWith.length; k++) {
        if (this.cardPredictors[index].startWith[k].includes('-')) {
          rangeStart = this.cardPredictors[index].startWith[k].split('-')[0];
          rangeEnd = this.cardPredictors[index].startWith[k].split('-')[1];
          if (digits >= rangeStart && digits <= rangeEnd) {
            return this.setData(index);
          }
        }
      }
      for (let j = 0; j < digits.length; j++) {
        if (j == 0) {
          number = digits[j];
        } else {
          number += digits[j];
        }
        if (this.cardPredictors[index].startWith.indexOf(number) !== -1) {
          return this.setData(index);
        }
      }
    }
  }

  setData(index: number) {
    const type = this.cardPredictors[index].type;
    // @ts-ignore
    const i = CARD_TYPES[`${type}`];
    const maxLength = Math.max(...this.cardPredictors[index].lenghts);
    this.largeMaxLength = maxLength % 4 == 0 ? maxLength + (Math.floor(maxLength / 4) - 1) : maxLength + Math.floor(maxLength / 4);
    this.cardForm.patchValue({
      cc_type: CARD_TYPES[i]
    });
    this.cardLogo = `../../../../assets/imgs/create-card/${CARD_TYPES[i]}.svg`;
  }

  allowNumbersAndSlash(e: any): boolean {
    let result = false;
    const charCode = e.charCode;
    if (charCode != 0) {
      if (charCode < 48 || charCode > 57) {
        e.preventDefault();
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  keyupExpirationCard(e: any) {
    // if (e.target.value.length == 2) {
    //   e.target.value += '/';
    // }

    const date = this.cardForm.get('expiration')?.value;
    const month = date?.split('/')[0];
    const year = date?.split('/')[1];
    const actualMonth = new Date().getUTCMonth() + 1;
    const actualYear = new Date().getUTCFullYear().toString().substring(2);

    this.wrongDate = (month > 12 || month < actualMonth && year <= actualYear) || year < actualYear;

    if (e.target.value.length == 2 && e.key.toString() !== 'Backspace') {
      e.target.value += '/';
    }
  }

  close(response: any = null): void {
    this.dialogRef.close(response);
  }

  getAllCountries() {
    this.isLoadingAllCountries = true;
    this.addressService.getCountries().subscribe(countries => {
      this.allCountries = [...countries];
      if (this.billingCountriesToHide.length === 0) {
        this.countries = [...countries];
      } else {
        this.countries = [...countries]
          .filter(country => this.billingCountriesToHide.some((countryCode) => country.iso !== countryCode));
      }
      // this.isLoadingAllCountries = false;

      if (this.isModeEdit) {
        this.isLoadingAllCountries = false;
        this.isLoadingCountries = false;
        this.isLoadingPhones = false;
        const country = this.countries.find(it => it.iso.toUpperCase() === this.card?.countryIso2.toUpperCase());
        if (country) {
          this.cardForm.get('country_iso')?.patchValue(country.iso);

          this.isLoadingStates = true;
          this.showSelectInput = this.countryCu.iso == country.iso;
          this.countryIsoSelected = country.iso.toLowerCase();
          this.addressService.getStates(country.id).subscribe((statesRequest) => {
            this.states = [...statesRequest];

            this.isLoadingCities = true;
            const state = this.states.find(it => it.abbr === this.card?.state);
            if (state) {
              this.addressService.getCities(country.iso, state.abbr).subscribe(citiesRequest => {
                this.cities = [...citiesRequest];
                const city = this.cities.find(it => it.name === this.card?.city);
                if (city) {
                  this.postalCodes = city.zip_code;
                }
                this.isLoadingCities = false;
              });
            }
            this.isLoadingStates = false;
          });
        }
      } else {
        this.isLoadingCountries = false;
        this.isLoadingAllCountries = false;
        this.isLoadingStates = false;
        this.isLoadingCities = false;
        this.showSelectInput = false;
        this.isLoadingPhones = false;
      }
    });
  }

  getStates(country: Country) {
    this.isLoadingStates = true;
    this.addressService.getStates(country.id).subscribe(states => {
      this.states = states;
      this.isLoadingStates = false;
    });
  }

  getCities(countryIso: string, stateAbbr: string) {
    this.isLoadingCities = true;
    this.addressService.getCities(countryIso, stateAbbr).subscribe(cities => {
      this.cities = cities;
      this.isLoadingCities = false;
    });
  }

  changeCountry(event: any) {
    this.isLoadingStates = true;
    this.showSelectInput = this.countryCu.iso == event.value;
    this.cardForm.patchValue({
      state_name: null,
      city: null,
      zipcode: null,
      phone: null
    });
    const country = this.countries.find(it => it.iso === event.value);
    if (country) {
      this.countryIsoSelected = event.value.toLowerCase();
      this.changeViewPhone();
      this.addressService.getStates(country.id).subscribe((statesRequest) => {
        this.states = [...statesRequest];
        this.cities = [];
        this.postalCodes = [];
        this.isLoadingStates = false;
      });
    }
  }

  changeState(event: any) {
    this.isLoadingCities = true;
    this.cardForm.patchValue({
      city: null,
      zipcode: null,
    });
    const countryIso = this.cardForm.get('country_iso')?.value;
    const country = this.countries.find(it => it.iso === countryIso);
    if (country && this.countryCu.iso == countryIso) {
      this.addressService.getCities(countryIso, event.value).subscribe(citiesRequest => {
        this.cities = [...citiesRequest];
        this.postalCodes = [];
        this.isLoadingCities = false;
      });
    } else {
      this.isLoadingCities = false;
    }
  }

  changeCity(event: any) {
    this.cardForm.patchValue({
      zipcode: null,
    });
    const city = this.cities.find(it => it.name === event.value);
    if (city) {
      this.postalCodes = city.zip_code;
    } else {
      this.postalCodes = [];
    }
  }

  changePhone(event: any) {
    this.countryPrefix = event.dialCode;
  }

  changeViewPhone() {
    this.isLoadingPhones = true;
    setTimeout(() => {
      this.isLoadingPhones = false;
    }, 300);
  }

  setEnableForm() {
    this.cardForm.get('name')?.enable();
    this.cardForm.get('lastname')?.enable();
    this.cardForm.get('number')?.enable();
    this.cardForm.get('verification_value')?.enable();
    this.cardForm.get('expiration')?.enable();
    this.cardForm.get('addressLine')?.enable();
    this.cardForm.get('country_iso')?.enable();
    this.cardForm.get('state_name')?.enable();
    this.cardForm.get('city')?.enable();
    this.cardForm.get('zipcode')?.enable();
    this.cardForm.get('phone')?.enable();
  }

  setDisableForm() {
    this.cardForm.get('name')?.disable();
    this.cardForm.get('lastname')?.disable();
    this.cardForm.get('number')?.disable();
    this.cardForm.get('verification_value')?.disable();
    this.cardForm.get('expiration')?.disable();
    this.cardForm.get('addressLine')?.disable();
    this.cardForm.get('country_iso')?.disable();
    this.cardForm.get('state_name')?.disable();
    this.cardForm.get('city')?.disable();
    this.cardForm.get('zipcode')?.disable();
    this.cardForm.get('phone')?.disable();
  }

  get name() { return this.cardForm.get('name'); }
  get lastname() { return this.cardForm.get('lastname'); }
  get number() { return this.cardForm.get('number'); }
  get verification_value() { return this.cardForm.get('verification_value'); }
  get expiration() { return this.cardForm.get('expiration'); }
  get addressLine() { return this.cardForm.get('addressLine'); }
  get country_iso() { return this.cardForm.get('country_iso'); }
  get state_name() { return this.cardForm.get('state_name'); }
  get city() { return this.cardForm.get('city'); }
  get zipcode() { return this.cardForm.get('zipcode'); }
  get phone() { return this.cardForm.get('phone'); }

  manageOperationResponse() {
    return {
      next: (response: WalletResponse) => {
        this.setEnableForm();
        this.isLoading = false;
        if (response.success) {
          this.close(response.success);
        }
      },
      error: () => {
        this.isLoading = false;
        this.setEnableForm();
      },
      complete: () => {
        this.isLoading = false;
        this.setEnableForm();
      },
    }
  }
}
