export class Token {
  access_token?: string;
  refresh_token?: string;
  created_at?: number;
  expires_in?: number;


  // @ts-ignore
  constructor() {
    this.access_token = '';
    this.refresh_token = '';
    this.created_at = undefined;
    this.expires_in = undefined;
  }

  // @ts-ignore
  constructor(access_token: string, refresh_token: string, created_at: number, expires_in: number) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.created_at = created_at;
    this.expires_in = expires_in;
  }
}
