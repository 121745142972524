import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";
import {Address} from "../models";
import {HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Country} from "../models";
import {State} from "../models";
import {City} from "../models";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private resource = '/storefront/account/addresses'; // peticion para /api/v2
  private resourceCountries = '/countries'; // peticion para /api/v1
  private resourceStates = '/states'; // peticion para /api/v1
  private resourceCities = '/cities'; // peticion para /api/v1

  countries!: Country[];

  constructor(
    private apiService: ApiService,
  ) { }

  getAllAddresses() {
    const httpParams = new HttpParams()
      .set('per_page', 100)
      .set('sort', '-created_at');
    return this.apiService.get(this.resource, httpParams).pipe(
      map((addresses): Address[] => {
        return addresses.data.map((address: any): Address => {
          return this.mapAddressData(address);
        });
      })
    );
  }

  createAddress(address: Address) {
    return this.apiService.post(this.resource, {address}).pipe(
      map((address: any) => {
        return this.mapAddressData(address.data);
      })
    );
  }

  updateAddress(addressId: number, address: Address) {
    return this.apiService.patch(`${this.resource}/${addressId}`, {address}).pipe(
      map((address: any) => {
        return this.mapAddressData(address.data);
      })
    );
  }

  deleteAddress(addressId: number) {
    return this.apiService.delete(`${this.resource}/destroy_address`, new HttpParams().set('id', addressId));
  }

  mapAddressData(address: any): Address {
    return {
      id: address.id,
      firstname: address.attributes.firstname,
      lastname: address.attributes.lastname,
      address1: address.attributes.address1,
      address2: address.attributes.address2,
      city: address.attributes.city,
      zipcode: address.attributes.zipcode,
      phone: address.attributes.phone,
      state_name: address.attributes.state_name,
      company: address.attributes.company,
      country_name: address.attributes.country_name,
      country_iso3: address.attributes.country_iso3,
      country_iso: address.attributes.country_iso,
      label: address.attributes.label,
      state_code: address.attributes.state_code,
    }
  }

  /**
   * @description Obtener los paises
   */
  getCountries(): Observable<Country[]> {
    const httpParams = new HttpParams()
      .set('per_page', 300);
    if (this.countries && this.countries.length) {
      return of(this.countries);
    } else {
      return this.apiService.get(this.resourceCountries, httpParams, false).pipe(map(response => {
        this.countries = response.countries;
        return response.countries;
      }));
    }
  }

  getStateByCountryIdAndZoneId(countryId: number, zoneId: number): Observable<State> {
    const httpParams = new HttpParams()
      .set('per_page', 300)
      .set('zone_id', zoneId)
      .set('country_id', countryId)
      .set('q[available_for_shipping_true]', true);
    return this.apiService.get(this.resourceStates, httpParams, false).pipe(map(response => response.states[0]))
  }

  /**
   * @description Obtener los estados(provincias) a partir del id del pais
   * @param countryId
   */
  getStates(countryId: number): Observable<State[]> {
    const httpParams = new HttpParams()
      .set('per_page', 300)
      .set('country_id', countryId);
    return this.apiService.get(this.resourceStates, httpParams, false).pipe(map(response => response.states))
  }

  /**
   * @description Obtener las ciudades(municipios) a partir del iso del pais y la abreviatura del estado(provincia)
   * @param countryIso
   * @param state
   */
  getCities(countryIso: string, state: string): Observable<City[]> {
    const httpParams = new HttpParams()
      .set('per_page', 300)
      .set('country', countryIso)
      .set('state', state);
    return this.apiService.get(this.resourceCities, httpParams, false).pipe(
      map(response => {
        return response?.municipalities || []
      }))
  }
}
