import {Component, OnDestroy, OnInit} from '@angular/core';
import {NewCartService} from "../../../../shared/services/newcart.service";
import {Observable, Subject, tap} from "rxjs";
import {Order, Product, SelectedZone} from "../../../../shared/models";
import {faCancel, faShoppingCart, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LanguageService} from "../../../../shared/services/language.service";
import {TranslateService} from "@ngx-translate/core";
import {ProductsService} from "../../../../shared/services/products.service";
import {ZonesService} from "../../../../shared/services/zones.service";
import {takeUntil} from "rxjs/operators";
import {OrdersService} from "../../../../shared/services/orders.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Root} from "../../../../shared/models/new-cart.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pending-orders-confirmation',
  templateUrl: './pending-orders-confirmation.component.html',
  styleUrls: ['./pending-orders-confirmation.component.scss']
})
export class PendingOrdersConfirmationComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  isSaving = false;

  ordersArray: Order[] = [];

  actualPage: number = 0;
  totalPages: number = 0;
  listedOrders: number = 0;
  totalOrders: number = 0;
  perPage: number = 24;
  skeletons: number[] = Array(4);

  faSpinner = faSpinner;
  spinLoadMore = false;

  specialProductsObs!: Observable<Product[] | null>;
  loadingSpecialOffers = true;
  skeletonsSpecialOffers: number[] = Array(6);

  filterForm: FormGroup = new FormGroup({
    count: new FormControl(5, {
      validators: [Validators.required]
    }),
  });

  faShoppingCart = faShoppingCart;
  faCancel = faCancel;

  isConvertingToCart = false;
  pendingOrder: { storeOrderId: string } | undefined = undefined;

  constructor(
    private newCartService: NewCartService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private productsService: ProductsService,
    private zonesService: ZonesService,

    private orderService: OrdersService,
    private dialog: MatDialog, private dialogref: MatDialogRef<PendingOrdersConfirmationComponent>,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    // window.scroll({ top: 0 });
    this.isSaving = true;
    // @ts-ignore
    this.newCartService.getOrderPendingPayable()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: { data: {storeOrderId: string} }) => {
          this.pendingOrder = response.data;
          this.isSaving = false;
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  convertToCart() {
    this.isConvertingToCart = true;
    this.isSaving = true;
    this.newCartService.revertPlacementOrder(this.pendingOrder!.storeOrderId).subscribe({
      next: (response: Root) => {
        this.isConvertingToCart = false;
        this.isSaving = false;
        this.dialogref.close({success: response.success, type: 'cart'});
      },
      error: () => {
          this.isConvertingToCart = false;
          this.isSaving = false;
      }
    })
  }

  proceedToPayment() {
    this.dialogref.close({success: true, type: 'payment'});
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  cancelOrder() {
    // this.dialogref.close({success: false});
      this.isSaving = true;
      this.newCartService.cancelOrderPendingPayable(this.pendingOrder!.storeOrderId).subscribe({
          next: (response: Root) => {
              this.isSaving = false;
              this.dialogref.close({success: response.success, type: 'home'});
          },
          error: () => {
              this.isSaving = false;
          }
      })
  }

  navigateToOrdersList() {
    this.router.navigate([this.getSelectedZone.area_selected.toLowerCase(), 'profile', 'orders']);
    this.dialogref.close();
  }

}
