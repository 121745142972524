import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.scss']
})
export class QrGeneratorComponent implements OnInit {

  @Input() qrValue: any = '';
  @Input() title: string = 'K_WALLET';

  joinedValues = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    if (data) {
      this.qrValue = data.qrValue;
      this.title = data.title;
    }

    this.joinedValues = `KWallet: ${this.qrValue}`;

  }

  ngOnInit(): void {
  }

}
