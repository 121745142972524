import {Component, OnInit} from "@angular/core";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-save-to-default-list',
  templateUrl: './save-to-default-list.component.html',
  styleUrls: ['./save-to-default-list.component.scss']
})
export class SaveToDefaultListComponent implements OnInit {
  faClose = faClose;
  constructor(
    private _snackRef: MatSnackBarRef<SaveToDefaultListComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  dismiss(){
    this._snackRef.dismiss();
  }
}
