import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faClose } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-cutom-quantity-modal',
  templateUrl: './cutom-quantity-modal.component.html',
  styleUrls: ['./cutom-quantity-modal.component.scss']
})

export class CutomQuantityModalComponent implements OnInit {
  faClose = faClose;
  form: FormGroup;


  constructor(
    private dialogRef: MatDialogRef<CutomQuantityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = new FormGroup({
      quantity: new FormControl(null, { validators: [Validators.required, Validators.pattern('^[0-9]+$')] })
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.form?.get('quantity')?.addValidators(Validators.max(Number(this.data)));
      this.form.updateValueAndValidity();
    }
  }

  closeWithData() {
    if (this.form.valid && this.quantityValue > 0) {
      this.dialogRef.close(this.quantityValue);
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  get quantityValue() {
    return this.form?.get('quantity')?.value;
  }

  get quantityControl() {
    return this.form?.get('quantity');
  }

}
