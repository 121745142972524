import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-passport-iframe',
  templateUrl: './passport-iframe.component.html',
  styleUrls: ['./passport-iframe.component.scss']
})
export class PassportIframeComponent implements OnInit {
  show = false;
  link: any;

  constructor(
    private languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) {

  }
  ngOnInit(): void {
    window.scroll({ top: 0 });
    this.languageService.selectedLanguage.subscribe(lang => {
      lang ?
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.oncubatravel.com/${lang}/doc/add/NEW/`) :
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.oncubatravel.com/es/doc/add/NEW/`);
      this.show = true;
    });
  }
}
