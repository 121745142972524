<section id="sigIn" class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2
lg-landscape:min-h-screen">
    <div class="relative flex
    us:w-full px-5.3vw us:mt-2.5 us:flex-col
    us-landscape:w-full us-landscape:px-5.3vw us-landscape:flex-row us-landscape:mt-10
    md:w-full md:px-5.3vw md:flex-row md:mt-10
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:mt-90px">
        <div class="col relative w-2/4 h-auto flex justify-center items-start
        us:hidden
        us-landscape:inline-flex us-landscape:w-2/4 us-landscape:pr-6
        md:inline-flex md:w-2/4 md:pr-6
        lg-landscape:inline-flex lg-landscape:pr-0">
            <img class="relative
            lg-landscape:w-513px" src="../../../../../assets/auth/register-img.jpg" alt="">
        </div>

        <div class="col relative h-auto justify-center items-start
        us:w-full us:pl-0 us:pb-7
        us-landscape:w-2/4 us-landscape:pl-6
        md:w-2/4 md:pl-6
        lg-landscape:pl-14 lg-landscape:w-2/4">
            <p class="relative capitalize font-Lato-Regular font-semibold text-gray-#252525
            us:text-19px
                lg-landscape:text-19px">{{'RESET_PASS' | translate}}</p>

            <form class="relative grid grid-cols-1
            us:w-full us:mt-19px us:gap-y-4
                lg-landscape:w-334px lg-landscape:mt-7 lg-landscape:gap-y-4" action="" [formGroup]="reset_pass">

                <div class="relative w-full h-auto">
                    <input class="relative text-gray-#727272 w-full bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0
                              us:h-47px us:rounded-3px us:pl-4 us:text-base
                               lg-landscape:h-47px lg-landscape:rounded-3px lg-landscape:pl-4 lg-landscape:text-base"
                        type="email" placeholder="Email*" formControlName="email">
                    <p class="relative font-Lato-Regular text-sm text-red-300 h-3">
                        <span
                            *ngIf="this.reset_pass.controls['email'].hasError('required') && this.reset_pass.controls['email'].touched">
                            {{'REQUIRED' | translate}}
                        </span>
                        <span
                            *ngIf="this.reset_pass.controls['email'].hasError('pattern') && !this.reset_pass.controls['email'].hasError('required') && this.reset_pass.controls['email'].touched">
                            {{'WRONG_FORMAT' | translate}}
                        </span>
                    </p>
                </div>
            </form>

            <button [disabled]="reset_pass.invalid || spin" (click)="onSubmit()"
                [ngClass]="spin || reset_pass.invalid ? 'cursor-not-allowed' : ''"
                class="relative bg-yellow-#FF6D03 font-Lato-Regular font-bold text-white flex justify-center items-center
                us:w-40 us:h-49px us:rounded-3xl us:mt-6 us:text-19px
                lg-landscape:w-40 lg-landscape:h-49px lg-landscape:rounded-3xl lg-landscape:mt-6 lg-landscape:text-19px">
                <span *ngIf="!spin">{{'SUBMIT' | translate}}</span>
                <fa-icon *ngIf="spin"
                    class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                    [icon]="faSpinner">
                </fa-icon>
            </button>
        </div>
    </div>
</section>