import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Municipality} from "../models";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MunicipalitiesService {

  private resource = '/municipality'; // peticion para /api/v1

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {
  }

  getAllMunicipalities() {
    return this.apiService.get(`${this.resource}/get_all_municipalities`, new HttpParams(), false, this.getHeader());
  }

  getMunicipalitiesFromZoneIds(zoneableIds: number[]) {
    return this.getAllMunicipalities()
      .pipe(
        map((municipalities: any) => {
          let mappedMunicipalities: any[] = [];
          zoneableIds.forEach((zi) => {
            mappedMunicipalities = [...mappedMunicipalities, ...(municipalities[zi] ? municipalities[zi] : [])];
          });

          return <Municipality[]>mappedMunicipalities.map(mM => <Municipality>{id: mM.id, name: mM.name, stateId: mM.state_id}).sort((a, b) => a.name.localeCompare(b.name))
        })
      )
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    return  this.authService.getHeaderNoRefresh();
  }
}
