import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { MenuComponent } from './components/menu/menu.component';
import { MainComponent } from './components/main/main.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/slider/slider.component';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HasPermissionDirective } from "./shared/directives/has-permission.directive";
import { AuthService } from "./shared/services/auth.service";
import { ProductsService } from "./shared/services/products.service";
import { ApiService } from "./shared/services/api.service";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { ConfirmComponent } from "./modules/auth/auth_components/confirm/confirm.component";
import { PasswordRecoveryComponent } from './modules/auth/auth_components/password-recovery/password-recovery.component';
import { ConfirmPasswordRecoverComponent } from './modules/auth/auth_components/confirm-password-recover/confirm-password-recover.component';
import { StoreDetailComponent } from './modules/stores/components/store-detail/store-detail.component';
import { StoreDetailGridComponent } from './modules/stores/components/store-detail-grid/store-detail-grid.component';
import { StoreDetailMenuComponent } from './modules/stores/components/store-detail-menu/store-detail-menu.component';
import {CacheLocation, FingerprintjsProAngularModule} from "@fingerprintjs/fingerprintjs-pro-angular";
import { environment } from "../environments/environment";
import { TokenInterceptor } from "./shared/directives/token.interceptor";
import { SentryErrorHandler } from "./shared/sentry/sentry";
import {CachingInterceptor} from "./shared/directives/caching.interceptor";
import {ContinueBuyingDialogComponent} from "./components/continue-buying-dialog/continue-buying-dialog.component";
import {StoreInterceptor} from "./shared/directives/store.interceptor";
import {ClickOutsideDirective} from "./shared/directives/click-outside.directive";
import {
  StoresFiltersSortAndViewComponent
} from "./modules/stores/components/stores-filters-sort-and-view/stores-filters-sort-and-view.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MainComponent,
    FooterComponent,
    SliderComponent,
    ConfirmComponent,
    PasswordRecoveryComponent,
    ConfirmPasswordRecoverComponent,
    HasPermissionDirective,
    StoreDetailComponent,
    StoreDetailGridComponent,
    StoreDetailMenuComponent,
    StoresFiltersSortAndViewComponent,
    ContinueBuyingDialogComponent,
    ClickOutsideDirective
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ScullyLibModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FingerprintjsProAngularModule.forRoot({
      loadOptions: { apiKey: environment.fingerprint_api_key, endpoint: environment.fingerprint_endpoint },
      cacheLocation: CacheLocation.SessionStorage
    })
  ],
  providers: [
    AuthService, ApiService, ProductsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StoreInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // console.log('APP MODULE LOADED');
  }
}
