import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-age-modal',
  templateUrl: './age-modal.component.html',
  styleUrls: ['./age-modal.component.scss']
})
export class AgeModalComponent implements OnInit {
  ageForm: FormGroup;
  days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  years: any = [];
  is_older = false;
  show_error = false;
  min_age: number = 1900;


  constructor(private dialog: MatDialog, private dialogref: MatDialogRef<AgeModalComponent>) {
    this.ageForm = new FormGroup({
      day: new FormControl(null, [Validators.required]),
      month: new FormControl(null, [Validators.required]),
      year: new FormControl(null, [Validators.required]),
      agree: new FormControl(true),
    });
  }

  ngOnInit(): void {
    this.generateYears();
    this.ageForm.valueChanges.subscribe((data: any) => {
      this.is_older = false;
      if (this.ageForm.valid) { this.show_error = true; }
      if (this.ageForm.valid && !this.ageForm.value.agree) { this.show_error = false; }
      if (this.ageForm.valid && this.ageForm.value.agree) {
        var years = moment().diff(`${this.ageForm?.value?.year}-${this.ageForm?.value?.month}-${this.ageForm?.value?.day}`, 'years');
        if (years >= 21) this.is_older = true;
      }
    })
  }

  generateYears() {
    const theDates = [];
    let year: any = new Date().getFullYear();
    for (let i = this.min_age; i <= (year - 21); i++) {
      theDates.push(i);
    }
    this.years = theDates.reverse();
  }

  onSubmit() {
    this.dialogref.close(true);
  }

  close() {
    this.dialogref.close(false);
  }

}
