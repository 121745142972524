import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, retry} from 'rxjs';
import {catchError, map} from "rxjs/operators";

import {environment} from 'src/environments/environment';
import {AuthService} from "./auth.service";
import {LanguageService} from "./language.service";
import {FormGroup} from "@angular/forms";
import {ReceiverDataInterface} from "../../modules/remittance/interfaces/receiver.interface";
import {GenericFormsService} from "./generic-forms.service";
import {RemittanceStatusEnum} from "../../modules/remittance/enums/remittance-status.enum";
import {
    RemittanceConfirmInformationResponseInterface
} from "../../modules/remittance/interfaces/remittance-confirm-information.interface";

@Injectable({
    providedIn: 'root'
})
export class RemittanceService {
    private resource = '/Remittance/Countries';
    private receiver_resource = '/Remittance/Receivers';
    private template_resource = '/Remittance/Countries/Settings/DeliveryMethods';
    private checkUserVerificationUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/Start`;
    private editRemittanceUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/DataSelection`;
    private receiversUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Receivers`;
    private setReceiversUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/SetReceiver`;
    private remittanceByStatusUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance`;
    private remittanceInformation = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/Information`;
    private remittanceStepTemplate = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/NotificationStepTemplate`;
    private remittanceSetNotificationData = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/NotificationData`;
    private remittanceDetailsUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/Details`;
    private remittanceCancelUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/Cancel`;
    private remittancePdsUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/DownloadPdsPdf`;
    private remittanceReceiptUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/DownloadReceiptPdf`;
    private remittancePaymentDataUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/PaymentData`;
    private remittanceAddPromoCodeUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/AddPromocode`;
    private remittanceRemovePromoCodeUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/RemovePromocode`;
    private acceptedFraudWarningUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/Remittance/AcceptedFraudWarning`;
    private userWarningsUrl = `${environment.wallet_api_url}${environment.v1}/Remittance/UserWarnings`;

    localStorageFingerPrintEnum = 'fingerPrint';
    addReceiverForm!: FormGroup;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private authService: AuthService,
        private languageService: LanguageService,
        private genericFormsService: GenericFormsService
    ) {
    }

    applyPromoCode(promoCodeObj: { id: string, promocode: string, sendAmount: number, receiveAmount: number }) {
        return this.http.put(this.remittanceAddPromoCodeUrl, promoCodeObj, {headers: this.getHeader()});
    }

    removePromoCode(promoCodeObj: { id: string, sendAmount: number, receiveAmount: number }) {
        return this.http.put(this.remittanceRemovePromoCodeUrl, promoCodeObj).pipe(
            retry(environment.retry_on_fail),
        );
    }

    acceptedFraudWarning(id: string) {
        return this.http.post(this.acceptedFraudWarningUrl, {id}, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    downloadPds(remittanceId: string): Observable<any> {
        const httpParams = new HttpParams()
            .set('Id', remittanceId);
        const headersAux = this.getHeader();
        const headers = headersAux
            .set('Accept', 'application/pdf');
        return this.http.get(this.remittancePdsUrl, {params: httpParams, headers, responseType: 'blob' as 'json'});
    }

    downloadReceipt(remittanceId: string): Observable<any> {
        const httpParams = new HttpParams()
            .set('Id', remittanceId);
        const headersAux = this.getHeader();
        const headers = headersAux
            .set('Accept', 'application/pdf');
        return this.http.get(this.remittanceReceiptUrl, {params: httpParams, headers, responseType: 'blob' as 'json'});
    }

    generateForm(data: any[], receiverData: ReceiverDataInterface[] = []): FormGroup {
        const meta = data;
        let groups: any = [];
        let form: FormGroup;
        if (meta.length > 0) {
            meta?.forEach((section: any) => {
                groups = {...groups, ...this.genericFormsService.toFormGroup(section.section, receiverData)};
            });
            // form = new FormGroup({ ...groups });
            // form.updateValueAndValidity();
        }
        form = new FormGroup({...groups});
        return form;
    }

    getCountries(isV1: boolean) {
        const httpParams = new HttpParams()
            .set('PageSize', 100);
        return this.http.request('GET', `${environment.remittance_api_url}${isV1 ? environment.v1 : environment.v2}${this.resource}`, {
            params: httpParams, headers: this.getHeader()
        })
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getCountrySettings(isV1: boolean, id: string) {
        const httpParams = new HttpParams()
            .set('RemittanceCountryId', id);
        return this.http.request(
            'GET',
            `${environment.remittance_api_url}${isV1 ? environment.v1 : environment.v2}${this.resource}/Settings`,
            {params: httpParams, headers: this.getHeader()}
        )
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getReceiverTemplate(isV1: boolean, id: string) {
        return this.http.request(
            'GET',
            `${environment.remittance_api_url}${isV1 ? environment.v1 : environment.v2}${this.template_resource}/${id}/ReceiverTemplate`,
            {headers: this.getHeader()}
        )
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    cancelRemittanceById(id: string) {
        return this.http.post(this.remittanceCancelUrl, {id}, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getRemittanceDetailsById(id: string) {
        const httpParams = new HttpParams().set('Id', id);
        return this.http.get(this.remittanceDetailsUrl, {params: httpParams, headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getInformation(id: string): Observable<RemittanceConfirmInformationResponseInterface | any> {
        const httpParams = new HttpParams().set('Id', id);
        return this.http.get(this.remittanceInformation, {params: httpParams, headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getWarnings(): Observable<any> {
        return this.http.get(this.userWarningsUrl, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getRemittanceStepTemplate() {
        return this.http.get(this.remittanceStepTemplate, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    setNotificationData(body: any) {
        return this.http.put(this.remittanceSetNotificationData, body, {headers: this.getHeader()})
            .pipe(
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    setRemittancePaymentData(body: any) {
        return this.http.put(this.remittancePaymentDataUrl, body, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    addReceiver(isV1: boolean, body: any) {
        return this.http.request(
            'POST',
            `${environment.remittance_api_url}${isV1 ? environment.v1 : environment.v2}${this.receiver_resource}`,
            {
                body: body, headers: this.getHeader()
            }
        )
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    editReceiver(body: any) {
        return this.http.put(this.receiversUrl, body, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    removeReceiver(id: string) {
        return this.http.post(this.receiversUrl + '/HideForUser', {id}, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    setReceiver(remittanceId: string, receiverId: string, complianceFieldsDto: any[] = [], senderAddressFieldsDto: any[] = []) {
        return this.http.put(this.setReceiversUrl, {
            id: remittanceId,
            receiverId,
            complianceFieldsDto,
            senderAddressFieldsDto
        }, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getRemittanceByStatus(status: RemittanceStatusEnum, pageSize: number, currentPage: number) {
        let httpParams;
        if (status === RemittanceStatusEnum.INCOMPLETE) {
            httpParams = new HttpParams()
                .set('page', currentPage)
                .set('pageSize', pageSize)
                .set('sorts', '-createdAt');
        } else {
            httpParams = new HttpParams()
                .set('page', currentPage)
                .set('pageSize', pageSize)
                .set('sorts', '-transactionTime');
        }

        return this.http.get(this.remittanceByStatusUrl + '/' + status, {params: httpParams, headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getReceivers() {
        const httpParams = new HttpParams().set('PageSize', 100);
        return this.http.get(this.receiversUrl, {params: httpParams, headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    setRemittance(data: any) {
        return this.http.put(this.editRemittanceUrl, data, {headers: this.getHeader()})
            .pipe(
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    getRemittanceResume(isV1: boolean, data: any) {
        let httpParams: HttpParams = new HttpParams({fromObject: data});

        return this.http.request(
            'GET',
            `${environment.wallet_api_url}${isV1 ? environment.v1 : environment.v2}${this.resource}/Settings/Summary`,
            {params: httpParams, headers: this.getHeader()}
        )
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    calculateResume(data: any) {
        let httpParams: HttpParams = new HttpParams({fromObject: data});

        return this.http.request(
            'GET',
            `${this.remittanceByStatusUrl}/CalculatePriceAmount`,
            {params: httpParams, headers: this.getHeader()}
        )
            .pipe(
                map((response: any) => {
                    this.messageFromServer(response);
                    // this.blockUI.stop();
                    return response;
                }),
                retry(environment.retry_on_fail),
                catchError((error: any) => this.apiService.handleErrors(error, true))
            );
    }

    checkUserVerification(data: any) {
        return this.http.post(this.checkUserVerificationUrl, data, {headers: this.getHeader()});
    }


    private messageFromServer(response: any): void {
        if (response?.msg_success) {
            // this.notificationService.success(response.msg_success, true);
            window.scrollTo(0, 0);
        }

        if (response?.msg_info) {
            // this.notificationService.info(response.msg_info, true);
            window.scrollTo(0, 0);
        }

        if (response?.msg_warn) {
            // this.notificationService.warn(response.msg_warn, true);
            window.scrollTo(0, 0);
        }

        if (response?.msg_error) {
            // this.notificationService.error(response.msg_error, true);
            window.scrollTo(0, 0);
        }
    }

    private getHeader(): HttpHeaders {
        let httpHeaders = this.authService.getHeader();
        const lang = this.languageService.selectedLanguage.getValue();
        httpHeaders = httpHeaders
            .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', lang);
        return httpHeaders;
    }

    getFingerPrintFromSession() {
        return JSON.parse(<string>localStorage.getItem(this.localStorageFingerPrintEnum));
    }
}
