export const CARD_PREDICTORS_INFO = [
  { lenghts: [15], startWith: ["34", "37"], type: 'AMERICANEXPRESS' },
  { lenghts: [14], startWith: ["300", "301", "302", "303", "304", "305"], type: 'DINERSCLUBCARTEBLANCHE' },
  { lenghts: [14], startWith: ["36"], type: 'DINERSCLUBINTERNATIONAL' },
  { lenghts: [16, 19], startWith: ["6011", "622126-622925", "644", "645", "646", "647", "648", "649", "65"], type: 'DISCOVER' },
  { lenghts: [16], startWith: ["637", "638", "639"], type: 'INSTAPAYMENT' },
  { lenghts: [16, 19], startWith: ["3528-3589"], type: 'JCB' },
  { lenghts: [16, 19], startWith: ["5018", "5020", "5038", "5893", "6304", "6759", "6761", "6762", "6763"], type: 'MAESTRO' },
  { lenghts: [16], startWith: ["51", "52", "53", "54", "55", "222100-272099"], type: 'MASTERCARD' },
  { lenghts: [16, 17, 18, 19], startWith: ["62"], type: 'UNIONPAY' },
  { lenghts: [13, 16, 19], startWith: ["4"], type: 'VISA' },
  { lenghts: [16], startWith: ["4026", "417500", "4508", "4844", "4913", "4917"], type: 'VISAELECTRON' },
];

export enum CARD_TYPES{
  AMERICANEXPRESS,
  DINERSCLUBCARTEBLANCHE,
  DINERSCLUBINTERNATIONAL,
  DISCOVER,
  INSTAPAYMENT,
  JCB,
  MAESTRO,
  MASTERCARD,
  UNIONPAY,
  VISA,
  VISAELECTRON
};
