<div *ngIf="control" [ngSwitch]="control.receiverFormFieldType" class="relative w-full">
    <app-text-input *ngSwitchCase="'SingleLineText'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-text-input>
    <app-date-input *ngSwitchCase="'Date'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-date-input>
    <app-dropdown *ngSwitchCase="'select'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-dropdown>
    <app-dropdown *ngSwitchCase="'DefaultSingleSelect'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-dropdown>
    <app-dropdown *ngSwitchCase="'SingleSelectWithNestedField'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-dropdown>
<!--    <app-dropdown *ngSwitchCase="'SingleSelectWithSelectionDependantNestedField'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-dropdown>-->
    <app-dropdown-with-selection-dependant *ngSwitchCase="'SingleSelectWithSelectionDependantNestedField'" [selectedReceiverData]="selectedReceiverData"
                                           [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched">
    </app-dropdown-with-selection-dependant>
    <app-yes-no-input *ngSwitchCase="'YesNo'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-yes-no-input>
    <app-multiline-input *ngSwitchCase="'MultiLineText'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-multiline-input>
    <app-boolean-input *ngSwitchCase="'Boolean'" [meta]="control" [form]="form" [formMarkAsTouched]="formMarkAsTouched"></app-boolean-input>
</div>
